<template>
  <div>
    <v-app-bar color="#10242B"
	  dense
      dark
      app
      fixed
      clipped-left
      class="custm-pd0 mynavbar"
      style="z-index: 9;"
    >
      <div>
        <v-btn class="rounded-btn" @click="$router.go(-1)" :ripple="false">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
      </div>
      <v-menu left offset-y bottom>
        <template v-slot:activator="{}">
          <v-layout align-center>
            <p
              style="text-transform: capitalize"
              class="mt-5 ml-2 font-weight-regular body-2"
            >
              {{
                headeTitle
              }}
            </p>
            <v-divider class="mx-2" vertical></v-divider>
            <span class="mr-1">{{ alwaysretain(computeaccess).txt }}</span>
            :
            <v-icon class="controlvicon ml-1"
              >{{ alwaysretain(computeaccess).icon }}
            </v-icon>
          </v-layout>
        </template>
        <div>
          <v-list class="overflow-y-auto" max-height="400">
            <v-list-item
              v-for="(item, index) in datas"
              :key="index"
              @click="headerData()"
            >
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </div>
      </v-menu>
      <v-spacer></v-spacer>
      <p
        class="text-uppercase title font-weight-bold font-italic text-xs-center mt-5 mediaqueryclaimtext pclaimMarginright"
        name="Claims"
        @click="changeOldMenuView"
      >
        Claimsdrive
      </p>

      <v-spacer></v-spacer>

      <v-menu
        left
        offset-y
        nudge-top="-25"
        nudge-left="14"
        bottom
        transition="slide-y-transition"
        content-class="carret-menu white"
        :close-on-content-click="false"
      >
        <template v-slot:activator="{ attrs, on }">
          <v-btn
            class="user-btn"
            dark
            :ripple="false"
            elevation="0"
            height="0"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-apps</v-icon>
          </v-btn>
        </template>
        <div>
          <v-list class="lob-actions">
            <v-list-item
              v-for="(lobName, index) in lobList"
              :key="index"
              @click="redirectLob(lobName)"
            >
              <v-list-item-title style="color:#10242B">
                <v-row no-gutters>
                  <v-col cols="1" class="mr-3">
                    <img src="../assets/images/health_icon.png" height="20px" width="20px" v-if="lobName=='We Care'" />
                    <img src="../assets/images/Travel_PA.png" height="20px" width="20px" v-else-if="['Travel', 'PA','Travel PA'].includes(lobName)" />
                    <img src="../assets/images/WeCAN_Tata AIG_20220915.png" height="20px" width="20px" v-else-if="['Marine', 'AIGC','Commercial Lines'].includes(lobName)" />
                    <img src="../assets/images/Logo_Personal_Lines.png" height="20px" width="20px" v-else-if="['EW','Personal Lines'].includes(lobName)" />
                    <img src="../assets/images/lob.png" height="23x" width="20px" v-else />
                  </v-col>
                  <v-col align-self="center" cols="10">
                    <span v-if="lobName !== 'Common Modules' ">{{ lobName }}</span>
                    <span class="color-font" v-else >{{ lobName }}</span>
                  </v-col>
                </v-row>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </div>
      </v-menu>
      <v-btn
        icon
        @click="onNotificationHandler"
        :class="
          this.toggleNotifications
            ? 'notification-btn show-count'
            : 'notification-btn'
        "
      >
        <div class="pending-notifications-circle" v-if="notificationCount">
          <span
            :class="
              this.toggleNotifications
                ? 'notifications-count shown'
                : 'notifications-count'
            "
            >{{ notificationCount }}</span
          >
        </div>
        <v-icon class="iconSizeHeader">mdi-bell</v-icon>
      </v-btn>

      <v-menu
        left
        offset-y
        nudge-top="-25"
        bottom
        transition="slide-y-transition"
        content-class="carret-menu teal-arrow"
        :close-on-content-click="false"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="user-btn"
            dark
            :ripple="false"
            elevation="0"
            height="0"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon class="user_logo">mdi-account</v-icon>
            <div class="user ml-5">
              <p>
                {{
                  $store.state &&
                  $store.state.Auth &&
                  $store.state.Auth.user &&
                  $store.state.Auth.user.signInUserSession &&
                  $store.state.Auth.user.signInUserSession.idToken &&
                  $store.state.Auth.user.signInUserSession.idToken.payload &&
                  $store.state.Auth.user.signInUserSession.idToken.payload
                    .employeeName
                    ? $store.state.Auth.user.signInUserSession.idToken.payload
                        .employeeName
                    : ""
                }}
              </p>
              <span
                >NTID:
                {{
                  $store.state &&
                  $store.state.Auth &&
                  $store.state.Auth.user &&
                  $store.state.Auth.user.signInUserSession &&
                  $store.state.Auth.user.signInUserSession.idToken &&
                  $store.state.Auth.user.signInUserSession.idToken.payload &&
                  $store.state.Auth.user.signInUserSession.idToken.payload
                    .ntidUserId
                    ? $store.state.Auth.user.signInUserSession.idToken.payload
                        .ntidUserId
                    : ""
                }}</span
              >
            </div>
            <v-icon dark right>
              mdi-menu-down
            </v-icon>
          </v-btn>
        </template>
        <div class="menu_dropdown">
          <div class="menu_header">
            <v-layout justify-space-between align-center class="pb-2">
              <h4 class="pa-0 ma-0" style="font-weight : 500;">
                {{
                  $store.state &&
                  $store.state.Auth &&
                  $store.state.Auth.user &&
                  $store.state.Auth.user.signInUserSession &&
                  $store.state.Auth.user.signInUserSession.idToken &&
                  $store.state.Auth.user.signInUserSession.idToken.payload &&
                  $store.state.Auth.user.signInUserSession.idToken.payload
                    .employeeName
                    ? $store.state.Auth.user.signInUserSession.idToken.payload
                        .employeeName
                    : ""
                }}
              </h4>
              <v-icon x-small color="#23B1A9">mdi-pencil </v-icon>
            </v-layout>
            <div class="text-small">
              User Id :
              {{
                $store.state &&
                $store.state.Auth &&
                $store.state.Auth.user &&
                $store.state.Auth.user.signInUserSession &&
                $store.state.Auth.user.signInUserSession.idToken &&
                $store.state.Auth.user.signInUserSession.idToken.payload &&
                $store.state.Auth.user.signInUserSession.idToken.payload
                  .ntidUserId
                  ? $store.state.Auth.user.signInUserSession.idToken.payload
                      .ntidUserId
                  : ""
              }}
            </div>
            <div class="text-small">Email Id : {{userData.email}}</div>
            <div class="text-small">Last Login : {{ userData.lastLogin }}</div> 
          </div>
          <div class="pt-2 white">
            <div class="px-4">
              <label class="form-label">SWITCH ROLE</label>
              <v-select
                class="form-control"
                label=""
                :items="roleItems"
                v-model="selectedRole"
                dense
                solo
              ></v-select>
            </div>
            <v-divider></v-divider>
            <!-- <div class="px-4">
                  <label class="form-label">SWITCH LOB</label>
                  <v-select class="form-control" label="" :items="lobList" v-model="selectedLob" @change="redirectLob()" dense solo></v-select>
                </div> -->
            <v-divider></v-divider>
            <div class="px-4">
              <v-btn icon color="#23B1A9">
                <v-icon>mdi-cog-outline</v-icon> </v-btn
              >Settings
            </div>
            <!-- <div class="px-4">
                  <v-btn @click="redirectForResetLob()">
                     Set Default LOB
                  </v-btn>
                </div> -->
          </div>
          <div class="pt-2 white">
            <div class="px-4">
              <label class="form-label">SET DEFAULT APPLICATION</label>
              <v-select
                :items="items"
                :label="currentDefaultLob"
                @click="redirectForResetLob()"
                readonly
                dense
                class="form-control"
                solo
              >
              <template v-slot:append>
                <v-icon class="tableicons" @click="redirectForResetLob()">mdi-arrow-right-circle</v-icon>
              </template>

              </v-select>
            </div>
            <!-- <div class="px-4">
              <label class="form-label">SET DEFAULT APPROVAL MASTER</label>
              <v-select
                :items="items"
                label="AIG Master"
                dense
                class="form-control"
                solo
              ></v-select>
            </div> -->
            <v-divider></v-divider>
            <div class="px-4" style="cursor:pointer;">
                <!-- @click="$router.push('/markuseroffline')" -->
              <v-btn
                icon
                color="#23B1A9"
                class="my-2"
                
              >
                <v-icon color="#23B1A9">mdi-reply-all</v-icon> </v-btn
              >Offline Responder
            </div>
          </div>
          <div
            class="px-4 py-2"
            dark
            style="background-color: #10242B; color: #fff;cursor: pointer;"
            @click="logout()"
          >
            <v-btn icon color="#fff"> <v-icon>mdi-export</v-icon> </v-btn>Logout
          </div>
        </div>
      </v-menu>
    </v-app-bar>
    <!-- new LHS menu by Health team   v-if="!oldmenu" -->
    <v-navigation-drawer
      v-if="!oldmenu"
      v-click-outside="theoutside"
      fixed
      app
      dark
      clipped
      :permanent='true'
      expand-on-hover
      :mini-variant.sync="mini"
      class="thems_color"
      disable-resize-watcher
      dense
      width="390px">
      <div class="" style="margin-top: -10px!important;" @click.stop="mini = !mini" v-if="mini===true">
        <img src="../assets/Right_arrow.png" style="width: 40px;height: 40px;position: relative;top: 10px;"/>
      </div>
      <div class="" style="margin-top: -10px!important;" v-else @click.stop="mini = !mini">
        <img src="../assets/Left_arrow.png" style="width: 40px;height: 40px;position: relative;top: 10px;"/>
      </div>
    <!-- @mouseover="mouseOver(index)"
                @mouseout="mouseOut" -->
      <div v-for="(sb, index) in side_barmenu" :key="index">
          <v-divider></v-divider>

           <div v-if="sb.side_menu" >
            <v-list-group v-if="sb.hasOwnProperty('tile')" no-action dense  
                prepend-icon="mdi-account-box-multiple-outline"   
                >
                <v-list-item slot="activator">
                        
                        <v-list-item-title  style="font-size: 0.8125rem;font-weight: 500;line-height: 1rem;">
                          {{sb.tile}}
                        </v-list-item-title>
                </v-list-item>
          
                <v-list-item   
                  v-for="(child, chindex) in sb.childern" 
                  :key="chindex" 
                  dense 
                  :to="child.path" 
                 >
                    <v-list-item-title >{{child.screenName}}</v-list-item-title>
                </v-list-item>
            
           </v-list-group>

            <v-list  v-else dense class="ma-0 pa-0">
                <v-list-item :to="sb.path">
                  <v-list-item-icon class="mr-4">
                  <div class="rtooltip" >
                    <!-- <img v-if="checkicon_img(sb.health_icon)" :src="sb.health_icon" style="width: 19px;height: 19px"/> -->
                    <v-img v-if="checkicon_img(sb.health_icon)" v-bind:src="require('../assets/MenuLeftBar/' + checkicon_img(sb.health_icon))" width="19" height="19">
                                </v-img>
                    <!-- <v-icon v-if="!checkicon_img(sb.health_icon)" style="width: 19px;height: 19px;"> {{sb.health_icon}} </v-icon> -->
                    <v-icon v-if="!checkicon_img(sb.health_icon)" style="width: 19px;height: 19px;">
                        <!-- sb.health_icon.img ?  sb.health_icon.img : -->
                        {{ sb.health_icon ? sb.health_icon : 'mdi-information' }}
                    </v-icon>
                    
                  </div> 
                  </v-list-item-icon>
        
                  <v-list-item-content>
                    <v-list-item-title> {{sb.screenName}} </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
            </v-list>
            </div>
      </div>
   </v-navigation-drawer>

    <!-- original menu is below   v-if="oldmenu"-->
    <v-navigation-drawer
      v-if="oldmenu"
      v-click-outside="theoutside"
      fixed
      app
      dark
      clipped
      :permanent='true'
      :mini-variant.sync="mini"
      class="thems_color"
       disable-resize-watcher
      dense>

      <div class="" style="margin-top: -10px!important;" @click.stop="mini = !mini" v-if="mini===true">
        <img src="../assets/Right_arrow.png" style="width: 40px;height: 40px;position: relative;top: 10px;"/>
      </div>
      <div class="" style="margin-top: -10px!important;" v-else @click.stop="mini = !mini">
        <img src="../assets/Left_arrow.png" style="width: 40px;height: 40px;position: relative;top: 10px;"/>
      </div>
      <v-divider></v-divider>
      <v-list dense class="ma-0 pa-0">
        
          <v-list-item to="/home">
            <v-list-item-icon class="mr-4">
             <div>
              <img src="../assets/homepage.png" style="width: 19px;height: 19px"/>
            </div>
            </v-list-item-icon>
  
            <v-list-item-content>
              <v-list-item-title>Home</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
      </v-list>
        <v-divider></v-divider>

        <v-list dense class="ma-0 pa-0">
        
          <v-list-item to="/OFRdashboard">
            <v-list-item-icon class="mr-4">
             <div>
              <img src="../assets/ProviderRegistration.png" style="width: 19px;height: 19px"/>
            </div>
            </v-list-item-icon>
  
            <v-list-item-content>
              <v-list-item-title>OFR Dashboard</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
      </v-list>
        <v-divider></v-divider>

        <v-list dense class="ma-0 pa-0">
        
          <v-list-item to="/CFRdashboard">
            <v-list-item-icon class="mr-4">
             <div>
              <img src="../assets/ProviderSearch.png" style="width: 19px;height: 19px"/>
            </div>
            </v-list-item-icon>
  
            <v-list-item-content>
              <v-list-item-title>CFR Dashboard</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
      </v-list>
        <v-divider></v-divider>

        <v-list dense class="ma-0 pa-0" v-if="superUsermnushw ||deomnushw||rvwermnushw||PvdrMngtTmmnushw">
          <v-list-item to="/ProviderSearch">
            <v-list-item-icon class="mr-4">
             <div>
              <img src="../assets/ProviderSearch.png" style="width: 19px;height: 19px"/>
            </div>
            </v-list-item-icon>
  
            <v-list-item-content>
              <v-list-item-title>Provider Search</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider v-if="superUsermnushw||deomnushw||rvwermnushw||PvdrMngtTmmnushw"></v-divider>

        <v-list dense class="ma-0 pa-0" v-if="superUsermnushw ||deomnushw||rvwermnushw||PvdrMngtTmmnushw">
        
          <v-list-item 
            to="/Providerregistration">
            <v-list-item-icon class="mr-4">
             <div>
              <img src="../assets/ProviderRegistration.png" style="width: 19px;height: 19px"/>
            </div>
            </v-list-item-icon>
  
            <v-list-item-content>
              <v-list-item-title>Provider Registration</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider v-if="superUsermnushw||deomnushw||rvwermnushw||PvdrMngtTmmnushw"></v-divider>

        <v-list dense class="ma-0 pa-0" v-if="superUsermnushw || rvwermnushw">
            <!-- active-class="actvMenu" -->
              <v-list-item  to="/providerRegistrationAppQue">
                <v-list-item-icon class="mr-4">
                <div>
                  <v-icon style="width: 19px;height: 19px;">mdi-account-multiple</v-icon>
                </div>
                </v-list-item-icon>
      
                <v-list-item-content>
                  <v-list-item-title>Provider Registration Approval</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
        <v-divider v-if="superUsermnushw || rvwermnushw"></v-divider>

        <v-list dense class="ma-0 pa-0" v-if="superUsermnushw || approver || rvwermnushw || PvdrMngtTmmnushw || networkMangRole || networkExeRole">
            <!-- active-class="actvMenu" -->
              <v-list-item  to="/providerEmpanelmentReview">
                <v-list-item-icon class="mr-4">
                <div>
                  <v-icon style="width: 19px;height: 19px;">mdi-account-multiple</v-icon>
                </div>
                </v-list-item-icon>
      
                <v-list-item-content>
                  <v-list-item-title>Provider Empanelment Review</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
        </v-list>

        <v-divider v-if="superUsermnushw || rvwermnushw || PvdrMngtTmmnushw || approver || networkMangRole || networkExeRole"></v-divider>
           
        
        <v-list dense class="ma-0 pa-0" v-if="superUsermnushw || approver || rvwermnushw || PvdrMngtTmmnushw || networkMangRole || networkExeRole">
            <!-- active-class="actvMenu" -->
              <v-list-item  to="/providerEmpanelmentApprovalQueue">
                <v-list-item-icon class="mr-4">
                <div>
                  <v-icon style="width: 19px;height: 19px;">mdi-account-multiple</v-icon>
                </div>
                </v-list-item-icon>
      
                <v-list-item-content>
                  <v-list-item-title>Provider Empanelment Approval</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
        <v-divider v-if="superUsermnushw || rvwermnushw || PvdrMngtTmmnushw || approver || networkMangRole || networkExeRole"></v-divider>

        
        <v-list dense class="ma-0 pa-0" v-if="superUsermnushw || rvwermnushw || PvdrMngtTmmnushw || networkMangRole">
            <!-- active-class="actvMenu" -->
              <v-list-item  to="/approvalContractDetials">
                <v-list-item-icon class="mr-4">
                <div>
                  <v-icon style="width: 19px;height: 19px;">mdi-account-multiple</v-icon>
                </div>
                </v-list-item-icon>
      
                <v-list-item-content>
                  <v-list-item-title>Approval & Contract Details</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
        <v-divider v-if="superUsermnushw || rvwermnushw || PvdrMngtTmmnushw || networkMangRole"></v-divider>



         <v-list dense class="ma-0 pa-0" v-if="superUsermnushw || rvwermnushw">
            <!-- active-class="actvMenu" -->
              <v-list-item  to="/TDSWorkItem">
                <v-list-item-icon class="mr-4">
                <div>
                  <v-icon style="width: 19px;height: 19px;">mdi-account-multiple</v-icon>
                </div>
                </v-list-item-icon>
      
                <v-list-item-content>
                  <v-list-item-title>TDS Review Queue</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
        <v-divider v-if="superUsermnushw || rvwermnushw"></v-divider>

         <v-list dense class="ma-0 pa-0" v-if="superUsermnushw || rvwermnushw || deomnushw || PvdrMngtTmmnushw || networkMangRole || networkExeRole">
            <!-- active-class="actvMenu" -->
              <v-list-item  to="/TDSDetials">
                <v-list-item-icon class="mr-4">
                <div>
                  <v-icon style="width: 19px;height: 19px;">mdi-file-document-edit</v-icon>
                </div>
                </v-list-item-icon>
      
                <v-list-item-content>
                  <v-list-item-title>TDS Details Capture</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
        <v-divider v-if="superUsermnushw || rvwermnushw || deomnushw || PvdrMngtTmmnushw || networkMangRole || networkExeRole"></v-divider>

       <!-- <v-list dense class="ma-0 pa-0" v-if="superUsermnushw">
          <v-list-item to="/providersummary">
            <v-list-item-icon class="mr-4">
             <div>
              <img src="../assets/ProviderSummaryInstitution.png" style="width: 19px;height: 19px"/>
            </div>
            </v-list-item-icon>
  
            <v-list-item-content>
              <v-list-item-title>Provider Summary</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-divider v-if="superUsermnushw"></v-divider> -->

        <v-list dense class="ma-0 pa-0" v-if="superUsermnushw||PvdrMngtTmmnushw">
          <v-list-item  to="/bulkuploadclaims">
            <v-list-item-icon class="mr-4">
            <v-icon style="width: 19px;height: 19px">publish</v-icon>
            </v-list-item-icon>
  
            <v-list-item-content>
              <v-list-item-title>Provider - Bulk Upload</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-divider v-if="superUsermnushw||PvdrMngtTmmnushw"></v-divider>

        <v-list dense class="ma-0 pa-0" v-if="adminmnushw">
          <v-list-item  to="/productsetupapprovalprocess">
            <v-list-item-icon class="mr-4">
             <div>
             <v-icon style="width: 19px;height: 19px">local_mall</v-icon>
            </div>
            </v-list-item-icon>
  
            <v-list-item-content>
              <v-list-item-title>Product setup</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-divider v-if="adminmnushw"></v-divider>

         <v-list dense class="ma-0 pa-0" v-if="adminmnushw">
          <v-list-item  to="/createsearchreporting">
            <v-list-item-icon class="mr-4">
             <div>
             <v-icon style="width: 19px;height: 19px">assignment</v-icon>
            </div>
            </v-list-item-icon>
  
            <v-list-item-content>
              <v-list-item-title>Create / Search Reporting Class</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-divider v-if="adminmnushw"></v-divider>

        <v-list dense class="ma-0 pa-0" v-if="adminmnushw">
         
          <v-list-item  to="/createsearchproducttype">
            <v-list-item-icon class="mr-4">
             <div>
             <v-icon style="width: 19px;height: 19px; transform: rotate(180deg);">style</v-icon>
            </div>
            </v-list-item-icon>
  
            <v-list-item-content>
              <v-list-item-title>Create / Search Product Type</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      <v-divider v-if="adminmnushw"></v-divider>
        
        <v-divider v-if="superUsermnushw"></v-divider>
  
        <v-list dense class="ma-0 pa-0"  v-if="superUsermnushw || approver || rvwermnushw|| PvdrMngtTmmnushw|| networkExeRole|| networkMangRole || deomnushw">
          <v-list-item  to="/providerempanelment">
            <v-list-item-icon class="mr-4">
             <div>
              <img src="../assets/providerempanelment.png" style="width: 19px;height: 19px"/>
            </div>
            </v-list-item-icon>
  
            <v-list-item-content>
              <v-list-item-title>Provider Empanelment</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-divider v-if="superUsermnushw || approver || deomnushw ||  networkMangRole || networkExeRole"></v-divider>

        <v-list dense class="ma-0 pa-0" v-if="adminmnushw">
         
          <v-list-item  to="/product/createSearchProduct" >
            <v-list-item-icon class="mr-4">
             <div>
             <v-icon style="width: 19px;height: 19px;">find_in_page</v-icon>
            </div>
            </v-list-item-icon>
  
            <v-list-item-content>
              <v-list-item-title>Create / Search Product</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-divider v-if="adminmnushw"></v-divider>
        <v-list dense class="ma-0 pa-0" v-if="adminmnushw">
         <!-- active-class="actvMenu" -->
          <v-list-item  to="/vwwrkitmcases">
            <v-list-item-icon class="mr-4">
             <div>
              <v-icon style="width: 19px;height: 19px;">today</v-icon>
            </div>
            </v-list-item-icon>
  
            <v-list-item-content>
              <v-list-item-title>View My Work Items/Cases</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        

        <v-divider v-if="adminmnushw"></v-divider>

        <v-list dense class="ma-0 pa-0" v-if="superUsermnushw || networkMangRole">
         <!-- active-class="actvMenu" -->
          <v-list-item  to="/quickEmpanelment">
            <v-list-item-icon class="mr-4">
             <div>
              <v-icon style="width: 19px;height: 19px;">restore_page</v-icon>
            </div>
            </v-list-item-icon>
  
            <v-list-item-content>
              <v-list-item-title>Provider Quick Empanelment</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        

          <v-divider v-if="superUsermnushw || networkMangRole"></v-divider>
          <v-list-group no-action dense v-if="superUsermnushw || networkMangRole" prepend-icon="public">
                <v-list-item slot="activator">
                  <v-list-item-title v-text="'Network Management'" style="font-size: 0.8125rem;font-weight: 500;line-height: 1rem;"></v-list-item-title>
                </v-list-item>

                <v-list-item dense to="/NetworkSearch">
                  <v-list-item-title v-text="'Network Search'"></v-list-item-title>
                </v-list-item>
                <v-list-item dense to="/NetworkSetup">
                  <v-list-item-title v-text="'Network Setup'"></v-list-item-title>
                </v-list-item>
                <!-- <v-list-item dense to="/add-provider">
                  <v-list-item-title v-text="'Add Providers'"></v-list-item-title>
                </v-list-item> -->

          </v-list-group>
          <v-divider v-if="superUsermnushw || networkMangRole"></v-divider>
          <v-list-group no-action dense v-if="superUsermnushw || networkMangRole" prepend-icon="payment"  >
                <v-list-item slot="activator">
                  <v-list-item-title v-text="'Tariff Management'" style="font-size: 0.8125rem;font-weight: 500;line-height: 1rem;"></v-list-item-title>
                </v-list-item>

                <v-list-item dense to="/search-tarrif">
                  <v-list-item-title v-text="'Tariff Search'"></v-list-item-title>
                </v-list-item>
                <v-list-item dense to="/CreateTariffPage">
                  <v-list-item-title v-text="'Tariff Setup'"></v-list-item-title>
                </v-list-item>
                <v-list-item dense to="/tarrifbulkuploadlogs">
                  <v-list-item-title v-text="'Tariff Bulk Upload Logs'"></v-list-item-title>
                </v-list-item>

          </v-list-group>
          <v-divider v-if="superUsermnushw || networkMangRole"></v-divider>
          <v-list dense class="ma-0 pa-0" v-if="superUsermnushw || approver">
          <!-- active-class="actvMenu" -->
            <v-list-item  to="/tariffapprovalqueue">
              <v-list-item-icon class="mr-4">
              <div>
                <v-icon style="width: 19px;height: 19px;">assessment</v-icon>
              </div>
              </v-list-item-icon>
    
              <v-list-item-content>
                <v-list-item-title>Tariff Approval Queue </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
      <v-divider v-if="superUsermnushw || approver"></v-divider>
      <!-- v-if="superUsermnushw || networkMangRole" -->
      
          <v-list-group no-action dense  prepend-icon="mdi-account-box-multiple-outline"  >
                <v-list-item slot="activator">
                  <v-list-item-title v-text="'User Management'" style="font-size: 0.8125rem;font-weight: 500;line-height: 1rem;"></v-list-item-title>
                </v-list-item>

                <v-list-item dense to="/permissionsearch">
                  <v-list-item-title v-text="'Permission Search'"></v-list-item-title>
                </v-list-item>
                <v-list-item dense to="/permissioncreation">
                  <v-list-item-title v-text="'Permission Creation'"></v-list-item-title>
                </v-list-item>

                <v-list-item dense to="/RoleSearch">
                  <v-list-item-title v-text="'Role Search'"></v-list-item-title>
                </v-list-item>
                <v-list-item dense to="/RoleCreate">
                  <v-list-item-title v-text="'Role Creation'"></v-list-item-title>
                </v-list-item>

                <v-list-item dense to="/SettlementOffice">
                  <v-list-item-title v-text="'Settlement Office Creation Master'"></v-list-item-title>
                </v-list-item>

                <!-- <v-list-item dense to="/FALimitMaster">
                  <v-list-item-title v-text="'Financial Authority Limit Master'"></v-list-item-title>
                </v-list-item> -->
                <v-list-item dense to="/FALimitMasterSearch">
                  <v-list-item-title v-text="'Financial Authority Limit Master Search'"></v-list-item-title>
                </v-list-item>
                <v-list-item dense to="/FAApproval">
                  <v-list-item-title v-text="'Financial Level Approval'"></v-list-item-title>
                </v-list-item>
                <v-list-item dense to="/usersearch">
                  <v-list-item-title v-text="'Create / Search User'"></v-list-item-title>
                </v-list-item>
                <v-list-item dense to="/famappingmaster">
                  <v-list-item-title v-text="'Financial Authority Mapping'"></v-list-item-title>
                </v-list-item>
                <v-list-item dense to="/MapUsertoFALevel">
                  <v-list-item-title v-text="'Map User to FA Level'"></v-list-item-title>
                </v-list-item>
                <!-- <v-list-item dense to="/UserApproval">
                  <v-list-item-title v-text="'User Approval'"></v-list-item-title>
                </v-list-item> -->
                <v-list-item dense to="/userzonemapping">
                  <v-list-item-title v-text="'User Zone Mapping'"></v-list-item-title>
                </v-list-item>
               
          </v-list-group>
          <v-divider v-if="superUsermnushw || approver"></v-divider>
      <!-- v-if="superUsermnushw || networkMangRole" -->
      
          <v-list-group no-action dense  prepend-icon="mdi-face-agent"  >
                <v-list-item slot="activator">
                  <v-list-item-title v-text="'Support'" style="font-size: 0.8125rem;font-weight: 500;line-height: 1rem;"></v-list-item-title>
                </v-list-item>

                 <v-list-item dense to="/failedtransactions">
                  <v-list-item-title v-text="'GC Sync Failure Report'"></v-list-item-title>
                </v-list-item>

                <v-list-item dense to="/resetretrycount">
                  <v-list-item-title v-text="'Reset Retry Count'"></v-list-item-title>
                </v-list-item>

                <v-list-item dense to="/fanewchanges">
                  <v-list-item-title v-text="'FA New Changes'"></v-list-item-title>
                </v-list-item>
                 <v-list-item dense to="/botStatus">
                  <v-list-item-title v-text="'Bot Status'"></v-list-item-title>
                </v-list-item>
          </v-list-group>

          <!-- <v-list-group no-action dense  prepend-icon="mdi-account-box-multiple-outline"  >
                <v-list-item slot="activator">
                  <v-list-item-title v-text="'Claims Intimation'" style="font-size: 0.8125rem;font-weight: 500;line-height: 1rem;"></v-list-item-title>
                </v-list-item>

                <v-list-item dense to="/ClaimIntimationSearch">
                  <v-list-item-title v-text="'Claim Intimation Search'"></v-list-item-title>
                </v-list-item>

                <v-list-item dense to="/SelectIntimation">
                  <v-list-item-title v-text="'Select Intimation to Claim Screen'"></v-list-item-title>
                </v-list-item>

          </v-list-group> -->
          
          <v-list dense class="ma-0 pa-0" v-if="superUsermnushw ||claimmnushw">
          <v-list-item to="/InwardPreAuth">
            <v-list-item-icon class="mr-4">
             <div>
              <v-icon style="width: 19px;height: 19px;">description</v-icon>
            </div>
            </v-list-item-icon>
  
            <v-list-item-content>
              <v-list-item-title>Inward Pre-Auth</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>

          <!-- <v-list dense class="ma-0 pa-0" v-if="superUsermnushw"> -->
         <!-- active-class="actvMenu" -->
          <!-- <v-list-item  to="/providerValidation">
            <v-list-item-icon class="mr-4">
             <div>
              <v-icon style="width: 19px;height: 19px;">build</v-icon>
            </div>
            </v-list-item-icon>
  
            <v-list-item-content>
              <v-list-item-title>Provider Validation</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        

        <v-divider v-if="superUsermnushw"></v-divider> -->

<v-divider></v-divider>
      <v-list dense class="ma-0 pa-0" v-if="superUsermnushw ||claimmnushw">
        
          <v-list-item to="/newlogHome">
            <v-list-item-icon class="mr-4">
             <div>
              <img src="../assets/homepage.png" style="width: 19px;height: 19px"/>
            </div>
            </v-list-item-icon>
  
            <v-list-item-content>
              <v-list-item-title>Claim User Home</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
      </v-list>
        <v-divider></v-divider>

        <v-list dense class="ma-0 pa-0" v-if="superUsermnushw ||claimmnushw">
          <v-list-item to="/HospitalSearch">
            <v-list-item-icon class="mr-4">
             <div>
              <v-icon style="width: 19px;height: 19px;">local_hospital</v-icon>
            </div>
            </v-list-item-icon>
  
            <v-list-item-content>
              <v-list-item-title>Hospital Search</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-divider v-if="superUsermnushw ||claimmnushw"></v-divider>
          <v-list dense class="ma-0 pa-0" v-if="superUsermnushw ||claimmnushw">
            <v-list-item to="/supervisordataentryqueue">
              <v-list-item-icon class="mr-4">
              <div>
                <v-icon style="width: 19px;height: 19px;">toc</v-icon>
              </div>
              </v-list-item-icon>
    
              <v-list-item-content>
                <v-list-item-title>Supervisor Data Entry Queue</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <v-divider v-if="superUsermnushw ||claimmnushw"></v-divider>

          <v-list dense class="ma-0 pa-0" v-if="superUsermnushw ||claimmnushw">
            <v-list-item to="/supervisorcodingqueue">
              <v-list-item-icon class="mr-4">
              <div>
                <v-icon style="width: 19px;height: 19px;">queue</v-icon>
              </div>
              </v-list-item-icon>
    
              <v-list-item-content>
                <v-list-item-title>Supervisor Coding Queue</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>

           <v-divider v-if="superUsermnushw ||claimmnushw"></v-divider>

          <v-list dense class="ma-0 pa-0" v-if="superUsermnushw ||claimmnushw">
            <v-list-item to="/supervisormedicalscrutinyqueue">
              <v-list-item-icon class="mr-4">
              <div>
                <v-icon style="width: 19px;height: 19px;">local_hospital</v-icon>
              </div>
              </v-list-item-icon>
    
              <v-list-item-content>
                <v-list-item-title>Supervisor Medical Scrutiny Queue</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <v-divider v-if="superUsermnushw ||claimmnushw"></v-divider>

          <v-list dense class="ma-0 pa-0" v-if="superUsermnushw ||claimmnushw">
            <v-list-item to="/supervisorcommercialscrutinyqueue">
              <v-list-item-icon class="mr-4">
              <div>
                <v-icon style="width: 19px;height: 19px;">view_list</v-icon>
              </div>
              </v-list-item-icon>
    
              <v-list-item-content>
                <v-list-item-title>Supervisor Commercial Scrutiny Queue</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <v-divider v-if="superUsermnushw ||claimmnushw"></v-divider>

          <v-list dense class="ma-0 pa-0" v-if="superUsermnushw ||claimmnushw">
            <v-list-item to="/supervisorpaymentqueue">
              <v-list-item-icon class="mr-4">
              <div>
                <v-icon style="width: 19px;height: 19px;">payment</v-icon>
              </div>
              </v-list-item-icon>
    
              <v-list-item-content>
                <v-list-item-title>Supervisor Payment Queue</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-divider v-if="superUsermnushw ||claimmnushw"></v-divider>

          <v-list dense class="ma-0 pa-0" v-if="superUsermnushw ||claimmnushw">
            <v-list-item to="/internalinvestigation">
              <v-list-item-icon class="mr-4">
              <div>
                <v-icon style="width: 19px;height: 19px;">zoom_out</v-icon>
              </div>
              </v-list-item-icon>
    
              <v-list-item-content>
                <v-list-item-title>Supervisor Internal Investigation</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <v-divider v-if="superUsermnushw ||claimmnushw"></v-divider>

          <v-list dense class="ma-0 pa-0" v-if="superUsermnushw ||claimmnushw">
            <v-list-item to="/ofr">
              <v-list-item-icon class="mr-4">
              <div>
                <v-icon style="width: 19px;height: 19px;">local_hospital</v-icon>
              </div>
              </v-list-item-icon>
    
              <v-list-item-content>
                <v-list-item-title>Supervisor OFR</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <v-divider v-if="superUsermnushw ||claimmnushw"></v-divider>

          <v-list dense class="ma-0 pa-0" v-if="superUsermnushw ||claimmnushw">
            <v-list-item to="/supervisorquickempanelmentqueue">
              <v-list-item-icon class="mr-4">
              <div>
                <v-icon style="width: 19px;height: 19px;">local_hospital</v-icon>
              </div>
              </v-list-item-icon>
    
              <v-list-item-content>
                <v-list-item-title>Supervisor Quick Empanelment Queue</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <v-divider v-if="superUsermnushw ||claimmnushw"></v-divider>

          <v-list dense class="ma-0 pa-0" v-if="superUsermnushw ||claimmnushw">
            <v-list-item to="/negotiation">
              <v-list-item-icon class="mr-4">
              <div>
                <v-icon style="width: 19px;height: 19px;">local_hospital</v-icon>
              </div>
              </v-list-item-icon>
    
              <v-list-item-content>
                <v-list-item-title>Supervisor Negotiation</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <v-divider v-if="superUsermnushw ||claimmnushw"></v-divider>

          <v-list dense class="ma-0 pa-0" v-if="superUsermnushw ||claimmnushw">
            <v-list-item to="/cfr">
              <v-list-item-icon class="mr-4">
              <div>
                <v-icon style="width: 19px;height: 19px;">local_hospital</v-icon>
              </div>
              </v-list-item-icon>
    
              <v-list-item-content>
                <v-list-item-title>Supervisor CFR</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <v-list dense class="ma-0 pa-0" v-if="superUsermnushw ||claimmnushw">
            <v-list-item to="/memberSearch">
              <v-list-item-icon class="mr-4">
              <div>
                <v-icon style="width: 19px;height: 19px;">mdi-clipboard-account</v-icon>
              </div>
              </v-list-item-icon>
    
              <v-list-item-content>
                <v-list-item-title>Member Search</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <v-list dense class="ma-0 pa-0" v-if="superUsermnushw ||claimmnushw">
            <v-list-item to="/claimPolicySearch">
              <v-list-item-icon class="mr-4">
              <div>
                <v-icon style="width: 19px;height: 19px;">mdi-file-find</v-icon>
              </div>
              </v-list-item-icon>
    
              <v-list-item-content>
                <v-list-item-title>Policy Search</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
  <!-- v-if="superUsermnushw ||claimmnushw" -->
           <v-list  dense class="ma-0 pa-0" v-if="false" >
            <v-list-item to="/claimsProviderSearch">
              <v-list-item-icon class="mr-4">
              <div>
                <v-icon style="width: 19px;height: 19px;" medium>mdi-account-search</v-icon>
              </div>
              </v-list-item-icon>
    
              <v-list-item-content>
                <v-list-item-title>Provider Search</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>     
      </v-navigation-drawer>

    <sessiontracker></sessiontracker>
  </div>
</template>

<script>
import sessiontracker from "./sessiontracker.vue";
// import userInbox from "./UserIcon";
import axios from "axios";
import datais from "../app/auth.js";
import * as API_CONFIG from '../common/API_Config'
import moment from 'moment'
var health_routes = [];
export default {
  props: ["togglerN", "notificationCount"],
  data() {
    return {
      aigcRouteList: [],
      cmRouteList: [],
      toggleNotifications: false,
      img_awaiting: false,
      oldmenu: false,
      side_barmenu: [],
      drawer: true,
      mini: true,
      // showbckBtn: true,
      showPageName: false,
      leftMenuBarOptions: [],
      items:[],
      datas:[],
      selectedRole:"",
      userData:{},
      roleItems:[],
      defaultLob:""
    };
  },
  components: {
    // userInbox,
    sessiontracker,
  },
  computed: {
    headeTitle(){
        var str = this.$store.getters.fetchHeader;
        console.log("str screenName => ",str)
        return str;
    },
    leftSideheadeTitle() {
      return this.$store.getters.fetchHeader;
    },
    computeaccess() {
      console.log("computeaccess");
      let access_types = {
        view: {
          icon: "mdi-eye",
          txt: "View Only",
        },
        edit: {
          icon: "mdi-pencil-outline",
          txt: "Edit",
        },
      };
      let cup_p = this.$store.state.current_page;
      let seeaccess = {
        ...access_types.view,
        title: this.leftSideheadeTitle,
      };
      console.log("cup_p >", cup_p);
      if (cup_p) {
        // adding
        seeaccess = {
          ...cup_p,
        };
        if (
          seeaccess.hasOwnProperty("isEdit") &&
          seeaccess.hasOwnProperty("isView")
        ) {
          if (!seeaccess.isEdit && seeaccess.isView) {
            seeaccess = {
              ...seeaccess,
              ...access_types.view,
            };
          }

          if (seeaccess.isEdit) {
            seeaccess = {
              ...seeaccess,
              ...access_types.edit,
            };
          }
          console.log("final seeaccess is", seeaccess);
          return seeaccess;
        } else {
          return seeaccess;
        }

        console.log("access via naming", cup_p);
      } else {
        return {
          ...seeaccess,
          ...access_types.view,
        };
      }
    },
    lobList(){
        // console.log("User Icon - ",this.user_details())
        let allotedLob  = this.$store.state?.Auth?.user?.signInUserSession?.idToken?.payload?.lob ? this.$store.state.Auth.user.signInUserSession.idToken.payload.lob.split(",") : []
        console.log("allotedLob ",allotedLob)
        if(allotedLob.includes("CM")){
          let index = allotedLob.findIndex((elem)=>{return elem == "CM"})
          allotedLob[index] = "Common Modules"
        }

        if(allotedLob.includes("EW")){
          let index = allotedLob.findIndex((elem)=>{return elem == "EW"})
          allotedLob[index] = "Personal Lines"
        }

        if(allotedLob.includes("Health")){
          let index = allotedLob.findIndex((elem)=>{return elem == "Health"})
          allotedLob[index] = "We Care"
        }

        if(allotedLob.find((elem)=>{ return elem == "Marine" || elem == "AIGC"})){
          allotedLob[allotedLob.findIndex((elem)=>{ return elem == "Marine" || elem == "AIGC"})] = "Commercial Lines"
        }

        while(allotedLob.findIndex((elem)=>{ return elem == "Marine" || elem == "AIGC"}) !== -1){ 
          allotedLob.splice(allotedLob.findIndex((elem)=>{ return elem == "Marine" || elem == "AIGC"}),1) 
        }

        if(allotedLob.find((elem)=>{ return elem == "Travel" || elem == "PA"})){
          allotedLob[allotedLob.findIndex((elem)=>{ return elem == "Travel" || elem == "PA"})] = 'Travel PA'
        }

        while(allotedLob.findIndex((elem)=>{ return elem == "Travel" || elem == "PA"}) !== -1){ 
          allotedLob.splice(allotedLob.findIndex((elem)=>{ return elem == "Travel" || elem == "PA"}),1) 
        }
        
        return allotedLob 
    },
    superUsermnushw(){
          if(this.$store.getters.fetchRole === 'SuperUser'){
            return true
          }else{
            return false
          }
        },
        adminmnushw(){
          if(this.$store.getters.fetchRole === 'AdminUser'){
            return true
          }else{
            return false
          }
        },

        deomnushw(){
          if(this.$store.getters.fetchRole === 'DEO'){
            return true
          }else{
            return false
          }
        },

        claimmnushw(){
          if(this.$store.getters.fetchRole === 'ClaimManager'){
            return true
          }else{
            return false
          }
        },
        rvwermnushw(){
          if(this.$store.getters.fetchRole === 'Reviewer'){
            return true
          }else{
            return false
          }
        },
        PvdrMngtTmmnushw(){
          if(this.$store.getters.fetchRole === 'ProviderManagementTeam'){
            return true
          }else{
            return false
          }
           
        },

        networkMangRole(){
          if(this.$store.getters.fetchRole === 'NetworkManager'){
            return true
          }else{
            return false
          }
        },

        networkExeRole(){
          if(this.$store.getters.fetchRole === 'NetworkExecutive'){
            return true
          }else{
            return false
          }
        },

        showbckBtn() {
          if(this.$route.name !== 'homePage'){
            return true
          }else{
            return false
          }
        },
        approver(){
          if(this.$store.getters.fetchRole === 'Approver'){
            return true
          }else{
            return false
          }
        },
    currentDefaultLob(){
      return this.defaultLob// || "Common Modules"
    }
  },

  watch: {
    togglerN: function(val) {
      this.toggleNotifications = val;
    },
    // selectedToggle: function(val, old) {
    //   try {
    //     console.log("my urllllllllllllllllll", val, old);
    //   } catch (error) {
    //     console.log(error);
    //   }
    // },
  },

  methods: {
    serverleftMenuBarOptions() {
      let index = 1;
      let modifyOptions = [];
      for (let k in this.side_barmenu) {
        if (this.side_barmenu[k].side_menu) {
          let icon = this.side_barmenu[k].health_icon;
          let obj = {
            title: this.side_barmenu[k].screenName,
            imagePath: icon ? require("../assets/MenuLeftBar/" +
              this.checkicon_img(icon)) : '',
            path: this.side_barmenu[k].path,
            id: index + modifyOptions.length,
            selected: false,
          };
          modifyOptions.push(obj);
          // console.log(modifyOptions)
        }
      }
      modifyOptions = [
        ...new Map(modifyOptions.map((item) => [item.title, item])).values(),
      ];
      this.leftMenuBarOptions = [...modifyOptions];
    },
    newOldMenuChange() {
      this.oldmenu = !this.oldmenu;
      if (this.oldmenu) {
        this.leftMenuBarOptions = this.getLHSMenuOptions();
      } else {
        this.serverleftMenuBarOptions();
      }
    },
    onUseroptionHandler() {
      this.toggleNotifications = false;
      this.$emit("notification-toggle", {
        toggler: false,
      });
    },
    onNotificationHandler() {
      // event emmission
      let toggle = !this.toggleNotifications;
      this.toggleNotifications = toggle;
      this.$emit("notification-toggle", {
        toggler: toggle,
      });
    },
    mouseOver(id) {
      console.log(" Mouse Over => ",id,"mini => ",this.mini)
      this.mini = false;      
      // this.autoScroll(id);
    },
    newMouseOver(event) {
      this.mini = !this.mini;
      //let content = document.getElementsByClassName("v-navigation-drawer__content");
      //       content[0].scrollTop=id * 40;
      //     console.log(  content[0].scrollTop)
    },
    mouseOut() {
      this.mini = true;
    },
    onClickOutside() {
      this.mini = true;
    },
    autoScroll(id) {
      //   let content = document.getElementsByClassName("v-navigation-drawer__content");
      //   content[0].scrollTop=id * 25;
    },
    logout() {
      // datais.logout();
      // this.$store.commit("LOGIN_SUCCESS", {});
      // this.$router.push("/login");
      // this.$router.push('/logout');
      this.log_out_function();
    },
    // getrequiredIMG(indata){

    //    return  this.img_awaiting ?  require(indata) : require('../assets/MarinSideMenu/Masters.png')
    // },

    checkicon_img(indata){
      // console.log(indata.split("/")[indata.split("/").length - 1] )
      return indata ? indata.split("/")[indata.split("/").length - 1] : "";//  return /([./.])\w+/g.test(indata)
     },
     theoutside(){
       console.log("the outside mini -> ", this.mini)
       if(!this.mini){
          this.mini = true
       }
     },

    callScreensLOB(){
        try{
            console.log("inside callScreensLOB response");
        //  this.screenslob(res=>{
        //       this.img_awaiting = false;
        //         // alert("now should call get auth data")
        //             health_routes = res[0].routes;
        //             console.log("incoming response",health_routes);
        //             this.getauthdata();
        //     })

        this.screenslob(res=>{
              this.img_awaiting = false;
            // alert("now should call get auth data")
                health_routes = res[0].routes;
                console.log("incoming response",health_routes);
                // https://claimsdrive.salesdrive.app/common-system-service/users/search-users?userid=&employeeId=setlleoff&lob=&employeeName=&settlementOffice=&phone=&email=&typeOfEmployee=&status=&employee=&externalResource=&perPage=10&pageNo=1&zone=
                // console.log("getTokenData -  ",this.$store.getters.getTokenData.user.signInUserSession.idToken.payload.ntidUserId)
                // let userReqData =   "perPage=10&pageNo=1&userid="+this.$store.getters.getTokenData.user.signInUserSession.idToken.payload.ntidUserId
                // this.GET("GET_USER_DATA",userReqData,(res)=>{
                //     //Surendra(25-06-2022):https://iortatech.atlassian.net/browse/MC-5478
                //     console.log(res)
                //     if(res.data && res.data.data && res.data.data.data && res.data.data.data[0].userZoneMapping && res.data.data.data[0].userZoneMapping.length > 0){
                //         this.$store.commit("SAVE_SETTLINGOFFICE",true)
                        
                //     }else{
                //         this.showAlert("User is not mapped to Settling office/Financial level.")
                //         this.$store.commit("SAVE_SETTLINGOFFICE",false)
                //     }
                // },(error)=>{
                //     console.log(error)
                // },true)
                
                this.getauthdata();
          })
       
        }catch(e){
                console.log(" from callScreensLOB-catch header-side-menu ",e)
        }
    },
    screenslob(cb){
            
            // alert('screenslob called now') 

        if(!(this.$store && this.$store.state && this.$store.state.Auth && this.$store.state.Auth.user && this.$store.state.Auth.user.signInUserSession && this.$store.state.Auth.user.signInUserSession.idToken && this.$store.state.Auth.user.signInUserSession.idToken.jwtToken)){
            return
        }

          console.log("screenslob Marine")
          let theheaders = {...this.authHeaderM(true)}
            //  theheaders.system='Marine'
              console.log("header ->",theheaders);
              
            // let that = this
            axios({
                method: "get",
                url:'https://claimsdrive.salesdrive.app/common-system-service/permission-screens/get-routes?lob='+theheaders.headers.system,
                headers:theheaders.headers,
            })
            .then(function (response){
                // alert("from screens lob")
                console.log("response of SCREENS-> ", response.data.data);
              
                cb(response.data.data)

            }).catch(e=>{
                console.log("from catch ->", e)
            })
             
        },
    changeOldMenuView(){
      console.log('oldmenu value for menu',this.oldmenu);
      this.oldmenu = !this.oldmenu
    },
    getUniqueObjList(arr) {
      let keys = ["screenName"];
      let filtered = arr.filter(
        ((s) => (o) =>
          ((k) => !s.has(k) && s.add(k))(keys.map((k) => o[k]).join("|")))(
          new Set()
        )
      );
      return filtered;
    },

    getauthdata(){
          // alert('get auth data called')
          // console.log(this.user_details().selectedLOB,"<-- this.user_details().selectedLOB | auth-data is ", datais.auth.signInUserSession.idToken.payload.frontendPermission);
          let authdata = this.$store.state.Auth.user.signInUserSession.idToken.payload;
          // let authdata = datais.auth.signInUserSession.idToken.payload;
          if(authdata.hasOwnProperty('frontendPermission')){
              this.GET_ByURLM("GET_BIT_ARRAY",authdata.frontendPermission,res=>{
                  // "Health:Main Screen:isEdit: true
                  // Health:Main Screen:isView: false
                  console.log("Res.data ", res.data.data)
                  
                  let aa = res.data
                  let c = []
                  c = health_routes
                  // authdata.selectedLOB == 'Marine' ? c = health_routes : c = []
                  let ss = null
                  let ar = Object.keys(aa.data).map(e=>{
                      ss = e.concat(":").concat(aa.data[e])
                  return ss

                  })
                  // console.log(aa.data)
                  // console.log(ar)

                  let newone = []
                  ar.map(e=>{
                      
                      if(e.split(':')[0] == "CM"){//this.user_details().selectedLOB){
                          newone.push(e)
                      }
                  })

                  console.log('only CM',JSON.stringify(newone))


                  /* a[0].split(':')[1] */
                  let final = { fastaccess:{} ,menudata:[]}
                  let a = newone;
                  a.map((e,ind)=>{
                      c.map((s,ind)=>{
                          
                              if(e.split(':')[1] == s.screenName){
                          
                                  let sobj = {}  
                                  let bool = final.fastaccess[s.screenName] == undefined ? false : true
                                  /* console.log("final.fastaccess[s.screenName] ", bool) */
                                  if(bool){
                                      // quick access via objects, instead of linear array iteration
                                          // console.log('from if',  final.menudata[final.fastaccess[s.screenName]])
                                  
                                          /* sobj[e.split(':')[2]] = true */
                                          final.menudata[final.fastaccess[s.screenName]][e.split(':')[2]] = JSON.parse(e.split(':')[3])
                                  }else{
                                      
                                      sobj[s.screenName] = s.screenName
                                      sobj[e.split(':')[2]] = JSON.parse(e.split(':')[3])
                                      sobj['screenName'] = s.screenName
                                      final.menudata.push(sobj)
                                      final.fastaccess[s.screenName] =  final.menudata.length -1
                                  } 
                              }
                          })
                  })

                      console.log('final menu-data',final);
                      this.$store.commit("SET_PAGE_PERMISSION_DATA", final)
                      // this.

                      this.branchmenu(final.menudata);


              },err=>{
                  console.log("bit array decryption from catch", err);
              })
              // axios.get('https://claimsdrive.salesdrive.app/common-system-service/users/parse-bitarray/'+authdata.frontendPermission)
              // .then(
              //     ).catch()

          }else{
              console.log("permissions key not found")
          }


    },
    branchmenu(res,that){

        console.log("incoming res is ",res)
        let aa = res
        res = []
          aa.map(e=>{
            if(!e.isEdit && !e.isView){
            }else{
              res.push(e)
            }
          })
       
        let c = null
        if(this.user_details && this.user_details()){
            // this.user_details().selectedLOB == 'Marine' ? c = health_routes : c = marine_routes
            c = health_routes
        }else if(that.user_details && that.user_details()){
            console.log(that.user_details())
            // that.user_details().selectedLOB == 'Marine' ? c = health_routes : c = marine_routes
            c = health_routes
        }else{
            return
        }
        
        let rdlen = c.length
        let rdata = c;
        var pmdata = res
      // this.login_response[0].permissionIds[0].screenForPermission
      // rdata is our own data
      // pmdata is server response data
      //@akshay  extracting Special permissions
       
      let special_permissions_data = []
      // this.$store.state.user_data.assignSpecialPermission.map(e=>{
      //          e.screenForPermission.map(sp=>{
      //             // console.log("sp ", sp);
      //             special_permissions_data.push(sp)
      //         })
      // })
      console.log(rdata,' rdata| rdlen --> ',rdlen);

      var temp_nested_array = []
      let changingscreens = [], persistantscreens = [];
      for(let i=0; i<rdlen; i++){
            // console.log(rdata[i],'for loop')
                if(rdata[i].hasOwnProperty('screenName')){
            // checking for nested side-menu items
            // config for nested items
                   if(rdata[i].hasOwnProperty('config')){
                         console.log('rdata is ', rdata[i].hasOwnProperty('config'))
                         if(rdata[i].config.hasOwnProperty('side_menu_nested')){
                            if(rdata[i].config.side_menu_nested){
                               temp_nested_array.push(rdata[i]);    
                            }
                         }  
                   }

                   
                  // checking for normal side menu items
                  // let permission = this.checkscreens(rdata[i], pmdata)
                  // this.side_barmenu.push(...permission);
                  // console.log("special_permissions_data >> ", special_permissions_data);
                  // let SP_permission_menu =  this.checkscreens(rdata[i], special_permissions_data);
                  // console.log(SP_permission_menu,"SOPECIAL permission menu ",special_permissions_data);
                  // for(let j=0; j<special_permissions_data.length;j++){

                  //     if(!rdata[i].hasOwnProperty('config')){

                  //       if(rdata[i].screenName == special_permissions_data[j].screenName){
                  //           console.log(rdata[i].screenName, "!<-IF cs ->", special_permissions_data[j].screenName);
                  //           persistantscreens.push({...rdata[i],...special_permissions_data[j]})
                  //           break;
                  //       }
                  //     }else{

                  //       if(rdata[i].screenName == special_permissions_data[j].screenName){
                  //             console.log(rdata[i].screenName, "<-ELSE cs ->", special_permissions_data[j].screenName);
                  //             persistantscreens.push({...rdata[i],...special_permissions_data[j]})
                  //             break;
                  //       }
                  //     }
                       
                  // }

                  for(let k=0; k<pmdata.length;k++){
                        if(!rdata[i].hasOwnProperty('config')){
                          if(rdata[i].screenName == pmdata[k].screenName){
                              changingscreens.push({...rdata[i],...pmdata[k]})
                              break;
                          }
                        }  
                  }

               
            }else{
              console.log('from-else as screenName not found',rdata[i])
            }
      }

       console.log(changingscreens,"<- temp_nested_array ->", temp_nested_array);

      //  return

       this && this.$store ? this.$store.commit("SET_SPECIAL_PERMISSION", persistantscreens) : that.$store.commit("SET_SPECIAL_PERMISSION", persistantscreens)

        // console.log( this.side_barmenu.length,"side menu",  this.side_barmenu) 
        let parent_menu_items = [];

        temp_nested_array.map(e=>{    
          parent_menu_items.push(e.config.sm_parent)
        })

        parent_menu_items = [...new Set(parent_menu_items)];
        let nested_childs = [];
        // let persistant_screen_data = [...persistantscreens]
        console.log(parent_menu_items,'<- temp nested array')
        parent_menu_items.map(parent=>{
            let somechildern = [];
              temp_nested_array.forEach(e=>{
                    if(e.config.sm_parent == parent){
                    
                        for(let k=0; k<pmdata.length;k++){
                            if(e.hasOwnProperty('screenName') && pmdata[k].hasOwnProperty('screenName')){
                                // console.log(e.screenName,"e.config.sm_parent ", pmdata[k].screenName)
                              if(e.screenName == pmdata[k].screenName){
                                somechildern.push({...e,... pmdata[k]})
                                // console.log("some childern", somechildern)
                                
                                // somechildern.push(e)
                                break;
                              }
                            }  
                        }
                    }
              })

               console.log("LENGTH some childern", somechildern.length)
            if(somechildern.length != 0){
               nested_childs.push({
                  tile:parent,
                  childern:somechildern,
                  side_menu:true,
                  // health_icon: parent == "Support" ? "./assets/update.png" : "./assets/MenuLeftBar/ProviderSearch.png" 
               })
            }
        })
        //  somechildern.forEach((per_screen,sc_index)=>{
        //                             persistant_screen_data.forEach((pscreendata,index)=>{
        //                                   if(pscreendata.screenName == per_screen.screenName){
        //                                      somechildern[sc_index] = pscreendata
        //                                     persistantscreens.splice(index,1);
        //                                   }
        //                             })
        //                         }) 
        console.error('----------------------nested_childs-->',nested_childs);
        if(!this.side_barmenu){
            console.log("Sending to claimDashboard",changingscreens,nested_childs)
            that.setSideMenu(changingscreens,nested_childs)
        }else{
            this.side_barmenu.push(...changingscreens,...nested_childs)
            console.warn('----------------------nested_childs =>>> this.side_barmenu-->', this.side_barmenu);
            this.$store.commit('SET_HELPER_ITEMS',this.side_barmenu)
            this.create_screens_obj(this.side_barmenu);
            this.serverleftMenuBarOptions(); //added by gopikrishna
        }
        

        return
  
        let per_copy = JSON.parse(JSON.stringify(persistantscreens))
           console.log('per_copy-->',per_copy);

        if(nested_childs.length == 0){
           
             parent_menu_items.map(parent=>{
            let somechildern = [];
              temp_nested_array.forEach(e=>{
                    if(e.config.sm_parent == parent){
                    
                        for(let k=0; k<per_copy.length;k++){
                            if(e.hasOwnProperty('screenName') && per_copy[k].hasOwnProperty('screenName')){
                                console.log(e.screenName,"e.config.sm_parent ", per_copy[k].screenName)
                              if(e.screenName == per_copy[k].screenName){
                                somechildern.push({...e,... per_copy[k]})
                                console.log("some childern", somechildern)
                                
                                // somechildern.push(e)
                                break;
                              }
                            }  
                        }
                    }
              })

             
            if(somechildern.length != 0){
               nested_childs.push({
                  tile:parent,
                  childern:somechildern
                  
               })
            }
        })

        }
  console.log(nested_childs,"nested_childs <<<<<<<<<| ");
        let persist_nested = []
        nested_childs.map((nc,nc_index)=>{    
                  nc.childern.map((chdata, chindex)=>{
                       per_copy.forEach(persist_s=>{
                          
                            if(persist_s.hasOwnProperty('config')){
                              if(nc.tile == persist_s.config.sm_parent){
                            
                                  
                                    // console.log(persist_s,"| persist_s")
                                    
                                    if(chdata.screenName == persist_s.screenName){
                                        console.log(persist_s.config.sm_parent,"| nc",nc.tile)
                                      //  && persistantscreens.length != 0
                                        nc.childern[chindex] = persist_s
                                        nc.childern[chindex].over_ride = true
                                        let ps_pos = persistantscreens.findIndex(ps=> ps.screenName == persist_s.screenName)
                                        if(ps_pos != -1){
                                          persistantscreens.splice(ps_pos, 1)
                                        }
                                    }
                              }
                            }                         
                    })
                  }) 
                  // console.log("persist_nested ", persist_nested);
                  // nc.childern.push(...persist_nested)
                  // persist_nested = []            
        })

        console.log(nested_childs,"persistantscreens after over-ride", persistantscreens)

        
        let newper_copy = JSON.parse(JSON.stringify(persistantscreens))
        if(persistantscreens.length != 0){
                  nested_childs.map((nc,nc_index)=>{    
                  // nc.childern.map((chdata, chindex)=>{
                       newper_copy.forEach(persist_s=>{
                          if(persist_s.hasOwnProperty('config')){
                              if(nc.tile == persist_s.config.sm_parent){
                                        console.log(persist_s,"| persist_s", nc.tile)
                                        //  nc.childern.push(...persist_nested)
                                        nested_childs[nc_index].childern.push(persist_s);
                                        
                                //   // console.log(persist_s,"| persist_s")
                                //  // if(chdata.screenName == persist_s.screenName){
                                //        if(!chdata.hasOwnProperty('over_ride')){
                                //           console.log(chdata,"IF chdata")
                                //               persist_nested.push(persist_s)
                                      let else_ps_pos = persistantscreens.findIndex(ps=> ps.screenName == persist_s.screenName)
                                      if(else_ps_pos != -1){
                                        persistantscreens.splice(else_ps_pos, 1)
                                      }
                                //        }
                                    // }
                                }
                          }
            
          })


                  // }) 
                  // console.log("persist_nested ", persist_nested);
                  // nc.childern.push(...persist_nested)
                  // persist_nested = []            
        })
        }
  
// persistantscreens = per_copy
        console.log(changingscreens," n_c | persistantscreens ", persistantscreens);
        let single_persist = JSON.parse(JSON.stringify(persistantscreens));

        changingscreens.map((cs, cs_index)=>{
            single_persist.forEach(ps=>{
                  if(cs.screenName == ps.screenName){
                       console.log(cs.screenName,"if duplicate_screens",ps.screenName)
                       changingscreens[cs_index] = ps
                      console.log(changingscreens[cs_index],"if duplicate_screens",ps.screenName)
                      let duplicateposs = persistantscreens.findIndex(per_sist=>per_sist.screenName == cs.screenName)
                      duplicateposs != -1 ? persistantscreens.splice(duplicateposs, 1) : null
                  }
            })
        })  
      
        this.side_barmenu.push(...changingscreens,...persistantscreens); 
        this.side_barmenu.push(...nested_childs);
           
      
        console.log(temp_nested_array,'oneone', this.side_barmenu);
               alert("oneone")
        this.create_screens_obj(this.side_barmenu);

    },
    forMultipleLobs(a, c) {
      let final = {
        fastaccess: {},
        menudata: [],
      };
      a.map((e) => {
        c.map((s) => {
          if (e.split(":")[1] == s.screenName) {
            let sobj = {};
            let bool =
              final.fastaccess[s.screenName] == undefined ? false : true;
            /* console.log("final.fastaccess[s.screenName] ", bool) */
            if (bool) {
              // quick access via objects, instead of linear array iteration
              // console.log('from if',  final.menudata[final.fastaccess[s.screenName]])

              /* sobj[e.split(':')[2]] = true */
              final.menudata[final.fastaccess[s.screenName]][
                e.split(":")[2]
              ] = JSON.parse(e.split(":")[3]);
            } else {
              sobj[s.screenName] = s.screenName;
              sobj[e.split(":")[2]] = JSON.parse(e.split(":")[3]);
              sobj["screenName"] = s.screenName;
              final.menudata.push(sobj);
              final.fastaccess[s.screenName] = final.menudata.length - 1;
            }
          }
        });
      });

      return final.menudata;
    },

    create_screens_obj(pmdata) {
      let screen_obj = {};
      for (let i = 0; i < pmdata.length; i++) {
        if (pmdata[i].hasOwnProperty("tile")) {
          if (pmdata[i].hasOwnProperty("childern")) {
            pmdata[i].childern.map((ch) => {
              screen_obj[ch.name] = ch;
            });
          }
        } else {
          screen_obj[pmdata[i].name] = pmdata[i];
        }
      }
      screen_obj["PageNotFound"] = {
        path: "/PNF",
        name: "PageNotFound",
        screenName: "PageNotFound", //added by gopikrishna
      };
      let loginDetails = this.user_details();
      if (!loginDetails.CM) {
        return screen_obj;
      }
      this.$store.commit("SET_SCRCEENS_OBJ", screen_obj);
      this.img_awaiting = true;
      console.log("single screen object ", screen_obj);
    },

    alwaysretain(indata) {
      if (indata) {
        //   console.log("always retain success", indata)
        return indata;
      } else {
        //    console.log("always retain failed", indata)
        let access_types = {
          view: {
            icon: "mdi-eye",
            txt: "View Only",
          },
          edit: {
            icon: "mdi-pencil-outline",
            txt: "Edit",
          },
        };

        let seeaccess = {
          ...access_types.view,
          title: this.headeTitle,
        };
        return seeaccess;
      }
    },
    getAllBinary() {
      this.img_awaiting = false;
      // alert("now should call get auth data")
      //  health_routes = res[0].routes;
      // console.log("incoming response",health_routes);
      // this.screenslob();
      // https://claimsdrive.salesdrive.app/common-system-service/users/search-users?userid=&employeeId=setlleoff&lob=&employeeName=&settlementOffice=&phone=&email=&typeOfEmployee=&status=&employee=&externalResource=&perPage=10&pageNo=1&zone=
      console.log(
        "getTokenData -  ",
        this.$store.getters.getTokenData.user.signInUserSession.idToken.payload
          .ntidUserId
      );
      // let userReqData = "perPage=10&pageNo=1&userid=" + this.$store.getters.getTokenData.user.signInUserSession.idToken.payload.ntidUserId
      // this.GET("GET_USER_DATA", userReqData, (res) => {
      //     //Surendra(25-06-2022):https://iortatech.atlassian.net/browse/MC-5478
      //     console.log(res)
      //     if (res.data && res.data.data && res.data.data.data && res.data.data.data[0].userZoneMapping && res.data.data.data[0].userZoneMapping.length > 0) {
      //         this.$store.commit("SAVE_SETTLINGOFFICE", true)

      //     } else {
      //         this.showAlert("User is not mapped to Settling office/Financial level.")
      //         this.$store.commit("SAVE_SETTLINGOFFICE", false)
      //     }
      // }, (error) => {
      //     console.log(error)
      // }, true)
      this.getauthdata();
    },
    redirectLob(selectedLob){
      console.log(selectedLob)
      if(selectedLob && selectedLob !== "Common Modules"){
            let sellob = {
              lob:'' ,
              usr_jti:this.$store.state.Auth.user.signInUserSession.idToken.payload.jti
            }
            
            let targetLob = selectedLob == 'Travel PA' ? 'Travel' : selectedLob == 'Commercial Lines' ? 'Marine' : selectedLob == 'Personal Lines' ? 'EW' : selectedLob == 'Common Modules' ? 'CommonModules' : selectedLob == 'We Care' ? 'Health' : selectedLob
            let apiis = API_CONFIG.SESSION_REDIRECT + targetLob.replace(" ","")  + '&jti=' + sellob.usr_jti
            
            console.log('apiis', apiis)
            
            window.location.href = apiis

        return
      }
      
        
    },
    redirectForResetLob(){
      window.open("https://claimsdrive.salesdrive.app/common-system-service/session/lob-selection?reset=true&jti="+this.$store.state.Auth.user.signInUserSession.idToken.payload.jti,"_blank")
    },
    getUsersById(id) {
      console.log("Incoming id", id);
      //users/get-by-ntid/:ntidUserId
      // this.GET(6, "users/all-users/" + id, (res, error) => {
        this.arrayItem  = [];
      this.GET_ByURLM("GET_USER_BY_ID", id, (res) => {
        
          if (res.data.statusCode === 200) {
            console.log(res.data.data, "Get users by _id");
            let tempData = res.data.data[0];
            let userData  = {
                employeeID : tempData.hasOwnProperty("employeeId") ? tempData.employeeId : "",
                mobileNumber : tempData.hasOwnProperty("contactMobileNo") ? tempData.contactMobileNo : "",
                email : tempData.hasOwnProperty("email")  ? tempData.email  : "",
                lastLogin : this.formatDateTime(tempData.lastLogin)
            }
            this.userData = userData
            this.defaultLob = tempData.defaultLob
             
            
            

          } else {
            this.showToast(res.data.msg, this.TOST().ERROR);
          }
      },(error)=>{
        console.log(error)
          this.showToast(error.response.data.msg, this.TOST().WARNING);
        },true);
    },
    formatDateTime(date) {
      return date ? moment(date).format("DD-MM-YYYY LT") : "";
    },
  },
  created() {
    //this.leftMenuBarOptions=this.serverleftMenuBarOptions();
    // this.leftMenuBarOptions=this.getLHSMenuOptions();
    // document.addEventListener('keydown', (event)=>{
    //    let code=event.key
    // if (code === 'Tab') {
    //    this.mini =!this.mini;
    // }
    // });
    //  window.onresize = ()=> {
    //   var screenCssPixelRatio = (window.outerWidth - 8) / window.innerWidth;
    //   let zoomCheck= parseInt(screenCssPixelRatio);
    //   //  this.showMenu=true;
    //   // this.showMenu=zoomCheck===0 ? true:false;
    try {
        this.userDetails = this.user_details()
        if (this.userDetails.CM) {
            this.roleItems.push(this.userDetails.CM.roleDescription);
        }
      
      if (this.roleItems.length == 1) {
        this.rolenme = this.roleItems[0];
      }
      if(this.roleItems.length > 0){
        this.selectedRole = this.roleItems[0]
      }
      console.log(
        "this.user_details() to check side menu",
        this.user_details()
      );
      if (!this.user_details()) {
        return;
      }
      this.getUsersById(this.user_details().ntidUserId)
      this.callScreensLOB();
    //   if (!this.user_details().Marine && !this.user_details().AIGC) {
    //     this.showAlert(
    //       "You don't have Marine or AIGC LOB assigned to you.",
    //       "",
    //       "",
    //       () => {
    //         this.log_out_function();
    //       }
    //     );
    //   } else {
    //     let userReqData =
    //       "perPage=10&pageNo=1&userid=" +
    //       this.$store.getters.getTokenData.user.signInUserSession.idToken
    //         .payload.ntidUserId;
    //     this.GETM(
    //       "GET_USER_DATA",
    //       userReqData,
    //       (res) => {
    //         //Surendra(25-06-2022):https://iortatech.atlassian.net/browse/MC-5478
    //         console.log("MC-5478 => ", res);
    //         if (
    //           res.data &&
    //           res.data.data &&
    //           res.data.data.data &&
    //           res.data.data.data.length > 0
    //         ) {
    //           let marineLobData = res.data.data.data.find((elem)=>{ return elem.selectedLOB == "Marine"})
    //           let aigcLobData    = res.data.data.data.find((elem)=>{ return elem.selectedLOB == "AIGC"})
    //           if((marineLobData.userZoneMapping && marineLobData.userZoneMapping.length > 0) || (aigcLobData.userZoneMapping && aigcLobData.userZoneMapping.length > 0)){
    //             this.$store.commit("SAVE_SETTLINGOFFICE", true);
                // this.screenslob();
    //     //     //   }else {
    //     //     //     this.showAlert(
    //     //     //       "User is not mapped to Settling office/Financial level."
    //     //     //     );
    //     //     //     this.$store.commit("SAVE_SETTLINGOFFICE", false);
    //     //     //   }
              
    //     //     // } else {
    //     //     //   this.showAlert(
    //     //     //     "User is not mapped to Settling office/Financial level."
    //     //     //   );
    //     //     //   this.$store.commit("SAVE_SETTLINGOFFICE", false);
    //     //     // }
    //     //   },
    //     //   (error) => {
    //     //     console.log(error);
    //     //   },
    //     //   true
    //     // );

    //     // this.screenslob()
    //   }
    } catch (e) {
      console.log(" from created-catch header-side-menu ", e);
    }
    // };
  },
};
</script>

<style scoped>
.vlistitemFont {
  font-size: 13px !important;
}

.stopMiniMT {
  margin-top: -10px !important;
}

.mainLayoutMargin {
  margin-right: -16px;
}

.pageNameMargin {
  flex: 1.1;
  /* margin-right: 108px; */
  margin-right: 252px;
}

.backbuttonImg {
  width: 22px;
  height: 22px;
}

.pclaimMarginright {
  flex: 1;
  /* margin-right: 264px; */
  margin-right: 65px;
}

.layoutbuttongroup {
  /* flex: 0.3; */
  background-color: #23b1a9;
  border-radius: 60px 0px 0px 60px;
  width: 7%;
}

.leftrightArrowImg {
  width: 40px;
  height: 40px;
  position: relative;
  top: 10px;
}

.mynavbar::-webkit-scrollbar {
  display: none !important;
}

.iconSizeHeader {
  font-size: 18px !important;
}

.lne {
  display: block;
  flex: 1 1 0px;
  max-width: 100%;
  height: 0;
  max-height: 0;
  border: solid;
  border-top-width: thin;
  border-right-width: 0px;
  border-bottom-width: 0px;
  border-left-width: 0px;
  transition: inherit;
  border-top-color: rgba(255, 255, 255, 0.1);
  border-right-color: rgba(255, 255, 255, 0.1);
  border-bottom-color: rgba(255, 255, 255, 0.1);
  border-left-color: rgba(255, 255, 255, 0.1);
}

.thems_color_headerMenuNav {
  background-color: #152f38 !important;
}

.my-menu {
  margin-top: 36px;
  contain: initial;
  overflow: visible;
}

.my-menu::before {
  position: absolute;
  content: "";
  top: 0;
  z-index: 5;
  /* right: 16px; */
  transform: rotate(180deg);
  width: 10px;
  height: 13px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 20px solid #23b1a9;
}

.v-navigation-drawer--close {
  visibility: visible !important;
}

.custm-pd0 .v-toolbar__content {
  padding: 0px !important;
}

@media only screen and (min-width: 1330px) and (max-width: 1370px) {
  .mediaqueryheaderbar {
    margin-right: -80px !important;
  }

  .mediaqueryclaimtext {
    margin-right: 55px !important;
  }
}

.controlvicon {
  font-size: 15.5px !important;
  margin-bottom: 0.5px;
}

.pending-notifications-circle {
  display: flex;
  background-color: #23b1a9;
  height: 8px;
  width: 8px;
  border-radius: 50%;
  position: absolute;
  z-index: 10;
  top: 0;
  right: 10px;
  transition: all ease-in-out 0.3s;
  overflow: hidden;
}

.notification-btn.show-count .pending-notifications-circle,
.notification-btn:hover .pending-notifications-circle {
  height: 25px !important;
  width: 25px !important;
  top: -10px !important;
  right: -5px !important;
}

.notifications-count.shown,
.notification-btn:hover .pending-notifications-circle .notifications-count {
  opacity: 1 !important;
}

.notifications-count {
  opacity: 0;
  margin: auto;
  font-size: 10px;
  transition: all ease-in-out 0.3s;
}

.smalllastlogin {
  /* font-size:9.3px; */
  color: #ffff34;
  /* font-weight: bold; */
}

.namefont {
  font-size: 11px;
}

/* margin-right: -17% !important; */
/* .namemrgn{
    
        margin-bottom: 11px;
    }
    
    .timeMrgn{
        margin-bottom: 11px;
    } */

/* new css */
.inbox-pos {
  position: fixed;
  top: 50px;
  left: 45%;
  z-index: 300;
  width: 970px;
  height: 100px;
}
.text-small {
  color: white;
  font-weight: normal !important;
  font-size: 14px;
}
.notifications-count {
  opacity: 0;
  margin: auto;
  font-size: 10px;
  transition: all ease-in-out 0.3s;
}
/* .pending-notifications-circle {
  display: flex;
  background-color: #ff5252;
  height: 8px;
  width: 8px;

  border-radius: 50%;
  position: absolute;
  z-index: 10;
  top: 0;
  right: 10px;
  transition: all ease-in-out 0.3s;
  overflow: hidden;
} */
.v-toolbar {
  background-color: black;
}

.v-list-item__content {
  flex: none;
}

.v-list-item__title,
.v-list-item__subtitle {
  color: #23b1a9;
  padding-left: 5px;
  font-size: 1px;
}
.carret-menu.rounded-lg {
  border-radius: 15px !important;
}
.carret-menu {
  margin-top: 0px;
  contain: initial;
  overflow: visible;
}
.carret-menu::before {
  position: absolute;
  content: "";
  top: 0;
  right: 10px;
  transform: translateY(-100%);
  width: 10px;
  height: 13px;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
}

.carret-menu.white::before {
  border-bottom: 6px solid #ffffff;
}
.carret-menu.teal-arrow::before {
  border-bottom: 6px solid #23b1a9;
}

.notification-list {
  color: #23b1a9;
  font-size: 16px;
  border-bottom: 2px solid;
  margin-right: 5px;
}

.rounded-btn {
  width: 25px !important;
  min-width: 25px !important;
  height: 25px !important;
  border-radius: 50%;
  background-color: #23b1a9 !important;
  color: #10242b;
  padding: 0 !important;
}

p {
  font-size: 14px;
}

.menu_header {
  background-color: #10242b !important;
  background: #10242b;
  color: #fff;
  padding: 10px;
}

.apps-dropdown {
  padding: 10px;
}

.menu_header h4 {
  margin-bottom: 10px;
}

.menu_header p {
  line-height: 1px;
}
.iconSizeHeader {
  font-size: 18px !important;
}
.user p {
  margin-bottom: 0 !important;
  font-weight: 300;
  text-transform: capitalize;
}

.user_logo {
  border: 1px solid #23b1a9;
  border-radius: 50%;
  padding: 5px;
}

.user {
  text-align: start;
}

.user span {
  color: #23b1a9;
}

.v-list-item {
  padding: 1px 6px;
  min-height: 33px;
}

.v-list-item .v-list-item__title,
.v-list-item .v-list-item__subtitle {
  font-size: 15px;
}
.toolbar-title {
  font-size: 16px;
  margin-left: 16px;
}
.user-btn:hover,
.user-btn:focus,
.user-btn {
  background: transparent !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}
.navbar-sticky {
  position: sticky;
  top: 0;
  z-index: 999;
}
.form-label {
  text-align: left;
  font: normal bold 13px Roboto;
  letter-spacing: 0px;
  /* color: #10242b; */
  text-transform: capitalize;
  opacity: 1;
}
.form-control {
  margin-top: 0.25em !important;
}


.rtooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.rtooltip .rtooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: -5px;
  left: 105%;
}

.rtooltip:hover .rtooltiptext {
  visibility: visible;
}

.v-application--is-ltr .v-list-item__icon:first-child {
    margin-right: 16px;
    /* margin-right: 90px; */
}
.v-list-group .v-list-group__header .v-list-item__icon.v-list-group__header__append-icon {
    align-self: center;
    margin: 0;
    /* min-width: 48px; */
    justify-content: flex-end;
    flex: 1;
}
.lne{
    display: block;
    flex: 1 1 0px;
    max-width: 100%;
    height: 0;
    max-height: 0;
    border: solid;
    border-top-width: thin;
    border-right-width: 0px;
    border-bottom-width: 0px;
    border-left-width: 0px;
    transition: inherit;
    border-top-color: rgba(255, 255, 255, 0.10);
    border-right-color: rgba(255, 255, 255, 0.10);
    border-bottom-color: rgba(255, 255, 255, 0.10);
    border-left-color: rgba(255, 255, 255, 0.10);
  }
.thems_color { background-color: #152F38!important;
    }

/* .actvMenu{
  background-color: #000000 !important;
} */
    .my-menu {
      margin-top: 36px;
      contain: initial;
      overflow: visible;
    }
    .my-menu::before {
      position: absolute;
      content: "";
      top: 0;
      z-index: 5;
      right: 16px;
      transform: rotate(180deg);
      width: 10px; 
      height: 13px; 
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 20px solid #23B1A9;
    }

    .custm-pd0 .v-toolbar__content{
      padding:0px !important;
    }

   
  .smalllastlogin{
    font-size:9.3px;
    color: #FFFF34;
    letter-spacing: 0.3px;
    /* font-weight: bold; */
}
.namefont{
    font-size:9.3px;
    letter-spacing: 0.2px;
}

.namemrgn{
    margin-right: -17% !important;
    margin-bottom: 17px;
}
.timeMrgn{
    margin-bottom: -14px;
}
.color-font {
  color: #23B1A9;
}
.tableicons {
  color: #23b1a9 !important;
  justify-content: right;
}
</style>
