<template>
  <v-row>
    <v-col cols="2">
      <h3>Line Of Business</h3>
      <label class="form-label">Choose LOB </label>
      <v-select
        placeholder="Enter..."
        class="form-control"
        maxlength="100"
        v-model="searchData.lob"
        solo
        dense
        depressed
        :items="['Health']"
      />
    </v-col>
    <v-col cols="2">
      <h3>Search</h3>
      <label class="form-label">Select</label>
      <v-select
        placeholder="Enter..."
        class="form-control"
        maxlength="100"
        v-model="showSearchField"
        :items="searchColumnsList"
        item-title="text"
        item-value="value"
        solo
        dense
        depressed
      />
    </v-col>
    <v-col cols="2" v-if="showSearchField == 'claimNumber'" class="align-down">
      <v-text-field
        placeholder="Enter..."
        class="form-control"
        :rules="[(v) => matchRegex(v, 'alphanum')]"
        v-model="searchData.claimNumber"
        maxlength="100"
        solo
        dense
        depressed
      />
    </v-col>
    <v-col
      cols="2"
      v-if="showSearchField == 'featureNumber'"
      class="align-down"
    >
      <v-text-field
        placeholder="Enter..."
        class="form-control"
        :rules="[(v) => matchRegex(v, 'alphanum')]"
        v-model="searchData.featureNumber"
        maxlength="100"
        solo
        dense
        depressed
      />
    </v-col>
    <v-col cols="2" v-if="showSearchField == 'policyNumber'" class="align-down">
      <v-text-field
        placeholder="Enter..."
        class="form-control"
        :rules="[(v) => matchRegex(v, 'alphanum')]"
        v-model="searchData.policyNumber"
        maxlength="100"
        solo
        dense
        depressed
      />
    </v-col>
    <v-col cols="2" v-if="showSearchField == 'siuZone'" class="align-down">
      <v-select
        placeholder="Enter..."
        class="form-control"
        v-model="searchData.siuZone"
        maxlength="100"
        solo
        dense
        depressed
      />
    </v-col>
    <v-col cols="2" v-if="showSearchField == 'claimType'" class="align-down">
      <v-select
        placeholder="Enter..."
        class="form-control"
        v-model="searchData.claimType"
        maxlength="100"
        solo
        dense
        depressed
        :items="claimTypeData"
      />
    </v-col>
    <v-col cols="2" v-if="showSearchField == 'referralType'" class="align-down">
      <v-select
        placeholder="Enter..."
        class="form-control"
        v-model="searchData.referralType"
        maxlength="100"
        solo
        dense
        depressed
      />
    </v-col>
    <v-col cols="2" v-if="showSearchField == 'referredFrom'" class="align-down">
      <v-select
        placeholder="Enter..."
        class="form-control"
        v-model="searchData.referredFrom"
        maxlength="100"
        solo
        dense
        depressed
      />
    </v-col>
    <v-col cols="2" v-if="showSearchField == 'referredTo'" class="align-down">
      <v-select
        placeholder="Enter..."
        class="form-control"
        v-model="searchData.referredTo"
        maxlength="100"
        solo
        dense
        depressed
      />
    </v-col>
    <v-col
      cols="2"
      v-if="showSearchField == 'reassignedFrom'"
      class="align-down"
    >
      <v-select
        placeholder="Enter..."
        class="form-control"
        v-model="searchData.reassignedFrom"
        maxlength="100"
        solo
        dense
        depressed
      />
    </v-col>
    <v-col cols="2" v-if="showSearchField == 'referredDate'" class="align-down">
      <v-menu
        ref="calender"
        :close-on-content-click="true"
        :nudge-right="40"
        transition="scale-transition"
        max-width="290px"
        min-width="290px"
        class="rounded-0"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-bind="attrs"
            label="Select.."
            :value="computedDate('referredDateFrom')"
            solo
            dense
            depressed
            class="rounded-0"
            v-on="on"
          >
            <div
              slot="prepend-inner"
              style="background-color: #f7f7f7; padding: 9px !important"
            >
              <v-icon>event</v-icon>
            </div>
          </v-text-field>
        </template>
        <v-date-picker v-model="searchData.referredDateFrom" no-title>
        </v-date-picker>
      </v-menu>
    </v-col>
    <v-col cols="2" v-if="showSearchField == 'referredDate'" class="align-down">
      <v-menu
        ref="calender"
        :close-on-content-click="true"
        :nudge-right="40"
        transition="scale-transition"
        max-width="290px"
        min-width="290px"
        class="rounded-0"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-bind="attrs"
            label="Select.."
            :value="computedDate('referredDateTo')"
            solo
            dense
            depressed
            class="rounded-0"
            v-on="on"
          >
            <div
              slot="prepend-inner"
              style="background-color: #f7f7f7; padding: 9px !important"
            >
              <v-icon>event</v-icon>
            </div>
          </v-text-field>
        </template>
        <v-date-picker v-model="searchData.referredDateTo" no-title>
        </v-date-picker>
      </v-menu>
    </v-col>
    <v-col
      cols="2"
      v-if="showSearchField == 'reassignedDate'"
      class="align-down"
    >
      <v-menu
        ref="calender"
        :close-on-content-click="true"
        :nudge-right="40"
        transition="scale-transition"
        max-width="290px"
        min-width="290px"
        class="rounded-0"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-bind="attrs"
            label="Select.."
            :value="computedDate('reassignedDateFrom')"
            solo
            dense
            depressed
            class="rounded-0"
            v-on="on"
          >
            <div
              slot="prepend-inner"
              style="background-color: #f7f7f7; padding: 9px !important"
            >
              <v-icon>event</v-icon>
            </div>
          </v-text-field>
        </template>
        <v-date-picker v-model="searchData.reassignedDateFrom" no-title>
        </v-date-picker>
      </v-menu>
    </v-col>
    <v-col
      cols="2"
      v-if="showSearchField == 'reassignedDate'"
      class="align-down"
    >
      <v-menu
        ref="calender"
        :close-on-content-click="true"
        :nudge-right="40"
        transition="scale-transition"
        max-width="290px"
        min-width="290px"
        class="rounded-0"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-bind="attrs"
            label="Select.."
            :value="computedDate('reassignedDateTo')"
            solo
            dense
            depressed
            class="rounded-0"
            v-on="on"
          >
            <div
              slot="prepend-inner"
              style="background-color: #f7f7f7; padding: 9px !important"
            >
              <v-icon>event</v-icon>
            </div>
          </v-text-field>
        </template>
        <v-date-picker v-model="searchData.reassignedDateTo" no-title>
        </v-date-picker>
      </v-menu>
    </v-col>
    <v-col cols="2" v-if="showSearchField == 'reopenDate'" class="align-down">
      <v-menu
        ref="calender"
        :close-on-content-click="true"
        :nudge-right="40"
        transition="scale-transition"
        max-width="290px"
        min-width="290px"
        class="rounded-0"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-bind="attrs"
            label="Select.."
            :value="computedDate('reopenDateFrom')"
            solo
            dense
            depressed
            class="rounded-0"
            v-on="on"
          >
            <div
              slot="prepend-inner"
              style="background-color: #f7f7f7; padding: 9px !important"
            >
              <v-icon>event</v-icon>
            </div>
          </v-text-field>
        </template>
        <v-date-picker v-model="searchData.reopenDateFrom" no-title>
        </v-date-picker>
      </v-menu>
    </v-col>
    <v-col cols="2" v-if="showSearchField == 'reopenDate'" class="align-down">
      <v-menu
        ref="calender"
        :close-on-content-click="true"
        :nudge-right="40"
        transition="scale-transition"
        max-width="290px"
        min-width="290px"
        class="rounded-0"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-bind="attrs"
            label="Select.."
            :value="computedDate('reopenDateTo')"
            solo
            dense
            depressed
            class="rounded-0"
            v-on="on"
          >
            <div
              slot="prepend-inner"
              style="background-color: #f7f7f7; padding: 9px !important"
            >
              <v-icon>event</v-icon>
            </div>
          </v-text-field>
        </template>
        <v-date-picker v-model="searchData.reopenDateTo" no-title>
        </v-date-picker>
      </v-menu>
    </v-col>
    <v-col
      cols="2"
      v-if="showSearchField == 'appointmentDate'"
      class="align-down"
    >
      <v-menu
        ref="calender"
        :close-on-content-click="true"
        :nudge-right="40"
        transition="scale-transition"
        max-width="290px"
        min-width="290px"
        class="rounded-0"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-bind="attrs"
            label="Select.."
            :value="computedDate('appointmentDateFrom')"
            solo
            dense
            depressed
            class="rounded-0"
            v-on="on"
          >
            <div
              slot="prepend-inner"
              style="background-color: #f7f7f7; padding: 9px !important"
            >
              <v-icon>event</v-icon>
            </div>
          </v-text-field>
        </template>
        <v-date-picker v-model="searchData.appointmentDateFrom" no-title>
        </v-date-picker>
      </v-menu>
    </v-col>
    <v-col
      cols="2"
      v-if="showSearchField == 'appointmentDate'"
      class="align-down"
    >
      <v-menu
        ref="calender"
        :close-on-content-click="true"
        :nudge-right="40"
        transition="scale-transition"
        max-width="290px"
        min-width="290px"
        class="rounded-0"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-bind="attrs"
            label="Select.."
            :value="computedDate('appointmentDateTo')"
            solo
            dense
            depressed
            class="rounded-0"
            v-on="on"
          >
            <div
              slot="prepend-inner"
              style="background-color: #f7f7f7; padding: 9px !important"
            >
              <v-icon>event</v-icon>
            </div>
          </v-text-field>
        </template>
        <v-date-picker v-model="searchData.appointmentDateTo" no-title>
        </v-date-picker>
      </v-menu>
    </v-col>
    <v-col cols="2" v-if="showSearchField == 'lossState'" class="align-down">
      <v-select
        placeholder="Enter..."
        class="form-control"
        v-model="searchData.lossState"
        maxlength="100"
        solo
        dense
        depressed
      />
    </v-col>
    <v-col cols="2" v-if="showSearchField == 'lossCity'" class="align-down">
      <v-select
        placeholder="Enter..."
        class="form-control"
        v-model="searchData.lossCity"
        maxlength="100"
        solo
        dense
        depressed
      />
    </v-col>
    <v-col cols="2" v-if="showSearchField == 'providerName'" class="align-down">
      <v-select
        placeholder="Enter..."
        class="form-control"
        v-model="searchData.providerName"
        maxlength="100"
        solo
        dense
        depressed
      />
    </v-col>
    <v-col
      cols="2"
      v-if="showSearchField == 'investigatorName'"
      class="align-down"
    >
      <v-select
        placeholder="Enter..."
        class="form-control"
        v-model="searchData.investigatorName"
        maxlength="100"
        solo
        dense
        depressed
      />
    </v-col>
    <v-col
      cols="2"
      v-if="showSearchField == 'irSubmissionDate'"
      class="align-down"
    >
      <v-menu
        ref="calender"
        :close-on-content-click="true"
        :nudge-right="40"
        transition="scale-transition"
        max-width="290px"
        min-width="290px"
        class="rounded-0"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-bind="attrs"
            label="Select.."
            :value="computedDate('irSubmissionDateFrom')"
            solo
            dense
            depressed
            class="rounded-0"
            v-on="on"
          >
            <div
              slot="prepend-inner"
              style="background-color: #f7f7f7; padding: 9px !important"
            >
              <v-icon>event</v-icon>
            </div>
          </v-text-field>
        </template>
        <v-date-picker v-model="searchData.irSubmissionDateFrom" no-title>
        </v-date-picker>
      </v-menu>
    </v-col>
    <v-col
      cols="2"
      v-if="showSearchField == 'irSubmissionDate'"
      class="align-down"
    >
      <v-menu
        ref="calender"
        :close-on-content-click="true"
        :nudge-right="40"
        transition="scale-transition"
        max-width="290px"
        min-width="290px"
        class="rounded-0"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-bind="attrs"
            label="Select.."
            :value="computedDate('irSubmissionDateTo')"
            solo
            dense
            depressed
            class="rounded-0"
            v-on="on"
          >
            <div
              slot="prepend-inner"
              style="background-color: #f7f7f7; padding: 9px !important"
            >
              <v-icon>event</v-icon>
            </div>
          </v-text-field>
        </template>
        <v-date-picker v-model="searchData.irSubmissionDateTo" no-title>
        </v-date-picker>
      </v-menu>
    </v-col>
    <v-col
      cols="2"
      v-if="showSearchField == 'qc1SubmissionDate'"
      class="align-down"
    >
      <v-menu
        ref="calender"
        :close-on-content-click="true"
        :nudge-right="40"
        transition="scale-transition"
        max-width="290px"
        min-width="290px"
        class="rounded-0"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-bind="attrs"
            label="Select.."
            :value="computedDate('qc1SubmissionDateFrom')"
            solo
            dense
            depressed
            class="rounded-0"
            v-on="on"
          >
            <div
              slot="prepend-inner"
              style="background-color: #f7f7f7; padding: 9px !important"
            >
              <v-icon>event</v-icon>
            </div>
          </v-text-field>
        </template>
        <v-date-picker v-model="searchData.qc1SubmissionDateFrom" no-title>
        </v-date-picker>
      </v-menu>
    </v-col>
    <v-col
      cols="2"
      v-if="showSearchField == 'qc1SubmissionDate'"
      class="align-down"
    >
      <v-menu
        ref="calender"
        :close-on-content-click="true"
        :nudge-right="40"
        transition="scale-transition"
        max-width="290px"
        min-width="290px"
        class="rounded-0"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-bind="attrs"
            label="Select.."
            :value="computedDate('qc1SubmissionDateTo')"
            solo
            dense
            depressed
            class="rounded-0"
            v-on="on"
          >
            <div
              slot="prepend-inner"
              style="background-color: #f7f7f7; padding: 9px !important"
            >
              <v-icon>event</v-icon>
            </div>
          </v-text-field>
        </template>
        <v-date-picker v-model="searchData.qc1SubmissionDateTo" no-title>
        </v-date-picker>
      </v-menu>
    </v-col>
    <v-col
      cols="2"
      v-if="showSearchField == 'siuCaseStatus'"
      class="align-down"
    >
      <v-select
        placeholder="Enter..."
        class="form-control"
        v-model="searchData.siuCaseStatus"
        maxlength="100"
        solo
        dense
        depressed
        :items="['Open','Closed']"
      />
    </v-col>
    <v-col
      cols="2"
      v-if="showSearchField == 'siuCaseSubStatus'"
      class="align-down"
    >
      <v-select
        placeholder="Enter..."
        class="form-control"
        v-model="searchData.siuCaseSubStatus"
        maxlength="100"
        solo
        dense
        depressed
        :items="siuCaseSubStatusData"
      />
    </v-col>
    <v-col
      cols="2"
      v-if="showSearchField == 'onTrack'"
      class="align-down checkbox-align"
    >
      <v-checkbox
        class="form-control"
        solo
        dense
        depressed
        v-model="searchData.onTrack"
      >
      </v-checkbox>
    </v-col>
    <v-col
      cols="2"
      v-if="showSearchField == 'atRisk'"
      class="align-down checkbox-align"
    >
      <v-checkbox
        class="form-control"
        solo
        dense
        depressed
        v-model="searchData.atRisk"
      >
      </v-checkbox>
    </v-col>
    <v-col
      cols="2"
      v-if="showSearchField == 'overdue'"
      class="align-down checkbox-align"
    >
      <v-checkbox
        class="form-control"
        solo
        dense
        depressed
        v-model="searchData.overdue"
      >
      </v-checkbox>
    </v-col>
    <v-col cols="2" v-if="showSearchField == 'ageing'" class="align-down">
      <v-select
        placeholder="Enter..."
        class="form-control"
        v-model="searchData.ageing"
        maxlength="100"
        solo
        dense
        depressed
      />
    </v-col>
    <v-col
      cols="2"
      v-if="showSearchField == 'reassignedFlag'"
      class="align-down checkbox-align"
    >
      <v-checkbox
        class="form-control"
        solo
        dense
        depressed
        v-model="searchData.reassignedFlag"
      >
      </v-checkbox
    ></v-col>
    <v-col
      cols="2"
      v-if="showSearchField == 'reopenFlag'"
      class="align-down checkbox-align"
    >
      <v-checkbox
        class="form-control"
        solo
        dense
        depressed
        v-model="searchData.reopenFlag"
      >
      </v-checkbox>
    </v-col>

    <v-col cols="2" v-if="showSearchField == 'siuFlag'" class="align-down">
      <v-select
        placeholder="Enter..."
        class="form-control"
        v-model="searchData.siuFlag"
        maxlength="100"
        solo
        dense
        depressed
      />
    </v-col>
    <v-col cols="2" class="align-down custom-margin">
      <v-btn
        dark
        class="text-capitalize"
        color="#E46A25"
        depressed
        @click="handleSearch"
      >
        <v-icon>mdi-magnify</v-icon>Search
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
    export default {
    name: 'DialogComponent',
    data() {
    return {
      siuCaseSubStatusData: ["Case Refered, pending for appointment",
        "Case Appointed, Pending for Acceptance",
        "Case Rejected by Investigator",
        "Case Appointed, Accepted, Pending for Final Report Submission",
        "Final report Submitted, Pending for QC 1 Review",
        "QC-1 submitted, Pending for QC-2 Review",
        "QC-2 send back, Pending for QC-1 Review",
        "QC-1 Send back, pending for final report submission",
        "Case withdrawn, Pending for re-appointment",
        "Claims Review sendback, Pending for QC 1 Review",
        "Case waived-off", "Case Send Back, Pending for referral",
        "QC-1 submitted, Pending for claims Review",
        "QC-2 submitted, Pending for claims Review",
        "Investigation completed and closed"
      ],
      claimTypeData:['Reimbursement','Cashless','Cashless Claim File'],
      searchData: {
        lob: "",
        policyNumber: "",
        claimNumber: "",
        featureNumber: "",
        siuZone: "",
        claimType: "",
        referralType: "",
        referredFrom: "",
        referredTo: "",
        reassignedFrom: "",
        referredDateFrom: "",
        referredDateTo: "",
        reassignedDateFrom: "",
        reassignedDateTo: "",
        reopenDateFrom: "",
        reopenDateTo: "",
        siuCaseStatus: "",
        siuCaseSubStatus:""
      },
      showSearchField : "claimNumber",
      searchColumnsList: [
        { text: "Claim Number", value: "claimNumber" },
        // { text: "Feature Number", value: "featureNumber" },
        // { text: "Policy Number", value: "policyNumber" },
        // { text: "SIU Zone", value: "siuZone" },
        { text: "Claim Type", value: "claimType" },
        // { text: "Referral Type", value: "referralType" },
        // { text: "Referred From", value: "referredFrom" },
        // { text: "Referred To", value: "referredTo" },
        // { text: "Reassigned From", value: "reassignedFrom" },
        // { text: "Referred Date", value: "referredDate" },
        // { text: "Reopen Date", value: "reopenDate" },
        // { text: "Appointment Date", value: "appointmentDate" },
        // { text: "Loss State", value: "lossState" },
        // { text: "Loss City", value: "lossCity" },
        // { text: "Provider Name", value: "providerName" },
        // { text: "Investigator name", value: "investigatorName" },
        // { text: "IR submission Date", value: "irSubmissionDate" },
        // { text: "QC 1 submission Date", value: "qc1SubmissionDate" },
        { text: "SIU Case Status", value: "siuCaseStatus" },
        { text: "SIU Case SUB Status", value: "siuCaseSubStatus" },
        // { text: "On Track", value: "onTrack" },
        // { text: "At Risk", value: "atRisk" },
        // { text: "Overdue", value: "overdue" },
        // { text: "Ageing", value: "ageing" },
        // { text: "Reassigned Flag", value: "reassignedFlag" },
        // { text: "Reopen Flag", value: "reopenFlag" },
        // { text: "SIU Flag", value: "siuFlag" },
      ],
    }
  },
    methods: {
      handleSearch(){
        this.$emit("searchFilterData",{"searchData":{...this.searchData},"showSearchField": this.showSearchField})
      }
    },
}
</script>
