export function SET_INVESTIGATION_FORMDATA(state, payload) {
  state.investigationFormData = {...state.investigationFormData, ...payload};
}
export function SAVE_PAGE_HEADER (state, payload) {
  state.headerName = payload;
  state.pageName = payload;
}

export function SAVE_PAGE_HEADER_CREATE_TARIFF (state, payload) {
  state.headerName = payload;
}

export function SET_SCREEN_REBOOT(state, indata){
  state.reboot_screens = indata
}

export function SAVE_CLAIM_MEMBER_DETAILS (state, payload) {
  state.claimMembersearchdata =payload;
  console.log('from  SAVE_CLAIM_MEMBER_DETAILS', state.claimMembersearchdata)
}

export function SET_CG(state, payload){
      console.log("set CG is ", payload);
       state.role_data = payload; 
}

export function SAVE_DETAILS (state, payload) {
  console.log(payload);
  state.createtarrifDetails = payload;
}
export function SAVE_SUBMITTED_CLAIM_ID (state, payload) {
  state.submittedClaimId = payload;
}

// claim details record id which is submitted from reimbursement or cashless tabs
export function SAVE_SUBMITTED_CLAIM_TYPE (state, payload) {
  console.log('submitted claim type',payload);
  state.currentClaimType = payload;
}
export function VALIDATE_MEMBER_SEARCH (state, payload) {
  console.log('submitted claim type',payload);
  state.didMembersearch = payload;
}
// id of claim type which is submitted from claimregistartion page
export function SAVE_REGISTERED_CLAIM_ID (state, payload) {
  console.log('registered claim Id',payload);
  state.registeredClaimId = payload;
}

export function SET_A_C_T_token(state, payload){
  state.a_c_t  = payload
}

export function showSnackbar(state, payload) {
  // console.log(payload, 'PAYLOAD');

  //  console.log(state.snackbar, 'STATE before');
 

  state.snackbar.text = payload.text
  state.snackbar.color = payload.color
      // state.snackbar.right = payload.right
  state.snackbar.multiline = payload.text.length > 50
  state.snackbar.visible = true
  //  console.log(state.snackbar, 'STATE after');

}

export function EMPDOCS(state, payload){
      state.emp_init_docs = payload
}

export function LINKS(state, tab) {
  console.log(tab, "links mutation check is -> ", typeof tab);
  state.links = tab
}

export function closeSnackbar(state) {
  state.snackbar.visible = false
  state.snackbar.multiline = false
  state.snackbar.timeout = 6000
  state.snackbar.text = null
}

export function SHOW_LOADER(state, config) {
   state.loader = config
}

export function SET_PER_ALL(state, payload){
   state.screens = payload
}

export function SET_SPECIAL_PERMISSION(state, payload){
   state.special_permissions.sp = payload
}

export function LOGIN_SUCCESS(state, response) {
  console.log("response of login from mutation",response)
  if(Object.getOwnPropertyNames(response).length === 0){
    //is empty
    state.token=null
    state.userRole=''
    state.employeeName=''
    state.name={}
    state.assignRole= []
    state.screens = ''
    state.user_data = {}
   
  }else{
    // console.log("from else in mutation ", response.data.data.token)
    state.lob_type = response.env_type_is
    state.token = response.data.data.token
    state.userRole=response.data.data.role
    state.name=response.data.data.name
    state.employeeName=response.data.data.employeeName
    state.user_data = response.data.data
    if(response.data.data.hasOwnProperty('assignRole')){
        if(response.data.data.assignRole.length ==0) return
        if(!response.data.data.assignRole[0].hasOwnProperty('permissionIds')) return
      state.assignRole= response.data.data.assignRole
      
    }else{
      console.log('Role not found, token can exist');
      state.assignRole = []
      state.screens = ''
      state.user_data = {}
    }
  }
  console.log("response login from committed store is --> ", state)
}


export function GET_PROVIDER_HOME(state, payload){
    console.log("provider home mutation -> ",payload);
    state.home_providerdata = payload
}

export function PRVDR_SUCCESS(state, payload) {
  console.log(payload, 'PAYLOAD');

  console.log(state), 'STATE';
  state.prvdrData=payload

}

export function SAVE_PRODUCT_NAME (state, payload) {
  console.log(payload, 'PAYLOAD');

  state.createProductCode = payload;
}

export function SAVE_PRODUCT_ITEM_DATA (state, payload) {
  console.log(payload, 'PAYLOAD');

  state.productItemData = payload;
}

export function SAVE_PRODUCT_BTN_NAME (state, payload) {
  console.log(payload, 'PAYLOAD');

  state.btnName.text = payload.text
  state.btnName.visible = payload.visible
  
}

export function SAVE_POLICYFORPRDCT_BTN_NAME (state, payload) {
  console.log(payload, 'PAYLOAD');
  console.log( state.plcyfrPrdctbtnName, ' state.plcyfrPrdctbtnName');
  state.plcyfrPrdctbtnName.txt=payload.text
  state.plcyfrPrdctbtnName.vsble = payload.visible
  state.plcyfrPrdctbtnName.plcy_id=payload.plcy_Id
}

export function SAVE_PRODUCT_CODE_ALL_DATA (state, payload) {
  // console.log(payload, 'PAYLOAD');
  state.sendProductData = payload;
}


export function SET_CURRENT_ROLE(state, payload){
  state.selected_current_role = payload
}

export function SAVE_PLCYID_FOR_PLAN (state, payload) {
  console.log(payload, 'PAYLOAD Policy  ID');
  console.log(state.plcyIdForPlan, ' state.plcyIdForPlan');

  state.plcyIdForPlan.text=payload.text
  state.plcyIdForPlan.visible = payload.visible
  state.plcyIdForPlan.plcy_id=payload.plcy_Id
  state.plcyIdForPlan.plcy_No=payload.plcy_No
  state.plcyIdForPlan.plan_No=payload.plan_No
  state.plcyIdForPlan.plan_Typ=payload.plan_Typ
  state.plcyIdForPlan.retailPlan_Id=payload.retailPlan_Id
  state.plcyIdForPlan.retailPlan_productCode=payload.retailPlan_productCode
  state.plcyIdForPlan.retailPlan_productDescription=payload.retailPlan_productDescription
  state.plcyIdForPlan.retailPlan_productId=payload. retailPlan_productId
  

}

export function SAVE_PLAN_FOR_RETAIL (state, payload) {
  console.log(payload, 'PAYLOAD Policy  ID');
  console.log(state.retailPlanPdt, ' state.retailPlanPdt');

  state.retailPlanPdt.text=payload.text
  state.retailPlanPdt.visible = payload.visible
  state.retailPlanPdt.plan_Typ = payload.plan_Typ
  state.retailPlanPdt.plan_Id = payload.plan_Id
  
}

export function GRP_PLCY_FOR_CLONE(state, payload) {
  console.log(payload, 'PAYLOAD');
  state.grpPlcyDtafrCln=payload

}

export function ENABLE_VIEW(state, payload){
  state.tariff_view = payload
}

export function NS_store(state, payload){
  state.NetworkSearch = payload
}

export function EMPANEL_PROVIDER_DATA(state, payload) {
  state.empanelProviderData = payload
}
export function EMPANEL_TAB_STATUS(state, payload) {
  console.log(payload, 'PAYLOAD this is from empanelment status');
  state.empanelmentTabStatus.mainScreenStatus = payload.mainScreenStatus
  state.empanelmentTabStatus.ownershipAndContactStatus = payload.ownershipAndContactStatus
  state.empanelmentTabStatus.contactAndFacilityStatus = payload.contactAndFacilityStatus
  state.empanelmentTabStatus.specialityStatus = payload.specialityStatus
  state.empanelmentTabStatus.qualityAndTeachingStatus = payload.qualityAndTeachingStatus
  state.empanelmentTabStatus.labAndRadiologyStatus = payload.labAndRadiologyStatus
  state.empanelmentTabStatus.staffStatus = payload.staffStatus
  state.empanelmentTabStatus.facilitiesStatus = payload.facilitiesStatus
  state.empanelmentTabStatus.paymentStatus = payload.paymentStatus
  state.empanelmentTabStatus.providerReviewStatus = payload.providerReviewStatus
  state.empanelmentTabStatus.approvalAndContractStatus = payload.approvalAndContractStatus
}

export function NETWORK_DATA(state, payload) {
  // console.log(payload, 'createNetworkData PAYLOAD');

  state.createNetworkData = payload;
}

export function UPDATE_NETWORK_DATA(state, payload) {
  // console.log(payload, 'createNetworkData PAYLOAD');
  state.updateNetworkData = payload;
}
export function SEARCH_TARRIF_DATA(state, payload) {
  state.selectTarrifData = payload;
}

export function SAVE_PDT_DATE_FOR_PLAN (state, payload) {
  console.log(payload, 'PAYLOAD Policy  ID');
  console.log(state.productDate, ' state.productDate');

  state.productDate.fromDate=payload.fromDate
  state.productDate.toDate = payload.toDate
  state.productDate.policyFromDate=payload.policyFromDate
  state.productDate.policyToDate=payload.policyToDate
}
// export function SAVE_PAGE_HEADER(state, payload) {
  // shifted to health 
  // state.pageName = payload;
// }
export function MENUBAR_SELECTED_NAME(state, payload) {
  state.menuBarSelectedName = payload;
}
export function SAVE_LANDINGPAGE_ID(state, payload) {
  state.claimMainscreen_id = payload.data;
}
export function FNOL_PAGE_EDIT_CHECK(state, payload) {
  state.fnloPageEditCheck = payload;
}
// export function LINKS(state, tab) {

  // Can use health's mutation as mutating same state 

//   state.links = tab
// }
// export function LOGIN_SUCCESS(state, payload) {

  // can use health's mutation as mutating same state from the Store...

//   if (Object.getOwnPropertyNames(payload).length === 0) {
//     state.token = null
//     state.userRole = ''
//     state.name = {}
//   } else {
//     state.token = payload.data.data.token
//     state.userRole = payload.data.data.role
//     state.name = payload.data.data.name
//   }
// }
export function SHOW_PROGRESS(state, payload) {
  state.showProgress = payload.value;
  state.showProgressMessage = payload.message;
}
export function HIDE_PROGRESS(state, payload) {
  state.showProgress = payload;
}
export function POLICY_SEARCHDATA(state, payload) {
  state.policySearchData = payload;
} export function POLICY_PROPOSALNO(state, payload) {
  state.policyproposalNo = payload;
}
export function SAVE_MAINCLAIMNUMBER(state, payload) {
  state.mainClaimNumberObj = payload;
}

export function SHOW_TOAST(state, payload) {
  state.toast.showToast = payload.value;//toast
  state.toast.toastMessage=payload.message;
}

export function SAVE_FEATUREMNUMBER(state, payload) {
  state.featureNumberObj = payload;
}


// export function SHOW_ALERT(state, payload) {
//   state.showAlertObj.showAlert = payload.value;
//   state.showAlertObj.showAlertMessage = payload.message;
//   // state.showAlertObj.showcancel= payload.showcancel;
//   state.showAlertObj.alertfunction = payload.alertfunction;
//   state.showAlertObj.failurealertfunction = payload.failurealertfunction;
//   state.showAlertObj.successAlertButtonName = payload.successBtnName;
//   state.showAlertObj.failureAlertButtonName = payload.FailreBtnName;
// }
// export function HIDE_ALERT(state, payload) {
//   state.showAlertObj.showAlert = payload;
// }
// export function SHOW_CONFIRM(state, payload) {
//   state.confirmTitle = payload.title
//   state.showConfirm = payload.value;
//   state.confirmMessage = payload.message;
//   state.successbutton = payload.successBtn;
//   state.failurebutton = payload.Failrebtn;
//   state.callfunction = payload.callfunction;
//   state.callfailurefunction = payload.callfailurefunction;
// }
// export function HIDE_CONFIRM(state, payload) {
//   state.showConfirm = payload;
// }
// export function CALL_Function(state, payload) {
//   state.callfunction = payload;
// }

export function USER_CREATE_EDIT_DATA(state, payload) {
  console.log(payload, 'PAYLOAD');

  if(Object.getOwnPropertyNames(payload).length === 0){
    console.log("from if");
    state.userCreateEditDta.editUser=false
    state.userCreateEditDta.user_id=null
    state.userCreateEditDta.ntid=null
  }else{
    console.log("from else",state.userCreateEditDta,payload.editUser);
    state.userCreateEditDta.editUser=payload.editUser
    state.userCreateEditDta.user_id=payload.user_id
    state.userCreateEditDta.ntid=payload.ntid
  }
 
}

export function SET_CLAIM_ICON(state, payload){
  console.log("SET_CLAIM_ICON ->",payload);
  state.claimicon = payload
}

export function SET_SCRCEENS_OBJ(state, payload){
  state.screens_obj = payload;
  // console.log("from mutation state is ", state.screens_obj)
}

export function SET_HELPER_ITEMS(state, payload){
  // console.log('| mutation final side_barmenu->',payload);
    state.sideitems = payload
}

export function SETCURRENT_PAGE(state, payload){
  state.current_page = payload
}


export function SET_LOGINDATA(state, payload){
  state.Auth = payload
}

export function SET_REFRESHSTATE_ONE(state,payload){
  state.is_refreshing = payload
}

export function SET_REFRESHCALL(state,payload){
  state.refreshCALL = payload
}

export function SET_OTHER_LOB_DATA(state,payload){
  state.otherLOBData = payload
}

export function SET_CURRENT_PAGE_DATA(state,payload){
  state.currentPageData = payload
}
export function RESET_PARENT_DATA(state, payload){
  state.parentData = payload
}

