<template>
    <div class="pa-5">
        <v-form ref="createCatastropheref" @submit.prevent="submitCatastrophe">
            <v-row no-gutters>
                <v-col class="mr-3">
                    <p class="claimlabel mb-2">LOB <span class="red--text">*</span></p>
                    <v-select
                        label="Select"
                        v-model="catastropheOj.lob"
                        :items="catastropheLobOptions"
                        :rules="[(v) => requiredField(v)]"
                        solo
                        dense
                        multiple
                        :disabled="viewOnly"
                        :class="viewOnly ? 'forminput textDisable font-weight-bold' : 'forminput'"
                    ></v-select>
                </v-col>
                <v-col class="ml-3 mr-3">
                    <p class="claimlabel mb-2">Catastrophe ID  <span class="red--text">*</span></p>
                    <v-text-field
                        placeholder="Enter"
                        v-model="catastropheOj.catastropheId"
                        solo
                        dense
                        :rules="[(v) => requiredField(v),(v) => matchRegex(v, 'alphanum')]"
                        :disabled="viewOnly"
                        :class="viewOnly ? 'forminput textDisable font-weight-bold' : 'forminput'"
                        clearable
                    ></v-text-field>
                </v-col>
                <v-col class="mr-3">
                    <p class="claimlabel mb-2">Catastrophe Description <span class="red--text">*</span></p>
                    <v-text-field
                        placeholder="Enter"
                        v-model="catastropheOj.catastropheDescription"
                        solo
                        dense
                        :rules="[(v) => requiredField(v),(v) => matchRegex(v, 'alphanum')]"
                        :disabled="viewOnly"
                        :class="viewOnly ? 'forminput textDisable font-weight-bold' : 'forminput'"
                        clearable
                    ></v-text-field>
                </v-col>
                <v-col class="mr-3">
                    <p class="claimlabel mb-2">Catastrophe Detail </p>
                    <v-text-field
                        placeholder="Enter"
                        v-model="catastropheOj.catastropheDetail"
                        solo
                        dense
                        :rules="[(v) => matchRegex(v, 'alphanum')]"
                        :disabled="viewOnly"
                        :class="viewOnly ? 'forminput textDisable font-weight-bold' : 'forminput'"
                        clearable
                    ></v-text-field>
                </v-col>
                <v-col class="mr-3">
                    <p class="claimlabel mb-2">Catastrophe Type </p>
                    <v-select
                        label="Select"
                        v-model="catastropheOj.catastropheType"
                        :items="catastropheTypeOptions"
                        solo
                        dense
                        :disabled="viewOnly"
                        :class="viewOnly ? 'forminput textDisable font-weight-bold' : 'forminput'"
                        clearable
                    ></v-select>
                </v-col>
               
            </v-row>
            <v-row no-gutters style="max-width:40.5%">
                <v-col class="mr-3">
                    <p class="claimlabel mb-2">Start Date <span :class="viewOnly ? '' : 'mandatorycolor'">*</span></p>
                    <v-menu
                        :close-on-content-click="false"
                        offset-y
                        v-model="startDateMenu"
                        min-width="auto">
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                :placeholder="viewOnly ? '' : 'DD/MM/YYYY'"
                                v-model="computedStartDate"
                                :rules="[(v) => requiredField(v)]"
                                dense
                                solo
                                readonly
                                v-on="on"
                                :disabled="viewOnly" 
                                :background-color="viewOnly ? '#f5f5f5' : '#ffffff'" 
                                :class="viewOnly ? 'forminput textDisable font-weight-bold' : 'forminput'"
                                clearable
                                
                            >
                                <template v-slot:prepend-inner class="mx-0 my-0">
                                <v-icon class="iconstyle" >mdi-calendar</v-icon>
                                </template>
                            </v-text-field>
                        </template>
                        <v-date-picker          
                            v-model="catastropheOj.startDate"    
                            no-title        
                            :max="todayDate"
                            @input="startDateMenu = false">
                        </v-date-picker>
                    </v-menu>
                </v-col>
                <v-col class="ml-3 mr-3">
                    <p class="claimlabel mb-2">End Date <span :class="viewOnly ? '' : 'mandatorycolor'">*</span></p>
                    <v-menu
                        :close-on-content-click="false"
                        offset-y
                        v-model="endDateMenu"
                        min-width="auto">
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                :placeholder="viewOnly ? '' : 'DD/MM/YYYY'"
                                v-model="computedEndDate"
                                :rules="[(v) => requiredField(v)]"
                                dense
                                solo
                                readonly
                                v-on="on"
                                :disabled="viewOnly" 
                                :background-color="viewOnly ? '#f5f5f5' : '#ffffff'" 
                                :class="viewOnly ? 'forminput textDisable font-weight-bold' : 'forminput'"
                                clearable
                                
                            >
                                <template v-slot:prepend-inner class="mx-0 my-0">
                                <v-icon class="iconstyle" >mdi-calendar</v-icon>
                                </template>
                            </v-text-field>
                        </template>
                        <v-date-picker          
                            v-model="catastropheOj.endDate"    
                            no-title        
                            :min="catastropheOj.startDate==null?todayDate:catastropheOj.startDate"
                            @input="endDateMenu = false">
                        </v-date-picker>
                    </v-menu>
                </v-col>
            </v-row>
            <v-row>
                <v-col align="right">
                    <v-btn color="#23B1A9" dark class="mr-3" type="submit" v-if="!viewOnly">
                        <v-icon class="mr-2 iconsize">mdi-check-circle</v-icon>
                        <div class="vertical_line"></div>Submit
                    </v-btn>
                    <v-btn outlined color="#D1121B" class="mr-3" @click="reset()" v-if="!viewOnly">
                        <v-icon class="mr-2 iconsize">mdi-close-box-outline</v-icon>
                        <div class="vertical_line"></div>Reset
                    </v-btn>
                    <v-btn outlined color="#D1121B" class="mr-3" @click="cancel()">
                        <v-icon class="mr-2 iconsize">mdi-close-box-outline</v-icon>
                        <div class="vertical_line"></div>Cancel
                    </v-btn>
                </v-col>
            </v-row>
        </v-form>
    </div>
</template>

<script>
    import moment from 'moment';
    export default {
        data() {
            return {
                viewOnly: false,
                todayDate:  moment(new Date()).format("YYYY-MM-DD"),
                startDateMenu: false,
                endDateMenu: false,
                catastropheOj: {
                    catastropheId: '',
                    catastropheDescription: '',
                    catastropheDetail: '',
                    catastropheType: '',
                    startDate: null,
                    endDate: null,
                    lob: ['Home'],
                },
                catastropheTypeOptions: ['Local', 'International'],
                catastropheLobOptions:["Home"]
            }
        },

        async created() {
            console.log(this.$route.params.catastropheDetails)
            if(Object.keys(this.$route.params).length != 0) {
                let routeValue = this.$route.params;
                this.viewOnly = routeValue.viewOnly
                let id = routeValue?.catastropheDetails?._id;
                this.getCatastropheDetail(id);
            }
            this.$store.commit("SAVE_PAGE_HEADER", "Catastrophe Master");
            const lob = await this.getLOB()
            this.catastropheLobOptions = lob
        },

        computed: {
            computedStartDate() {
                return this.catastropheOj.startDate == null ? "" : moment(this.catastropheOj.startDate).format('DD/MM/YYYY');
            },
            computedEndDate() {
                return this.catastropheOj.endDate == null ? "" : moment(this.catastropheOj.endDate).format('DD/MM/YYYY');
            },
        },

        methods: {
            getCatastropheDetail(_id) {
                this.GET_ByURLM("getSingleCatastrophe", _id, (res) => {
                        if(res.data.statusCode === 200) {
                            this.catastropheOj = res.data.data;
                        }
                    }, (error) => {
                        console.log(error);
                    }, true)
            },
            submitCatastrophe() {
                // this.catastropheOj.lob=[this.catastropheOj.lob];
                console.log("calling submit");
                if(this.catastropheOj && this.catastropheOj._id) {
                    this.updateCatastrophe();
                } else {
                    this.createCatastrophe();
                }
            },
            
            createCatastrophe() {
                if(this.$refs.createCatastropheref.validate()) {
                    this.POSTM("createCatastrophe", this.catastropheOj, (res) => {
                        if(res.data.statusCode === 200) {
                            this.showAlert(res?.data?.msg || "Success", "", "", () => {
                                this.$router.push({
                                    name: 'SearchCatastropheMaster',
                                });
                            });
                        }else {
              this.showAlert(res.data.msg);
              this.hideProgress();
            }
                    }, (error) => {
                        this.showAlert(error?.data?.msg)
                        console.log(error);
                    }, true)
                } else {
                    this.showAlert("Please enter mandatory fields");
                }
            },

            updateCatastrophe() {
                if(this.$refs.createCatastropheref.validate()) {
                    this.PUTM("updateCatastrophe", this.catastropheOj._id, this.exceptObject(this.catastropheOj, ['_id', '__v', 'updatedAt', 'createdAt', 'createdBy', 'createdById']), (res) => {
                        if(res.data.statusCode === 200) {
                            this.showAlert(res?.data?.msg || "Success", "", "", () => {
                                this.$router.push({
                                    name: 'SearchCatastropheMaster',
                                });
                            });
                        }
                        else {
              this.showAlert(res.data.msg);
              this.hideProgress();
            }
                    }, (error) => {
                        console.log(error);
                    }, true)
                } else {
                    this.showAlert("Please enter mandatory fields");
                }
            },
            
            reset() {
                this.showConfirm("Confirmation", `Are you sure you want to reset?`, "Yes", "No", () => {
                     
                    this.catastropheOj.catastropheId= '',
                    this.catastropheOj.catastropheDescription= '',
                    this.catastropheOj.catastropheDetail= '',
                    this.catastropheOj.catastropheType= '',
                    this.catastropheOj.startDate= null,
                    this.catastropheOj.endDate= null,
                    this.catastropheOj.lob= ['Home'],
                    
                    console.log(this.catastropheOj)
                    // this.$refs.createCatastropheref.resetValidation();
                }, () => {})
                
            },

            cancel() {
                this.showConfirm("Confirmation", `Are you sure you want to ${this.viewOnly ? 'leave' : 'cancel'}?`, "Yes", "No", () => {
                    this.$router.push({
                        name: 'SearchCatastropheMaster'
                    });
                }, () => {})
            },
        }
    }
</script>

<style scoped>
    .iconsize {
        font-size: 16px !important;
        font-weight: bold;
    }
    .btnstyle{
        height: 28px !important;
        text-transform: capitalize;
        font-size: 11px !important;
        letter-spacing: 0.5px;
    }
</style>