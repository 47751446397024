import Vue from 'vue'
import App from './App.vue'
import router from './routes.js'
import store from './store/index'
import mixins from './mixins/index'
import vuetify from './plugins/vuetify';
import 'material-design-icons/iconfont/material-icons.css'
import './CommonStyles.scss';
import './styles.scss';
import './common/filters'
import VueSimpleAlert from "vue-simple-alert";
import validationRules from './mixins/validationRules';
Vue.use(VueSimpleAlert);
import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)


Vue.config.productionTip = false

Vue.mixin(mixins);
Vue.mixin(validationRules);


// console.log("main js ---> ", router)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')




