<template>
    <v-app>
        <ProviderDetails/>
        <v-card color="#F7F7F7" class="mb-1" tile>
            <v-btn-toggle v-model="text" dense mandatory class="" @change="tabseleted(text)" style="background-color:#F7F7F7;opacity:100%;display:flex;" >
                 
                <div style="flex:1;"  :class="[tabStatus.roomConfiguration ? 'cust_brdr_green':'cust_brdr_red']">
                  <v-btn  flat  min-height="90px" style="width:100%;letter-spacing: unset"  value="RoomConfiguration" class="text-capitalize font-weight-bold rounded-0" active-class="tarrifConfgSelected">
                    Room Configuration
                  </v-btn>
                </div>

                <div style="flex:1;"  :class="[tabStatus.roomAndNursingCharges ? 'cust_brdr_green':'cust_brdr_red']">
                  <v-btn  flat :disabled="shwtabs" min-height="90px" style="width:100%;letter-spacing: unset"  value="RoomNursing" class="text-capitalize font-weight-bold rounded-0" active-class="tarrifConfgSelected">
                    Room & Nursing
                  </v-btn>
                </div>

                <div style="flex:1;"  :class="[tabStatus.ICUCharges ? 'cust_brdr_green':'cust_brdr_red']">
                  <v-btn  flat :disabled="shwtabs" min-height="90px" style="width:100%;letter-spacing: unset"  value="Icu" class="text-capitalize font-weight-bold rounded-0" active-class="tarrifConfgSelected">
                    ICU
                  </v-btn>
                </div>
                 <div style="flex:1;"  :class="[tabStatus.OTCharges ? 'cust_brdr_green':'cust_brdr_red']">
                  <v-btn  flat :disabled="shwtabs" min-height="90px" style="width:100%;letter-spacing: unset"  value="Ot" class="text-capitalize font-weight-bold rounded-0" active-class="tarrifConfgSelected">
                    OT
                  </v-btn>
                </div>
                 
                 <div style="flex:1;"  :class="[tabStatus.MedAndConsumablesCharges ? 'cust_brdr_green':'cust_brdr_red']">
                  <v-btn  flat :disabled="shwtabs" min-height="90px" style="width:100%;letter-spacing: unset"  value="MedConsumeable" class="text-capitalize font-weight-bold rounded-0" active-class="tarrifConfgSelected">
                    Med & Consumables
                  </v-btn>
                </div>

                <div style="flex:1;"  :class="[tabStatus.ProfessionalFeeCharges ? 'cust_brdr_green':'cust_brdr_red']">
                  <v-btn  flat :disabled="shwtabs" min-height="90px" style="width:100%;letter-spacing: unset"  value="ProfessionalFee" class="text-capitalize font-weight-bold rounded-0" active-class="tarrifConfgSelected">
                    Professional Fee
                  </v-btn>
                </div>

                <div style="flex:1;" :class="[tabStatus.InvestigationCharges ? 'cust_brdr_green':'cust_brdr_red']" >
                  <v-btn  flat :disabled="shwtabs" min-height="90px" style="width:100%;letter-spacing: unset"  value="InvestigationsCharges" class="text-capitalize font-weight-bold rounded-0" active-class="tarrifConfgSelected">
                    Investigations
                  </v-btn>
                </div>
                <div style="flex:1;"  :class="[tabStatus.AmbulanceCharges ? 'cust_brdr_green':'cust_brdr_red']">
                  <v-btn  flat :disabled="shwtabs" min-height="90px" style="width:100%;letter-spacing: unset"  value="AmbulanceCharges" class="text-capitalize font-weight-bold rounded-0" active-class="tarrifConfgSelected">
                    Ambulance
                  </v-btn>
                </div>

                <div style="flex:1;"  :class="[tabStatus.MiscellaneousCharges ? 'cust_brdr_green':'cust_brdr_red']">
                  <v-btn  flat :disabled="shwtabs" min-height="90px" style="width:100%;letter-spacing: unset"  value="Miscellaneous" class="text-capitalize font-weight-bold rounded-0" active-class="tarrifConfgSelected">
                    Miscellaneous
                  </v-btn>
                </div>

                <div style="flex:1;"  :class="[tabStatus.PackageCharges ? 'cust_brdr_green':'cust_brdr_red']">
                  <v-btn  flat :disabled="shwtabs" min-height="90px" style="width:100%;letter-spacing: unset"  value="PackagesPage" class="text-capitalize font-weight-bold rounded-0" active-class="tarrifConfgSelected">
                    Packages
                  </v-btn>
                </div>

            </v-btn-toggle>

            <!-- <MedConsumeable></MedConsumeable> -->

            <component :is="selectedComponent" @forenable="enableTabs" @fordisable="disableTabs"></component>

        </v-card>
    </v-app>
</template>
<script>
import ProviderDetails from '../../component/Provider-Name_ID';
import MedConsumeable from '../MedConsumeables/MedConsumeable';
import ProfessionalFee from '../ProfessionalFee/ProfessionalFee';
import InvestigationsCharges from '../InvestigationsCharges/InvestigationsCharges';
import AmbulanceCharges from '../AmbulanceCharges/AmbulanceCharges';
import PackagesPage from '../PackagesPage/PackagesPage';
import RoomConfiguration from './RoomConfiguration';
import RoomNursing from './RoomNursing';
import Icu from './Icu';
import Ot from './Ot';
import Miscellaneous from './Miscellaneous';



export default {
    components:{
        ProviderDetails,
        RoomConfiguration,
        RoomNursing,
        Icu,
        Ot,
        MedConsumeable,
        ProfessionalFee,
        InvestigationsCharges,
        AmbulanceCharges,
        Miscellaneous,
        PackagesPage
    },

    created(){
        // if(this.$route.params.from == 'view'){
        //     this.$store.commit('ENABLE_VIEW', true);
        // }
          // this.$store.state.tariff_view
    },

    data(){
        return{
            text:"RoomConfiguration",
            selectedComponent:"RoomConfiguration",
            tabsTobeDisabled: true,
            shwtabs:true,
            tabStatus:{
              // roomConfig:false,
              // roomNursing:false,
              // icu:false,
              // ot:false,
              // medConsumables:false,
              // professionalFee:false,
              // investigations:false,
              // ambulance:false,
              // miscellaneous:false,
              // packages:false
              roomConfiguration:false,
              AmbulanceCharges: false,
              ICUCharges: false,
              InvestigationCharges: false,
              MedAndConsumablesCharges: false,
              MiscellaneousCharges: false,
              MultiplePackagesDiscount: false,
              OTCharges: false,
              PackageCharges: false,
              ProfessionalFeeCharges: false,
              roomAndNursingCharges: false,
            }
        }
    },
    methods:{
      tabseleted(text){
            console.log(text,"Selected tab")
            this.selectedComponent = text;
      },

      enableTabs(data){
        console.log("%c this is the emit event","color:blue",data);

         if(data.emitType == 'all'){
             this.tabStatus = data;
         }else{
             this.tabStatus[data.emitType] = data[data.emitType]
         }

          this.shwtabs = false
      },

      disableTabs(){
          console.log("%c this is the emit event","color:blue");
          this.shwtabs = true
      },
    }
    
}
</script>
<style>

.tarrifConfgSelected{
  background-color: #23B1A9 !important;
  color:#FFFFFF !important;
  
}
.cust_brdr_green{
  /* z-index:200 !important; */
  border-bottom-width:5px !important;
  border-bottom-color:green !important;
  border-bottom-style: solid !important ;
  
  

}
.cust_brdr_red{
   border-bottom-width:5px !important;
  border-bottom-color:red !important;
  border-bottom-style: solid !important ;
}

</style>