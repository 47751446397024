<template>
  <div class="ma-3 mt-8">
    <v-form ref="createForm">
      <v-expansion-panels v-model="panels" multiple>
        <v-expansion-panel>
          <v-expansion-panel-header color="#1EBBD9">
            <span class="page-title-small white--text">Court Details</span>
            <template v-slot:actions>
              <v-icon class="white--text">
                {{
                  panels.indexOf(0) !== -1 ? "mdi-minus-box" : "mdi-plus-box"
                }}
              </v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row class="mt-3">
              <v-col>
                <label class="form-label ml-1"
                  >Court Type
                  <span class="red--text"><strong> * </strong></span>
                </label>
                <v-select
                  class="form-control"
                  v-model="formData.courtType"
                  placeholder="Select"
                  :disabled="isView"
                  :items="courtTypeItems"
                  :rules="[(v) => requiredField(v)]"
                  dense
                  solo
                  required
                ></v-select>
              </v-col>
              <v-col>
                <label class="form-label"
                  >Court Name
                  <span class="red--text"><strong> * </strong></span>
                </label>
                <v-text-field
                  placeholder="Enter..."
                  class="form-control"
                  solo
                  :disabled="isView"
                  dense
                  v-model="formData.courtName"
                  depressed
                  :rules="[(v) => requiredField(v)]"
                />
              </v-col>
              <v-col>
                <label class="form-label"
                  >Address Line 1
                  <span class="red--text"><strong> * </strong></span>
                </label>
                <v-text-field
                  placeholder="Enter..."
                  class="form-control"
                  solo
                  :disabled="isView"
                  dense
                  v-model="formData.addressLine1"
                  depressed
                  :rules="[(v) => requiredField(v)]"
                />
              </v-col>
              <v-col>
                <label class="form-label"
                  >Address Line 2
                  <span class="red--text"><strong> * </strong></span></label
                >
                <v-text-field
                  placeholder="Enter..."
                  class="form-control"
                  solo
                  :disabled="isView"
                  dense
                  v-model="formData.addressLine2"
                  depressed
                  :rules="[(v) => requiredField(v)]"
                />
              </v-col>
              <v-col>
                <label class="form-label"
                  >Address Line 3
                  <span class="red--text"><strong> * </strong></span></label
                >
                <v-text-field
                  placeholder="Enter..."
                  class="form-control"
                  solo
                  dense
                  :disabled="isView"
                  v-model="formData.addressLine3"
                  depressed
                  :rules="[(v) => requiredField(v)]"
                />
              </v-col>
            </v-row>
            <v-row>
              <!-- <v-col>
                  <label class="form-label"
                    >Pin Code
                    <span class="red--text"><strong> * </strong></span></label
                  >
                  <v-text-field
                    placeholder="Enter..."
                    class="form-control"
                    solo
                    dense
                    v-model="formData.pinCode"
                    depressed
                    :rules="[(v) => requiredField(v)]"
                  />
                </v-col> -->
              <v-col>
                <PincodeSearch
                  :pinCode="formData.pinCode"
                  @on-change="getPincodes"
                  :isDisabled="isView"
                  :pincodeRequired="true"
                />
              </v-col>
              <v-col>
                <label class="form-label"
                  >City
                  <span class="red--text"><strong> * </strong></span></label
                >
                <v-text-field
                  placeholder="Enter..."
                  class="form-control"
                  solo
                  disabled
                  dense
                  v-model="formData.city"
                  depressed
                  :rules="[(v) => requiredField(v)]"
                />
              </v-col>
              <v-col>
                <label class="form-label"
                  >District
                  <span class="red--text"><strong> * </strong></span></label
                >
                <v-text-field
                  placeholder="Enter..."
                  class="form-control"
                  solo
                  disabled
                  dense
                  v-model="formData.district"
                  depressed
                  :rules="[(v) => requiredField(v)]"
                />
              </v-col>
              <v-col>
                <label class="form-label"
                  >State
                  <span class="red--text"><strong> * </strong></span></label
                >
                <v-text-field
                  placeholder="Enter..."
                  class="form-control"
                  solo
                  dense
                  disabled
                  v-model="formData.state"
                  depressed
                  :rules="[(v) => requiredField(v)]"
                />
              </v-col>
              <v-col>
                <v-checkbox
                  class="form-control"
                  v-model="formData.isActive"
                  dense
                  label="Active"
                  :disabled="isView"
                >
                </v-checkbox>
              </v-col>
            </v-row>
            <v-row style="max-width:20.3%">
              <v-col>
                <v-checkbox
                  class="form-control"
                  v-model="formData.isWatchList"
                  dense
                  label="Watch List"
                  :disabled="isView"
                >
                </v-checkbox>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header color="#1EBBD9">
            <span class="page-title-small white--text">Bank Details</span>
            <template v-slot:actions>
              <v-icon class="white--text">
                {{
                  panels.indexOf(1) !== -1 ? "mdi-minus-box" : "mdi-plus-box"
                }}
              </v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-layout wrap class="pt-4 form-group-container">
              <v-flex class="form-group">
                <v-checkbox
                  class="form-control"
                  label="Bank Details Available"
                  v-model="formData.isBankDetailsAvailable"
                  @change="checkBankDetails"
                  :disabled="isView"
                >
                </v-checkbox>
              </v-flex>
              <v-spacer></v-spacer>
              <div class="me-3 px-2 mb-2" v-if="cancelledChequeData.length > 0">
                <label
                  :disabled="!isOCRUploaded"
                  class="form-label mt-2 text-right"
                  @click="cancelChequeView"
                >
                  <div
                    style="color:#0AB417; font: normal normal medium 12px/14px Roboto;cursor:pointer"
                  >
                    <v-icon color="#0AB417">
                      mdi-eye
                    </v-icon>
                    <span class="ml-2">View Cancelled Cheque</span>
                  </div>
                </label>
              </div>

              <div>
                <p
                  v-show="formData.isBankDetailsAvailable"
                  :class="isView ? 'mr-8 grey--text' : 'teal--text mr-8'"
                  :disabled="isView"
                  style="cursor:pointer"
                  @dragover="dragoverCancel"
                  @drop="dropCancel"
                  @click="browseCancel"
                >
                  <v-icon :color="isView ? '#9E9E9E' : '#23B1A9'" class="mr-3"
                    >mdi-tray-arrow-up</v-icon
                  >Upload Canceled Cheque
                  <v-file-input
                    style="display:none;"
                    v-model="cancelledCheque"
                    id="cancelchequeupload"
                    label=""
                    dense
                    solo
                    @change="OCRUpload"
                    :disabled="isView"
                    accept="image/png,image/jpeg,application/msword,application/pdf,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  />
                </p>
              </div>
            </v-layout>
            <v-layout wrap class="pt-4 form-group-container">
              <v-layout
                class="py-4 cancel-cheque-view-port"
                v-if="cancelledChequeFileShow && cancelledChequeData.length > 0"
              >
                <v-flex xs12>
                  <div
                    class="close-cancell-cheque"
                    @click="cancelledChequeFileShow = !cancelledChequeFileShow"
                  >
                    Close
                  </div>
                  <v-layout justify-center>
                    <object
                      :data="
                        cancelledChequeData[0].fileBase
                          ? cancelledChequeData[0].fileBase
                          : showCheque
                      "
                      width="1000"
                      height="300"
                    ></object>
                    <!-- <img height="300" :src="investigatorMasterObj.documents.fileBase" /> -->
                  </v-layout>
                  <!-- <v-layout justify-center class="mt-2">
                                              <div class="cancel-cheque-success-btn">OCR was successfully completed.</div>
                                          </v-layout> -->
                </v-flex>
              </v-layout>
            </v-layout>

            <v-layout wrap class="pt-4 form-group-container">
              <v-flex
                class="form-group mb-2"
                v-if="formData.isBankDetailsAvailable"
              >
                <v-checkbox
                  class="form-control"
                  v-model="formData.isOverWritten"
                  :disabled="
                    isView ||
                      (!confirmDisable
                        ? !cancelledChequeData.length > 0
                        : confirmDisable)
                  "
                  @click="overWrittingChange"
                  dense
                  label="Overwritten"
                >
                </v-checkbox>
              </v-flex>
              <v-flex class="form-group">
                <label class="form-label ml-1">IFSC Code</label>
                <v-autocomplete
                  class="form-control"
                  v-model="formData.ifscCode"
                  placeholder="Select"
                  :items="IFSCCodes"
                  disabled
                  item-text="ifscCode"
                  dense
                  solo
                  required
                ></v-autocomplete>
              </v-flex>
              <v-flex class="form-group">
                <label class="form-label">Bank Name</label>
                <v-text-field
                  placeholder="Enter..."
                  class="form-control"
                  disabled
                  solo
                  dense
                  v-model="formData.bankName"
                  depressed
                />
              </v-flex>
              <v-flex class="form-group">
                <label class="form-label">Account Holder Name</label>
                <v-text-field
                  placeholder="Enter..."
                  class="form-control"
                  solo
                  dense
                  v-model="formData.accountHolderName"
                  disabled
                  depressed
                />
              </v-flex>

              <v-flex class="form-group">
                <label class="form-label">City</label>
                <v-text-field
                  placeholder="Enter..."
                  class="form-control"
                  solo
                  disabled
                  dense
                  v-model="formData.bankCity"
                  depressed
                />
              </v-flex>
              <v-flex class="form-group">
                <label class="form-label">Bank Address Line 1</label>
                <v-text-field
                  placeholder="Enter..."
                  class="form-control"
                  solo
                  dense
                  disabled
                  v-model="formData.bankAddressLine1"
                  depressed
                />
              </v-flex>
              <v-flex class="form-group">
                <label class="form-label">Bank Address Line 2</label>
                <v-text-field
                  placeholder="Enter..."
                  class="form-control"
                  solo
                  dense
                  disabled
                  v-model="formData.bankAddressLine2"
                  depressed
                />
              </v-flex>
              <v-flex class="form-group">
                <label class="form-label">Bank Address Line 3</label>
                <v-text-field
                  placeholder="Enter..."
                  class="form-control"
                  solo
                  dense
                  disabled
                  v-model="formData.bankAddressLine3"
                  depressed
                />
              </v-flex>
              <v-flex class="form-group">
                <label class="form-label">Branch</label>
                <v-text-field
                  placeholder="Enter..."
                  class="form-control"
                  solo
                  dense
                  v-model="formData.bankBranch"
                  depressed
                  disabled
                />
              </v-flex>
              <v-flex class="form-group">
                <label class="form-label">District</label>
                <v-text-field
                  placeholder="Enter..."
                  class="form-control"
                  solo
                  dense
                  v-model="formData.bankDistrict"
                  depressed
                  disabled
                />
              </v-flex>
              <v-flex class="form-group">
                <label class="form-label">State</label>
                <v-text-field
                  placeholder="Enter..."
                  class="form-control"
                  solo
                  disabled
                  dense
                  v-model="formData.bankState"
                  depressed
                />
              </v-flex>
              <v-flex class="form-group">
                <label class="form-label">Pin Code</label>
                <v-text-field
                  placeholder="Enter..."
                  class="form-control"
                  solo
                  dense
                  v-model="formData.bankPincode"
                  depressed
                  disabled
                />
              </v-flex>
              <v-flex class="form-group">
                <label class="form-label">Bank Account Number</label>
                <v-text-field
                  placeholder="Enter..."
                  class="form-control"
                  solo
                  dense
                  disabled
                  v-model="formData.bankAccountNumber"
                  depressed
                />
              </v-flex>
              <v-flex class="form-group">
                <label class="form-label">Re-enter Bank Account Number</label>
                <v-text-field
                  placeholder="Enter..."
                  class="form-control"
                  solo
                  dense
                  v-model="formData.ReEnterBankAccountNumber"
                  disabled
                  depressed
                />
              </v-flex>
              <v-flex class="form-group">
                <label class="form-label ml-1">Account Type</label>
                <v-select
                  class="form-control"
                  v-model="formData.AccountType"
                  placeholder="Select"
                  :disabled="isView"
                  :items="['Savings', 'Current']"
                  dense
                  solo
                  required
                ></v-select>
              </v-flex>
            </v-layout>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <!-- // upload Documents -->
        <v-expansion-panel>
          <v-expansion-panel-header color="#1EBBD9">
            <span class="page-title-small white--text">Upload Documents</span>
            <template v-slot:actions>
              <v-icon class="white--text">
                {{
                  panels.indexOf(3) !== -1 ? "mdi-minus-box" : "mdi-plus-box"
                }}
              </v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-layout wrap class="pt-4 form-group-container">
              <v-tabs v-model="tab" hide-slider>
                <!-- active-class="claimProcessingTabsActive" -->
                <v-tab
                  v-for="item in tab_items"
                  :key="item"
                  @change="
                    documentTab = item
                    checkTabChanged()
                  "
                  :class="
                    documentTab == item
                      ? 'claimProcessingTabsActive'
                      : 'unActive'
                  "
                >
                  {{ item }}
                </v-tab>
              </v-tabs>
              <v-layout class="mt-5">
                <div :disabled="isView">
                  <div
                    class="neftStyle"
                    :disabled="isView"
                    @dragover="dragover"
                    @dragleave="dragleave"
                    @drop="drop"
                    :class="visualEffect ? 'dragbackground' : ''"
                  >
                    <v-icon color="#23b1a9" class="iconfont"
                      >mdi-tray-arrow-up</v-icon
                    >
                    <span
                      class="ml-2 margincls font-weight-bold"
                      :disabled="isView"
                      >Drop Files To Upload or
                      <a @click="browse"><u> Browse</u></a></span
                    >
                    <p class="margincls dlt_text mb-0">
                      Max 5MB of PDF or Image(.jpeg, .jpg, .png)
                    </p>
                    <p class="dlt_text mt-0 ml-4" style="font-size:10px">
                      Multiple documents can be uploaded
                    </p>
                  </div>
                  <span></span>
                  <v-file-input
                    multiple
                    v-model="all_attachments"
                    @change="selectFile"
                    accept=".doc, .docx, .csv, image/png,image/jpeg,application/msword,application/pdf,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.wordprocessingml.document, text/csv"
                    id="SP_fileupload"
                    :disabled="isView"
                    style="display: none"
                  >
                  </v-file-input>
                  <!-- @change="selectFile" -->
                </div>
                <div>
                  <v-data-table
                    :headers="neftHeaders"
                    :items="documentAttachments"
                    hide-default-footer
                    no-data-text="No Records for Attachements"
                    class="tabelwidth vDataTableStyling"
                  >
                    <!-- <template v-slot:[`item.file`]="{item}">     
                     {{item.fileName}}
                      </template> -->
                    <template v-slot:[`item.action`]="{ item, index }">
                      <v-icon
                        class="tableicons mr-4"
                        color="#23B1A9"
                        @click="viewDocument(item)"
                        >mdi-eye</v-icon
                      >
                      <v-icon
                        class="tableicons mr-4"
                        color="#23B1A9"
                        @click="downloadDoc(item)"
                        >mdi-download</v-icon
                      >
                      <v-icon
                        class="tableicons"
                        color="#D1121B"
                        @click="deleteDocument(index)"
                        v-if="!item.documentId"
                        :disabled="isView"
                        >mdi-delete</v-icon
                      >
                    </template>
                  </v-data-table>
                </div>
              </v-layout>
            </v-layout>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-form>
    <v-layout wrap class="mt-5">
      <v-layout class="mt-0">
        <v-btn
          type="button"
          color="red"
          outlined
          class="ml-3 mx-2"
          @click="onCancel(false)"
        >
          <v-icon> mdi-backspace </v-icon>

          <v-divider class="mx-2 ml-5" vertical></v-divider>
          <span class="subheading">Cancel</span>
        </v-btn>
        <v-spacer></v-spacer>

        <v-btn
          outlined
          color="red"
          class="btn mr-5"
          v-show="isView"
          @click="reject_Dialog = !reject_Dialog"
        >
          <v-icon> mdi-backspace </v-icon>
          <v-divider vertical class="mx-2"> </v-divider> Reject</v-btn
        >

        <v-btn
          class="btn"
          color="teal white--text"
          dense
          solo
          v-show="isView"
          @click="approve_Dialog=!approve_Dialog"
          ><v-icon>mdi-arrow-right-circle</v-icon>
          <v-divider vertical class="mx-2"></v-divider>
          Approve</v-btn
        >
      </v-layout>
    </v-layout>

    <v-dialog v-model="reject_Dialog" width="600">
                <v-card>
                    <v-card-title class="white--text" style="background-color: #23b1a9"
                    ><v-layout style="display:flex;justify-content:center;margin-left: 170px;" >Reject
                       </v-layout>
                       <v-layout style="display:flex;justify-content:end;">
                        <v-icon class="white--text " @click="reject_Dialog=!reject_Dialog;reason=''">mdi-close</v-icon>
                      </v-layout>
                      </v-card-title>
                      <v-form ref="rejectForm" @submit="false">
                        <v-layout class="pt-4 form-group-container" >
                          
                            <v-flex class="mr-2">
                                <label class="form-label">Reason <span><strong class="red--text">*</strong></span></label>
                                <v-text-field  class="form-control" solo dense :rules="reject_Dialog ? [(v)=>requiredField(v)]:[]" v-model="reason" placeholder="Enter..."></v-text-field>
                            </v-flex>
                          
                        </v-layout>
                      </v-form>
                        <v-card-actions style="display:flex;justify-content:center">
                          <v-btn class="btn" color="red white--text mr-0" @click="reject_Dialog=!reject_Dialog;reason=''">
                            No
                        </v-btn>

                        <v-btn class="btn" color="green white--text mr-0" @click="onSubmit">
                            Yes
                        </v-btn>
                      </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog v-model="approve_Dialog" width="600">
                <v-card>
                    <v-card-title class="white--text" style="background-color: #23b1a9"
                    ><v-layout style="display:flex;justify-content:center;margin-left: 170px;" >Approval
                       </v-layout>
                       <v-layout style="display:flex;justify-content:end;">
                        <v-icon class="white--text " @click="approve_Dialog=!approve_Dialog;reason=''">mdi-close</v-icon>
                      </v-layout>
                      </v-card-title>
                        <v-layout class="pt-4 form-group-container justify-center my-5" >
                           
                            <h4>Are u sure Want to Approve ?</h4>
                        </v-layout>
                        <v-card-actions style="display:flex;justify-content:center">
                          <v-btn class="btn" color="red white--text mr-0" @click="approve_Dialog=!approve_Dialog;reason=''">
                            No
                        </v-btn>

                        <v-btn class="btn" color="green white--text mr-0" @click="onSubmit">
                            Yes
                        </v-btn>
                      </v-card-actions>
                </v-card>
            </v-dialog>
  </div>
</template>

<script>
import PincodeSearch from "../../../../component/PincodeSearch.vue"
export default {
  data() {
    return {
      showCheque:"",
      courtTypeItems: [
        "High Court",
        "Supreme Court",
        "District Consumer Forum",
        "State Consumer Forum",
        "NCDRC",
        "E.C.C",
        "PLA",
        "Ombudsman",
        "Civil Court",
        "Other",
      ],
      reject_Dialog:false,
      approve_Dialog:false,
      tab: 0,
      reason:"",
      panels: [0, 1, 2, 3],
      isView: false,
      cancelledChequeData: [],
      ifscCode: [],
      courtDetails:{},
      isOCRUploaded: false,
      cancelledChequeFileShow: false,
      cancelledCheque: [],
      bankAccount: "",
      bankDetailsDisable: true,
      otherAttachment: [],
      modifyItems: [],
      formData: {
        //court Detail
        courtType: "",
        courtName: "",
        pinCode: "",
        city: "",
        district: "",
        state: "",
        addressLine1: "",
        addressLine2: "",
        addressLine3: "",
        //Bank Details
        isBankDetailsAvailable: false,
        ifscCode: "",
        bankName: "",
        accountHolderName: "",
        isOverWritten: false,
        bankCity: "",
        bankAddressLine1: "",
        bankAddressLine2: "",
        bankAddressLine3: "",
        bankBranch: "",
        bankDistrict: "",
        bankState: "",
        bankPincode: "",
        bankAccountNumber: "",
        ReEnterBankAccountNumber: "",
        AccountType: "",
        isActive: false,
        isWatchList: false,
      },
      documentAttachments: [],
      confirmDisable: false,
      neftAttachment: [],
      all_attachments: [],
      IFSCCodes: [],
      tab_items: ["NEFT Form", "Others"],
      neftHeaders: [
        {
          text: "Document Name",
          align: "start",
          value: "fileName",
          sortable: false,
        },
        { text: "Action", align: "start", value: "action", sortable: false },
      ],
      documentTab: "NEFT Form",
      visualEffect: false,
    }
  },
  methods: {
    // approve_Dialog(){
    //   this.showConfirm('Approval','Are u sure Want to Approve','Yes','No',(Yes)=>{
    //     this.onSubmit()
    //   })
    // },
    
    confirmReset() {
      let resetDataForm = {
        //court Detail
        courtType: "",
        courtName: "",
        pinCode: "",
        city: "",
        district: "",
        state: "",
        addressLine1: "",
        addressLine2: "",
        addressLine3: "",
        //Bank Details
        isBankDetailsAvailable: false,
        ifscCode: "",
        bankName: "",
        accountHolderName: "",
        isOverWritten: false,
        bankCity: "",
        bankAddressLine1: "",
        bankAddressLine2: "",
        bankAddressLine3: "",
        bankBranch: "",
        bankDistrict: "",
        bankState: "",
        bankPincode: "",
        bankAccountNumber: "",
        ReEnterBankAccountNumber: "",
        AccountType: "",
        isActive: false,
        isWatchList: false,
      }
      this.formData = resetDataForm
      this.documentAttachments = []
      this.neftAttachment = []
      this.otherAttachment = []
      this.cancelledChequeData = []
    },
    viewDocumentCheque(id){
        let requestData = "documentId=" + id + "&b64=true";
        this.GETM(
          "VIEW_DOCUMENT",
          requestData,
          (res) => {
            if (res.data.statusCode === 200) {
              this.showCheque=res.data.data
            }
          },
          (error) => {
            console.log("Error", error);
          },
          true
        );
      },
    onReset() {
      this.showConfirm(
        "Confirmation",
        "Are you sure you want to Reset?",
        "Yes",
        "No",
        (Yes) => {
          this.confirmReset()
        },
        (No) => {}
      )
    },
    getPincodes(value) {
      this.formData.pinCode = value.pinCode
      this.formData.city = value.pinCodeLocality
      this.formData.district = value.cityDistrictName //cityDistrictName
      this.formData.state = value.stateName
    },
    overWrittingChange(value) {
      if (value) {
        this.showConfirm(
          "Confirmation",
          "Once checked cannot be unchecked. Do you want to proceed ?",
          "Yes",
          "No",
          (Yes) => {
            return (this.confirmDisable = true)
          },
          () => {
            this.formData.isOverWritten = false
          }
        )
      }
    },
    handleBankAvailablechange(value) {
      if (value) this.showAlert("Please Upload Cancelled Cheque")
    },
    onCancel(onSubmit) {
      if(onSubmit){
      this.$router.push({name:'SearchCourtApprovals'} );
    } else {
      this.showConfirm("Confirmation","Are you sure you want to leave?","Yes","No",(Yes) => {
         
          this.$router.push({name:'SearchCourtApprovals'} );//params:{pageNo:this.pageNo}}
          
      })
    }
     
    },
    checkBankDetails(value) {
      this.handleBankAvailablechange(value)
    },
    clearBankDetails() {},
    seeModify(item) {
      console.log(item.modification)
      this.seeModifyDialog = true
      this.modifyItems = item.modification.map((m, index) => {
        let object = { ...m, srNo: index + 1 }
        if (this.dateFields.includes(m.fieldName)) {
          object.newValue = this.formatDateOnly(m.newValue)
          object.oldValue = this.formatDateOnly(m.oldValue)
        }
        return object
      })
    },
    getPayload() {
      let data = this.formData
      let documents = []
      return {
        court: {
          type: data.courtType,
          name: data.courtName,
          city: data.city,
          addressLine1: data.addressLine1,
          addressLine2: data.addressLine2,
          addressLine3: data.addressLine3,
          district: data.district,
          state: data.district,
          pinCode: data.pinCode,
        },
        approveRequest: false,
        bank: {
          accountHolderName: data.accountHolderName,
          bankDetails: data.isBankDetailsAvailable,
          bankName: data.bankName,
          ifscCode: data.ifscCode,
          documents: [
            ...this.cancelledChequeData,
            ...this.otherAttachment,
            ...this.neftAttachment,
          ],
          city: data.bankCity,
          bankAddressLine1: data.bankAddressLine1,
          bankAddressLine2: data.bankAddressLine2,
          bankAddressLine3: data.bankAddressLine3,
          branch: data.bankBranch,
          district: data.bankDistrict,
          state: data.bankState,
          pinCode: data.bankPincode,
          accountNumber: this.bankAccount,
          confirmAccountNumber: this.bankAccount,
          accountType: data.AccountType,
          active: data.isActive,
          watchList: data.isWatchList,
        },
      }
    },
    onSubmit(){
      if(this.reject_Dialog){
      if(!this.$refs?.rejectForm?.validate()){
              return this.showAlert("Please fill mandatory fields.");
            }
          }
          let sendData={approveStatus:this.reject_Dialog ? "Rejected" : "Approved",
          rejectionReason:this.reason}
          this.courtDetails.approveStatus=this.reject_Dialog ? "Rejected" : "Approved"
          this.courtDetails.rejectionReason=this.reason
          // this.frequencyPayee.approveStatus=this.reject_Dialog ? "Rejected" : "Approved"
          this.reject_Dialog=false
          this.approve_Dialog=false

        this.PUTM(
          "updateMasterApprove",this.$route.params.item._id,
          sendData,
          (res) => {
            this.hideProgress();
            if (res.data && res.data.statusCode == 200) {
              this.showAlert(res?.data?.msg || "Success", "", "", () => {
               this.onCancel(true)               
              });
            } else {
              this.showAlert(res.data.msg);
              this.hideProgress();
            }
          },
          (err) => {
            console.log(err);
            this.hideProgress();
            let msg =
              err?.response?.data?.msg || "Unable to submit court Master Approval.";
            this.showAlert(msg);
          },true
        );
      
     
  },
    dragleave(event) {
      this.visualEffect = false
    },
    browseCancel() {
      //to select attachments
      document.getElementById("cancelchequeupload").click()
    },
    viewDocUsingBase(base64) {
      const base64ImageData = base64
      const contentType = base64.split(":")[1].split(";")[0]
      const byteCharacters = window.atob(
        base64ImageData.substr(`data:${contentType};base64,`.length)
      )
      const byteArrays = []
      for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
        const slice = byteCharacters.slice(offset, offset + 1024)
        const byteNumbers = new Array(slice.length)
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i)
        }
        const byteArray = new Uint8Array(byteNumbers)
        byteArrays.push(byteArray)
      }
      const blob = new Blob(byteArrays, { type: contentType })
      const blobUrl = window.URL.createObjectURL(blob)
      window.open(blobUrl, "_blank")
    },
    getDistrictState(pinCode) {
      if (pinCode === "") return
      let reqData = "pageNo=1&perPage=100&pinCode=" + pinCode
      this.GETM(
        "getPincodes",
        reqData,
        (res) => {
          let result = res.data.data.data[0]
          this.formData.bankCity = result.cityDistrictName
          this.formData.bankState = result.stateName
          this.formData.bankDistrict = result.cityDistrictName
        },
        (err) => {
          console.error("Issue while fetching pin codes.", err)
        }
      )
    },
    dragover(event) {
      event.preventDefault()
      this.visualEffect = true
    },
    viewDocument(itemData) {
      if (itemData.fileBase) {
        this.viewDocUsingBase(itemData.fileBase)
      } else {
        let requestData = ""
        if (itemData !== null) {
          requestData = "documentId=" + itemData.documentId + "&b64=true"
        }
        this.GETM(
          "VIEW_DOCUMENT",
          requestData,
          (res) => {
            if (res.data.statusCode === 200) {
              this.viewDocUsingBase(res.data.data)
            }
          },
          (error) => {
            console.log("Error", error)
          },
          true
        )
      }
    },
    dropCancel(event) {
      event.preventDefault()
      this.cancelledCheque = event.dataTransfer.files
      // console.log("all", this.all_attachments);
      this.OCRUpload() // Trigger the onChange event manually
    },
    dragoverCancel(event) {
      event.preventDefault()
    },
    cancelChequeView() {
      this.cancelledChequeFileShow = !this.cancelledChequeFileShow
    },
    downloadDoc(item) {
      if (item.fileBase) {
        let base64 = item.fileBase
        const base64ImageData = base64
        const contentType = base64.split(":")[1].split(";")[0]
        const byteCharacters = window.atob(
          base64ImageData.substr(`data:${contentType};base64,`.length)
        )
        const byteArrays = []
        for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
          const slice = byteCharacters.slice(offset, offset + 1024)
          const byteNumbers = new Array(slice.length)
          for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i)
          }
          const byteArray = new Uint8Array(byteNumbers)
          byteArrays.push(byteArray)
        }
        const blob = new Blob(byteArrays, { type: contentType })
        const blobUrl = window.URL.createObjectURL(blob)

        const element = document.createElement("a")
        element.href = blobUrl
        element.download = item.fileName
        element.click()
      } else {
        let url =
          this.getURL("VIEW_DOCUMENT") + "?documentId=" + item.documentId
        window.open(url)
      }
    },
    OCRUpload() {
      if (this.cancelledCheque) {
        console.log("4444", this.cancelledCheque)
        this.cancelledChequeFileShow = false //hide view port
        this.isOCRUploaded = false //check if OCR uploaded
        this.formData.isOverWritten = false //check for manual entry
        this.clearBankDetails() //reset bank fields
        if (!this.cancelledCheque.name) {
          return // for while resetting the form
        }
        this.showProgress()

        this.readImageM(
          this.cancelledCheque.name,
          this.cancelledCheque,
          (res) => {
            let requestPayload = {
              document: res.fileBase,
            }
            this.cancelledCheque = [] //reset field value
            this.POSTM(
              "GET_OCR_FORMAT",
              requestPayload,
              (response) => {
                this.hideProgress()
                if (
                  response.data.data.Message ==
                  "Uploaded Image/Document orientation is incorrect"
                ) {
                  this.showAlert(response.data.data.Message)
                  this.formData.isOverWritten = true
                } else if (
                  response.data.data.Message == "Invalid Image/Document"
                ) {
                  this.showAlert(response.data.data.Message)
                  this.formData.isOverWritten = true
                } else if (response.data.data) {
                  let bankres = response.data.data
                  console.log(response.data.data)
                  if (
                    !bankres.AccountNumber ||
                    !bankres.IFSC ||
                    !bankres.PayerName
                  ) {
                    this.isOCRUploaded = true

                    this.formData.isOverWritten = true
                    this.showAlert(
                      "Could not retrieve data from OCR either re-upload the cancelled cheque or enter the details manually"
                    )
                  } else {
                    //temprory fix
                    this.showProgress()
                    let request = bankres.IFSC + "?lob=Marine"
                    this.GET_ByURLM(
                      "GET_GC_BANK",
                      request,
                      (res) => {
                        this.IFSCCodes = []
                        this.hideProgress()
                        console.log(res.data.data)
                        if (res.data.data && res.data.data.length > 0) {
                          let ifscCodeValue = res.data.data[0]
                          console.log("9999", bankres)
                          console.log("33333", ifscCodeValue)
                          this.IFSCCodes = res.data.data
                          this.formData.ifscCode = bankres.IFSC
                          this.formData.bankAccountNumber =
                            bankres.AccountNumber
                          this.checkBankAccount()
                          this.formData.ReEnterBankAccountNumber =
                            bankres.AccountNumber
                          this.formData.bankBranch = ifscCodeValue.branchName

                          this.formData.bankName = ifscCodeValue.financierName
                          this.formData.bankAddressLine1 =
                            ifscCodeValue.branchAddress
                          let address2 = ""
                          if (ifscCodeValue.district) {
                            address2 += `${ifscCodeValue.district}, `
                          }
                          if (ifscCodeValue.city) {
                            address2 += `${ifscCodeValue.city}, `
                          }
                          if (ifscCodeValue.pincode) {
                            address2 += ifscCodeValue.pincode
                          }
                          this.formData.bankAddressLine2 = address2
                          this.formData.bankAddressLine3 = ifscCodeValue.state
                          this.formData.bankPincode = ifscCodeValue.pincode
                          this.formData.bankState = ifscCodeValue.state

                          this.getDistrictState(ifscCodeValue.pincode)

                          // this.pincode1=ifscCodeValue.pincode

                          // this.changePincodeAndState(1)
                          // this.formDataAddressLine2 = `${ifscCodeValue.district}, ${ifscCodeValue.city}, ${ifscCodeValue.state}`
                        }
                      },
                      (error) => {
                        this.IFSCCodes = []
                        this.hideProgress()
                        console.log("error", error)
                        this.showAlert(
                          error.response &&
                            error.response.data &&
                            error.response.data.message
                            ? error.response.data.message
                            : error.message
                        )
                      }, true
                    )
                    this.cancelledChequeData.push({
                      fileName: res.file,
                      fileBase: res.fileBase,
                      documentType: "Cancelled Cheque",
                    })

                    // this.formData.AccountNumber = bankres.AccountNumber;
                    // this.reEnterBankAccountNumber = bankres.AccountNumber;
                    this.formData.accountHolderName = bankres.PayerName
                    this.isOCRUploaded = true
                  }
                } else {
                  this.showAlert("No data found from OCR")
                }
              },
              (error) => {
                this.hideProgress()
                this.formData.isOverWritten = true
                this.showAlert(
                  "Somthing went wrong in OCR,Please enter the details manually"
                )
              },
              true
            )
          }
        )
      } else {
        this.showAlert("Please upload a file.")
      }
    },
    checkBankAccount() {
      this.bankAccount = this.formData.bankAccountNumber
      this.formData.bankAccountNumber = ""
      for (let i = 0; i <= this.bankAccount.length - 1; i++) {
        this.formData.bankAccountNumber += "*"
      }
    },
    deleteDocument(index) {
      // if(type === 'neft') {
      //     this.neftAttachments.splice(index, 1);
      // }

      // if(type === 'kyc') {
      //     this.kycAttachments.splice(index, 1);
      // }

      // if(type === 'others') {
      //     this.otherAttachments.splice(index, 1);
      // }
      // this.all_attachments = []
      if (this.documentTab == "NEFT Form") {
        this.neftAttachment.splice(index, 1)
      }

      if (this.documentTab == "Others") {
        this.otherAttachment.splice(index, 1)
      }
    },
    browse() {
      //to select attachments
      document.getElementById("SP_fileupload").click()
    },

    drop(event) {
      event.preventDefault()
      this.all_attachments = event.dataTransfer.files
      // console.log("all", this.all_attachments);
      this.selectFile() // Trigger the onChange event manually
      this.visualEffect = false
    },

    checkTabChanged() {
      if (this.documentTab == "NEFT Form") {
        this.documentAttachments = this.neftAttachment
      }
      if (this.documentTab == "Others") {
        this.documentAttachments = this.otherAttachment
      }
    },
    selectFile() {
      if (this.all_attachments.length !== 0) {
        const fsize = this.all_attachments[0].size
        const file = Math.round(fsize / 1024)
        let self = this
        if (file > 5120) {
          self.showAlert("File is too big, please select a file less than 5MB")
        } else {
          self.readImageM(
            self.all_attachments[0].name,
            self.all_attachments[0],
            (res) => {
              console.log(res)
              var type = []
              type = self.all_attachments[0].type
              console.log("--", self.documentTab)
              if (self.documentTab == "NEFT Form") {
                if (self.neftAttachment.length < 5) {
                  self.neftAttachment.push({
                    fileName: res.file,
                    fileBase: res.fileBase,
                    documentType: "NEFT Form",
                  })
                  self.neftAttachment = self.neftAttachment.reduce(
                    (unique, o) => {
                      if (!unique.some((obj) => obj.fileName === o.fileName)) {
                        unique.push(o)
                      }
                      return unique
                    },
                    []
                  )
                } else {
                  self.neftAttachment.splice(0, 1)
                  self.neftAttachment.push({
                    fileName: res.file,
                    fileBase: res.fileBase,
                    documentType: "NEFT Form",
                  })
                  self.neftAttachment = self.neftAttachment.reduce(
                    (unique, o) => {
                      if (!unique.some((obj) => obj.fileName === o.fileName)) {
                        unique.push(o)
                      }
                      return unique
                    },
                    []
                  )
                }
                console.log("---neft", self.neftAttachment)
              } else if (self.documentTab == "Others") {
                if (self.otherAttachment.length < 5) {
                  self.otherAttachment.push({
                    fileName: res.file,
                    fileBase: res.fileBase,
                    documentType: "OTHERS",
                  })
                  self.otherAttachment = self.otherAttachment.reduce(
                    (unique, o) => {
                      if (!unique.some((obj) => obj.fileName === o.fileName)) {
                        unique.push(o)
                      }
                      return unique
                    },
                    []
                  )
                } else {
                  self.otherAttachment.splice(0, 1)
                  self.otherAttachment.push({
                    fileName: res.file,
                    fileBase: res.fileBase,
                    documentType: "OTHERS",
                  })
                  self.otherAttachment = self.otherAttachment.reduce(
                    (unique, o) => {
                      if (!unique.some((obj) => obj.fileName === o.fileName)) {
                        unique.push(o)
                      }
                      return unique
                    },
                    []
                  )
                }
              }
              // // Phase-2 function call starts
              // this.getCancelledChequeData(res.base);
              // this.manualOverwrite = false;
              // // Phase-2 function call ends
            }
          )
        }
      }
      this.checkTabChanged()
    },
    getModifyDoc(documents) {
      let result = documents.map((doc) => {
        let { fileName, ...rest } = doc
        rest.file = fileName
        return rest
      })
      return result
    },
    populateFields(result) {
      console.log(result)
      //court Detail
      this.formData.courtType = result.court.type
      this.formData.courtName = result.court.name
      this.formData.pinCode = result.court.pinCode
      this.formData.city = result.court.city
      this.formData.district = result.court.district
      this.formData.state = result.court.state
      this.formData.addressLine1 = result.court.addressLine1
      this.formData.addressLine2 = result.court.addressLine2
      this.formData.addressLine3 = result.court.addressLine3
      this.getDistrictState(result.bank.bankPincode)

      //Bank Details
      this.IFSCCodes.push(result.bank.ifscCode)
      this.formData.isBankDetailsAvailable = result.bank.bankDetails
      this.formData.bankName = result.bank.bankName
      this.formData.accountHolderName = result.bank.accountHolderName
      this.formData.bankCity = result.bank.city
      this.formData.bankAddressLine1 = result.bank.bankAddressLine1
      this.formData.bankAddressLine2 = result.bank.bankAddressLine2
      this.formData.bankAddressLine3 = result.bank.bankAddressLine3
      this.formData.bankBranch = result.bank.branch
      this.formData.bankDistrict = result.bank.district
      this.formData.bankState = result.bank.state
      this.formData.ifscCode = result.bank.ifscCode
      this.formData.bankPincode = result.bank.pinCode
      this.formData.bankAccountNumber = result.bank.accountNumber //
      this.formData.ReEnterBankAccountNumber = result.bank.confirmAccountNumber
      this.bankAccount = result.bank.accountNumber
      this.formData.AccountType = result.bank.accountType
      this.formData.isActive = result.bank.active
      this.formData.isWatchList = result.bank.watchList
      this.formData.isOverWritten=result.overwritten[0].status
      // let docs = this.getModifyDoc(result.bank.documents)
      let docs = result.bank.documents
      docs.map(data=>{
        if(data.documentType=="Cancelled Cheque"){
         
          this.cancelledChequeData.push(data)
          this.viewDocumentCheque(data.documentId)
        }
        if(data.documentType=="OTHERS"){
         
          this.otherAttachment.push(data)
        }
        if(data.documentType=="NEFT Form"){
         
          this.neftAttachment.push(data)
        }
      })
      this.documentAttachments = this.neftAttachment
      this.checkBankAccount()
      
    },
    getCourt(item) {
      //GET_COURT
      let requestData = item._id
      this.GET_ByURLM(
        "getFrequentApprove",
        requestData,
        (res) => {
          // this.showAlert(err)
          if (res.data && res.data.data) {
            let result = res.data.data.operationData[0]
            this.populateFields(result)
          }
        },
        (err) => {
          this.showAlert(err)
          console.log(err)
        },
        true
      )
    },
  },
  components: {
    PincodeSearch,
  },
  created() {
    this.$store.commit("SAVE_PAGE_HEADER", "Court Master Approval");
    if (Object.keys(this.$route.params).length != 0) {
      //isView
      // let a=`${this.$route.params.item._id}`
      // this.GET_ByURLM('getFrequentApprove',a,(res)=>{
      //   console.log("RES",res.data.data)
      //   console.log(res.data.data.operationData[0],this.$route.params.isView)
      //   this.courtDetails=res.data.data
      // })
      this.isView = this.$route.params.isView
      let result = this.$route.params.item
      console.log(result)
      this.getCourt(result)
    }
  },
}
</script>

<style scoped>
.controlfield {
  box-shadow: 0px 1px 3px rgb(0 0 0 / 25%) !important;
  border: rgba(0, 0, 0, 0.125) solid 1px;
  padding: 6px;
}
.claimProcessingTabsActive {
  background-color: #1ebbd9;
  color: #fff !important;
}
.unActive {
  border: 1px solid #ccc;
  margin: 0px 5px;
}
.dragbackground {
  background-color: #e7e7e7;
}
.dlt_text {
  color: rgb(145, 145, 145);
}

.tabelwidth {
  width: 500px;
  margin: 0px 150px;
}
.neftStyle {
  height: 150px;
  width: 350px;
  border: 3px dashed #ccc;
  padding-top: 40px;
  padding-left: 16%;
}
.margincls {
  font-size: 12px;
  margin-left: -12px;
}
</style>
