<template>
  <v-app id="baseRateTDS">
    <v-container class="containerStyle">
      <v-row class="header-alignment">
        <h3>Base Rate TDS Master</h3>
        <v-spacer></v-spacer>
        <v-badge color="orange" :content="bulkCount.toString()" overlap>
          <v-btn class="btn-toggle pull-right" outlined elevation="0" @click="switchToBaseRateBulkUpload">
            <v-icon>mdi-repeat</v-icon>
            <v-divider class="mx-2 ml-2 white" vertical></v-divider>
            Switch to Bulk Upload
          </v-btn>
        </v-badge>
      </v-row>
      <v-layout no-gutters class="flex-wrap">
        <!-- TABS -->
        <div
          v-for="card in masters"
          :key="card.textTitle"
          class="master-single-card"
          @click="changeClickedTab(card.textTitle)"
          :class="{ 'selected-tab-outline': card.textTitle == selectedTab }">
          <div class="master-title">{{ card.textTitle }}</div>
          <sup><span class="data-count">{{ card.count }}</span></sup>
        </div>
      </v-layout>

      <v-layout class="mt-6">
        <h4 class="mr-3">
          Base Rate TDS Master List
          <span class="orange--text">({{ currentTabRateCount }})</span>
        </h4>
      </v-layout>

      <!-- Main Table -->
      <v-data-table
        hide-default-footer
        :headers="baseRateTableHeader"
        class="mt-4 vDataTableStyling"
        :items="baseRateTDSList"
        :class="{ 'data-available': currentTabRateCount }" must-sort>
          <template v-slot:[`item.status`]="{ item }">
            {{ item.status ? 'Active' : 'Inactive' }}
          </template>
        <template v-slot:[`item.action`]="{ item }">
          <button class="approve-button" @click="openApprovalDialog(item,true)" :disabled="!hasEditAccess" >
            APPROVE
          </button>
          <v-dialog v-model="item.approveDialog" width="600">
            <v-card>
              <v-card-title
                class="white--text"
                style="background-color: #23b1a9"
                ><v-layout style="display: flex; justify-content: center"
                >Approval
                </v-layout>
              </v-card-title>
              <v-card-text>
                <v-layout class="form-group-container justify-center my-3">
                  <h4>Are you sure want to approve ?</h4>
                </v-layout>
              </v-card-text>
              <v-card-actions style="display: flex; justify-content: center" class="bottom-alignment">
                <v-btn class="btn" color="red white--text mr-0" @click="openApprovalDialog(item, false)">
                  No
                </v-btn>

                <v-btn class="btn" color="teal white--text mr-0" @click="submitApproval(item)">
                  Yes, Approve
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <button @click="openRejectDialog(item,true)" class="reject-button" :disabled="!hasEditAccess">
            REJECT
          </button>
          <v-dialog v-model="item.rejectDialog" width="600">
            <v-card>
              <v-card-title
                class="white--text"
                style="background-color: #23b1a9"
              ><v-layout
                    style="
                    display: flex;
                    justify-content: center;
                    margin-left: 170px;
                  "
                  >Reject
                </v-layout>
                <v-layout style="display: flex; justify-content: end">
                  <v-icon
                    class="white--text"
                    @click="openRejectDialog(item,false)"
                  >mdi-close</v-icon
                  >
                </v-layout>
              </v-card-title>
              <v-card-text>
                <v-layout class="form-group-container justify-center my-3">
                  <h4>Are you sure you want to reject ?</h4>
                </v-layout>
              </v-card-text>
              <v-form ref="rejectForm" @submit="false">
                <v-layout class="form-group-container">
                  <v-flex class="mr-2">
                    <label class="form-label">Reason</label>
                    <v-text-field
                      class="form-control"
                      solo
                      dense
                      :rules="item.rejectDialog ? [(v) => requiredField(v)] : []"
                      v-model="reason"
                      placeholder="Enter..."
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-form>
              <v-card-actions style="display: flex; justify-content: center">
                <v-btn
                  class="btn"
                  color="red white--text mr-0"
                  @click="openRejectDialog(item,false)"
                >
                  No
                </v-btn>

                <v-btn
                  class="btn"
                  color="green white--text mr-0"
                  @click="submitApproval(item)"
                >
                  Yes
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </template>
      </v-data-table>
    </v-container>
  </v-app>
</template>

<script>
export default {
  data() {
    return {
      baseRateTableHeader: [
        {
          text: "Type of Vendor",
          value: "typeOfVendor",
          sortable: false,
          align: "left",
          key: "typeOfVendor",
        },
        { text: "PAN Code", value: "panCode", align: "left" },
        {
          text: "TDS with PAN",
          value: "tdsRateWithPan",
          align: "left",
          key: "tdsRateWithPan",
        },
        {
          text: "Old Value for TDS with PAN",
          value: "oldTdsRateWithPan",
          align: "left",
          key: "oldTdsRateWithPan",
        },
        {
          text: "TDS w/o PAN / Invalid",
          value: "tdsRateWithoutPan",
          align: "left",
          key: "tdsRateWithoutPan",
        },
        {
          text: "Status",
          value: "status",
          align: "left",
          key: "newStatus",
        },
        {
          text: "Approval Status",
          value: "approveStatus",
          sortable: false,
          align: "left",
          key: "approveStatus",
        },
        {
          text: "Initiated by",
          value: "initiatedBy",
          align: "left",
          key: "initiatedBy",
        },
        { text: "Action", value: "action", sortable: false, align: "left" },
      ],
      reason: "",
      baseRateTDSList: [],
      masters: [
        {
          textTitle: "Hospital",
          count: 0,
        },
        {
          textTitle: "Service Provider",
          count: 0,
        },
        {
          textTitle: "Repairer",
          count: 0
        },
        {
          textTitle: "Vendor",
          count: 0
        },
      ],
      bulkCount: 0,
      selectedTab: "Hospital",
    };
  },
  computed: {
    hasEditAccess () {
      const routeName = this?.$router?.history?.current?.name;

      return this?.$store?.state?.screens_obj?.[routeName]?.isEdit;
    },
    currentTabRateCount() {
      this.masters.find(m => {
        if (m === this.selectedTab) {
          return m.count;
        }
      });

      return 0;
    }
  },
  created() {
    this.$store.commit("SAVE_PAGE_HEADER", "Base Rate TDS Master");
    this.fetchSelectedTabData();
    this.getApprovalCounts();
  },
  methods: {
    changeClickedTab(selectedTabName) {
      this.selectedTab = selectedTabName;
      this.baseRateTDSList = [];
      this.fetchSelectedTabData();
    },
    fetchSelectedTabData() {
      this.showProgress();
      let reqData = `pageNo=1&approveStatus=Pending for approval&modelName=baseTdsRate&vendorType=${this.selectedTab}`;
      this.GETM(
        "searchFrequentApprove",
        reqData,
        (res) => {
          if (
            res &&
            res.data &&
            res.data.data &&
            res.data.data.data &&
            res.data.data.data.length > 0
          ) {
            let result = res.data.data.data;
            this.baseRateTDSList = result.map((item) => ({
              ...item.operationData[0],
              approveStatus: item.approveStatus,
              initiatedBy: item.initiatedBy,
              _id: item._id,
              approveDialog: false,
              rejectDialog: false,
            }));
          } else {
              this.baseRateTDSList = [];
          }
          this.hideProgress();
        },
        (err) => {
          this.hideProgress();
        },
        true
      );
    },
    submitApproval(item) {
      if (this.$refs.rejectForm && !this.$refs.rejectForm.validate()) {
        return this.showAlert("Please fill mandatory fields.");
      } else {
        if (item?._id) {
          let sendData = {
            approveStatus: item.rejectDialog ? "Rejected" : "Approved",
            rejectionReason: this.reason,
          };
          this.PUTM(
            "updateMasterApprove",
            item._id,
            sendData,
            (res) => {
              this.hideProgress();
              item.rejectDialog = false;
              item.approveDialog = false;
              if (res.data && res.data.statusCode == 200) {
                this.showAlert(res?.data?.msg || "Success", "", "", () => {
                  this.fetchSelectedTabData();
                  this.reason = "";
                });
              } else {
                this.showAlert(res.data.msg);
                this.hideProgress();
              }
            },
            (err) => {
              console.log(err);
              this.hideProgress();
              let msg =
                err?.response?.data?.msg ||
                "Unable to submit Service Provider Approval.";
              this.showAlert(msg);
            },
            true
          );
        }
      }
    },
    openRejectDialog(item, value){
      item.rejectDialog = value;
      this.reason = "";
    },
    openApprovalDialog(item, value){
      item.approveDialog = value;
    },
    switchToBaseRateBulkUpload(){
      this.$router.push('/tds-master-approval/bulk-upload/base-rate')
    },
    getApprovalCounts() {
      /**
       * Calling an API
       */
      this.GETM(
        'TDS_MASTER_PENDING_COUNTS',
        'tdsModel=baseTds',
        (res) => {
          if (res?.data?.data) {
            this.masters.forEach(m => {
              if (res.data.data[m.textTitle]) {
                m.count = res.data.data[m.textTitle];
              }
            });

            if (res.data?.data?.bulkCount) {
              this.bulkCount = res.data.data.bulkCount;
            }
          }
        },
        console.error,
        "CM"
      );
    }
  },
};
</script>
<style scoped>
#baseRateTDS .containerStyle {
  margin: 0;
  padding: 1.5rem;
  max-width: 100%;
}
.master-single-card:not(:last-child) {
  margin-right: 14px;
}
.master-single-card:hover {
  border-color: teal;
}
.master-single-card {
  cursor: pointer;
  width: fit-content;
  height: 75px;
  padding: 0 50px;
  margin-top: 14px;
  background: transparent linear-gradient(180deg, #ffffff 0%, #f0f0f0 100%) 0%
    0% no-repeat padding-box;
  box-shadow: 0px 4px 8px #0000000a;
  border: 2px solid #e0e0e0;
  border-radius: 4px;
  display: flex;
  align-items: center;
}
.master-title {
  word-wrap: break-word;
  font-size: 16px !important;
  text-align: center;
  font-weight: 500;
  font-style: bold;
}
.data-count {
  color: white;
  border-radius: 4px;
  margin-left: 5px;
  padding: 1px 4px;
  font-size: 12px;
  font-weight: 600;
  background-color: #ff9800 !important;
  border-color: #ff9800 !important;
}
.approve-button {
  color: #23b1a9;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
}
.reject-button {
  color: red;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
}
.approve-button:disabled, .reject-button:disabled {
  color: grey;
  cursor: not-allowed;
}

.show-count {
  visibility: hidden;
}
.selected-tab-outline {
  border-color: teal;
}
.btn-toggle {
    text-transform: none;
    color: #fff;
    background-color: #1ebbd9;
}
.header-alignment{
  margin: 0px !important;
}
</style>