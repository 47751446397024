 <template>
    <v-app>
        <div >
            <v-card class=" mb-3 mt-3" color="#F7F7F7" style="background-color: ;" tile>
                <v-form v-model="valid" ref="form">

                <v-layout color="#F7F7F7" class="ma-2" >
                    
                    <v-flex xs3>
                        <v-layout column class=" mx-2 my-2 cust_pd">
                            <v-text class="font-weight-bold cstm-clr-psap ">
                                Plan
                            </v-text>
                                   <v-select
                                        :items="planItems"
                                        item-text="planName"
                                        item-value="_id"
                                        v-model="plan"
                                        label="Select"
                                        dense
                                        solo
                                        class="rounded-0"
                                        disabled
                                    ></v-select>
                        </v-layout>
                    </v-flex>
                    <v-flex xs3 class=" mx-2">
                        <v-layout column class="cust_pd mx-2 mt-2">
                            <v-text class="font-weight-bold cstm-clr-psap">
                                Corporate Buffer Applicable
                            </v-text>

                     <v-select
                        :items="selectCorpBuffAppl"
                        label="Select"
                        v-model="corporateBuffAppl"
                        @change="selctdWtngPrdAplble"
                        :disabled="inputdsbld"
                        :error-messages="corporateBuffAppl_chk"
                        @blur="$v.corporateBuffAppl.$touch()"
                        solo
                        class="rounded-0">
                        </v-select>
                            
                                 <!-- <v-radio-group
                                    style="background-color: #F7F7F7;border:0.3px solid #C1C8CC;display:flex; align-items:center;justify-content:center"
                                    row
                                    v-model="corporateBuffAppl"
                                    :error-messages="corporateBuffAppl_chk"
                                    @blur="$v.corporateBuffAppl.$touch()"
                                    @change="selctdWtngPrdAplble"
                                    solo
                                    class="rounded-0 "
                                    :disabled="inputdsbld"
                                    >
                                    <v-radio
                                        label="Yes"
                                        value="Yes"
                                        
                                        class=""
                                        color=#23B1A9
                                    ></v-radio>
                                    <v-radio
                                        label="No"
                                        value="No"
                                        class=" "
                                        color=#23B1A9
                                    ></v-radio>
                                </v-radio-group>  -->
                        
                        </v-layout>
                    </v-flex>
      
                </v-layout>
                </v-form>
            </v-card>




    <v-card class="mb-3 mt-3 cust-bnkAcc-tbl" v-if="showdata===true" style="border:0.5px solid #C1C8CC;" tile>
		<v-form v-model="valid" ref="form">
                <v-layout row class="mt-4 ml-2">
                 <v-flex xs3>
                 <v-layout column class=" mx-3">
                        
                          <v-text class="cstm-clr-psap ">
                                Applicable For
                            </v-text>
                            
                          
                    </v-layout>
                </v-flex>
                    <v-flex xs3 class=" mx-1">
                        <v-checkbox
                            v-model="AplcableSeltd"
                            :disabled="inputdsbld"
                            value="All Illness"
                            @change="aplsltdval()"
                            dense
                            :error-messages="AplcableSeltd_chk"
                            @blur="$v.AplcableSeltd.$touch()"
                            class="ml-1 pa-0 pb-2 ma-0"
                            color=#23B1A9>
                        <span slot="label" class="font-weight-bold" style="font-size: 12px">All Illness</span>
                        </v-checkbox>
                            
                    </v-flex>

                    <v-flex xs3 class=" mx-">
                        <v-checkbox
                            v-model="AplcableSeltd"
                             value="Critical Illness"
                             @change="aplsltdval()"
                            :error-messages="AplcableSeltd_chk"
                            @blur="$v.AplcableSeltd.$touch()"
                            dense
                            :disabled="inputdsbld"
                            :rules="[rules.required]"
                            class="ml- pa-0 pb-2 ma-0"
                            color=#23B1A9>
                        <span slot="label" class="font-weight-bold" style="font-size: 12px">Specified Illness</span>
                        </v-checkbox>
                            
                    </v-flex>


                </v-layout>
<!-- 1 -->
            
            <v-layout row class="mt-4 ml-2">
                <v-flex xs3>
                    <v-layout column class="cust_pr mx-3">    
                          <v-text class=" cstm-clr-psap">
                                Corporate Buffer
                            </v-text>
                    </v-layout>
                </v-flex>

                    <v-flex xs3>
                        <v-layout column class="cust_pr mx-3">
                            <v-text-field
                                label="Enter..."
                                v-model="allIllness.allCorBuffer"
                                solo
                                dense
                                :error-messages="allIllness_allCorBuffer_chk"
                                @blur="$v.allIllness.allCorBuffer.$touch()"
                                 @change="$v.allIllness.allCorBuffer.$touch()"
                                class="rounded-0"
                                :disabled="alIllnsDsbld"
                                 :maxlength="max10"
                                         counter
                                
                                >
                            </v-text-field>
                          
                        </v-layout>
                    </v-flex>

                    <v-flex xs3>
                        <v-layout column class="cust_pr mx-3">
                            <v-text-field
                                label="Enter..."
                                v-model="criticalIllness.criCorBuffer"
                                solo
                                dense
                                class="rounded-0"
                                 :error-messages="criticalIllness_criCorBuffer_chk"
                                @blur="$v.criticalIllness.criCorBuffer.$touch()"
                                 @change="$v.criticalIllness.criCorBuffer.$touch()"
                                :disabled="crtclllnsDsbld"
                                 :maxlength="max10"
                                         counter
                                
                                >
                            </v-text-field>
                          
                        </v-layout>
                    </v-flex>

                </v-layout>
<!-- 2 -->

                
                <v-layout row class="mt-4 ml-2">
                    <v-flex xs3>
                        <v-layout column class="cust_pr mx-3">
                            <v-text class=" cstm-clr-psap">
                                Corporate Buffer Limit Per Family
                            </v-text>  
                        </v-layout>
                </v-flex>
                    <v-flex xs3>
                        <v-layout column class="cust_pr mx-3">
                            <v-text-field
                                label="Enter..."
                                v-model="allIllness.allCorBufFamily"
                                solo
                                dense
                                class="rounded-0"
                               :error-messages="allIllness_allCorBufFamily_chk"
                                :disabled="alIllnsDsbld"
                                 @blur="$v.allIllness.allCorBufFamily.$touch()"
                                 @change="$v.allIllness.allCorBufFamily.$touch()"
                                  :maxlength="max10"
                                         counter
                                
                                >
                            </v-text-field>
                          
                        </v-layout>
                    </v-flex>

                    <v-flex xs3>
                        <v-layout column class="cust_pr mx-3">
                            <v-text-field
                                label="Enter..."
                                v-model="criticalIllness.criCorBufFamily"
                                solo
                                dense
                                class="rounded-0"
                                :disabled="crtclllnsDsbld"
                                :error-messages="criticalIllness_criCorBufFamily_chk"
                                @blur="$v.criticalIllness.criCorBufFamily.$touch()"
                                 @change="$v.criticalIllness.criCorBufFamily.$touch()"
                                  :maxlength="max10"
                                         counter
                                
                                >
                            </v-text-field>
                          
                        </v-layout>
                    </v-flex>

                </v-layout>
<!-- 3 -->

                
                <v-layout row class="mt-4 ml-2">
                    <v-flex xs3>
                        <v-layout column class="cust_pr mx-3">
                            <v-text class=" cstm-clr-psap">
                                Corporate Buffer Limit Per Member
                            </v-text>
                        </v-layout>
                    </v-flex>
                    <v-flex xs3>
                        <v-layout column class="cust_pr mx-3">
                            <v-text-field
                                label="Enter..."
                                v-model="allIllness.allCorBufMember"
                                solo
                                dense
                                class="rounded-0"
                               :error-messages="allIllness_allCorBufMember_chk"
                                :disabled="alIllnsDsbld"
                                 @blur="$v.allIllness.allCorBufMember.$touch()"
                                 @change="$v.allIllness.allCorBufMember.$touch()"
                                  :maxlength="max10"
                                         counter
                                
                                >
                            </v-text-field>
                        </v-layout>
                    </v-flex>

                    <v-flex xs3>
                        <v-layout column class="cust_pr mx-3">
                            <v-text-field
                                label="Enter..."
                                v-model="criticalIllness.criCorBufMember"
                                solo
                                dense
                                class="rounded-0"
                                :error-messages="criticalIllness_criCorBufMember_chk"
                                @blur="$v.criticalIllness.criCorBufMember.$touch()"
                                 @change="$v.criticalIllness.criCorBufMember.$touch()"
                                :disabled="crtclllnsDsbld"
                                 :maxlength="max10"
                                         counter
                                >
                            </v-text-field>
                          
                        </v-layout>
                    </v-flex>

                </v-layout>
		    </v-form>
        </v-card>

            <v-card class="mb-2 mt-3 cust-bnkAcc-tbl" style="border:0.5px solid #C1C8CC;" tile>
				<v-layout  style="padding:15px;background-color:#F7F7F7;align-items:center; justify-center; justify-content: flex-end;">
                    <!-- @click="$router.push('/homePage')" -->
                    <v-btn dark  tile style="background-color:#E46A25"  @click="returnTab()" class="text-capitalize px-3 mx-3" small><v-icon dark small>arrow_back</v-icon> <v-divider vertical class="mx-2"></v-divider>Return</v-btn>
                    <v-btn :dark="!inputdsbld" :disabled="inputdsbld" tile style="background-color:#23B1A9" @click="corpEligi_save()" class="text-capitalize px-3 mx-3" small><v-icon dark small>check_circle</v-icon> <v-divider vertical class="mx-2"></v-divider> Save</v-btn>
                    <v-btn dark tile  @click="nextTab()" style="font-size:12px;background-color:#152F38"  class="text-capitalize px-3 mx-2" small><v-icon dark small>arrow_forward</v-icon> <v-divider vertical class="mx-2"></v-divider>Next</v-btn>
				</v-layout>
            </v-card>
        </div>

        
    </v-app>
</template>
<script>
import axios from "axios";
import moment from 'moment';

 import { required, minLength, requiredIf,alpha ,numeric,maxValue} from 'vuelidate/lib/validators'
 const requiredIfRoot = requiredIf(function () { return this.isRequired })
 const requiredIfAllIlns = requiredIf(function () { return this.isRequiredAllIll })
 const requiredIfCrtclIllns = requiredIf(function () { return this.isRequiredCrtclIllnss })
 const  maxValAllIllns = function (v) { 
     console.log(this.allIllness.allCorBuffer)
        return this.allIllness.allCorBuffer? v:""
    }
 const  maxValCrtclllns = function (v) { 
    console.log(this.criticalIllness.allCorBuffer)
    return this.criticalIllness.allCorBuffer?v:""
}




export default {

    validations: {

            corporateBuffAppl:{
                required
            },
            AplcableSeltd:{
                required:requiredIfRoot,
            },
            allIllness:{
                allCorBuffer:{
                    required:requiredIfAllIlns,
                    numeric
                    
                },
                allCorBufFamily:{
                    required:requiredIfAllIlns,
                    numeric,
                    // maxValue: maxValAllIllns
                    // maxValue:maxValue(allIllness.allCorBuffer)
                    // maxValAllIllns
                },
                allCorBufMember:{
                    required:requiredIfAllIlns,
                    numeric,
                    //  maxValue:maxValue(allIllness.allCorBuffer)
                }
            },

            criticalIllness:{
                criCorBuffer:{
                    required:requiredIfCrtclIllns,
                    numeric
                },
                criCorBufFamily:{
                    required:requiredIfCrtclIllns,
                    numeric,
                    //  maxValue:maxValue(100)
                },
                criCorBufMember:{
                    required:requiredIfCrtclIllns,
                    numeric,
                    // maxValue:maxValue(100)
                }
            }

    },

    components:{
      
    },

    created() {

        this.$store.commit("SAVE_PAGE_HEADER", "Corporate Eligibility");
        this.token=this.$store.getters.successToken
        this.btnName=this.$store.getters.fetchPlcyIdForPlnData.text
        if(this.btnName==="EditPlnFrPlcy"||this.btnName==="ConfgrPlnFrPlcy"){
                // this.nextbtn=false
                this.getGroupPlanbyId(this.$store.getters.fetchPlcyIdForPlnData.plan_No)
        }
        if(this.btnName==="EditPlnFrPlcy"||this.btnName==="CrteNwPlnFrPlcy"){
            this.inputdsbld=true
            this.alIllnsDsbld=true
            this.crtclllnsDsbld=true
        }

    },

    computed: {

        corporateBuffAppl_chk(){
                const errors = []
                if (!this.$v.corporateBuffAppl.$dirty) return errors
                !this.$v.corporateBuffAppl.required && errors.push('This field is required')
                return errors

        },
        AplcableSeltd_chk(){
            const errors = []
                if (!this.$v.AplcableSeltd.$dirty) return errors
                !this.$v.AplcableSeltd.required && errors.push('This field is required')
                return errors

        },
        allIllness_allCorBuffer_chk(){

            if(this.allIllness.allCorBuffer.length > 0){
                console.log("from if ");
                if(/^[0-9 ]+$/g.test(this.allIllness.allCorBuffer) != true){
                    // this.ttl_err = true
                    return "Only numbers are allowed"
                }else{
                    // this.ttl_err = false
                    return []
                }
            }else{
                const errors = []
                if (!this.$v.allIllness.allCorBuffer.$dirty) return errors
                !this.$v.allIllness.allCorBuffer.required && errors.push('This field is required')
                return errors}

        },
        allIllness_allCorBufFamily_chk(){
            console.log("from if ",parseInt(this.allIllness.allCorBuffer));
            // console.log(this.$v.allIllness.allCorBufFamily.maxValAllIllns, "maxValAllIllns")
            if(this.allIllness.allCorBufFamily.length > 0){
                console.log("from if ");
                if(/^([0-9 ])+$/g.test(this.allIllness.allCorBufFamily) != true){
                    return "Only numbers are allowed"
                }else if(parseInt(this.allIllness.allCorBuffer)<parseInt(this.allIllness.allCorBufFamily)){
                    // return "Must be less Corporate Buffer"
                    return "This amount cannot be greater than corporate buffer amount."
                }else{
                    return []
                }

            }
            else{
                const errors = []
                if (!this.$v.allIllness.allCorBufFamily.$dirty) return errors
                !this.$v.allIllness.allCorBufFamily.required && errors.push('This field is required')
                return errors
            }

            

            

        },
        allIllness_allCorBufMember_chk(){
            //  console.log(this.$v.allIllness.allCorBufMember.maxValAllIllns, "maxValAllIllns")

            if(this.allIllness.allCorBufMember.length > 0){
                console.log("from if ");
                if(/^(([0-9 ])?)+$/g.test(this.allIllness.allCorBufMember) != true){
                    return "Only numbers are allowed"
                // }else if(this.allIllness.allCorBuffer<this.allIllness.allCorBufMember){
                                    }else if(parseInt(this.allIllness.allCorBufMember) > parseInt(this.allIllness.allCorBuffer)){

                    // return "Must be less Corporate Buffer"
                    return "This amount cannot be greater than corporate buffer amount."
                }else{
                    return []
                }
            }else{
                const errors = []
                if (!this.$v.allIllness.allCorBufMember.$dirty) return errors
                !this.$v.allIllness.allCorBufMember.required && errors.push('This field is required')
                return errors
            }
            if(this.allIllness.allCorBuffer<this.allIllness.allCorBufMember){
                //  return "Must be less"
                return "This amount cannot be greater than corporate buffer amount."

            }else{
                return []
            }

        },
        criticalIllness_criCorBuffer_chk(){
            
            if(this.criticalIllness.criCorBuffer.length > 0){
                console.log("from if ");
                if(/^([0-9 ])+$/g.test(this.criticalIllness.criCorBuffer) != true){
                    return "Only numbers are allowed"
                }else{
                    return []
                }
            }else{
                const errors = []
                if (!this.$v.criticalIllness.criCorBuffer.$dirty) return errors
                !this.$v.criticalIllness.criCorBuffer.required && errors.push('This field is required')
                return errors
            }
        },

        criticalIllness_criCorBufFamily_chk(){

             if(this.criticalIllness.criCorBufFamily.length > 0){
                console.log("from if ");
                if(/^([0-9 ])+$/g.test(this.criticalIllness.criCorBufFamily) != true){
                    return "Only numbers are allowed"
                }else if(parseInt(this.criticalIllness.criCorBuffer)<parseInt(this.criticalIllness.criCorBufFamily)){
                    // return "Must be less Corporate Buffer"
                   return "This amount cannot be greater than corporate buffer amount."
                }else{
                    return []
                }
            }else{
                const errors = []
                if (!this.$v.criticalIllness.criCorBufFamily.$dirty) return errors
                !this.$v.criticalIllness.criCorBufFamily.required && errors.push('This field is required')
                return errors
            }
        },

        criticalIllness_criCorBufMember_chk(){

            if(this.criticalIllness.criCorBufMember.length > 0){
                console.log("from if ");
                if(/^([0-9 ])+$/g.test(this.criticalIllness.criCorBufMember) != true){
                    return "Only numbers are allowed"
                }else if(parseInt(this.criticalIllness.criCorBuffer)<parseInt(this.criticalIllness.criCorBufMember)){
                    // return "Must be less Corporate Buffer"
                    return "This amount cannot be greater than corporate buffer amount."
                }else{
                    return []
                }
            }else{
                const errors = []
                if (!this.$v.criticalIllness.criCorBufMember.$dirty) return errors
                !this.$v.criticalIllness.criCorBufMember.required && errors.push('This field is required')
                return errors
            }
        },

        


        isRequired(){
            if(this.corporateBuffAppl==="Yes"){
                return true;
            }else if (this.corporateBuffAppl==="No"){
                return false;
            }
                
        },

        isMax(){
             if(this.allIllness.allCorBuffer<this.allIllness.allCorBufFamily||this.allIllness.allCorBuffer<this.allIllness.allCorBufMember){
                return true; 
             }else{
                return false;
            }
        },

        isMax2(){
             if(this.criticalIllness.criCorBuffer<this.criticalIllness.criCorBufFamily||this.criticalIllness.criCorBuffer<this.criticalIllness.criCorBufMember){
                return true; 
             }else{
                return false;
            }
        },

        isRequiredAllIll(){
            let a=false
            for(let i=0; i<this.AplcableSeltd.length;i++){
                if(this.AplcableSeltd[i]==="All Illness"){
                    a=true
                }
            }
            if(a===true){
                return true
            }else{
                return false
            }
        },

        isRequiredCrtclIllnss(){
            let a=false
            for(let i=0; i<this.AplcableSeltd.length;i++){
                if(this.AplcableSeltd[i]==="Critical Illness"){
                     a=true
                }    
            } 
            if(a===true){
                return true
            }else{
                return false
            }   
        },

    },

   

    data() {
        return {
        
          nextbtn:true,

          selectPlan:['Group Medicare -Plan A','Group Medicare -Plan B'],
          planItems:[],
          radioBtn:'radio-1',
          AplcableSeltd:[],

          selectCorpBuffAppl:['Yes','No'],
          corporateBuffAppl:'',
            alIllnsDsbld:true,
             crtclllnsDsbld:true,
          showdata:false,
          token:null,
            max10:10,
            max30:30,
            max20:20,
            max40:40,
            max50:50,

          plan:'',
           btnName:'',
           inputdsbld:false,

          allIllness:{
            allIll:'',
            allCorBuffer:'',
            allCorBufFamily:'',
            allCorBufMember:''
          },

        criticalIllness:{
            criticalIll:'',
            criCorBuffer:'',
            criCorBufFamily:'',
            criCorBufMember:'',

        },
          

          rules: {
                required: value => !!value || 'This Field is Required.',

                minNo: value => {
                            // const pattern = /^([0-9])+$/
                        const pattern = /^[0-9]+$/
                        return pattern.test(value) || 'Only Numbers are allowed.'
                },
                
            },

        //   rules: {
        //         required: value => !!value || 'This Field is Required.',
        //         // minRegNo: v =>v.length <= 3|| v.length == 5  || 'Enter valid Registration Number',
        //         minNo: value => {
        //                     // const pattern = /^([0-9])+$/
        //                 const pattern = /^[0-9]+$/
        //                 return pattern.test(value) || 'Only Numbers are allowed.'
        //         },
        //         minless: value => {
        //                     // const pattern = /^([0-9])+$/
        //                 const pattern = /^[0-9]+$/
        //                 return this.allCorBufFamily.value >value || pattern.test(value) || 'Only less Number are allowed.'
        //         },

        //         minROHINI: v =>v.length <= 3|| v.length == 13 || 'Invalid ROHINI ID',
                   
        //     },

        };
    },


    
    methods: {
        nextStep(){
            this.nextbtn=false
        },

        required(ident, indata){ 
                //  console.log(indata, " ident is ", ident);
                   if(ident == 0){
                        return v=>v && v.length>0 || "This Field is validate."
                   }
                     
                        
        },

        selctdWtngPrdAplble(){
            console.log("corporateBuffAppl---->",this.corporateBuffAppl)
             this.showdata=false
            // this.WtngPrdNofDys=''
            // this.criCorBufMember=''
            // this.$v.WtngPrdNofDys.$reset()
            // this.$v.criCorBufMember.$reset()
            if(this.corporateBuffAppl==="No"){
                this.showdata=false
            }
            if(this.corporateBuffAppl==="Yes"){
                this.showdata=true
            }
            if(this.showdata===false){
                this.AplcableSeltd=[]
                this.allIllness.allCorBuffer=''
                this.criticalIllness.criCorBuffer=''
                this.allIllness.allCorBufFamily=''
                this.criticalIllness.criCorBufFamily=''
                this.allIllness.allCorBufMember=''
                this.criticalIllness.criCorBufMember=''
                this.alIllnsDsbld=true
                this.crtclllnsDsbld=true
            }
            this.$v.$reset()
        },

        corpEligi_save(){
            
                this.$v.$touch();
                console.log(this.alIllnsDsbld,"this.alIllnsDsbld",this.crtclllnsDsbld,"this.crtclllnsDsbld")
                console.log("this.allIllness.allCorBuffer<this.allIllness.allCorBufFamily",this.allIllness.allCorBuffer,this.allIllness.allCorBufFamily,parseInt(this.allIllness.allCorBuffer)<parseInt(this.allIllness.allCorBufFamily)); 
                 console.log("this.allIllness.allCorBuffer<this.allIllness.allCorBufMember",this.allIllness.allCorBuffer,this.allIllness.allCorBufMember,this.allIllness.allCorBuffer<this.allIllness.allCorBufMember); 
                 console.log("this.criticalIllness.criCorBuffer<this.criticalIllness.criCorBufFamily",this.criticalIllness.criCorBuffer,this.criticalIllness.criCorBufFamily,this.criticalIllness.criCorBuffer<this.criticalIllness.criCorBufFamily);
                 console.log("this.criticalIllness.criCorBuffer<this.criticalIllness.criCorBufMember",this.criticalIllness.criCorBuffer,this.criticalIllness.criCorBufMember,this.criticalIllness.criCorBuffer<this.criticalIllness.criCorBufMember); 
                if(this.$v.$invalid){
                    // console.log("Form has errors",this.$v);  
                    this.showToast('Please fill all required fields', this.TOST().WARNING);
                    return
                }
                if(parseInt(this.allIllness.allCorBuffer)<parseInt(this.allIllness.allCorBufFamily)){
                    console.log("Form has errors",this.$v,this.allIllness.allCorBuffer<this.allIllness.allCorBufFamily);  
                    this.showToast('Please fill all required fields', this.TOST().WARNING);
                    return
                }
                if(parseInt(this.allIllness.allCorBuffer)<parseInt(this.allIllness.allCorBufMember)){
                    console.log("Form has errors",this.$v,this.allIllness.allCorBuffer<this.allIllness.allCorBufMember);  
                    this.showToast('Please fill all required fields', this.TOST().WARNING);
                    return
                }
                if(parseInt(this.criticalIllness.criCorBuffer)<parseInt(this.criticalIllness.criCorBufFamily)){
                    console.log("Form has errors",this.$v,this.criticalIllness.criCorBuffer<this.criticalIllness.criCorBufFamily);  
                    this.showToast('Please fill all required fields', this.TOST().WARNING);
                    return
                }
                if(parseInt(this.criticalIllness.criCorBuffer)<parseInt(this.criticalIllness.criCorBufMember)){
                    console.log("Form has errors",this.$v,this.criticalIllness.criCorBuffer<this.criticalIllness.criCorBufMember);  
                    this.showToast('Please fill all required fields', this.TOST().WARNING);
                    return
                }

                console.log('proceed')
                // console.log("corporateBufferApplicable",this.corporateBuffAppl)
                // console.log("allIllness",this.allIllness)
                // console.log("criticalIllness",this.criticalIllness)
                // console.log("AplcableSeltd",this.AplcableSeltd)
                let self=this
            var formData ={
                corporateBufferApplicable: this.corporateBuffAppl,
                applicableFor: this.AplcableSeltd,
                corporateBuffer:this.allIllness.allCorBuffer,
                corporateBufferLimitPerFamily: this.allIllness.allCorBufFamily,
                corporateBufferLimitPerMember: this.allIllness.allCorBufMember,
                corporateBufferCritical: this.criticalIllness.criCorBuffer,
                corporateBufferLimitPerFamilyCritical: this.criticalIllness.criCorBufFamily,
                corporateBufferLimitPerMemberCritical: this.criticalIllness.criCorBufMember
            }

            // this.nextbtn=false
            console.log('Basic Plan form saved data',formData)
            
            axios({
                method: "put",
                url:
                    this.API_Service_admin()+"group-plan/update-group-corporate-eligibility/"+self.plan._id,
                headers: {
                    "Content-Type": "application/json",
                    "x-access-token": self.token,
                    // "x-access-token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI1ZmJlMDg4ZGM0NmRhODNlZTdmODcxYmIiLCJyb2xlIjoiQWRtaW5Vc2VyIiwiaWF0IjoxNjA2MzY5MzI0LCJleHAiOjE2Mzc5MDUzMjR9.iS5hZs0uImNFz-rxNaQ7CTCc6qS_hG4H0FsePZ5Sgqc",
                },
                data:formData,
                })
            .then(function (response) {
                console.log(" update-group-corporate-eligibility API Response>>>>>>", response);
                self.showToast(response.data.msg ,self.TOST().SUCCESS)
                self.$v.$reset()

            })
            .catch(function (error) {
                console.log("update-group-corporate-eligibility Error APi Response>>>>>>", error);
                self.showToast(error.response.data.msg,self.TOST().ERROR);
                self.$v.$reset()
            });
                

    
                    
             

        },
        getGroupPlanbyId(planId){
        console.log(planId," Incoming PlanId")
        let self = this;   
        axios({
            method: "get",
            url:this.API_Service_admin()+"group-plan/get-group-plan/"+planId, 
            headers: {
                "Content-Type": "application/json", 
                "x-access-token": self.token,
            }
        })
        .then(function(response) {
            console.log('group-plan/get-group-plan Response------->>>',response.data.data);
            let pdtDta={}
            pdtDta=response.data.data
            self.planItems.push(pdtDta)
            self.plan=self.planItems[0]

            self.corporateBuffAppl=pdtDta.hasOwnProperty('corporateEligibility')?pdtDta.corporateEligibility.hasOwnProperty('corporateBufferApplicable')?self.chkplndata(pdtDta.corporateEligibility.corporateBufferApplicable):"":""
            self.selctdWtngPrdAplble()
            self.AplcableSeltd=pdtDta.hasOwnProperty('corporateEligibility')?pdtDta.corporateEligibility.hasOwnProperty('applicableFor')?self.chkplndataarry(pdtDta.corporateEligibility.applicableFor):[]:[]
            self.allIllness.allCorBuffer=pdtDta.hasOwnProperty('corporateEligibility')?pdtDta.corporateEligibility.hasOwnProperty('corporateBuffer')?self.chkplndata(pdtDta.corporateEligibility.corporateBuffer):"":""
            self.allIllness.allCorBufFamily=pdtDta.hasOwnProperty('corporateEligibility')?pdtDta.corporateEligibility.hasOwnProperty('corporateBufferLimitPerFamily')?self.chkplndata(pdtDta.corporateEligibility.corporateBufferLimitPerFamily):"":""
            self.allIllness.allCorBufMember=pdtDta.hasOwnProperty('corporateEligibility')?pdtDta.corporateEligibility.hasOwnProperty('corporateBufferLimitPerMember')?self.chkplndata(pdtDta.corporateEligibility.corporateBufferLimitPerMember):"":""

            self.criticalIllness.criCorBuffer=pdtDta.hasOwnProperty('corporateEligibility')?pdtDta.corporateEligibility.hasOwnProperty('corporateBufferCritical')?self.chkplndata(pdtDta.corporateEligibility.corporateBufferCritical):"":""
            self.criticalIllness.criCorBufFamily=pdtDta.hasOwnProperty('corporateEligibility')?pdtDta.corporateEligibility.hasOwnProperty('corporateBufferLimitPerFamilyCritical')?self.chkplndata(pdtDta.corporateEligibility.corporateBufferLimitPerFamilyCritical):"":""
            self.criticalIllness.criCorBufMember=pdtDta.hasOwnProperty('corporateEligibility')?pdtDta.corporateEligibility.hasOwnProperty('corporateBufferLimitPerMemberCritical')?self.chkplndata(pdtDta.corporateEligibility.corporateBufferLimitPerMemberCritical):"":""
            for(let i=0; i<self.AplcableSeltd.length;i++){
                console.log("loop")
                if(self.AplcableSeltd[i]==="All Illness" && self.btnName==="ConfgrPlnFrPlcy"){
                    self.alIllnsDsbld=false
                }
            }
            for(let i=0; i<self.AplcableSeltd.length;i++){
                console.log("loop")
                if(self.AplcableSeltd[i]==="Critical Illness" && self.btnName==="ConfgrPlnFrPlcy"){
                    self.crtclllnsDsbld=false
                }
            }

           
        })
        .catch(function(error) {
            console.log(error);
            // self.showToast(error.response.data.msg,self.TOST().ERROR);
        });
    },
    chkplndata(data){
            console.log("chkplndata",data); 
            try{
                if (data ==="" || data === "undefined" || data === null) {
                    return ""
                } else {
                    return data;
                }

             }catch(err) {
                console.log(err)
            }
            
        },
        chkplndataarry(data){
            console.log("chkplndataarry",data); 
            try{
                if (data ==="" || data === "undefined" || data === null) {
                    return []
                } else {
                    return data;
                }

             }catch(err) {
                console.log(err)
            }
            
        },
        aplsltdval(){
            this.alIllnsDsbld=true
            this.crtclllnsDsbld=true
            console.log("this.AplcableSeltd", this.AplcableSeltd)
            if(this.AplcableSeltd.length===0){
                this.alIllnsDsbld=true
                this.crtclllnsDsbld=true
            }
            for(let i=0; i<this.AplcableSeltd.length;i++){
                console.log("loop")
                if(this.AplcableSeltd[i]==="All Illness" && this.btnName==="ConfgrPlnFrPlcy"){
                    this.alIllnsDsbld=false
                }
            }
            for(let i=0; i<this.AplcableSeltd.length;i++){
                console.log("loop")
                if(this.AplcableSeltd[i]==="Critical Illness" && this.btnName==="ConfgrPlnFrPlcy"){
                    this.crtclllnsDsbld=false
                }
            }
            if(this.alIllnsDsbld===true){
                this.allIllness.allCorBuffer=''
                this.allIllness.allCorBufFamily=''
                this.allIllness.allCorBufMember=''
            }
            if(this.crtclllnsDsbld===true){
                this.criticalIllness.criCorBuffer=''
                this.criticalIllness.criCorBufFamily=''
                this.criticalIllness.criCorBufMember=''
            }
        },
        nextTab(){
            console.log("emitted")
             this.$emit('next');
        },
        returnTab(){
            console.log("emitted")
            this.$emit('return');
        },


    },

    watch: {
    
    },
    
    
}

</script>
<style >


.v-btn__content { width: 100% !important; white-space: normal !important; font-size:13px !important}




</style>