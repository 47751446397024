<template>
    <v-row justify="center">
        <v-dialog v-model="openPennyDropPopUp" width="90%">
            <v-card>
                <v-toolbar height="40" dark color="#23b1a9">
                    <v-toolbar-title>PennyDrop Payment Verification</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn icon dark @click="openPennyDropPopUp = false">
                            CLOSE
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <v-card-text>
                    <v-data-table :headers="pennyDropheaders" hide-default-footer dense class="pa-6 table-outline"
                        :items="pennyDropDetails" no-data-text="No Records Found!!">
                            <template v-slot:[`item.createdAt`]="{ item }">
                                <span>{{ formatDate(item.createdAt) }}</span>
                            </template>
                            <template v-slot:[`item.response.beneficiaryName`]="{ item }">
                                <span v-if="checkForSuccessCase(item)" >{{ item.response.beneficiaryName }}</span>
                            </template>
                            <template v-slot:[`item.request`]="{ item }">
                                <b v-if="item.request"
                                    @click="showMorePennyDropReqRes(1, item)"
                                    style="color:#5ac4be; cursor: pointer;"
                                >VIEW</b>
                            </template>
                            <template v-slot:[`item.response`]="{ item }">
                                <b v-if="item.response"
                                    @click="showMorePennyDropReqRes(2, item)"
                                    style="color:#5ac4be; cursor: pointer;"
                                >VIEW</b>
                            </template>
                    </v-data-table>
                    <v-col v-if="isVerifyButtonVisible" align="right" class="verifyButtonAlign">
                        <v-btn :disabled="isVerifyButtonDisable" color="#23b1a9"
                            class="btncommonstyle mr-2 text-capitalize verifyButtonColor"
                            @click="clickOnVerifyButton">Verify</v-btn>
                    </v-col>
                </v-card-text>

            </v-card>
        </v-dialog>
        <div>
          <v-row justify="center">
            <v-dialog v-model="openPennyDropShowMenu" persistent max-width="50%">
              <v-card height="auto">
                <v-card-title
                  class="specialComponetDailogboxVcardText pl-0 px-0 py-0"
                >
                  <v-row>
                    <span class="specialComponetdescrptionSpan mb-5 ml-8">{{
                        pennyDropInOutHeader
                    }}</span>
                    <v-icon
                      @click="openPennyDropShowMenu = false"
                      class="ml-7 mt-0"
                      >mdi-window-close</v-icon
                    >
                  </v-row>
                  <v-card-text class="specialComponetpopupBackGround">
                    <div class="py-2 bg-gray rounded">
                        <ul class="list-style-none w-400">
                            <li v-for="(value, key) in pennyDropInOutValue">
                                <span class="font-weight-bold"> {{ convertToTitleCase(key) }} </span>: {{ pennyDropInOutValue[key] }}
                            </li>
                        </ul>
                    </div>
                  </v-card-text>
                </v-card-title>
              </v-card>
            </v-dialog>
          </v-row>
        <v-row>
            <v-dialog v-model="openRemarkDialog" persistent max-width="450px" >
                <v-card tile color="#F7F7F7" class='px-5 py-5'>
            <div>
                <span class='font-weight-bold my-2'>{{ remarkHeader }}</span>
                  <v-layout column class="cust_usrsrch">
                    <v-text class="font-weight-bold cstm-clr-pln-usrsrch pt-3">
                      Remarks
                      <span style="color: red">*</span>
                    </v-text>
                   <v-textarea
                        autocomplete="off"
                        maxlength="2000"
                        solo
                        v-model="remarks"
                        label="Please Enter Remarks..."
                        class="rounded-0 pt-1"
                        rows=3
                  >
                  </v-textarea>
                  </v-layout>
              </div>
              <v-card-actions  class='pa-1 justify-center'>
                <v-btn
                  dark
                  tile
                  color="#23B1A9"
                  @click="submitRemark"
                  class="text-capitalize px-3 mx-2"
                  small
                  ><v-icon dark small>check_circle</v-icon>
                  <v-divider vertical class="mx-2"></v-divider>Submit</v-btn
                >
                <v-btn
                  dark
                  tile
                  color="black"
                  @click="openRemarkDialog = false"
                  class="text-capitalize px-3 mx-2"
                  small
                  ><v-icon dark small>cancel</v-icon>
                  <v-divider vertical class="mx-2"></v-divider>Cancel</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
        </div>
    </v-row>
</template>

<script>
import moment from 'moment'
export default {
    props: {
        showVerifyButton: {
            default: false,
        },
    },
    data() {
        return {
            openPennyDropPopUp: false,
            pennyDropheaders: [{
                text: "PD Corelation",
                align: "start",
                value: "merchantRefId",
                sortable: false,
                divider: true,
                class: "bg-table-header-color"
            }, {
                text: "Date",
                align: "start",
                value: "createdAt",
                sortable: false,
                divider: true,
                class: "bg-table-header-color"
            }, {
                text: "Maker ID",
                align: "start",
                value: "makerId",
                sortable: false,
                divider: true,
                class: "bg-table-header-color"
            }, {
                text: "Payee Name",
                align: "start",
                value: "request.data.BeneficiaryName",
                sortable: false,
                divider: true,
                class: "bg-table-header-color"
            }, {
                text: "Account Name",
                align: "start",
                value: "response.beneficiaryName",
                sortable: false,
                divider: true,
                class: "bg-table-header-color"
            }, {
                text: "Score",
                align: "start",
                value: "pdScore",
                sortable: false,
                divider: true,
                class: "bg-table-header-color"
            }, {
                text: "Status",
                align: "start",
                value: "status",
                sortable: false,
                divider: true,
                class: "bg-table-header-color"
            }, {
                text: "Remark",
                align: "start",
                value: "remark",
                sortable: false,
                divider: true,
                class: "bg-table-header-color"
            }, {
                text: "Request",
                align: "start",
                value: "request",
                sortable: false,
                divider: true,
                class: "bg-table-header-color"
            }, {
                text: "Response",
                align: "start",
                value: "response",
                sortable: false,
                divider: true,
                class: "bg-table-header-color"
            }],
            pennyDropDetails: [],
            openPennyDropShowMenu: false,
            pennyDropInOutHeader: "",
            pennyDropInOutValue: "",
            openRemarkDialog:false,
            remarks: '',
            remarkHeader: '',
            remarkHeaderMapping: {
                'success': 'Are you sure, You want to Continue?',
                'fail': 'PD Verification is Failed. Are you sure, You want to Continue?',
                'error': 'PD Verification is having error. Are you sure, You want to Continue?',
            }
        }
    },
    methods: {
        convertToTitleCase(str){
            let formattedKey = str.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/^./, str => str.toUpperCase())
            formattedKey = formattedKey == 'Status' ? 'Bank Response Status' : formattedKey;
            return formattedKey;
        },
        openPopup(requestData = "") {
            this.showProgress();
            this.fetchPennyDropData(requestData);
        },
        fetchPennyDropData(requestData) {
            this.GETM("GET_PENNYDROP_SCORE", requestData, (response) => {
                this.pennyDropDetails = response?.data?.data ? response?.data?.data : [];
                this.openPennyDropPopUp = true;
                this.hideProgress();
            }, (error) => {
                this.hideProgress();
                let errorMsg = error.response && error.response.data && error.response.data.msg ? error.response.data.msg : error.response.data.message ? error.response.data.message : "Something went wrong!";
                this.showAlert(errorMsg);
            },true);
        },
        clickOnVerifyButton(){
            let isSuccess = this.checkForSuccessCase(this.pennyDropDetails[0]);
            let isPdLessThanThreshold = this.checkForSuccessAndThresholdCase;
            this.remarks = "";
            if(isSuccess && isPdLessThanThreshold){
                this.remarkHeader = 'PD Score is below than Threshold Limit. Are you sure, You want to Continue?';
            } else{
                this.remarkHeader = this.remarkHeaderMapping[this.pennyDropDetails[0].status.toLowerCase()];
            }
            this.openRemarkDialog = true;
        },
        formatDate(date) {
            return date ? moment(date).format("DD-MM-YYYY") + " " + moment(date).format('LT') : ""
        },
        showMorePennyDropReqRes(text, data){
            this.pennyDropInOutValue = "";
            this.pennyDropInOutHeader = "";
            if (text === 1) {
                this.pennyDropInOutHeader = "PennyDrop Request";
                this.pennyDropInOutValue = data.request.data;
            } else {
                this.pennyDropInOutHeader = "PennyDrop Response";
                this.pennyDropInOutValue = data.response;
            }
            if (this.pennyDropInOutValue) {
                this.openPennyDropShowMenu = true;
            } else {
                this.showAlert("No Data Found.");
            }
        },
        checkForSuccessCase(item){
            let isSuccess = item?.status.toLowerCase() == 'success' ? true : false;
            return isSuccess;
        },
        checkForDisabledConditions(pennyDropDetails){
            let lowerCaseStatus = pennyDropDetails[0].status.toLowerCase()
            let isSuccess = this.checkForSuccessCase(pennyDropDetails[0])
            let isPdLessThanThreshold = this.checkForSuccessAndThresholdCase;
            if(['fail', 'failed', 'error'].includes(lowerCaseStatus) || (isSuccess && isPdLessThanThreshold)){
                return true;
            } else {
                return false;
            }
        },
        postUserRemark(pennyDropDetails){
            let pennyDropVerificationPayload = {
                "merchantReferenceId": pennyDropDetails.merchantRefId,
                "remark": this.remarks,
            };
            this.POSTM("POST_PENNYDROP_REMARK", pennyDropVerificationPayload, (response) => {
                if(response){
                    this.showAlert(response.data.msg);
                }
            }, (error) => {
                this.showAlert(error.response.data.msg);
            },true);
        },
        submitRemark(){
            if(this.checkForDisabledConditions(this.pennyDropDetails) && this.remarks == ""){
                this.showAlert('Please Enter remarks');
                return;
            } 
            let pdDetails = {
                "isPdVerify": true, 
                "remark": this.remarks, 
                "status": this.pennyDropDetails[0].status,
                "pdScore": this.pennyDropDetails[0].pdScore
            };
            this.$emit('verifyPdScore', pdDetails)
            this.postUserRemark(this.pennyDropDetails[0]);
            this.openRemarkDialog = false;
            this.openPennyDropPopUp = false;
        }
    },
    computed: {
        isVerifyButtonVisible() {
            let verifyButton = this.showVerifyButton ? this.pennyDropDetails.length ? true : false : false;
            return verifyButton;
        },
        isVerifyButtonDisable() {
            return this.pennyDropDetails[0].status.toLowerCase() == "pending";
        },
        checkForSuccessAndThresholdCase(){
            return this.pennyDropDetails[0]?.pdScore < this.pennyDropDetails[0]?.pdThreshold
        },
    }

}
</script>
<style>
.bg-table-header-color {
    background-color: #23b1a9;
    color: #fff !important;
}

.verifyButtonAlign {
    padding: 0px 12px;
}

.verifyButtonColor {
    color: #fff !important;
}
.specialComponetpopupBackGround {
  background-color: #f7f7f7;
}
.specialComponetdescrptionSpan {
  width: 85%;
  margin-left: 53px;
  margin-top: 21px;
  margin-bottom: 14px !important;
  font-weight: bold;
}
.specialComponetDailogboxVcardText {
  font-size: 14px;
}
.dailogboxAllvcardTableBackground {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 5px 7px #00000029;
    opacity: 1;
    font-size: 14px;
    padding: 0px !important;
    display: block;
}
.descrptionSpan {
    font-weight: 500;
    font-size: 17px;
    letter-spacing: 0.4px;
}
.cancelbtn {
    border: 1px solid #4c4c4c;
}
.verifyDialogHeader{
    display: flex;
    justify-content: space-between;
    padding: 8px 12px;
    align-items: center;
}
.PdScoreHeader{
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 10px;
}
</style>