<template>
   <div >

       <v-layout class="mt-5">
            <v-flex xs3>
                <v-layout column class="cust_pr_Ms mx-2  ">
                            <v-text class="font-weight-bold cstm-clr-pr-Ms">
                                Policy Number
                            </v-text>
                        
                            <v-text-field
                                solo
                                disabled
                                label="Enter..."
                                v-model="policyNum"
                                class="rounded-0"
                                dense
                                >
                            </v-text-field>
                </v-layout>
            </v-flex>
         
            <v-flex xs3>
                <v-layout column class="cust_pr_Ms mx-2  ">
                            <v-text class="font-weight-bold cstm-clr-pr-Ms">
                                Policy Status
                            </v-text>
                        
                            <v-text-field
                                solo
                                disabled
                                label="Enter..."
                                v-model="policyStatus"
                                class="rounded-0"
                                dense
                                >
                            </v-text-field>
                </v-layout>
            </v-flex>

            <v-flex xs3>
                <v-layout column class="cust_pr_Ms mx-2  ">
                            <v-text class="font-weight-bold cstm-clr-pr-Ms">
                                Policy Type
                            </v-text>
                        
                            <v-text-field
                                solo
                                disabled
                                label="Enter..."
                                v-model="policyType"
                                class="rounded-0"
                                dense
                                >
                            </v-text-field>
                </v-layout>
            </v-flex>      

            <v-flex xs3>
                <v-layout column class="cust_pr_Ms mx-2  ">
                            <v-text class="font-weight-bold cstm-clr-pr-Ms">
                                Corporate Group Name
                            </v-text>
                        
                            <v-text-field
                                solo
                                disabled
                                label="Enter..."
                                v-model="corporateGN"
                                class="rounded-0"
                                dense
                                >
                            </v-text-field>
                </v-layout>
            </v-flex>
            
            <v-flex xs3>
                <v-layout column class="cust_pr_Ms mx-2  ">
                            <v-text class="font-weight-bold cstm-clr-pr-Ms">
                                Product Name
                            </v-text>
                        
                            <v-text-field
                                solo
                                disabled
                                label="Enter..."
                                v-model="productName"
                                class="rounded-0"
                                dense
                                >
                            </v-text-field>
                </v-layout>
            </v-flex> 
                    


        </v-layout>

        <v-layout>
            <v-flex xs3>
                <v-layout column class="cust_pr_Ms mx-2  ">
                            <v-text class="font-weight-bold cstm-clr-pr-Ms">
                                Plan Type
                            </v-text>
                        
                            <v-text-field
                                solo
                                disabled
                                label="Enter..."
                                v-model="planType"
                                class="rounded-0"
                                dense
                                >
                            </v-text-field>
                </v-layout>
            </v-flex> 


            <v-flex xs3>
                <v-layout column class="cust_pr_Ms mx-2  cust-rmvpd-pr-quick">
                            <v-text class="font-weight-bold cstm-clr-pr-Ms">
                                First Policy Inception Date
                            </v-text>
                        
                            <v-menu
                                ref="calender"
                                :close-on-content-click="false"
                                v-model="firstPolicyIncepDate"
                                :nudge-right="40"
                                lazy
                                transition="scale-transition"
                                full-width
                                max-width="290px"
                                min-width="290px" 
                                class="rounded-0 cstm-clr-pr-Ms"
                                dense
                                >
                                <template dense v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        dense
                                        v-bind="attrs"
                                        v-on="on"
                                        label="dd/mm/yyyy"
                                        :value="computedfirstPolicyIncepDate"
                                        solo
                                        disabled
                                        class="rounded-0"
                                        readonly
                                    >
                                    <v-icon 
                                        slot="prepend-inner" 
                                        dense  
                                        style="background-color:#F7F7F7; height: 38px; width: 40px;">
                                            date_range
                                        </v-icon>
                                    
                                    </v-text-field>
                                </template>                        
                                        <v-date-picker
                                            dense
                                            v-model="firstPolicyIncepDateModel"
                                            no-title
                                            @input="firstPolicyIncepDate = false"
                                            
                                        ></v-date-picker>
                            </v-menu>
                </v-layout>
            </v-flex>

            <v-flex xs3>
                <v-layout column class="cust_pr_Ms mx-2  cust-rmvpd-pr-quick">
                            <v-text class="font-weight-bold cstm-clr-pr-Ms">
                                Policy Effective Date
                            </v-text>
                        
                            <v-menu
                                ref="calender"
                                :close-on-content-click="false"
                                v-model="policyEffDate"
                                :nudge-right="40"
                                lazy
                                transition="scale-transition"
                                full-width
                                max-width="290px"
                                min-width="290px" 
                                class="rounded-0 cstm-clr-pr-Ms"
                                dense
                                >
                                <template dense v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        dense
                                        v-bind="attrs"
                                        v-on="on"
                                        label="dd/mm/yyyy"
                                        :value="computedpolicyEffDate"
                                        solo
                                        disabled
                                        class="rounded-0"
                                        readonly
                                    >
                                    <v-icon 
                                        slot="prepend-inner" 
                                        dense  
                                        style="background-color:#F7F7F7; height: 38px; width: 40px;">
                                            date_range
                                        </v-icon>
                                    
                                    </v-text-field>
                                </template>                        
                                        <v-date-picker
                                            dense
                                            v-model="policyEffDateModel"
                                            no-title
                                            @input="policyEffDate = false"
                                            
                                        ></v-date-picker>
                            </v-menu>
                </v-layout>
            </v-flex>

            <v-flex xs3>
                <v-layout column class="cust_pr_Ms mx-2  cust-rmvpd-pr-quick">
                            <v-text class="font-weight-bold cstm-clr-pr-Ms">
                                Policy Expiry Date
                            </v-text>
                        
                            <v-menu
                                ref="calender"
                                :close-on-content-click="false"
                                v-model="policyExpDate"
                                :nudge-right="40"
                                lazy
                                transition="scale-transition"
                                full-width
                                max-width="290px"
                                min-width="290px" 
                                class="rounded-0 cstm-clr-pr-Ms"
                                dense
                                >
                                <template dense v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        dense
                                        v-bind="attrs"
                                        v-on="on"
                                        label="dd/mm/yyyy"
                                        :value="computedpolicyExpDate"
                                        solo
                                        disabled
                                        class="rounded-0"
                                        readonly
                                    >
                                    <v-icon 
                                        slot="prepend-inner" 
                                        dense  
                                        style="background-color:#F7F7F7; height: 38px; width: 40px;">
                                            date_range
                                        </v-icon>
                                    
                                    </v-text-field>
                                </template>                        
                                        <v-date-picker
                                            dense
                                            v-model="policyExpDateModel"
                                            no-title
                                            @input="policyExpDate = false"
                                            
                                        ></v-date-picker>
                            </v-menu>
                </v-layout>
            </v-flex>

             <v-flex xs3>
                <v-layout column class="cust_pr_Ms mx-2  ">
                            <v-text class="font-weight-bold cstm-clr-pr-Ms">
                                Producer Code
                            </v-text>
                        
                            <v-text-field
                                solo
                                disabled
                                label="Enter..."
                                v-model="producerCode"
                                class="rounded-0"
                                dense
                                >
                            </v-text-field>
                </v-layout>
            </v-flex> 
        </v-layout>

        <v-layout >
             <v-flex xs3>
                <v-layout column class="cust_pr_Ms mx-2  ">
                            <v-text class="font-weight-bold cstm-clr-pr-Ms">
                                No. Of Years Renewal
                            </v-text>
                        
                            <v-text-field
                                solo
                                disabled
                                label="Enter..."
                                v-model="numOfYearsRenewal"
                                class="rounded-0"
                                dense
                                >
                            </v-text-field>
                </v-layout>
            </v-flex> 

             <v-flex xs3>
                <v-layout column class="cust_pr_Ms mx-2  cust-rmvpd-pr-quick">
                            <v-text class="font-weight-bold cstm-clr-pr-Ms">
                                Policy Cancel Date
                            </v-text>
                        
                            <v-menu
                                ref="calender"
                                :close-on-content-click="false"
                                v-model="policyCancelDate"
                                :nudge-right="40"
                                lazy
                                transition="scale-transition"
                                full-width
                                max-width="290px"
                                min-width="290px" 
                                class="rounded-0 cstm-clr-pr-Ms"
                                dense
                                >
                                <template dense v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        dense
                                        v-bind="attrs"
                                        v-on="on"
                                        label="dd/mm/yyyy"
                                        :value="computedpolicyCancelDate"
                                        solo
                                        disabled
                                        class="rounded-0"
                                        readonly
                                    >
                                    <v-icon 
                                        slot="prepend-inner" 
                                        dense  
                                        style="background-color:#F7F7F7; height: 38px; width: 40px;">
                                            date_range
                                        </v-icon>
                                    
                                    </v-text-field>
                                </template>                        
                                        <v-date-picker
                                            dense
                                            v-model="policyCancelDateModel"
                                            no-title
                                            @input="policyCancelDate = false"
                                            
                                        ></v-date-picker>
                            </v-menu>
                </v-layout>
            </v-flex>

            <v-flex xs9>
                
            </v-flex>



        </v-layout>

    <div>
       <v-layout row class="policyType " elevation-1>
            <p>Portability Details</p>
        </v-layout>

        <v-layout class="mt-5">
            <v-flex xs3>
                <v-layout column class="cust_pr_Ms mx-2  ">
                            <v-text class="font-weight-bold cstm-clr-pr-Ms">
                                Portability Flag
                            </v-text>
                        
                            <v-text-field
                                solo
                                disabled
                                label="Enter..."
                                v-model="portabilityFlag"
                                class="rounded-0"
                                dense
                                >
                            </v-text-field>
                </v-layout>
            </v-flex> 
           
            <v-flex xs3>
                <v-layout column class="cust_pr_Ms mx-2  ">
                            <v-text class="font-weight-bold cstm-clr-pr-Ms">
                                Previous Policy Number
                            </v-text>
                        
                            <v-text-field
                                solo
                                disabled
                                label="Enter..."
                                v-model="previousPolicyNum"
                                class="rounded-0"
                                dense
                                >
                            </v-text-field>
                </v-layout>
            </v-flex> 

            <v-flex xs3>
                <v-layout column class="cust_pr_Ms mx-2  ">
                            <v-text class="font-weight-bold cstm-clr-pr-Ms">
                                Previous Insurer
                            </v-text>
                        
                            <v-text-field
                                solo
                                disabled
                                label="Enter..."
                                v-model="previousInsurer"
                                class="rounded-0"
                                dense
                                >
                            </v-text-field>
                </v-layout>
            </v-flex> 

            <v-flex xs3>
                <v-layout column class="cust_pr_Ms mx-2  ">
                            <v-text class="font-weight-bold cstm-clr-pr-Ms">
                                Previous Sum Insured
                            </v-text>
                        
                            <v-text-field
                                solo
                                disabled
                                label="Enter..."
                                v-model="previousSumInsured"
                                class="rounded-0 comboxPadding"
                                maxlength="10"
                                dense
                                >
                                <v-icon 
                                slot="prepend-inner" 
                                dense  
                                style="background-color:#F7F7F7; height: 37px; width: 40px;">
                                    mdi-currency-inr
                                </v-icon>
                            </v-text-field>
                </v-layout>
            </v-flex>

            <v-flex xs3>
                <v-layout column class="cust_pr_Ms mx-2  cust-rmvpd-pr-quick">
                            <v-text class="font-weight-bold cstm-clr-pr-Ms">
                                Previous Effective Start Date
                            </v-text>
                        
                            <v-menu
                                ref="calender"
                                :close-on-content-click="false"
                                v-model="previousEffStartDate"
                                :nudge-right="40"
                                lazy
                                transition="scale-transition"
                                full-width
                                max-width="290px"
                                min-width="290px" 
                                class="rounded-0 cstm-clr-pr-Ms"
                                dense
                                >
                                <template dense v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        dense
                                        v-bind="attrs"
                                        v-on="on"
                                        label="dd/mm/yyyy"
                                        :value="pomputedPreviousEffStartDate"
                                        solo
                                        disabled
                                        class="rounded-0"
                                        readonly
                                    >
                                    <v-icon 
                                        slot="prepend-inner" 
                                        dense  
                                        style="background-color:#F7F7F7; height: 38px; width: 40px;">
                                            date_range
                                        </v-icon>
                                    
                                    </v-text-field>
                                </template>                        
                                        <v-date-picker
                                            dense
                                            v-model="previousEffStartDateModel"
                                            no-title
                                            @input="previousEffStartDate = false"
                                            
                                        ></v-date-picker>
                            </v-menu>
                </v-layout>
            </v-flex>
        </v-layout>

        <v-layout>
            <v-flex xs3>
                <v-layout column class="cust_pr_Ms mx-2  cust-rmvpd-pr-quick">
                            <v-text class="font-weight-bold cstm-clr-pr-Ms">
                                Previous Expiry Date
                            </v-text>
                        
                            <v-menu
                                ref="calender"
                                :close-on-content-click="false"
                                v-model="previousExpiryDate"
                                :nudge-right="40"
                                lazy
                                transition="scale-transition"
                                full-width
                                max-width="290px"
                                min-width="290px" 
                                class="rounded-0 cstm-clr-pr-Ms"
                                dense
                                >
                                <template dense v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        dense
                                        v-bind="attrs"
                                        v-on="on"
                                        label="dd/mm/yyyy"
                                        :value="pomputedPreviousExpiryDate"
                                        solo
                                        disabled
                                        class="rounded-0"
                                        readonly
                                    >
                                    <v-icon 
                                        slot="prepend-inner" 
                                        dense  
                                        style="background-color:#F7F7F7; height: 38px; width: 40px;">
                                            date_range
                                        </v-icon>
                                    
                                    </v-text-field>
                                </template>                        
                                        <v-date-picker
                                            dense
                                            v-model="previousExpiryDateModel"
                                            no-title
                                            @input="previousExpiryDate = false"
                                            
                                        ></v-date-picker>
                            </v-menu>
                </v-layout>
            </v-flex>

            <v-flex xs3>
                <v-layout column class="cust_pr_Ms mx-2  ">
                            <v-text class="font-weight-bold cstm-clr-pr-Ms">
                                Previous Cumulative Bonus
                            </v-text>
                        
                            <v-text-field
                                solo
                                disabled
                                label="Enter..."
                                v-model="previousCumulativeBonus"
                                class="rounded-0 comboxPadding"
                                maxlength="10"
                                dense
                                >
                                <v-icon 
                                slot="prepend-inner" 
                                dense  
                                style="background-color:#F7F7F7; height: 37px; width: 40px;">
                                    mdi-currency-inr
                                </v-icon>
                            </v-text-field>
                </v-layout>
            </v-flex>

            <v-flex xs9>
            </v-flex>
        </v-layout>

    </div>

    <div>
        <v-layout row class="policyType " elevation-1>
            <p>Member Details</p>
        </v-layout>

         <v-layout class="mt-5">
            <v-flex xs3>
                <v-layout column class="cust_pr_Ms mx-2  ">
                            <v-text class="font-weight-bold cstm-clr-pr-Ms">
                                Member ID
                            </v-text>
                        
                            <v-text-field
                                solo
                                disabled
                                label="Enter..."
                                v-model="memberId"
                                class="rounded-0"
                                dense
                                >
                            </v-text-field>
                </v-layout>
            </v-flex>

            <v-flex xs3>
                <v-layout column class="cust_pr_Ms mx-2  ">
                            <v-text class="font-weight-bold cstm-clr-pr-Ms">
                                First Name
                            </v-text>
                        
                            <v-text-field
                                solo
                                disabled
                                label="Enter..."
                                v-model="firstName"
                                class="rounded-0"
                                dense
                                >
                            </v-text-field>
                </v-layout>
            </v-flex>

            <v-flex xs3>
                <v-layout column class="cust_pr_Ms mx-2  ">
                            <v-text class="font-weight-bold cstm-clr-pr-Ms">
                                Last Name
                            </v-text>
                        
                            <v-text-field
                                solo
                                disabled
                                label="Enter..."
                                v-model="lastName"
                                class="rounded-0"
                                dense
                                >
                            </v-text-field>
                </v-layout>
            </v-flex>

            <v-flex xs3>
                <v-layout column class="cust_pr_Ms mx-2  cust-rmvpd-pr-quick">
                            <v-text class="font-weight-bold cstm-clr-pr-Ms">
                                Member inception date – Tata AIG
                            </v-text>
                        
                            <v-menu
                                ref="calender"
                                :close-on-content-click="false"
                                v-model="memberIncepDate"
                                :nudge-right="40"
                                lazy
                                transition="scale-transition"
                                full-width
                                max-width="290px"
                                min-width="290px" 
                                class="rounded-0 cstm-clr-pr-Ms"
                                dense
                                >
                                <template dense v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        dense
                                        v-bind="attrs"
                                        v-on="on"
                                        label="dd/mm/yyyy"
                                        :value="computedmemberIncepDate"
                                        solo
                                        disabled
                                        class="rounded-0"
                                        readonly
                                    >
                                    <v-icon 
                                        slot="prepend-inner" 
                                        dense  
                                        style="background-color:#F7F7F7; height: 38px; width: 40px;">
                                            date_range
                                        </v-icon>
                                    
                                    </v-text-field>
                                </template>                        
                                        <v-date-picker
                                            dense
                                            v-model="memberIncepDateModel"
                                            no-title
                                            @input="memberIncepDate = false"
                                            
                                        ></v-date-picker>
                            </v-menu>
                </v-layout>
            </v-flex>

            <v-flex xs3>
                <v-layout column class="cust_pr_Ms mx-2  ">
                            <v-text class="font-weight-bold cstm-clr-pr-Ms">
                                Member Status
                            </v-text>
                        
                            <v-text-field
                                solo
                                disabled
                                label="Enter..."
                                v-model="memberStatus"
                                class="rounded-0"
                                dense
                                >
                            </v-text-field>
                </v-layout>
            </v-flex>
        </v-layout>

        <v-layout>
            <v-flex xs3>
                <v-layout column class="cust_pr_Ms mx-2  cust-rmvpd-pr-quick">
                            <v-text class="font-weight-bold cstm-clr-pr-Ms">
                                Date of Birth
                            </v-text>
                        
                            <v-menu
                                ref="calender"
                                :close-on-content-click="false"
                                v-model="dobDate"
                                :nudge-right="40"
                                lazy
                                transition="scale-transition"
                                full-width
                                max-width="290px"
                                min-width="290px" 
                                class="rounded-0 cstm-clr-pr-Ms"
                                dense
                                >
                                <template dense v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        dense
                                        v-bind="attrs"
                                        v-on="on"
                                        label="dd/mm/yyyy"
                                        :value="computeddobDate"
                                        solo
                                        disabled
                                        class="rounded-0"
                                        readonly
                                    >
                                    <v-icon 
                                        slot="prepend-inner" 
                                        dense  
                                        style="background-color:#F7F7F7; height: 38px; width: 40px;">
                                            date_range
                                        </v-icon>
                                    
                                    </v-text-field>
                                </template>                        
                                        <v-date-picker
                                            dense
                                            v-model="dobDateModel"
                                            no-title
                                            @input="dobDate = false"
                                            
                                        ></v-date-picker>
                            </v-menu>
                </v-layout>
            </v-flex>

            <v-flex xs3>
                <v-layout column class="cust_pr_Ms mx-2  ">
                            <v-text class="font-weight-bold cstm-clr-pr-Ms">
                                Age
                            </v-text>
                        
                            <v-text-field
                                solo
                                disabled
                                label="Enter..."
                                v-model="age"
                                class="rounded-0"
                                dense
                                >
                            </v-text-field>
                </v-layout>
            </v-flex>

            <v-flex xs3>
                <v-layout column class="cust_pr_Ms mx-2  ">
                            <v-text class="font-weight-bold cstm-clr-pr-Ms">
                                Gender
                            </v-text>
                        
                            <v-text-field
                                solo
                                disabled
                                label="Enter..."
                                v-model="gender"
                                class="rounded-0"
                                dense
                                >
                            </v-text-field>
                </v-layout>
            </v-flex>

            <v-flex xs3>
                <v-layout column class="cust_pr_Ms mx-2  ">
                            <v-text class="font-weight-bold cstm-clr-pr-Ms">
                                Sum Insured
                            </v-text>
                        
                            <v-text-field
                                solo
                                disabled
                                label="Enter..."
                                v-model="sumInsured"
                                class="rounded-0 comboxPadding"
                                maxlength="10"
                                dense
                                >
                                <v-icon 
                                slot="prepend-inner" 
                                dense  
                                style="background-color:#F7F7F7; height: 37px; width: 40px;">
                                    mdi-currency-inr
                                </v-icon>
                            </v-text-field>
                </v-layout>
            </v-flex>

            <v-flex xs3>
                <v-layout column class="cust_pr_Ms mx-2  ">
                            <v-text class="font-weight-bold cstm-clr-pr-Ms">
                                Cumulative Bonus
                            </v-text>
                        
                            <v-text-field
                                solo
                                disabled
                                label="Enter..."
                                v-model="cumulativeBonus"
                                class="rounded-0 comboxPadding"
                                maxlength="10"
                                dense
                                >
                                <v-icon 
                                slot="prepend-inner" 
                                dense  
                                style="background-color:#F7F7F7; height: 37px; width: 40px;">
                                    mdi-currency-inr
                                </v-icon>
                            </v-text-field>
                </v-layout>
            </v-flex>

        </v-layout>

        <v-layout>
            <v-flex xs3>
                <v-layout column class="cust_pr_Ms mx-2  cust-rmvpd-pr-quick">
                            <v-text class="font-weight-bold cstm-clr-pr-Ms">
                                Joining Date
                            </v-text>
                        
                            <v-menu
                                ref="calender"
                                :close-on-content-click="false"
                                v-model="joiningDate"
                                :nudge-right="40"
                                lazy
                                transition="scale-transition"
                                full-width
                                max-width="290px"
                                min-width="290px" 
                                class="rounded-0 cstm-clr-pr-Ms"
                                dense
                                >
                                <template dense v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        dense
                                        v-bind="attrs"
                                        v-on="on"
                                        label="dd/mm/yyyy"
                                        :value="computedjoiningDate"
                                        solo
                                        disabled
                                        class="rounded-0"
                                        readonly
                                    >
                                    <v-icon 
                                        slot="prepend-inner" 
                                        dense  
                                        style="background-color:#F7F7F7; height: 38px; width: 40px;">
                                            date_range
                                        </v-icon>
                                    
                                    </v-text-field>
                                </template>                        
                                        <v-date-picker
                                            dense
                                            v-model="joiningDateModel"
                                            no-title
                                            @input="joiningDate = false"
                                            
                                        ></v-date-picker>
                            </v-menu>
                </v-layout>
            </v-flex>

            <v-flex xs3>
                <v-layout column class="cust_pr_Ms mx-2  cust-rmvpd-pr-quick">
                            <v-text class="font-weight-bold cstm-clr-pr-Ms">
                                Leaving Date
                            </v-text>
                        
                            <v-menu
                                ref="calender"
                                :close-on-content-click="false"
                                v-model="leavingDate"
                                :nudge-right="40"
                                lazy
                                transition="scale-transition"
                                full-width
                                max-width="290px"
                                min-width="290px" 
                                class="rounded-0 cstm-clr-pr-Ms"
                                dense
                                >
                                <template dense v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        dense
                                        v-bind="attrs"
                                        v-on="on"
                                        label="dd/mm/yyyy"
                                        :value="computedleavingDate"
                                        solo
                                        disabled
                                        class="rounded-0"
                                        readonly
                                    >
                                    <v-icon 
                                        slot="prepend-inner" 
                                        dense  
                                        style="background-color:#F7F7F7; height: 38px; width: 40px;">
                                            date_range
                                        </v-icon>
                                    
                                    </v-text-field>
                                </template>                        
                                        <v-date-picker
                                            dense
                                            v-model="leavingDateModel"
                                            no-title
                                            @input="leavingDate = false"
                                            
                                        ></v-date-picker>
                            </v-menu>
                </v-layout>
            </v-flex>

            <v-flex xs3>
                <v-layout column class="cust_pr_Ms mx-2  ">
                            <v-text class="font-weight-bold cstm-clr-pr-Ms">
                                Nominee Name
                            </v-text>
                        
                            <v-text-field
                                solo
                                disabled
                                label="Enter..."
                                v-model="nomineeName"
                                class="rounded-0"
                                dense
                                >
                            </v-text-field>
                </v-layout>
            </v-flex>

            <v-flex xs3>
                <v-layout column class="cust_pr_Ms mx-2  ">
                            <v-text class="font-weight-bold cstm-clr-pr-Ms">
                                Loading Reason
                            </v-text>
                        
                            <v-text-field
                                solo
                                disabled
                                label="Enter..."
                                v-model="loadingReason"
                                class="rounded-0"
                                dense
                                >
                            </v-text-field>
                </v-layout>
            </v-flex>

            <v-flex xs3>
                <v-layout column class="cust_pr_Ms mx-2  ">
                            <v-text class="font-weight-bold cstm-clr-pr-Ms">
                                Mobile Number
                            </v-text>
                        
                            <v-text-field
                                solo
                                disabled
                                label="Enter..."
                                v-model="mobileNumber"
                                class="rounded-0 comboxPadding"
                                maxlength="10"
                                dense
                                >
                                <v-icon 
                                slot="prepend-inner" 
                                dense  
                                style="background-color:#F7F7F7; height: 37px; width: 40px;">
                                    call
                                </v-icon>
                            </v-text-field>
                </v-layout>
            </v-flex>

        </v-layout>

        <v-layout >
            
            <v-flex xs3>
                <v-layout column class="cust_pr_Ms mx-2  ">
                            <v-text class="font-weight-bold cstm-clr-pr-Ms">
                                E-mail
                            </v-text>
                        
                            <v-text-field
                                solo
                                disabled
                                label="Enter..."
                                v-model="email"
                                class="rounded-0"
                                dense
                                >
                            </v-text-field>
                </v-layout>
            </v-flex>

            <v-flex xs3>
                <v-layout column class="cust_pr_Ms mx-2  ">
                            <v-text class="font-weight-bold cstm-clr-pr-Ms">
                                AADHAAR Number
                            </v-text>
                        
                            <v-text-field
                                solo
                                disabled
                                label="Enter..."
                                v-model="aadharNumber"
                                class="rounded-0"
                                maxlength="12"
                                dense
                                >
                            </v-text-field>
                </v-layout>
            </v-flex>

            <v-flex xs3>
                <v-layout column class="cust_pr_Ms mx-2  ">
                            <v-text class="font-weight-bold cstm-clr-pr-Ms">
                                PAN Number
                            </v-text>
                        
                            <v-text-field
                                solo
                                disabled
                                label="Enter..."
                                v-model="panNo"
                                class="rounded-0"
                                dense
                                >
                            </v-text-field>
                </v-layout>
            </v-flex>

            <v-flex xs3>
                <v-layout column class="cust_pr_Ms mx-2  ">
                            <v-text class="font-weight-bold cstm-clr-pr-Ms">
                                Coverages
                            </v-text>
                        
                            <v-select
                                :items="coveragesItem"
                                solo
                                disabled
                                label="Enter..."
                                v-model="coverages"
                                class="rounded-0"
                                dense
                                >
                            </v-select>
                </v-layout>
            </v-flex>

            <v-flex xs3>
            </v-flex>




        </v-layout>

    </div>

     <v-card class="mb-8 pa-5 rounded-0" style="background-color: #F7F7F7; display:flex;justify-content:space-between;">
            <v-layout style="flex:1" justify-end > 
                <v-btn dark  tile color="#10242b" class="text-capitalize px-3 mx-3" small><v-icon dark small>mdi-arrow-left</v-icon><v-divider vertical class="mx-2"></v-divider>Return</v-btn>
            </v-layout>
        </v-card>
   </div>
</template>



<script>
export default {
    created(){
        this.$store.commit("SAVE_PAGE_HEADER", "Policy Details");
        console.log("from route", this.$route.params);
        this.policyNum = this.$route.params.policyNumber
        this.memberId = this.$route.params.memberId
        this.getPolicyDet();
    },

    data(){
        return {
            policyNum:'',
            policyStatus:'',
            policyType:'',
            corporateGN:'',
            productName:'',
            planType:'',
            firstPolicyIncepDate:false,
            firstPolicyIncepDateModel:'',
            policyEffDate:false,
            policyEffDateModel:'',
            policyExpDate:false,
            policyExpDateModel:'',
            producerCode:'',
            numOfYearsRenewal:'',
            policyCancelDate:false,
            policyCancelDateModel:'',
            portabilityFlag:'',
            previousPolicyNum:'',
            previousInsurer:'',
            previousSumInsured:'',
            previousEffStartDate:false,
            previousEffStartDateModel:'',
            previousExpiryDate:false,
            previousExpiryDateModel:'',
            previousCumulativeBonus:'',
            memberId:'',
            firstName:'',
            lastName:'',
            memberIncepDate:false,
            memberIncepDateModel:'',
            memberStatus:'',
            dobDate:false,
            dobDateModel:'',
            age:'',
            gender:'',
            sumInsured:'',
            cumulativeBonus:'',
            joiningDate:false,
            joiningDateModel:'',
            leavingDate:false,
            leavingDateModel:'',
            nomineeName:'',
            loadingReason:'',
            mobileNumber:'',
            email:'',
            aadharNumber:'',
            panNo:'',
            coverages:'',
            coveragesItem:[]

        }
    },
    methods:{

        getPolicyDet(){
             this.GET(8,'policy-details/get-policy-details?memberId='+this.memberId,(res,error)=>{
                        if(!error){
                            console.log(res.data.data);
                        }else{
                            console.log("their was error",error);
                        }
             })
        }
    }

}
</script>



<style scoped>
.policyType{
    background-color: #01BBD9;
    height: 35px;
    padding: 5px;
}
.policyType p{
    /* margin: auto; */
    margin-top: 2px;
    margin-left: 16px;
    color: white;
    /* margin-bottom: 0px; */
    font-size: 15px;
}

.cstm-clr-pr-Ms{
    color:#646666 !important;
    margin-bottom: 5px !important;
    font-size: 13px !important;
}

.cstm-clr-pr-Ms+.v-text-field >>> label{ 
    font-size: 13px;
    
}

.cust_pr_Ms .v-input{
    font-size: 13px !important;
}

.comboxPadding >>> .v-input__slot{
    padding-left: 0 !important;
}

</style>