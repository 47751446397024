<template>
  <div>
    <v-app-bar
      color="#10242B"
      dense
      dark
      app
      fixed
      clipped-left
      class="custm-pd0 mynavbar"
    >
      <v-layout
        row
        align-center
        justify-space-between
        class="mainLayoutMargin"
      >
        <v-layout
         
          align-center
          class="mediaqueryheaderbar pageNameMargin"
          :class="showPageName === true ? 'ml-0' : 'ml-0'"
        >
          <!-- below style marginLeft is added by gopikrishna for small text header Name-->
          <v-btn
            icon
            v-if="showbckBtn === true"
            @click="$router.go(-1)"
            :style="{
              marginLeft:
                leftSideheadeTitle.length == 7 ||
                leftSideheadeTitle.length == 9 ||
                leftSideheadeTitle.length == 10
                  ? '-7px'
                  : leftSideheadeTitle.length == 4
                  ? '-45px'
                  : '0px',
            }"
          >
            <div class="mt-1">
              <img
                src="../assets/images/backbtn.png"
              class="backbuttonImg"
              />
            </div>
          </v-btn>
          <p
            v-if="showPageName"
            style="text-transform: capitalize"
            class="mt-5"
            :class="showbckBtn === true ? 'ml-2 font-weight-regular body-2' : 'ml-14 font-weight-regular body-2'"
          >
            {{ leftSideheadeTitle }}
          </p>
        </v-layout>
        <p
          class="text-uppercase title font-weight-bold font-italic text-xs-center mt-5 mediaqueryclaimtext pclaimMarginright"
          name="Claims"
        >
          Claimsdrive
        </p>
        <v-layout class="pr-0 layoutbuttongroup" justify-end>
          <v-btn icon>
            <v-icon class="iconSizeHeader">mdi-magnify</v-icon></v-btn
          >
          <v-btn icon> <v-icon class="iconSizeHeader">mdi-bell</v-icon></v-btn>
          <!-- <v-btn icon>
            <v-icon class="iconSizeHeader">mdi-account</v-icon></v-btn
          > -->
          <v-menu transition="slide-y-transition" content-class="my-menu">
            <template v-slot:activator="{ on, attrs }">
              <v-btn dark v-bind="attrs" v-on="on" icon class="mr-3">
                <v-icon>mdi-account</v-icon>
              </v-btn>
            </template>
            <user-inbox></user-inbox>
          </v-menu>
        </v-layout>
      </v-layout>
    </v-app-bar>
    <v-navigation-drawer
      fixed
      app
      dark
      clipped
      v-model="drawer"
      :mini-variant.sync="mini"
      class="thems_color"
      disable-resize-watcher
      dense
      v-click-outside="onClickOutside"
    >
      <div
        class="stopMiniMT"
        v-if="mini === false"
        @click.stop="mini = !mini"
      >
        <img
          src="../assets/images/Left_arrow.png"
         class="leftrightArrowImg"
        />
      </div>
      <div
        class="stopMiniMT"
        @click.stop="mini = !mini"
        v-else
      >
        <img
          src="../assets/images/Right_arrow.png"
            class="leftrightArrowImg"
        />
      </div>
      <v-list
        dense
        class="ma-0 pa-0"
        v-for="(item, index) in leftMenuBarOptions"
        :key="item.title"
      >
        <v-list-item
          @click="leftMenuRouter(index, item.title, item.path)"
          :style="{ backgroundColor: item.selected ? '#00000070' : '#152F38' }"
        >
          <v-list-item-icon class="mr-4">
            <div>
              <v-img v-bind:src="item.imagePath" width="19" height="19">
              </v-img>
            </div>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title
              class="font-weight-light"
              style="font-size: 11px"
              >{{ item.title }}</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import userInbox from "./UserIcon";
export default {
  data() {
    return {
      drawer: true,
      mini: true,
      showbckBtn: true,
      showPageName: false,
      leftMenuBarOptions: [
        {
          title: "Home",
          imagePath: require("../assets/MenuLeftBar/homepage.png"),
          path: "/home",
          id:0
        },
        {
          title: "Claim Main Screen",
          imagePath: require("../assets/MenuLeftBar/ClaimMainScreen.png"),
          path: "/claimmainscreen",
           id:1
        },
        {
          title: "Claim Initimation/Registration",
          imagePath: require("../assets/MenuLeftBar/ClaimRegistration.png"),
          path: "/fnol",
           id:2
        },
        {
          title: "Claim Search",
          imagePath: require("../assets/MenuLeftBar/ClaimSearch.png"),
          path: "",
           id:3
        },
        {
          title: "Service Provider Search",
          imagePath: require("../assets/MenuLeftBar/ServiceProviderSearch.png"),
          path: "",
           id:4
        },
        {
          title: "Claim Processing",
          imagePath: require("../assets/MenuLeftBar/ClaimProcessing.png"),
          path: "",
           id:5
        },
        {
          title: "Claim Assessment",
          imagePath: require("../assets/MenuLeftBar/ClaimAssessment.png"),
          path: "",
           id:6
        },
        {
          title: "Adjustor Appointment",
          imagePath: require("../assets/MenuLeftBar/AdjustorAppointment.png"),
          path: "",
           id:7
        },
        {
          title: "Payment",
          imagePath: require("../assets/MenuLeftBar/Payment.png"),
          path: "",
           id:8
        },
        {
          title: "Approvals",
          imagePath: require("../assets/MenuLeftBar/Approvals.png"),
          path: "",
           id:9
        },
        {
          title: "Claim Cancellation",
          imagePath: require("../assets/MenuLeftBar/ClaimCancellation.png"),
          path: "",
           id:10
        },
        {
          title: "Re-open Claim",
          imagePath: require("../assets/MenuLeftBar/ReopenClaim.png"),
          path: "",
           id:11
        },
        {
          title: "Document Checklist",
          imagePath: require("../assets/MenuLeftBar/DocumentChecklist.png"),
          path: "",
           id:12
        },
        {
          title: "Co-Insurance",
          imagePath: require("../assets/MenuLeftBar/CoInsurance.png"),
          path: "",
           id:13
        },
        {
          title: "Masters",
          imagePath: require("../assets/MenuLeftBar/Masters.png"),
          path: "/masters",
           id:14
        },
        {
          title: "Mark user offline",
          imagePath: require("../assets/MenuLeftBar/cloud-off-outline.png"),
          path: "/markuseroffline",
          id:15
        },
         {
          title: "Workitem Assignment",
          imagePath: require("../assets/MenuLeftBar/ClaimAssessment.png"),
          path: "/workitemAssignment",
          id:16
        },
      ],
    };
  },
  components: {
    userInbox,
  },
  computed: {
    leftSideheadeTitle() {
      return this.$store.getters.fetchHeader;
    },

    mySelectedToggle() {
      // let index = this.leftMenuBarOptions.findIndex((elem) => {
      //   return elem.path == this.$route.fullPath;
      // });
      // if (index !== -1) {
      //   this.leftMenuBarOptions.map((e) => {
      //     return (e.selected = false);
      //   });
      //   this.leftMenuBarOptions[index].selected = true;
      //   this.$forceUpdate();
      // } else {
      //   this.leftMenuBarOptions.map((e) => {
      //     return (e.selected = false);
      //   });
      // }
        this.leftMenuBarOptions.map((e) => {
            return (e.selected = false);
          });
          this.showbckBtn = true;
          this.showPageName = true;
      if (this.$route.name == "Home") {
          this.$store.commit("SAVE_PAGE_HEADER", "Home");
          this.showbckBtn = false;
          this.leftMenuBarOptions[0].selected = true;
      } else if (this.$route.name == "ClaimMainScreen") {
        this.$store.commit("SAVE_PAGE_HEADER", "Claim Main Screen");
        this.leftMenuBarOptions[1].selected = true;
      } else if (this.$route.name == "PolicySearch") {
        this.$store.commit("SAVE_PAGE_HEADER", "FNOL Policy Search");
        this.leftMenuBarOptions[2].selected = true;
      } else if (this.$route.name == "PolicyDetails") {
        this.$store.commit("SAVE_PAGE_HEADER", "FNOL Policy Details");
        this.leftMenuBarOptions[2].selected = true;
        this.showbckBtn = false;
      }
       else if (this.$route.name == "Masters") {
        this.$store.commit("SAVE_PAGE_HEADER", "Masters");
        this.leftMenuBarOptions[14].selected = true;
      } else if (this.$route.name == "CatastropheMasters") {
        this.$store.commit("SAVE_PAGE_HEADER", "Catastrophe Masters");
        this.leftMenuBarOptions[14].selected = true;
      } else if (this.$route.name == "AIGMaster" || this.$route.name == "SearchAIGOffice") {
        this.$store.commit("SAVE_PAGE_HEADER", "AIG Office Master");
        this.leftMenuBarOptions[14].selected = true;
      } else if (this.$route.name == "TransactionHistory") {
        this.$store.commit("SAVE_PAGE_HEADER", "Transaction History");
      } else if (this.$route.name == "MajorClientMaster") {
         this.leftMenuBarOptions[14].selected = true;
        this.$store.commit("SAVE_PAGE_HEADER", "Major Client Master");
      } else if(this.$route.name == "MarkUserOffline"){
         this.leftMenuBarOptions[15].selected = true;
         this.$store.commit("SAVE_PAGE_HEADER", "Mark User offline");
      }
      else if(this.$route.name == "statusAuditTrail"){
        this.$store.commit("SAVE_PAGE_HEADER", "Status Audit Trail");
      }
      else if(this.$route.name == "generalAuditTrail"){
        this.$store.commit("SAVE_PAGE_HEADER", "General Audit Trail");
      }else if(this.$route.name == "auditTrail"){
         this.$store.commit("SAVE_PAGE_HEADER", "Audit Trail");
      }
      else if(this.$route.name == "masterAuditTrail"){
         this.$store.commit("SAVE_PAGE_HEADER", "Master Audit Trail");
      }else if(this.$route.name =="workitemAssignment"){
          this.$store.commit("SAVE_PAGE_HEADER", "Workitem Assignment");
          this.leftMenuBarOptions[16].selected = true;
      }
      else if(this.$route.name == "AssignReassignAuditTrail"){
         this.$store.commit("SAVE_PAGE_HEADER", "Claim Assignment / Re-assignment Audit Trail");
      }else {
        this.$store.commit("SAVE_PAGE_HEADER", "");
        this.showPageName = false;
        this.showbckBtn = false;
      }
    },
  },

  watch: {
    mySelectedToggle: function (val, old) {
      try {
        console.log("my urllllllllllllllllll", val, old);
      } catch (error) {
        console.log(error);
      }
    },
  },

  methods: {
    onClickOutside() {
      this.mini = true;
    },

    leftMenuRouter(index, buttonText, path) {
      if (path !== "") {
        if (buttonText == "Claim Initimation/Registration") {
          this.$store.commit("FNOL_PAGE_EDIT_CHECK", "FnolNewPage");
          let selected={claimType: "", claimId: ""};
          this.$store.commit("SAVE_LANDINGPAGE_ID", {data: selected});
        }
        this.$router.push(path);
      }
    },
  },
};
</script>

<style scoped >
.stopMiniMT{
 margin-top: -10px !important
}
.mainLayoutMargin {
	margin-right: -16px;
}
.pageNameMargin {
	flex: 1.1;
	margin-right: 108px;
}
.backbuttonImg {
	width: 22px;
	height: 22px
}
.pclaimMarginright {
	flex: 1;
	margin-right: 264px;
}
.layoutbuttongroup {
	flex: 0.3;
	background-color: #23b1a9;
	border-radius: 60px 0px 0px 60px;
}
.leftrightArrowImg {
	width: 40px;
	height: 40px;
	position: relative;
	top: 10px
}
.mynavbar::-webkit-scrollbar {
	display: none !important;
}
.iconSizeHeader {
	font-size: 18px !important;
}
.lne {
	display: block;
	flex: 1 1 0px;
	max-width: 100%;
	height: 0;
	max-height: 0;
	border: solid;
	border-top-width: thin;
	border-right-width: 0px;
	border-bottom-width: 0px;
	border-left-width: 0px;
	transition: inherit;
	border-top-color: rgba(255, 255, 255, 0.1);
	border-right-color: rgba(255, 255, 255, 0.1);
	border-bottom-color: rgba(255, 255, 255, 0.1);
	border-left-color: rgba(255, 255, 255, 0.1);
}
.thems_color {
	background-color: #152f38 !important;
}
.my-menu {
	margin-top: 36px;
	contain: initial;
	overflow: visible;
}
.my-menu::before {
	position: absolute;
	content: "";
	top: 0;
	z-index: 5;
	right: 16px;
	transform: rotate(180deg);
	width: 10px;
	height: 13px;
	border-left: 10px solid transparent;
	border-right: 10px solid transparent;
	border-bottom: 20px solid #23b1a9;
}
.custm-pd0 .v-toolbar__content {
	padding: 0px !important;
}
@media only screen and (min-width: 1330px) and (max-width: 1370px) {
	.mediaqueryheaderbar {
		margin-right: -80px !important;
	}
	.mediaqueryclaimtext {
		margin-right: 55px !important;
	}
}
</style>