<template>  
    <v-app>
        <!-- isNonserviceProvider False -->
        <div style="background-color:#FFFFFF;border: 1px solid #C1C8CC;" v-if="instutionData.isNonServiceProvider === false">
        <div style="background-color:#FFFFFF; display:flex;border-bottom: 1px solid #C1C8CC;">

            <v-flex xs3 style="background-color:#FFFFFF; " >
                <v-layout column class="px-4 py-4 ">
                        <p class="text-uppercase font-weight-bold title-clr-prsmry">
                            Provider Details
                        </p>
                        <v-divider></v-divider>
                    </v-layout>
            </v-flex>
            <v-flex xs3 style="background-color:#FFFFFF; border-left: 1px solid #C1C8CC;word-break: break-word;" >
                 <v-layout column class=" px-4 py-4 " >
                     <p class="font-weight-bold cstm-clr-prtbsvw">
                        Registered Entity *
                     </p>
                      <p class="font-weight-regular fnt-sz-13">
                         {{instutionData.registeredEntity|checkdata}}
                     </p>
                 </v-layout>
             </v-flex>
             <v-flex xs3 style="background-color:#FFFFFF;border-left: 1px solid #C1C8CC;word-break: break-word; ">
                 <v-layout column class=" px-4 py-4 " >
                     <p class="font-weight-bold cstm-clr-prtbsvw">
                         is Non-Service Provider
                     </p>
                      <p class="font-weight-regular fnt-sz-13">
                        {{instutionData.isNonServiceProvider === false ? "No" : "Yes "|checkdata}}
                     </p>
                     
                 </v-layout>
             </v-flex>
             <v-flex xs3 style="background-color:#FFFFFF;border-left: 1px solid #C1C8CC;word-break: break-word;">
                 <v-layout column class=" px-4 py-4 " >
                     <p class="font-weight-bold cstm-clr-prtbsvw">
                         Provider Flag 
                     </p>
                      <p class="font-weight-regular fnt-sz-13">
                         {{ this.providerFlagIs | checkdata}}
                     </p>
                     
                 </v-layout>
             </v-flex>
             <v-flex xs3 style="background-color:#FFFFFF; border-left: 1px solid #C1C8CC;word-break: break-word;">
                 <v-layout column class=" px-4 py-4 " >
                     <p class="font-weight-bold cstm-clr-prtbsvw">
                        PRN
                     </p>
                      <p class="font-weight-regular fnt-sz-13">
                       {{instutionData.prn|checkdata}}
                     </p>
                     
                 </v-layout>
             </v-flex>
             <v-flex xs3 style="background-color:#FFFFFF;border-left: 1px solid #C1C8CC;word-break: break-word; ">
                 <v-layout column class=" px-4 py-4 " >
                     <p class="font-weight-bold cstm-clr-prtbsvw">
                         Entity Name *
                     </p>
                      <p class="font-weight-regular fnt-sz-13">
                      {{instutionData.entityName|checkdata}}
                     </p>
                     
                 </v-layout>
             </v-flex>
             <v-flex xs3 style="background-color:#FFFFFF;border-left: 1px solid #C1C8CC;word-break: break-word; ">
                 <v-layout column class=" px-4 py-4 " >
                     <p class="font-weight-bold cstm-clr-prtbsvw">
                        Alias Name
                     </p>
                      <p class="font-weight-regular fnt-sz-13">
                       {{instutionData.aliasName|checkdata}}
                     </p>
                     
                 </v-layout> 
             </v-flex>
             
        </div>

        <div style="background-color:#FFFFFF; display:flex;border-bottom: 1px solid #C1C8CC;">
            <v-flex xs3 style="background-color:#FFFFFF;">
                 <v-layout column class=" px-4 py-4 " >
                     <p class="font-weight-bold cstm-clr-prtbsvw">
                        Provider Type
                     </p>
                      <p class="font-weight-regular fnt-sz-13">
                        {{instutionData.providerType|checkdata}}
                     </p>
                     
                 </v-layout>
             </v-flex>
              <v-flex xs3 style="background-color:#FFFFFF;border-left: 1px solid #C1C8CC;word-break: break-word; " >
                 <v-layout column class=" px-4 py-4 " >
                     <p class="font-weight-bold cstm-clr-prtbsvw">
                       Other
                     </p>
                      <p class="font-weight-regular fnt-sz-13">
                        {{instutionData.providerTypeOther|checkdata}}

                     </p>
                 </v-layout>
             </v-flex>
            <v-flex xs3 style="background-color:#FFFFFF;border-left: 1px solid #C1C8CC;word-break: break-word; " >
                 <v-layout column class=" px-4 py-4 " >
                     <p class="font-weight-bold cstm-clr-prtbsvw">
                        ROHINI ID
                     </p>
                      <p class="font-weight-regular fnt-sz-13">
                        {{instutionData.rohiniId|checkdata}}
                     </p>
                 </v-layout>
             </v-flex>
             <v-flex xs3 style="background-color:#FFFFFF;border-left: 1px solid #C1C8CC;word-break: break-word; ">
                 <v-layout column class=" px-4 py-4 " >
                     <p class="font-weight-bold cstm-clr-prtbsvw">
                        Registration Number
                     </p>
                      <p class="font-weight-regular fnt-sz-13">
                       {{instutionData.registrationNumber|checkdata}}
                     </p>
                     
                 </v-layout>
             </v-flex>
             <v-flex xs3 style="background-color:#FFFFFF;border-left: 1px solid #C1C8CC;word-break: break-word; ">
                 <v-layout column class=" px-4 py-4 " >
                     <p class="font-weight-bold cstm-clr-prtbsvw">
                        Registration Body
                     </p>
                      <p class="font-weight-regular fnt-sz-13">
                        {{instutionData.registeredBody|checkdata}}
                     </p>
                     
                 </v-layout>
             </v-flex>
             <v-flex xs3 style="background-color:#FFFFFF;border-left: 1px solid #C1C8CC;word-break: break-word; ">
                 <v-layout column class=" px-4 py-4 " >
                     <p class="font-weight-bold cstm-clr-prtbsvw">
                        Registration Valid Upto
                     </p>
                      <p class="font-weight-regular fnt-sz-13">
                       {{(instutionData.registrationValidUpto)| checkDate | checkdata}}
                     </p>
                     
                 </v-layout>
             </v-flex>
             <v-flex xs3 style="background-color:#FFFFFF;border-left: 1px solid #C1C8CC;word-break: break-word; ">
                 <v-layout column class=" px-4 py-4 " >
                     <p class="font-weight-bold cstm-clr-prtbsvw">
                        Services Provided
                     </p>
                      <p class="font-weight-regular fnt-sz-13">
                        {{instutionData.servicesProvided.toString()|checkdata}}
                     </p>
                     
                 </v-layout>
             </v-flex>
            
          
             
        </div>




        <div style="background-color:#FFFFFF;display:flex;border-bottom: 1px solid #C1C8CC;">
                <v-flex xs3 style="background-color:#FFFFFF;border-left: 1px solid #C1C8CC;word-break: break-word; ">
                 <v-layout column class=" px-4 py-4 " >
                     <p class="font-weight-bold cstm-clr-prtbsvw">
                        Other
                     </p>
                      <p class="font-weight-regular fnt-sz-13">
                        {{instutionData.servicesProvidedOther|checkdata}}
                     </p>
                     
                 </v-layout>
             </v-flex>
               <v-flex xs3 style="background-color:#FFFFFF;border-left: 1px solid #C1C8CC;word-break: break-word;">
                 <v-layout column class=" px-4 py-4 " >
                     <p class="font-weight-bold cstm-clr-prtbsvw">
                         E-mail
                     </p>
                      <p class="font-weight-regular fnt-sz-13">
                         {{instutionData.email|checkdata}}

                     </p>
                     
                 </v-layout>
             </v-flex>
            
            <v-flex xs3 style="background-color:#FFFFFF;border-left: 1px solid #C1C8CC;word-break: break-word;">
                 <v-layout column class=" px-4 py-4 " >
                     <p class="font-weight-bold cstm-clr-prtbsvw">
                        Telephone Number
                     </p>
                      <p class="font-weight-regular fnt-sz-13">
                       {{instutionData.telephoneNumber|checkdata}}
                       
                     </p>
                     
                 </v-layout>
             </v-flex>


            <v-flex xs3 style="background-color:#FFFFFF;border-left: 1px solid #C1C8CC;word-break: break-word; " >
                 <v-layout column class=" px-4 py-4 " >
                     <p class="font-weight-bold cstm-clr-prtbsvw">
                        Fax Number
                     </p>
                      <p class="font-weight-regular fnt-sz-13">
                        {{instutionData.faxNumber|checkdata}}
                     </p>
                 </v-layout>
             </v-flex>
             
             
             <v-flex xs3 style="background-color:#FFFFFF;border-left: 1px solid #C1C8CC;word-break: break-word; ">
                 <v-layout column class=" px-4 py-4 " >
                     <p class="font-weight-bold cstm-clr-prtbsvw">
                        Parent Provider
                     </p>
                      <p class="font-weight-regular fnt-sz-13">
                        {{instutionData.parentProvider.entityName|checkdata}}
                     </p>
                     
                 </v-layout>
             </v-flex>
             <v-flex xs3 style="background-color:#FFFFFF;border-left: 1px solid #C1C8CC;word-break: break-word; ">
                <v-layout column class=" px-4 py-4 " >
                     <p class="font-weight-bold cstm-clr-prtbsvw">
                        Mapping Type
                     </p>
                      <p class="font-weight-regular fnt-sz-13">
                      {{instutionData.mappingType|checkdata}}
                      </p>
                       
                     
                </v-layout>
             </v-flex>
             <v-flex xs3 style="background-color:#FFFFFF;border-left: 1px solid #C1C8CC;word-break: break-word; ">
                 <v-layout column class=" px-4 py-4 " >
                     <p class="font-weight-bold cstm-clr-prtbsvw">
                        PAN
                     </p>
                      <p class="font-weight-regular fnt-sz-13">
                          {{instutionData.panNumber|checkdata}}
                     </p>
                     
                 </v-layout>
             </v-flex>
             
            
        </div>
        <div style="background-color:#FFFFFF;display:flex;border-bottom: 1px solid #C1C8CC;">
             <v-flex xs3 style="background-color:#FFFFFF;border-left: 1px solid #C1C8CC;word-break: break-word;">
                 <v-layout column class=" px-4 py-4 " >
                     <p class="font-weight-bold cstm-clr-prtbsvw">
                        Status
                     </p>
                      <p class="font-weight-regular fnt-sz-13">
                       {{instutionData.status|checkdata}}
                     </p>
                     
                 </v-layout>
             </v-flex>
             <v-flex xs3 style="background-color:#FFFFFF;border-left: 1px solid #C1C8CC;word-break: break-word;">
                 <v-layout column class=" px-4 py-4 " >
                     <p class="font-weight-bold cstm-clr-prtbsvw">
                       
                     </p>
                      <p class="font-weight-regular fnt-sz-13">
                      
                     </p>
                     
                 </v-layout>
             </v-flex>
             <v-flex xs3 style="background-color:#FFFFFF;border-left: 1px solid #C1C8CC;word-break: break-word;">
                 <v-layout column class=" px-4 py-4 " >
                     <p class="font-weight-bold cstm-clr-prtbsvw">
                       
                     </p>
                      <p class="font-weight-regular fnt-sz-13">
                      
                     </p>
                     
                 </v-layout>
             </v-flex>
             <v-flex xs3 style="background-color:#FFFFFF;border-left: 1px solid #C1C8CC;word-break: break-word;">
                 <v-layout column class=" px-4 py-4 " >
                     <p class="font-weight-bold cstm-clr-prtbsvw">
                       
                     </p>
                      <p class="font-weight-regular fnt-sz-13">
                      
                     </p>
                     
                 </v-layout>
             </v-flex>
             <v-flex xs3 style="background-color:#FFFFFF;border-left: 1px solid #C1C8CC;word-break: break-word;">
                 <v-layout column class=" px-4 py-4 " >
                     <p class="font-weight-bold cstm-clr-prtbsvw">
                       
                     </p>
                      <p class="font-weight-regular fnt-sz-13">
                      
                     </p>
                     
                 </v-layout>
             </v-flex>
             <v-flex xs3 style="background-color:#FFFFFF;border-left: 1px solid #C1C8CC;word-break: break-word;">
                 <v-layout column class=" px-4 py-4 " >
                     <p class="font-weight-bold cstm-clr-prtbsvw">
                       
                     </p>
                      <p class="font-weight-regular fnt-sz-13">
                      
                     </p>
                     
                 </v-layout>
             </v-flex>
             <v-flex xs3 style="background-color:#FFFFFF;border-left: 1px solid #C1C8CC;word-break: break-word;">
                 <v-layout column class=" px-4 py-4 " >
                     <p class="font-weight-bold cstm-clr-prtbsvw">
                       
                     </p>
                      <p class="font-weight-regular fnt-sz-13">
                      
                     </p>
                     
                 </v-layout>
             </v-flex>
        </div>

        <div style="background-color:#FFFFFF; display:flex;border-bottom: 1px solid #C1C8CC;">

            <v-flex xs3 style="background-color:#FFFFFF; " >
                <v-layout column class="px-4 py-4 ">
                        <p class="text-uppercase font-weight-bold title-clr-prsmry">
                           Address
                        </p>
                        <v-divider></v-divider>
                    </v-layout>
            </v-flex>
            <v-flex xs3 style="background-color:#FFFFFF;border-left: 1px solid #C1C8CC;word-break: break-word; " >
                 <v-layout column class=" px-4 py-4 " >
                     <p class="font-weight-bold cstm-clr-prtbsvw">
                        Address Line 1 *
                     </p>
                      <p class="font-weight-regular fnt-sz-13">
                        {{instutionData.addressLine1|checkdata}}
                     </p>
                 </v-layout>
             </v-flex>
             <v-flex xs3 style="background-color:#FFFFFF;border-left: 1px solid #C1C8CC;word-break: break-word; ">
                 <v-layout column class=" px-4 py-4 " >
                     <p class="font-weight-bold cstm-clr-prtbsvw">
                         Address Line 2 *
                     </p>
                      <p class="font-weight-regular fnt-sz-13">
                      {{instutionData.addressLine2|checkdata}}
                     </p>
                     
                 </v-layout>
             </v-flex>
             <v-flex xs3 style="background-color:#FFFFFF;border-left: 1px solid #C1C8CC;word-break: break-word; ">
                 <v-layout column class=" px-4 py-4 " >
                     <p class="font-weight-bold cstm-clr-prtbsvw">
                        Address Line 3
                     </p>
                      <p class="font-weight-regular fnt-sz-13">
                        {{instutionData.addressLine3|checkdata}}
                     </p>
                     
                 </v-layout>
             </v-flex>
             <v-flex xs3 style="background-color:#FFFFFF;border-left: 1px solid #C1C8CC;word-break: break-word; ">
                 <v-layout column class=" px-4 py-4 " >
                     <p class="font-weight-bold cstm-clr-prtbsvw">
                       Country *
                     </p>
                      <p class="font-weight-regular fnt-sz-13">
                      {{instutionData.country|checkdata}}
                     </p>
                     
                 </v-layout>
             </v-flex>
             <v-flex xs3 style="background-color:#FFFFFF;border-left: 1px solid #C1C8CC;word-break: break-word; ">
                 <v-layout column class=" px-4 py-4 " >
                     <p class="font-weight-bold cstm-clr-prtbsvw">
                        State *
                     </p>
                      <p class="font-weight-regular fnt-sz-13">
                       {{instutionData.state|checkdata}}
                     </p>
                     
                 </v-layout>
             </v-flex>
             <v-flex xs3 style="background-color:#FFFFFF;border-left: 1px solid #C1C8CC;word-break: break-word;">
                 <v-layout column class=" px-4 py-4 " >
                     <p class="font-weight-bold cstm-clr-prtbsvw">
                        City/Town/Village*
                     </p>
                      <p class="font-weight-regular fnt-sz-13">
                       {{instutionData.cityTownVillage|checkdata}}
                     </p>
                     
                 </v-layout>
             </v-flex>
        </div>
        <div style="background-color:#FFFFFF;; display:flex;border-bottom: 1px solid #C1C8CC;">
           <v-flex xs3 style="background-color:#FFFFFF; " >
                 <v-layout column class=" px-4 py-4 " >
                     <p class="font-weight-bold cstm-clr-prtbsvw">
                      Other City/Town/Village
                     </p>
                      <p class="font-weight-regular fnt-sz-13">
                        {{instutionData.cityOthers|checkdata}}
                     </p>
                 </v-layout>
             </v-flex>
           
           
           
            <v-flex xs3 style="background-color:#FFFFFF;border-left: 1px solid #C1C8CC;word-break: break-word; ">
                 <v-layout column class=" px-4 py-4 " >
                     <p class="font-weight-bold cstm-clr-prtbsvw">
                        Pin Code *
                     </p>
                      <p class="font-weight-regular fnt-sz-13">
                        {{instutionData.pinPostalCode|checkdata}}
                     </p>
                 </v-layout>
             </v-flex>
             <v-flex xs3 style="background-color:#FFFFFF;border-left: 1px solid #C1C8CC;word-break: break-word; ">
                 <v-layout column class=" px-4 py-4 " >
                     <p class="font-weight-bold cstm-clr-prtbsvw">
                        District
                     </p>
                      <p class="font-weight-regular fnt-sz-13">
                       {{instutionData.district|checkdata}}
                     </p>
                     
                 </v-layout>
             </v-flex>
             
             <v-flex xs3 style="background-color:#FFFFFF;border-left: 1px solid #C1C8CC;word-break: break-word; ">
                 <v-layout column class=" px-4 py-4 " >
                     <p class="font-weight-bold cstm-clr-prtbsvw">
                        
                     </p>
                      <p class="font-weight-regular fnt-sz-13">
                        
                     </p>
                     
                 </v-layout>
             </v-flex>
             <v-flex xs3 style="background-color:#FFFFFF;border-left: 1px solid #C1C8CC;word-break: break-word; ">
                 <v-layout column class=" px-4 py-4 " >
                     <p class="font-weight-bold cstm-clr-prtbsvw">
                        
                     </p>
                      <p class="font-weight-regular fnt-sz-13">
                        
                     </p>
                     
                 </v-layout>
             </v-flex>
             <v-flex xs3 style="background-color:#FFFFFF;border-left: 1px solid #C1C8CC;word-break: break-word; ">
                 <v-layout column class=" px-4 py-4 " >
                     <p class="font-weight-bold cstm-clr-prtbsvw">
                        
                     </p>
                      <p class="font-weight-regular fnt-sz-13">
                        
                     </p>
                     
                 </v-layout>
             </v-flex>
             
             <v-flex xs3 style="background-color:#FFFFFF;border-left: 1px solid #C1C8CC;word-break: break-word;">
                 <v-layout column class=" px-4 py-4 " >
                     <p class="font-weight-bold cstm-clr-prtbsvw">
                       
                     </p>
                      <p class="font-weight-regular fnt-sz-13">
                      
                     </p>
                     
                 </v-layout>
             </v-flex>
             
        </div>

        <div style="background-color:#FFFFFF;display:flex;border-bottom: 1px solid #C1C8CC;" >

            <v-flex xs3 style="background-color:#FFFFFF;" >
                <v-layout column class="px-4 py-4 " style="background-color:#FFFFFF;flex:1" >
                        <p class="text-uppercase font-weight-bold title-clr-prsmry">
                           Validation Details
                        </p>
                        <v-divider></v-divider>
                    </v-layout>
            </v-flex>
            <v-flex xs3 style="background-color:#FFFFFF; border-left: 1px solid #C1C8CC;word-break: break-word;" >
                 <v-layout column class=" px-4 py-4 " style="background-color:#FFFFFF;flex:1 ">
                     <p class="font-weight-bold cstm-clr-prtbsvw">
                        No. of Beds
                     </p>
                      <p class="font-weight-regular fnt-sz-13">
                        {{ instutionData.noOfBeds|checkdata}}
                     </p>
                 </v-layout>
             </v-flex>
             <v-flex xs3 style="background-color:#FFFFFF; border-left: 1px solid #C1C8CC;word-break: break-word;">
                 <v-layout column class=" px-4 py-4 "  style="background-color:#FFFFFF;flex:1 ">
                     <p class="font-weight-bold cstm-clr-prtbsvw">
                       Medical Practitioner Round The Clock
                     </p>
                      <p class="font-weight-regular fnt-sz-13">
                        <!-- {{instutionData.validationDetails.medicalPractionerRoundTheClock|checkdata}} -->
                        {{instutionData.medicalPractionerRoundTheClock|checkdata}}
                     </p>
                     
                 </v-layout>
             </v-flex>
             <v-flex xs3 style="background-color:#FFFFFF;border-left: 1px solid #C1C8CC;word-break: break-word; ">
                 <v-layout column class=" px-4 py-4 " style="background-color:#FFFFFF;flex:1" >
                     <p class="font-weight-bold cstm-clr-prtbsvw">
                        Daily Medical Records

                     </p>
                      <p class="font-weight-regular fnt-sz-13">
                        <!-- {{instutionData.validationDetails.dailyMedicalRecords|checkdata}} -->
                        {{instutionData.dailyMedicalRecords |checkdata}}


                     </p>
                     
                 </v-layout>
             </v-flex>
             <v-flex xs3 style="background-color:#FFFFFF; border-left: 1px solid #C1C8CC;word-break: break-word;">
                 <v-layout column class=" px-4 py-4 " style="background-color:#FFFFFF;flex:1" >
                     <p class="font-weight-bold cstm-clr-prtbsvw">
                        Has Operation Theatre
                     </p>
                      <p class="font-weight-regular fnt-sz-13">
                        <!-- {{instutionData.validationDetails.hasOperationTheatre|checkdata}} -->
                        {{instutionData.hasOperationTheatre|checkdata}}

                     </p>
                     
                 </v-layout>
             </v-flex>

             
             <v-flex xs3 style="background-color:#FFFFFF;border-left: 1px solid #C1C8CC;word-break: break-word; ">
                 <v-layout column class=" px-4 py-4 " style="background-color:#FFFFFF;flex:1">
                     <p class="font-weight-bold cstm-clr-prtbsvw">
                        Nursing Round The Clock
                     </p>
                      <p class="font-weight-regular fnt-sz-13">
                        <!-- {{instutionData.validationDetails.nursingRoundTheClock|checkdata}} -->
                        {{instutionData.nursingRoundTheClock |checkdata}}

                     </p>
                     
                 </v-layout>
             </v-flex>

             <v-flex xs3 style="background-color:#FFFFFF;border-left: 1px solid #C1C8CC;word-break: break-word;">
                 <v-layout column class=" px-4 py-4 " >
                     <p class="font-weight-bold cstm-clr-prtbsvw">
                        Registration Date effective from
                     </p>
                      <p class="font-weight-regular fnt-sz-13">
                       <!-- {{(instutionData.validationDetails.registrationDateEffectiveFrom)| checkDate | checkdata}} -->
                    {{instutionData.registrationDateEffectiveFrom |checkDate}}
                     
                     </p>
                     
                 </v-layout>
             </v-flex>

             

             
             
             
        </div>
        <div style="background-color:#FFFFFF;display:flex;border-bottom: 1px solid #C1C8CC;">
            
            <v-flex xs3 style="background-color:#FFFFFF; " >
                 <v-layout column class=" px-4 py-4 " >
                     <p class="font-weight-bold cstm-clr-prtbsvw">
                        Registration effective date to
                     </p>
                      <p class="font-weight-regular fnt-sz-13">
                         <!-- {{(instutionData.validationDetails.registrationDateEffectiveTo)| checkDate | checkdata}} -->
                    {{instutionData.registrationDateEffectiveTo |checkDate}}
                    
                     </p>
                 </v-layout>
             </v-flex>
             <v-flex xs3 style="background-color:#FFFFFF; border-left: 1px solid #C1C8CC;word-break: break-word;">
                 <v-layout column class=" px-4 py-4 " >
                     <p class="font-weight-bold cstm-clr-prtbsvw">
                        Registration date
                     </p>
                      <p class="font-weight-regular fnt-sz-13">
                        {{instutionData.registrationDate| checkDate}}
                        <!-- {{instutionData.hasOwnProperty('validationDetails')?instutionData.validationDetails.hasOwnProperty('registrationDate')? instutionData.validationDetails.registrationDate : '':''|checkdata}} -->

                     </p>
                     
                 </v-layout>
             </v-flex>
             <v-flex xs3 style="background-color:#FFFFFF;border-left: 1px solid #C1C8CC;word-break: break-word; ">
                 <v-layout column class=" px-4 py-4 " >
                     <p class="font-weight-bold cstm-clr-prtbsvw">
                        Reviewer remarks
                     </p>
                      <p class="font-weight-regular fnt-sz-13">
                      {{instutionData.reviewerRemarks| checkdata}}
                     </p>
                     
                 </v-layout>
             </v-flex>
             
             <v-flex xs3 style="background-color:#FFFFFF; border-left: 1px solid #C1C8CC;word-break: break-word;">
                 <v-layout column class=" px-4 py-4 " >
                     <p class="font-weight-bold cstm-clr-prtbsvw">
                        
                     </p>
                      <p class="font-weight-regular fnt-sz-13">
                        
                     </p>
                     
                 </v-layout>
             </v-flex>
             <v-flex xs3 style="background-color:#FFFFFF;border-left: 1px solid #C1C8CC;word-break: break-word; ">
                 <v-layout column class=" px-4 py-4 " >
                     <p class="font-weight-bold cstm-clr-prtbsvw">
                        
                     </p>
                      <p class="font-weight-regular fnt-sz-13">
                        
                     </p>
                     
                 </v-layout>
             </v-flex>
             
             <v-flex xs3 style="background-color:#FFFFFF;border-left: 1px solid #C1C8CC;word-break: break-word;">
                 <v-layout column class=" px-4 py-4 " >
                     <p class="font-weight-bold cstm-clr-prtbsvw">
                       
                     </p>
                      <p class="font-weight-regular fnt-sz-13">
                      
                     </p>
                     
                 </v-layout>
             </v-flex>
             <v-flex xs3 style="background-color:#FFFFFF;border-left: 1px solid #C1C8CC;word-break: break-word;">
                 <v-layout column class=" px-4 py-4 " >
                     <p class="font-weight-bold cstm-clr-prtbsvw">
                       
                     </p>
                      <p class="font-weight-regular fnt-sz-13">
                      
                     </p>
                     
                 </v-layout>
             </v-flex>
             
        </div>

        <div class="pa-3" style="background-color:#FFFFFF;display:flex;align-items:center;border-bottom: 1px solid #C1C8CC;" v-if="docspool.length != 0">
            <p  v-if="docspool.length != 0" class="text-uppercase font-weight-bold title-clr-prsmry mt-1" >List of treating doctors</p>
                          
            <div v-for="(listdocs, index) in docspool" :key="index" >
                <v-checkbox
                    readonly
                    v-model="ex4"
                    :value="listdocs._id"
                    color="#23B1A9"
                  
                    dense
                    hide-details
                    class="ma-0 pa-0 ml-5 mr-3"
                > 
                    <span slot="label" class="font-weight-bold" 
                    style="text-transform:capitalize ;font-size: 13px;color:#152F38">
                    {{checkdataone(listdocs.firstName)+' '+checkdataone(listdocs.lastName)}}</span>
            </v-checkbox>
            </div>
          
             <!-- <v-checkbox
              v-model="ex4"
              
              color="#23B1A9"
              value="doctor2"
              hide-details
              dense
              class="ma-0 pa-0 mr-3"
            > <span slot="label" class="font-weight-bold" style="font-size: 13px;color:#152F38">Doctor 2</span></v-checkbox>
             <v-checkbox
              v-model="ex4"
              
              color="#23B1A9"
              value="doctor3"
              hide-details
              dense
              class="ma-0 pa-0 mr-3"
            >
            <span slot="label" class="font-weight-bold" style="font-size: 13px;color:#152F38">Doctor 3</span>
            </v-checkbox> -->
        </div>
    </div>

        <!-- isNonserviceProvider True -->
    <div style="background-color:#FFFFFF;border: 1px solid #C1C8CC;" v-if="instutionData.isNonServiceProvider === true">
            <div style="background-color:#FFFFFF; display:flex;border-bottom: 1px solid #C1C8CC;">
                <v-flex xs3 style="background-color:#FFFFFF; " >
                    <v-layout column class="px-4 py-4 ">
                            <p class="text-uppercase font-weight-bold title-clr-prsmry">
                                Provider Details
                            </p>
                            <v-divider></v-divider>
                        </v-layout>
                </v-flex>
                <v-flex xs3 style="background-color:#FFFFFF; border-left: 1px solid #C1C8CC;word-break: break-word;" >
                    <v-layout column class=" px-4 py-4 " >
                        <p class="font-weight-bold cstm-clr-prtbsvw">
                            Registered Entity*
                        </p>
                        <p class="font-weight-regular fnt-sz-13">
                            {{instutionData.registeredEntity|checkdata}}
                        </p>
                    </v-layout>
                </v-flex>     
                    <v-flex xs3 style="background-color:#FFFFFF;border-left: 1px solid #C1C8CC;word-break: break-word; ">
                        <v-layout column class=" px-4 py-4 " >
                            <p class="font-weight-bold cstm-clr-prtbsvw">
                                is Non-Service Provider
                            </p>
                            <p class="font-weight-regular fnt-sz-13">
                                {{instutionData.isNonServiceProvider === false ? "No" : "Yes "|checkdata}}
                            </p>
                            
                        </v-layout>
                    </v-flex>
                
                    <v-flex xs3 style="background-color:#FFFFFF; border-left: 1px solid #C1C8CC;word-break: break-word;">
                        <v-layout column class=" px-4 py-4 " >
                            <p class="font-weight-bold cstm-clr-prtbsvw">
                                PRN
                            </p>
                            <p class="font-weight-regular fnt-sz-13">
                            {{instutionData.prn|checkdata}}
                            </p>
                            
                        </v-layout>
                    </v-flex>
                    <v-flex xs3 style="background-color:#FFFFFF;border-left: 1px solid #C1C8CC;word-break: break-word; ">
                        <v-layout column class=" px-4 py-4 " >
                            <p class="font-weight-bold cstm-clr-prtbsvw">
                                Entity Name*
                            </p>
                            <p class="font-weight-regular fnt-sz-13">
                                {{instutionData.entityName|checkdata}}
                            </p>
                            
                        </v-layout>
                    </v-flex>
                    <v-flex xs3 style="background-color:#FFFFFF;border-left: 1px solid #C1C8CC;word-break: break-word; ">
                        <v-layout column class=" px-4 py-4 " >
                            <p class="font-weight-bold cstm-clr-prtbsvw">
                                Alias Name
                            </p>
                            <p class="font-weight-regular fnt-sz-13">
                                {{instutionData.aliasName|checkdata}}
                            </p>
                            
                        </v-layout> 
                    </v-flex>  

                    <v-flex xs3 style="background-color:#FFFFFF;border-left: 1px solid #C1C8CC;word-break: break-word;">
                        <v-layout column class=" px-4 py-4 " >
                            <p class="font-weight-bold cstm-clr-prtbsvw">
                                Status
                            </p>
                            <p class="font-weight-regular fnt-sz-13">
                            {{instutionData.status|checkdata}}
                            
                            </p>
                            
                        </v-layout>
                    </v-flex>
                    
                </div>
            <div style="background-color:#FFFFFF; display:flex;border-bottom: 1px solid #C1C8CC;">

                
                
                 <v-flex xs3 style="background-color:#FFFFFF;border-left: 1px solid #C1C8CC;word-break: break-word;">
                    <v-layout column class=" px-4 py-4 " >
                        <p class="font-weight-bold cstm-clr-prtbsvw">
                           Email
                        </p>
                        <p class="font-weight-regular fnt-sz-13">
                            {{instutionData.email|checkdata}}
                        </p>
                        
                    </v-layout>
                </v-flex>


                <v-flex xs3 style="background-color:#FFFFFF;border-left: 1px solid #C1C8CC;word-break: break-word; " >
                    <v-layout column class=" px-4 py-4 " >
                        <p class="font-weight-bold cstm-clr-prtbsvw">
                             Telephone Number
                        </p>
                        <p class="font-weight-regular fnt-sz-13">
                            {{instutionData.telephoneNumber|checkdata}}
                        </p>
                    </v-layout>
                </v-flex>

                <v-flex xs3 style="background-color:#FFFFFF;border-left: 1px solid #C1C8CC;word-break: break-word; ">
                    <v-layout column class=" px-4 py-4 " >
                        <p class="font-weight-bold cstm-clr-prtbsvw">
                           Fax Number
                        </p>
                        <p class="font-weight-regular fnt-sz-13">
                            {{instutionData.faxNumber|checkdata}}
                        </p>
                        
                    </v-layout>
                </v-flex>
                <v-flex xs3 style="background-color:#FFFFFF;border-left: 1px solid #C1C8CC;word-break: break-word; ">
                    <v-layout column class=" px-4 py-4 " >
                        <p class="font-weight-bold cstm-clr-prtbsvw">
                       
                        </p>
                        <p class="font-weight-regular fnt-sz-13">
                       
                        </p>
                        
                    </v-layout>
                </v-flex>
                <v-flex xs3 style="background-color:#FFFFFF;border-left: 1px solid #C1C8CC;word-break: break-word; ">
                    <v-layout column class=" px-4 py-4 " >
                        <p class="font-weight-bold cstm-clr-prtbsvw">
                           
                        </p>
                        <p class="font-weight-regular fnt-sz-13">
                        
                        </p>
                        
                    </v-layout>
                </v-flex>
                <v-flex xs3 style="background-color:#FFFFFF;border-left: 1px solid #C1C8CC;word-break: break-word;">
                    <v-layout column class=" px-4 py-4 " >
                        <p class="font-weight-bold cstm-clr-prtbsvw">
                            
                        </p>
                        <p class="font-weight-regular fnt-sz-13">
                        
                        </p>
                        
                    </v-layout>
                </v-flex>
                 <v-flex xs3 style="background-color:#FFFFFF;border-left: 1px solid #C1C8CC;word-break: break-word;">
                    <v-layout column class=" px-4 py-4 " >
                        <p class="font-weight-bold cstm-clr-prtbsvw">
                            
                        </p>
                        <p class="font-weight-regular fnt-sz-13">
                        
                        </p>
                        
                    </v-layout>
                </v-flex>
            </div>
            <div style="background-color:#FFFFFF; display:flex;border-bottom: 1px solid #C1C8CC;">

                <v-flex xs3 style="background-color:#FFFFFF; " >
                    <v-layout column class="px-4 py-4 ">
                            <p class="text-uppercase font-weight-bold title-clr-prsmry">
                            Address
                            </p>
                            <v-divider></v-divider>
                        </v-layout>
                </v-flex>
                <v-flex xs3 style="background-color:#FFFFFF;border-left: 1px solid #C1C8CC;word-break: break-word; " >
                    <v-layout column class=" px-4 py-4 " >
                        <p class="font-weight-bold cstm-clr-prtbsvw">
                            Address Line 1 *
                        </p>
                        <p class="font-weight-regular fnt-sz-13">
                            {{instutionData.addressLine1|checkdata}}
                        </p>
                    </v-layout>
                </v-flex>
                <v-flex xs3 style="background-color:#FFFFFF;border-left: 1px solid #C1C8CC;word-break: break-word; ">
                    <v-layout column class=" px-4 py-4 " >
                        <p class="font-weight-bold cstm-clr-prtbsvw">
                            Address Line 2*
                        </p>
                        <p class="font-weight-regular fnt-sz-13">
                        {{instutionData.addressLine2|checkdata}}
                        </p>
                        
                    </v-layout>
                </v-flex>
                <v-flex xs3 style="background-color:#FFFFFF;border-left: 1px solid #C1C8CC;word-break: break-word; ">
                    <v-layout column class=" px-4 py-4 " >
                        <p class="font-weight-bold cstm-clr-prtbsvw">
                            Address Line 3
                        </p>
                        <p class="font-weight-regular fnt-sz-13">
                            {{instutionData.addressLine3|checkdata}}
                        </p>
                        
                    </v-layout>
                </v-flex>
                <v-flex xs3 style="background-color:#FFFFFF;border-left: 1px solid #C1C8CC;word-break: break-word; ">
                    <v-layout column class=" px-4 py-4 " >
                        <p class="font-weight-bold cstm-clr-prtbsvw">
                        Country*
                        </p>
                        <p class="font-weight-regular fnt-sz-13">
                        {{instutionData.country|checkdata}}
                        </p>
                        
                    </v-layout>
                </v-flex>
                <v-flex xs3 style="background-color:#FFFFFF;border-left: 1px solid #C1C8CC;word-break: break-word; ">
                    <v-layout column class=" px-4 py-4 " >
                        <p class="font-weight-bold cstm-clr-prtbsvw">
                            State *
                        </p>
                        <p class="font-weight-regular fnt-sz-13">
                        {{instutionData.state|checkdata}}
                        </p>
                        
                    </v-layout>
                </v-flex>
                <v-flex xs3 style="background-color:#FFFFFF;border-left: 1px solid #C1C8CC;word-break: break-word;">
                    <v-layout column class=" px-4 py-4 " >
                        <p class="font-weight-bold cstm-clr-prtbsvw">
                            City/Town/Village*
                        </p>
                        <p class="font-weight-regular fnt-sz-13">
                        {{instutionData.cityTownVillage|checkdata}}
                        </p>
                        
                    </v-layout>
                </v-flex>
            </div>
            <div style="background-color:#FFFFFF;; display:flex;border-bottom: 1px solid #C1C8CC;">
                <v-flex xs3 style="background-color:#FFFFFF; " >
                    <v-layout column class=" px-4 py-4 " >
                        <p class="font-weight-bold cstm-clr-prtbsvw">
                            Pin Code*
                        </p>
                        <p class="font-weight-regular fnt-sz-13">
                            {{instutionData.pinPostalCode|checkdata}}
                        </p>
                    </v-layout>
                </v-flex>
                <v-flex xs3 style="background-color:#FFFFFF;border-left: 1px solid #C1C8CC;word-break: break-word; ">
                    <v-layout column class=" px-4 py-4 " >
                        <p class="font-weight-bold cstm-clr-prtbsvw">
                            District*
                        </p>
                        <p class="font-weight-regular fnt-sz-13">
                        {{instutionData.district|checkdata}}
                        </p>
                        
                    </v-layout>
                </v-flex>
                <v-flex xs3 style="background-color:#FFFFFF;border-left: 1px solid #C1C8CC;word-break: break-word; ">
                    <v-layout column class=" px-4 py-4 " >
                        <p class="font-weight-bold cstm-clr-prtbsvw">
                            
                        </p>
                        <p class="font-weight-regular fnt-sz-13">
                        
                        </p>
                        
                    </v-layout>
                </v-flex>
                <v-flex xs3 style="background-color:#FFFFFF;border-left: 1px solid #C1C8CC;word-break: break-word; ">
                    <v-layout column class=" px-4 py-4 " >
                        <p class="font-weight-bold cstm-clr-prtbsvw">
                            
                        </p>
                        <p class="font-weight-regular fnt-sz-13">
                            
                        </p>
                        
                    </v-layout>
                </v-flex>
                <v-flex xs3 style="background-color:#FFFFFF;border-left: 1px solid #C1C8CC;word-break: break-word; ">
                    <v-layout column class=" px-4 py-4 " >
                        <p class="font-weight-bold cstm-clr-prtbsvw">
                            
                        </p>
                        <p class="font-weight-regular fnt-sz-13">
                            
                        </p>
                        
                    </v-layout>
                </v-flex>
                <v-flex xs3 style="background-color:#FFFFFF;border-left: 1px solid #C1C8CC;word-break: break-word; ">
                    <v-layout column class=" px-4 py-4 " >
                        <p class="font-weight-bold cstm-clr-prtbsvw">
                            
                        </p>
                        <p class="font-weight-regular fnt-sz-13">
                            
                        </p>
                        
                    </v-layout>
                </v-flex>
                
                <v-flex xs3 style="background-color:#FFFFFF;border-left: 1px solid #C1C8CC;word-break: break-word;">
                    <v-layout column class=" px-4 py-4 " >
                        <p class="font-weight-bold cstm-clr-prtbsvw">
                        
                        </p>
                        <p class="font-weight-regular fnt-sz-13">
                        
                        </p>
                        
                    </v-layout>
                </v-flex>
                
            </div>      
    </div>

        <!-- <v-card tile class="ma-3 mt-4" color="#F7F7F7">
            <div style="display:flex; justify-content:flex-end" class="pa-5">
                <v-btn tile dark color="#23B1A9" class="text-capitalize px-3" small @click="close_drLstFrm()">
                    <v-icon color="" small >edit</v-icon>
                    <v-divider vertical class="ml-2 mr-2"></v-divider>Edit
                </v-btn>
            </div>
        </v-card> -->
        
    </v-app>
</template>

<script>
import moment from 'moment';
export default {

    components:{
        
    },

    created() {

        // this.$store.commit("SET_PAGE_TITLE", "Provider Summary");
        console.log(this.$store.getters.fetchPrvdrData,"fetchPrvdrData")
        console.log("%c this is for flag","background-color:#eeee20;color:black", this.$store.getters.fetchPrvdrData.flagDetails);
        let flagData = this.$store.getters.fetchPrvdrData.flagDetails 
        let gotFlag = this.getKeyByValue(flagData) 
        console.log("%c got the flag selected =>","background-color:#dddd45;color:black",flagData);
        console.log("%c this is for flag","background-color:#dddd20;color:black",gotFlag);
       if(gotFlag.length<=1){ 
            this.providerFlagIs = gotFlag[0];
            console.log("%c this is for flagArray","background-color:#dddd20;color:black",gotFlag[0]);
       }else{
           this.providerFlagIs = gotFlag;
       }
        this.instutionData=this.$store.getters.fetchPrvdrData
        this.getdocspool()
        // let valData = this.instutionData.validationDetails
        console.log("this is intit ------------>", this.instutionData);

        


    },
    filters:{

      truncate: function (text, length, suffix) {
            if (text.length > length) {
                return text.substring(0, length) + suffix;
            } else {
                return text;
            }
        },
        checkdata(data){// eslint-disable-line no-mixed-spaces-and-tabs
            // console.log('Validity Data', data);
            if (data == "" || data == "undefined" || data == null || data == '' || data == 'Invalid Date') {
                return "-"
            } else {
                return data;
            }
        },

        checkDate(data){
            if (data == "" || data == "undefined" || data == null || data == '' || data == 'Invalid Date') {
                return "-"
            } else {
                return moment(data, 'YYYY-MM-DD').format("DD-MM-YYYY");
            }
        }

    },

    computed: {

       

    },

    data() {
        return {
            ex4: [],
            instutionData: null,
            docspool:[],
            providerFlagIs:''
        };



    },

    methods: {

        getKeyByValue(flagData) {
            return Object.keys(flagData).filter(key => flagData[key] === true);
            },

        checkdataone(str){
            if(str == null || str == undefined || str == ''){
                return '-'
            }else{
                return str
            }
        },

         getdocspool(){
            // dp
                // console.log("get docs ident is ", ident);
              this.GET(2,'provider/doctors-list', res=>{
                    console.log("GETTING POOL OF DOCSTORS  => ", res.data);
                  try{
                       
                       var alldocslist = res.data.data;
                       this.$store.getters.fetchPrvdrData.doctorsList.map(e=>{
                           alldocslist.map(all=>{
                               if(all._id == e){
                                    if(!all.hasOwnProperty('firstName')){
                                        all.firstName = ""
                                    }
                                    if(!all.hasOwnProperty('lastName')){
                                        all.lastName = ""
                                    }
                                    this.ex4.push(all._id)
                                    this.docspool.push(all)
                               }
                           })
                       })
                       console.log(" getdocs pool list ", this.docspool);

                        // gd
                   } catch (error) {
                       console.log("error occurred during mapping doctors list",error)
                   }
                                      
             })

        },

    },

    watch: {
    
    },
    
    
}

</script>
<style >
.cstm-clr-prtbsvw{
    color:#10242B !important;
    margin-bottom: 5px !important;
    font-size: 13px !important;
}
.title-clr-prsmry{
    color:#E46A25 !important;
    margin-bottom: 5px !important;
    font-size: 13px !important;
}
.fnt-sz-13{
    font-size: 13px !important;
}
</style>
