<template>

  <v-app>

    <div style="background-color: #FFFFFF;" column class="">
        <v-form ref="form" v-model="valid">

          <v-layout color="#FFFFFF" class="mx-2 mt-3" style="background-color: #FFFFFF;">
                <v-flex xs3>
                    <v-layout column class=" mx-2 cust_pr">
                        <p class="font-weight-bold cstm-clr-pr">
                            Intimation ID
                        </p>
                        <v-text-field
                            label="Select.."
                            maxlength=50
                            v-model="intimationID"
                            solo
                            dense
                            :error-messages="intimationIDErrors"
                            @input="$v.intimationID.$touch()"
                            @blur="$v.intimationID.$touch()"
                            class="rounded-0"
                            type="text"
                            required> 
                        </v-text-field>
                    </v-layout>
                </v-flex>
                <v-flex xs3>
                    <v-layout column class=" mx-2 cust_pr">
                        <p class="font-weight-bold cstm-clr-pr">
                            Claim ID
                        </p>
                        <v-text-field
                            label="Select.."
                            maxlength=50
                            v-model="claimID"
                            solo
                            dense
                            :error-messages="claimIDErrors"
                            @input="$v.claimID.$touch()"
                            @blur="$v.claimID.$touch()"
                            class="rounded-0"
                            type="text"
                            required> 
                        </v-text-field>
                    </v-layout>
                </v-flex>
                <v-flex xs3>
                    <v-layout column class=" mx-2 cust_pr">
                        <p class="font-weight-bold cstm-clr-pr">
                            Member ID
                        </p>
                        <v-text-field
                            label="Select.."
                            maxlength=50
                            v-model="memberID"
                            solo
                            dense
                            :error-messages="memberIDErrors"
                            @input="$v.memberID.$touch()"
                            @blur="$v.memberID.$touch()"
                            class="rounded-0"
                            type="text"
                            required> 
                        </v-text-field>
                    </v-layout>
                </v-flex>

                <v-flex xs3 >
                    <v-layout column class=" mx-2 cust_pr">
                        <p class="font-weight-bold cstm-clr-pr">
                            Policy Number
                        </p>
                        <v-text-field
                            autocomplete="off"
                            maxlength=30
                            solo
                            label="Enter..."
                            v-model="policyNumber"
                            class="rounded-0"
                            :error-messages="policyNumberError"
                            @input="$v.policyNumber.$touch()"
                            @blur="$v.policyNumber.$touch()"
                            >
                        </v-text-field>
                          <!-- :rules="[required(0,''), rules.spclChar(1,'frstnmeInd')]" -->
                    </v-layout>
                </v-flex>
                <v-flex xs3 >
                    <v-layout column class="mx-2 mb-5" justify-center >
                            
                        <span class="font-weight-bold cstm-clr-pr" style="color:#FFFFFF" > </span>
                        <div style="box-shadow: 2px 2px 4px #ccc;border: 1px solid #fff; padding:7px" class="mt-5">
                            <v-checkbox class="ma-0 pa-0" v-model="secondOpinion"  hide-details >
                                <span slot="label" class="lbl-bgclrTDS font-weight-bold" style="font-size: 13px;">Second Opinion</span>
                            </v-checkbox>
                        </div>
                    </v-layout>
                </v-flex>

          </v-layout>        

          <v-layout color="#FFFFFF" class="mx-2" style="background-color: #FFFFFF;">
                <v-flex xs3>
                    <v-layout column class=" mx-2 cust_pr">
                        <p class="font-weight-bold cstm-clr-pr">
                            First Name Filter
                        </p>
                        <v-select
                            :items="selectFirstNameFilter"
                            item-text="text"
                            item-value="value"
                            v-model="firstNameFilter"
                            label="Contains"
                            dense
                            solo       
                            class="rounded-0"
                            >
                        </v-select>
                    </v-layout>
                </v-flex>
                <v-flex xs3>
                    <v-layout column class=" mx-2 cust_pr">
                        <p class="font-weight-bold cstm-clr-pr">
                            First Name
                        </p>
                        <v-text-field
                            autocomplete="off"
                            maxlength=20
                            solo
                            label="Enter..."
                            v-model="firstName"
                            class="rounded-0"
                            :error-messages="firstNameError"
                            @input="$v.firstName.$touch()"
                            @blur="$v.firstName.$touch()"
                            >
                        </v-text-field>
                    </v-layout>
                </v-flex>
                
                <v-flex xs3>
                    <v-layout column class=" mx-2 cust_pr">
                        <p class="font-weight-bold cstm-clr-pr">
                            Last Name Filter
                        </p>
                        <v-select
                            :items="selectLastNameFilter"
                            item-text="text"
                            item-value="value"
                            v-model="lastNameFilter"
                            label="Contains"
                            dense
                            solo       
                            class="rounded-0"
                            >
                        </v-select>   
                    </v-layout>
                </v-flex>

                 <v-flex xs3>
                    <v-layout column class=" mx-2 cust_pr">
                        <p class="font-weight-bold cstm-clr-pr">
                            Last Name
                        </p>
                        <v-text-field
                            autocomplete="off"
                            maxlength=20
                            solo
                            label="Enter..."
                            v-model="lastName"
                            class="rounded-0"
                            :error-messages="lastNameError"
                            @input="$v.lastName.$touch()"
                            @blur="$v.lastName.$touch()"
                            >
                        </v-text-field>
                          
                    </v-layout>
                </v-flex>
                <v-flex xs3>
                    <v-layout column class=" mx-2 cust_pr">
                        <p class="font-weight-bold cstm-clr-pr">
                            Illness Filter
                        </p>
                        <v-select
                            :items="illnessFilterItems"
                            item-text="text"
                            item-value="value"
                            v-model="illnessFilter"
                            label="Contains"
                            dense
                            solo       
                            class="rounded-0"
                            >
                        </v-select> 
                    </v-layout>
                </v-flex>

          </v-layout>  

          <v-layout color="#FFFFFF" class="mx-2" style="background-color: #FFFFFF;">
                
                <v-flex xs3>
                    <v-layout column class=" mx-2 cust_pr">
                        <p class="font-weight-bold cstm-clr-pr">
                            Illness
                        </p>
                        <v-text-field
                            autocomplete="off"
                            maxlength=200
                            solo
                            label="Enter..."
                            v-model="Illness"
                            class="rounded-0"
                            :error-messages="illnessErrors"
                            @input="$v.Illness.$touch()"
                            @blur="$v.Illness.$touch()"
                            >
                        </v-text-field>
                          
                    </v-layout>
                </v-flex>
                <v-flex xs3>
                    <v-layout column class=" mx-2 cust_pr">
                        <p class="font-weight-bold cstm-clr-pr">
                            Date of Admission(Loss) Filter
                        </p>
                        <v-select
                            :items="admissionDateFilterItems"
                            item-text="text"
                            item-value="value"
                            v-model="admissionDateFilter"
                            label="Equals"
                            dense
                            solo       
                            class="rounded-0"
                            >
                        </v-select> 
                    </v-layout>
                </v-flex>
                <v-flex xs3>
                    <v-layout column class=" mx-2  cust_pr_quick cust-rmvpd-pr-quick">
                        <p class="font-weight-bold cstm-clr-pr-quick">
                            Date of Admission(Loss)
                        </p>
                        
                        <v-menu
                            ref="calender"
                            :close-on-content-click="false"
                            v-model="regCalenderDate"
                            :nudge-right="40"
                            lazy
                            transition="scale-transition"
                            full-width
                            max-width="290px"
                            min-width="290px" 
                            class="rounded-0"   
                            >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    autocomplete="off"
                                    maxlength=10
                                    v-bind="attrs"
                                    v-on="on"
                                    label="dd/mm/yyyy"
                                    :value="admissionDateTo"
                                    solo
                                    class="rounded-0"
                                    required>
                            <div  slot="prepend-inner" style="background-color:#F7F7F7;  padding: 8px !important"><span class="material-icons">insert_invitation</span></div>
                                
                                </v-text-field>
                            </template>                        
                                    <v-date-picker 
                                        v-model="admissionDate"
                                        no-title
                                        @input="regCalenderDate = false"
                                    ></v-date-picker>
                        </v-menu>

                    </v-layout>
                </v-flex>
                <v-flex xs3>
                    <v-layout column class=" mx-2 cust_pr">
                        <p class="font-weight-bold cstm-clr-pr">
                            Intimation Date Filter
                        </p>
                        <v-select
                            :items="intimationDateFilterItems"
                            item-text="text"
                            item-value="value"
                            v-model="intimationDateFilter"
                            label="Equals"
                            dense
                            solo       
                            class="rounded-0"
                            >
                        </v-select> 
                    </v-layout>
                </v-flex>
                <v-flex xs3>
                    <v-layout column class=" mx-2  cust_pr_quick cust-rmvpd-pr-quick">
                        <p class="font-weight-bold cstm-clr-pr-quick">
                            Intimation Date
                        </p>
                        
                        <v-menu
                            ref="calender"
                            :close-on-content-click="false"
                            v-model="regCalenderDate3"
                            :nudge-right="40"
                            lazy
                            transition="scale-transition"
                            full-width
                            max-width="290px"
                            min-width="290px" 
                            class="rounded-0"   
                            >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    autocomplete="off"
                                    maxlength=10
                                    v-bind="attrs"
                                    v-on="on"
                                    id="fromDate"
                                    label="dd/mm/yyyy"
                                    :value="intimationDateTo"
                                    solo
                                    dense
                                    class="rounded-0"
                                    required>
                            <div  slot="prepend-inner" style="background-color:#F7F7F7;  padding: 8px !important"><span class="material-icons">insert_invitation</span></div>
                                
                                </v-text-field>
                            </template>                        
                                    <v-date-picker 
                                        v-model="intimationDate"
                                        no-title
                                        @input="regCalenderDate3 = false"
                                    ></v-date-picker>
                        </v-menu>

                    </v-layout>
                </v-flex>

          </v-layout>           

          <v-layout color="#FFFFFF" class="mx-2" style="background-color: #FFFFFF;">
                <v-flex xs3>
                    <v-layout column class="cust_pr mx-2 ">
                        <p class="font-weight-bold cstm-clr-pr">
                            Intimation Status
                        </p>
                        <v-select
                            :items="intimationStatusItems"
                            label="Select.."
                            v-model="intimationStatus"
                            solo
                            dense
                            class="rounded-0"
                            type="text"
                            >
                        </v-select>
                    </v-layout>
                </v-flex>
                <v-flex xs3>
                    <v-layout column class=" mx-2 cust_pr">
                        <p class="font-weight-bold cstm-clr-pr">
                            Mobile Number
                        </p>
                        <v-text-field
                            autocomplete="off"
                            label="Enter..."
                            v-model="mobileNumber"
                            solo
                            maxlength=10
                            class="rounded-0"
                            :error-messages="mobileNumberError"
                            @input="$v.mobileNumber.$touch()"
                            @blur="$v.mobileNumber.$touch()"
                            >
                            <div slot="prepend-inner" style="position:relative;right:12px;background-color:#F7F7F7;padding: 9px !important"><v-icon dense>call</v-icon></div>
                        </v-text-field>
                    </v-layout>
                </v-flex> 

          </v-layout>  

        <v-card color="#F7F7F7" class="mx-2 pa-3 overflow-y-auto">
            <v-row class="pt-2 pb-2 mt-1 mr-2 float-right">
              <v-btn @click="clearFields" tile dark color="#E56C24" class="float-right text-capitalize px-3 mr-5" small>
                <v-icon color="" small >cancel</v-icon>
                <v-divider vertical class="ml-2 mr-2"></v-divider>Clear
              </v-btn>
              <v-btn @click="$router.go(-1)" tile dark color="#152F38" class="float-right text-capitalize px-3 mr-5" small>
                <v-icon color="" small >mdi-arrow-left</v-icon>
                <v-divider vertical class="ml-2 mr-2"></v-divider>Return
              </v-btn>  
              <v-btn @click="searchFields" tile dark color="#23B1A9" class="float-right text-capitalize px-3 mr-5" small>
                <v-icon color="" small >search</v-icon>
                <v-divider vertical class="ml-2 mr-2"></v-divider>Search
              </v-btn>
              <v-btn @click="closeIntimationStatus" tile dark color="#E56C24" class="float-right text-capitalize px-3 mr-5" small>
                <v-icon color="" small >cancel</v-icon>
                <v-divider vertical class="ml-2 mr-2"></v-divider>Close Intimation
              </v-btn>  
              <v-btn @click="$router.push({name: 'NewClaimIntimation', params: {claimIntiVal: false}})" tile dark color="#E56C24" class="float-right text-capitalize px-3" small>
                <v-icon color="" small >mdi-plus-circle</v-icon>
                <v-divider vertical class="ml-2 mr-2"></v-divider>Create New Intimation
              </v-btn>
            </v-row>
        </v-card>

        </v-form>
        
        <div class="mt-6 px-4 py-3" style="background-color:#F7F7F7">
                    <p class="font-weight-bold cstm-clr-pln-Ot pa-0 ma-0">Intimations</p>
                </div>
                <v-divider></v-divider>
     <v-card tile>
         <div>
                <v-data-table
                     v-model="selectTableData"
                    :headers="headers"
                    :items="statusData"
                    :page.sync="page"
                    :items-per-page="statusItemsPerPage"
                    hide-default-footer
                    dense
                    show-select
                    @page-count="pageCount = $event"
                >
                <!-- <template #header="{ props: { headers } }">
            <thead  class="v-data-table-header makepointer">
            <tr>
                
                <th
                v-for="header in headers"
                :key="header.value"
                class="hdr-txt-clr-WrkItemQue hdr-bg-clr-WrkItemQue"
                    color="red"
                scope="col"
                    @click="headercontrol(header)"
                >
                {{ header.text  }} 
                
                    <v-icon style="background-color:#ccc; border-radius:55px;" v-if="header.stype != ' '" >
                        {{ header.stype == 'asc' ? 'arrow_upward':'arrow_downward' }}  </v-icon>
                
                </th>
            
                
            </tr>
            </thead>
                </template> -->
                <template v-slot:item.intimationId="{ item }">
                    <!-- to="/CreateEditProduct" -->
                    <div @click="intimationIdClick(item)" class="text-decoration-underline" style="color:#1976d2;cursor:pointer">{{ item.intimationId }}</div>
                </template> 

                </v-data-table>
       </div>
     </v-card>

     <v-card class="pb-3 mb-6" style="background-color:#F7F7F7">
        
         <v-layout row justify-end align-center style="background-color:#f7f7f7" class="pt-2 mr-2">
                <span class="mr-1 font-weight-bold body-1" style="color:#3C3D3D; font-size:12px !important">
                <span class="" style="color:#E46A25;font-size:12px;">Showing {{fval}}-{{sval}} </span>out of {{totalLen}} records</span>
                <v-divider  class="ma-2" vertical ></v-divider>
                <v-pagination 
                    class="cust-bnkAcc-Permission"
                    v-model="page"
                    color="#152F38"
                    flat
                    :total-visible="9"
                    @input="nextPageBtn(page)"
                    :length="pageLength"
                    ></v-pagination>
            </v-layout>
        </v-card>

    </div>      

  </v-app>

</template>

<script>
import moment from 'moment';
let dateIs = null;
import { required, numeric, alpha, alphaNum, helpers,minLength} from 'vuelidate/lib/validators';
const nameSpaces = helpers.regex('nameSpaces', /^[a-zA-Z.' ]+$/);
const newChange = helpers.regex('newChange', /^(([I,N,T]){3}([0-9]){7}?)+$/g);

export default {
  data(){
    return{
        selectTableData: [],
        tempArr:[],
        fval: 0,
        sval: 0,
        totalLen: '',
        statusItemsPerPage: 10,
        page: 1,
        lastPage: false,
      intimationID: '',
      regCalenderDate3: false,
      regCalenderDate: false,
      admissionDate: '',
      intimationDate: '',
      selectFirstNameFilter: ['Contains','Begins With' ,'Ends With' ,'Equals'],
      selectLastNameFilter: ['Contains','Begins With' ,'Ends With' ,'Equals'],
      illnessFilterItems: ['Contains','Begins With' ,'Ends With' ,'Equals'],
      admissionDateFilterItems: ['Equals','Before','After'],
      intimationDateFilterItems: ['Equals','Before','After'],
      intimationStatusItems: ['Created','Closed'],
      firstNameFilter: '',
      intimationDateFilter: '',
      Illness: '',
      lastNameFilter: '',
      illnessFilter: '',
      admissionDateFilter: '',
      intimationStatus: '',
      mobileNumber: '',
      firstName: '',
      lastName: '',
      policyNumber: '',
      memberID: '',
      claimID: '',
      secondOpinion: false,
      headers:[
            {text: 'Intimation ID',value:'intimationId',sortable: false,divider:true,width: "120px",class:"hdr-txt-clr-WrkItemQue hdr-bg-clr-WrkItemQue srNoWidth1"},
            {text: 'Claim ID', value: 'claimId',sortable: false,divider:true,width: "100px",class:"hdr-txt-clr-WrkItemQue hdr-bg-clr-WrkItemQue"},
            {text: 'Member ID', value: 'memberId',sortable: false,divider:true,width: "110px",class:"hdr-txt-clr-WrkItemQue hdr-bg-clr-WrkItemQue"},
            {text: 'Policy Number', value: 'policyNumber',sortable: false,divider:true,width: "120px",class:"hdr-txt-clr-WrkItemQue hdr-bg-clr-WrkItemQue"},
            {text: 'First Name',value: 'firstName',sortable: false,divider:true,width: "110px",class:"hdr-txt-clr-WrkItemQue hdr-bg-clr-WrkItemQue"},  
            {text: 'Last Name',value: 'lastName',sortable: false,divider:true,width: "110px",class:"hdr-txt-clr-WrkItemQue hdr-bg-clr-WrkItemQue"},
            {text: 'Illness',value: 'illness',sortable: false,divider:true,width: "120px",class:"hdr-txt-clr-WrkItemQue hdr-bg-clr-WrkItemQue"},   
            {text: 'Intimation Status',value: 'intimationStatus',sortable: false,divider:true,width: "130px",class:"hdr-txt-clr-WrkItemQue hdr-bg-clr-WrkItemQue"}, 
            {text: 'Intimation Date',value:'intimationDate',sortable: false,divider:true,width: "130px",class:"hdr-txt-clr-WrkItemQue hdr-bg-clr-WrkItemQue"}, 
            {text: 'Date of Admission',value:'dateofAdmission',sortable: false,divider:true,width: "150px",class:"hdr-txt-clr-WrkItemQue hdr-bg-clr-WrkItemQue"},
            // {text: 'Select All',value:'select',sortable: false,divider:true,width: "90px",class:"hdr-txt-clr-Ot backColorOt"}, 
        ],
        dataItems: [],
        afterCheckArr: [],
        statusData: [],
        selectedTasks: [],
        sendAdmissionDate: [],
        store_header_index:null,
        filter_status:false,
        sortable_params:{}, 
        // checkboxField: false
    }
  },

  created(){
        this.$store.commit("SAVE_PAGE_HEADER", "Claim Intimation Search");
        this.dataOnCreated(this.page);
        // this.set_custom_headers();
        // this.set_table_data(this.$router.history.current.fullPath, 'normal')
    },

  validations: {
       firstName: {nameSpaces},
       lastName: {nameSpaces},
       mobileNumber: {numeric,minLength:minLength(10)},
       policyNumber: {alphaNum},
       intimationID: {alphaNum,newChange},
       claimID: {alphaNum},
       memberID: {alphaNum},
       Illness: {nameSpaces}
    },

    methods: {
    //     set_custom_headers(){
    //         //ssetting new key for tracking sorting order for a particular column, along with index mapping to new key
    //          this.headers = this.headers.map((e, index)=>{
    //              return {...e,index: index, stype:' ' }
    //          }) 
    //     },

    //     headercontrol(indata){
    //         console.log("getting header click now ?", indata)

    //                 if(indata.stype == ' ' &&  this.store_header_index != indata.index && indata.index!=7){
    //                         // ASC - from none
    //                         this.headers[indata.index].stype = 'asc'
    //                         if(this.store_header_index != null ){
    //                             this.headers[this.store_header_index].stype = ' '
    //                         }
    //                         this.store_header_index = indata.index

    //                 }else if(indata.stype == 'dsc'){
    //                         // ASC - from DSC
    //                         this.headers[indata.index].stype = ' '
    //                         this.store_header_index = null

    //                 }else if(indata.stype == 'asc'){
    //                         //DSC - from ASC
    //                         this.headers[indata.index].stype = 'dsc'
    //                         this.store_header_index = indata.index
    //                 }

    //                 console.log(this.headers[indata.index].stype);
    //                 // return
    //               let srtby = this.headers[indata.index].stype == 'asc' ? '1' : '-1'
                  

    //               if(this.headers[indata.index].stype == ' '){
    //                   this.sort_status = false;
    //                   let setting_type_outter = {type:'normal', params:''}
    //                   if(this.filter_status){
    //                       setting_type_outter.type = 'filter'
    //                       setting_type_outter.params = {CT:this.claimType,CSS:this.claimSubStatus,CS:this.claimStatus,FL:this.flag}
    //                   }
                        
    //                   this.set_table_data(this.set_route_for_page, setting_type_outter.type, setting_type_outter.params)
    //                   return
    //               }
    //                 var sortable_parameters = {
    //                     sortBy_data:'&sortBy='+indata.value+'&sortdirection='+srtby
    //                 }

    //                 console.log("sortable_parameters ---> ", sortable_parameters);
    //                 this.sortable_params = sortable_parameters
    //                 this.set_table_data(this.set_route_for_page, 'sort', sortable_parameters)
    //                 this.headers.map(e=> {
    //                     if(e.stype == ' '){
    //                         e.stype = ' '
    //                     }
    //                 }) 
    //     //this.headers[indata.index].stype=earlier == 'asc' ? 'dsc' : 'asc'
    //   },

    //   set_table_data(indata, type, custom_params){

    //         // var claims_role_is = '';

    //         // if(indata == '/supervisordataentryqueue'){
    //         //     claims_role_is = 'Data entry Queue'
    //         // }else if(indata == '/supervisorcodingqueue'){
    //         //     claims_role_is = 'Coding Queue'
    //         // }else if(indata == '/supervisormedicalscrutinyqueue'){
    //         //     claims_role_is = 'Medical Scrutiny Queue'
    //         // }else if(indata == '/supervisorcommercialscrutinyqueue'){
    //         //     claims_role_is = 'Commercial Scrutiny Queue'
    //         // }else if(indata == '/supervisorpaymentqueue'){
    //         //     claims_role_is = 'Payment Queue'
    //         // }else if(indata == '/internalinvestigation'){
    //         //     claims_role_is = 'Internal investigation'
    //         // }else if(indata == '/ofr'){
    //         //     claims_role_is = 'OFR'
    //         // }else if(indata == '/supervisorquickempanelmentqueue'){
    //         //     claims_role_is = 'Quick empanelment Queue'
    //         // }else if(indata == '/negotiation'){
    //         //     claims_role_is = 'Negotiation'
    //         // }else if(indata == '/cfr'){
    //         //     claims_role_is = 'CFR'
    //         // }
    //         // indata is route names
    //         // Data Entry Queue
    //         // Coding Queue
    //         // Medical Scrutiny Queue
    //         // Commercial Scrutiny Queue
    //         // Payment Queue    
    //         // indata
    //         // return
    //     // let default_test_parameter = 'Payment Queue'; // this is a rolename
       
    //     let build_api = 'claim-management/get-claim-list?'
    //     console.log("type is ", type);
    //     if(type == 'filter'){
            
    //         this.filter_status = true
    //         build_api = build_api+'&claimType='+custom_params.CT+'&claimSubStatus='+custom_params.CSS+'&claimStatus='+custom_params.CS+'&flag='+custom_params.FL
    //         if(this.sort_status){
    //               build_api = build_api+this.sortable_params.sortBy_data
    //         }
    //         console.log(custom_params,'<- from filter ', build_api);
    //     }

    //     if(type == 'sort'){
    //          this.sort_status = true
        
    //          build_api = build_api+custom_params.sortBy_data;
    //          console.log(custom_params,'from sort ', build_api);
    //          if(this.filter_status){
    //               let custp = {CT:this.claimType,CSS:this.claimSubStatus,CS:this.claimStatus,FL:this.flag}  
    //               build_api = build_api+'&claimType='+custp.CT+'&claimSubStatus='+custp.CSS+'&claimStatus='+custp.CS+'&flag='+custp.FL
    //          }
    //     }


    //     this.type_for_pagination = type

    //     build_api = build_api+'&perPage=20&pageNo='+this.page

    //     console.log(this.type_for_pagination,'final build_api -> ', build_api);


        
    //         try{
    //             this.GET(7, build_api, (res,error)=>{
    //                 if(res != null){
                       
    //                     console.log("reponse of api is ", res.data.data)
    //                     if(!res.data.hasOwnProperty('data')){
    //                         this.showToast(res.data.msg ,this.TOST().ERROR);
    //                         this.table_data = []
    //                        this.fval = 0
    //                        this.sval = 0 
    //                        this.totalLen = 0
                           
    //                         return
    //                     }
                     
    //                     // this.showSearchdata=false
    //                     // this.showrsltnotfound=true

    //                     this.table_data = res.data.data.data;
    //                     this.typesearch = true
                    


    //             //pagination 
    //                 this.pagelength=res.data.data.total_pages
    //                 console.log(this.page,' <-- pageindu | next btn, pg length is ----->',this.pagelength)

    //                  this.totalLen=res.data.data.total
    //                   let l = this.table_data.length
                
                
    //                 console.log( 20*this.page,'skip simulation ');

    //                   var less_enough = this.totalLen
    //                     var checkless_init = false
    //                     if(less_enough < 20){
    //                         checkless_init = false
    //                     }else{
    //                         checkless_init = true
    //                     }

    //                     // if less than 15 we have second value same as total value as no pagination will occur
    //                     if(checkless_init){
                            
    //                             // checkinit is true means the final count is more than 15 (- 1)
    //                             var traverse =  20*this.page
    //                             this.fval = traverse-19
    //                             if(this.swap_final_count){
    //                                 this.sval =  this.totalLen
    //                             }else{
    //                                 this.sval = traverse
    //                             }

    //                     }else if(this.totalLen == 0){
                            
    //                       this.sval = this.fval = 0
                        
    //                    }else{
    //                          this.fval = 1
    //                          this.sval = this.totalLen
    //                     }

    //                 console.log('total ----->',this.totalLen)
    //                 console.log(this.sval,'<------sval<<   >>fval ----->',this.fval)

	// 					console.log(" table DATA is---->>::",this.table_data);
		
    //                 }else{
                        
    //                     console.log(" table no DATA error msg is---->>::");
                      
                        
                        
    //                  }
    //             })
            
    //          }catch(error){

    //            console.log("table no DATA  error -> ",error)
    //         // //    this.showToast(error.res.data.msg,this.TOST().ERROR);
    //          }
    //     //    this.get_roles(claims_role_is);
    //     },

        nextPageBtn(page){
            if(this.pageLength === page){
                this.lastPage = true
                console.log("this is last page",page);
            }else{
                this.lastPage = false
                console.log("this is not last page",page);
            }
            this.dataOnCreated(page);
        },

        closeIntimationStatus(){
            console.log(this.selectTableData);
            this.tempArr = this.selectTableData.map((item) => {
                return {
                    id: item.id,
                    intimationStatus: 'Closed'
                }
            })
            console.log(this.tempArr);
            this.PUT(8, 'claims-intimation/update-intimation-status',this.tempArr, (res, err) => {
                    if(!err){
                        console.log(res.data);
                        this.showToast(res.data.msg ,this.TOST().SUCCESS);
                        this.selectTableData = [];
                        // for(let i=0; i<this.statusData.length; i++){
                        //     this.statusData[i].intimationStatus = 'Created';
                        // }
                        this.dataOnCreated();
                    // this.intimationStatus = 
                    }else{
                        console.log(err);
                    }
            })  
             
        },

        // clickCheck(data){
        //     console.log(data);
        //     this.afterCheckArr.push({status: data.intimationStatus});
        //     // let tempArr = [];
        //     this.tempArr.push({
        //         id: data.id,
        //         status: 'Closed'
        //     });
        //     console.log(this.tempArr);
        // },

        dataOnCreated(page){
            this.GET(8,'claims-intimation/search-intimation?perPage=10&pageNo='+page, (res, err) => {
               if(!err){
                   console.log(res.data.data.data);
                   this.pageLength = res.data.data.total_pages;
                   this.totalLen = res.data.data.total
                   var temp =  this.page*10;
                   this.fval = temp-9;
                   this.sval = temp;
                   this.dataItems = res.data.data.data;

                //    this.dataItems = res.data.data.data;

                   this.statusData =  this.dataItems.map((item,index) => {

                    //    let nullDate = '';
                           let toDateData = '';
                           dateIs = moment(item.intimationDate)._i.split("T")[0];
                           console.log(dateIs);
                           toDateData = moment(dateIs).format("DD-MM-YYYY");
                           console.log(toDateData);
                           console.log(item.dateOfAdmissionLoss);

                           let toAdmissionDateData = '';
                           if(item.dateOfAdmissionLoss == undefined){
                               toAdmissionDateData = ''
                           }else{
                           dateIs = moment(item.dateOfAdmissionLoss)._i.split("T")[0];
                           console.log(dateIs);
                           toAdmissionDateData = moment(dateIs).format("DD-MM-YYYY");
                           console.log(toAdmissionDateData);
                           }
                    //    }

                       return {
                           id: item._id,
                          intimationId: item.intimationId,
                        //   claimId:
                          memberId: item.memberId,
                          policyNumber: item.policyNo,
                          firstName: item.firstName,
                          lastName: item.lastName,
                          mobileNo: item.mobileNo,
                          emailId: item.emailId,
                          dateofAdmission: toAdmissionDateData,
                          dateOfDischarge: item.dateOfDischarge,
                          intimationDate: toDateData,
                          modeOfIntimation: item.modeOfIntimation,
                          intimationStatus: item.intimationStatus,
                          illness: item.illness,
                          illnessDescription: item.illnessDescription,
                          diagnosis: item.diagnosis,
                          treatment: item.treatment,
                          actualOrEstimatedAmount: item.actualOrEstimatedAmount,
                          intimationType: item.intimationType,
                          hospitalName: item.hospitalName,
                          secondOpinion: item.secondOpinion,
                          hospitalAddressLine1: item.hospitalAddressLine1,
                          hospitalAddressLine2: item.hospitalAddressLine2,
                          hospitalAddressLine3: item.hospitalAddressLine3,
                          pincode: item.pincode,
                          country: item.country,
                          state: item.state,
                          district: item.district,
                          cityTownVillage: item.cityTownVillage,
                          remarks: item.remarks,
                        //   intimationStatus: item.intimationStats,
                        //   intimationDate: item.intimationDate,
                        //   dateofAdmission: item.dateofAdmission,
                       }
                   })
               }else{
                   console.log(err);
               }
            })

       },

       searchFields(){
            this.$v.$touch();
            if(this.$v.$invalid || this.illnessErrors.length > 0 || this.lastNameError.length > 0 || this.firstNameError.length > 0 || this.intimationIDErrors.length > 0 || this.claimIDErrors.length > 0 || this.memberIDErrors.length > 0 || this.policyNumberError.length > 0 || this.mobileNumberError.length > 0){
                console.log("It is not valid");
                this.showToast("Please fill required fields",this.TOST().WARNING);
                return
            }
            
            if(this.intimationID == '' && this.claimID == '' && this.memberID == '' && this.policyNumber == '' && this.secondOpinion == false && this.firstName == '' && this.lastName == '' && this.Illness == '' && this.intimationStatus == '' && this.admissionDate == '' && this.intimationDate == '' && this.mobileNumber == '' && this.illnessFilter == '' && this.admissionDateFilter == '' && this.illnessFilter == '' && this.intimationDateFilter == ''){
                this.showToast("Enter search criteria",this.TOST().WARNING);
                console.log("Not entered");
            }
            else{
            this.GET(8,'claims-intimation/search-intimation?intimationId='+this.intimationID+'&memberId='+this.memberID+'&claimId='+this.claimID+
            '&policyNumber='+this.policyNumber+'&secondOpinion='+this.secondOpinion+'&firstNameFilter='+this.firstNameFilter+'&firstName='+this.firstName+'&lastNameFilter='+this.lastNameFilter+'&lastName='+this.lastName+
            '&illnessFilter='+this.illnessFilter+'&illness='+this.Illness+'&intimationDate='+this.intimationDate+'&intimationDateFilter='+this.intimationDateFilter+
            '&dateOfAdmissionLoss='+this.admissionDate+'&dateOfAdmissionLossFilter='+this.admissionDateFilter+'&intimationStatus='+this.intimationStatus+'&mobileNumber='+this.mobileNumber, (res, err) => {
               if(!err){
                   this.showToast(res.data.msg,this.TOST().SUCCESS);
                   console.log(res.data.data.data);
                   this.pageLength = res.data.data.total_pages;
                   this.totalLen = res.data.data.total
                   var temp =  this.page*10;
                   this.fval = temp-9;
                   if(this.lastPage){
                        this.sval = this.totalLen
                        console.log("value of sval is fo",this.sval);
                    }else{
                        if(!this.totalLen < 10){
                            this.sval = this.totalLen
                            console.log("this are the pageLength for searchTable",this.totalLen);
                         }else{
                            this.sval = temp;
                            console.log("value of sval is",this.sval);
                        }
                    }

                   this.dataItems = res.data.data.data;

                   this.statusData =  this.dataItems.map((item,index) => {

                           let toDateData = '';
                           dateIs = moment(item.intimationDate)._i.split("T")[0];
                           console.log(dateIs);
                           toDateData = moment(dateIs).format("DD-MM-YYYY");
                           console.log(toDateData);

                           let toAdmissionDateData = '';
                           if(item.dateOfAdmissionLoss == undefined){
                               toAdmissionDateData = ''
                           }else{
                           dateIs = moment(item.dateOfAdmissionLoss)._i.split("T")[0];
                           console.log(dateIs);
                           toAdmissionDateData = moment(dateIs).format("DD-MM-YYYY");
                           console.log(toAdmissionDateData);
                           }

                       return {
                           id: item._id,
                          intimationId: item.intimationId,
                        //   claimId:
                          memberId: item.memberId,
                          policyNumber: item.policyNo,
                          firstName: item.firstName,
                          lastName: item.lastName,
                          mobileNo: item.mobileNo,
                          emailId: item.emailId,
                          dateofAdmission: toAdmissionDateData,
                          dateOfDischarge: item.dateOfDischarge,
                          intimationDate: toDateData,
                          modeOfIntimation: item.modeOfIntimation,
                          intimationStatus: item.intimationStatus,
                          illness: item.illness,
                          illnessDescription: item.illnessDescription,
                          diagnosis: item.diagnosis,
                          treatment: item.treatment,
                          actualOrEstimatedAmount: item.actualOrEstimatedAmount,
                          intimationType: item.intimationType,
                          hospitalName: item.hospitalName,
                          secondOpinion: item.secondOpinion,
                          hospitalAddressLine1: item.hospitalAddressLine1,
                          hospitalAddressLine2: item.hospitalAddressLine2,
                          hospitalAddressLine3: item.hospitalAddressLine3,
                          pincode: item.pincode,
                          country: item.country,
                          state: item.state,
                          district: item.district,
                          cityTownVillage: item.cityTownVillage,
                          remarks: item.remarks,
                        //   intimationStatus: item.intimationStats,
                        //   intimationDate: item.intimationDate,
                        //   dateofAdmission: item.dateofAdmission,
                       }
                   })
               
                //    this.statusData =  
               }else{
                   console.log(err);
                   this.showToast("No intimations available for entered search criteria",this.TOST().ERROR);
               }
            })
          }  
       },

       intimationIdClick(item){
              console.log(item);
            //   console.log(this.dataItems); 
            //   this.sendDetailsScreen = item;
              this.$router.push({name: 'NewClaimIntimation', params: {claimIntiVal: true, claimDetailsData: item}});
       },

       clearFields(){
           this.intimationID = '';
           this.claimID = '';
           this.memberID = '';
           this.policyNumber = '';
           this.firstNameFilter = '';
           this.firstName = '';
           this.lastNameFilter = '';
           this.lastName = '';
           this.admissionDateFilter = '';
           this.intimationDateFilter = '';
           this.illnessFilter = '';
           this.secondOpinion = '';
           this.Illness = '';
           this.admissionDate = ''; 
           this.intimationDate = '';
           this.intimationStatus = '';
           this.mobileNumber = '';

           this.dataOnCreated();
       },

    },

  computed: {
      admissionDateTo() {
            console.log(this.admissionDate)
            console.log(this.admissionDate ? moment(this.admissionDate).format("DD-MM-YYYY"): '')
            return this.admissionDate ? moment(this.admissionDate).format("DD-MM-YYYY"): ''
      },

      intimationDateTo() {
            console.log(this.intimationDate)
            console.log(this.intimationDate ? moment(this.intimationDate).format("DD-MM-YYYY"): '')
            return this.intimationDate ? moment(this.intimationDate).format("DD-MM-YYYY"): ''
      },

      intimationIDErrors(){
          const errors = [];

          if (!this.$v.intimationID.$dirty) return errors   
          !this.$v.intimationID.newChange && errors.push('Enter valid intimation ID');
          return errors;
      },

      illnessErrors(){
          const errors = [];

          if (!this.$v.Illness.$dirty) return errors   
          !this.$v.Illness.nameSpaces && errors.push('Special characters & Numbers not allowed');
          return errors;
      },

      claimIDErrors(){
          const errors = [];

          if (!this.$v.claimID.$dirty) return errors   
          !this.$v.claimID.alphaNum && errors.push('Only Alphanumeric');
          return errors;
      },

      memberIDErrors(){
          const errors = [];

          if (!this.$v.memberID.$dirty) return errors   
          !this.$v.memberID.alphaNum && errors.push('Enter valid member ID');
          return errors;
      },

      policyNumberError(){
            const errors = [];

            if (!this.$v.policyNumber.$dirty) return errors   
            !this.$v.policyNumber.alphaNum && errors.push('Enter valid policy number');
            return errors;
      },
      
      mobileNumberError(){
            const errors = [];

            if (!this.$v.mobileNumber.$dirty) return errors   
            !this.$v.mobileNumber.numeric && errors.push('Only Numbers are allowed');
            !this.$v.mobileNumber.minLength && errors.push('Enter Valid Mobile Number');
            return errors;
      },

      firstNameError(){
            const errors = [];

            if (!this.$v.firstName.$dirty) return errors   
            !this.$v.firstName.nameSpaces && errors.push('Only alphabets are allowed');
            return errors;
        },

      lastNameError(){
            const errors = [];

            if (!this.$v.lastName.$dirty) return errors   
            !this.$v.lastName.nameSpaces && errors.push('Only alphabets are allowed');
            return errors;
      },

  }

}
</script>

<style>

</style>