 <template>
    <v-app>
       <!-- <NetworkSearchVue @clear-network='clearname'>

       </NetworkSearchVue> -->

        <v-card tile class="pt-2 mt-4 ma-">
                <v-form v-model="valid" ref="netform">

                <v-layout color="#FFFFFF" class=" mt-1" style="background-color: #FFFFFF;">
                <v-flex xs3 class="errormsgUT">
                    <v-layout column class="cust_pr mx-3 ">
                        <p class="font-weight-bold cstm-clr-pr">
                            Network Name
                        </p>
                        <v-text-field
                            solo
                            dense
                            label="Enter..."
                            v-model="networkName"
                            
                            class="rounded-0"
                            :rules="[rules.netname,rules.required]"
                          
                        >
                        </v-text-field>
                    </v-layout>
                </v-flex>
                    <!-- <v-divider  class="ma-2" vertical ></v-divider> -->

                
                <v-flex xs3 class="">
                    <v-layout column class=" mx-3 pb-5 cust_pr cust-rmvpd-pr">
                        <p class="font-weight-bold cstm-clr-pr">
                            Network Effective Start Date
                        </p>
                        <v-menu
                            ref="calender"
                            :close-on-content-click="false"
                            v-model="netstartDatecalender"
                            :nudge-right="40"
                            lazy
                            transition="scale-transition"
                            full-width
                            max-width="290px"
                            min-width="290px" 
                            class="rounded-0"
                            dense>
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    slot="activator"
                                    label="dd/mm/yyyy"
                                    :value="cmputdpdtEfftvDteFrmMmntjs"
                                    solo
                                     class="rounded-0"
                                    :rules="[rules.required,pastdateCheck()]"
                                    v-bind="attrs"
                                    v-on="on"
                                    readonly>
                                    <div slot="prepend-inner" style="background-color:#F7F7F7;  padding: 6px !important"><span class="material-icons">date_range</span></div>
                                </v-text-field>
                            </template>
                            <v-date-picker @input="netstartDatecalender = false" v-model="netstartdate" no-title @change="validDateChk"></v-date-picker>
                        </v-menu>
                    </v-layout>
                </v-flex>
                <v-flex xs3 class="errordatemsgUT">
                    <v-layout column class=" mx-3 cust_pr cust-rmvpd-pr">
                        <p class="font-weight-bold cstm-clr-pr">
                            Network Effective End Date
                        </p>
                        <v-menu
                            ref="calender"
                            :close-on-content-click="false"
                            v-model="netendDatecalender"
                            :nudge-right="40"                           
                            transition="scale-transition"                            
                            max-width="290px"
                            min-width="290px" 
                            class="rounded-0">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    slot="activator"
                                    label="dd/mm/yyyy"
                                    :value="cmputdpdtEfftvDteToMmntjs"
                                    solo
                                    class="rounded-0"
                                    readonly
                                    :rules="[dateCheck()]"
                                    v-bind="attrs"
                                    v-on="on">
                                    <div  slot="prepend-inner" style="background-color:#F7F7F7;  padding: 6px !important"><span class="material-icons">date_range</span></div>
                                </v-text-field>
                            </template>              
                            <v-date-picker @input="netendDatecalender = false" v-model="netenddate" no-title></v-date-picker>
                        </v-menu>
                    </v-layout>
                </v-flex>

                 <v-flex xs3 >
                    <v-layout column class="  mx-3 cust_pr pb-5">
                        <p class="font-weight-bold cstm-clr-pr ">
                            Clinical Specialties
                        </p>

                            <v-select
                                        :items="selectclinicalspl"
                                        item-text="type"
                                        item-value="_id"
                                        v-model="clinicalspl"
                                        label="Select"
                                        return-object
                                        solo
                                        dense
                                        multiple
                                        
                                        :rules="[rules.requiredarr]"
                                        class="rounded-0 errorMsg123"
                                    >
                                    <template v-slot:selection="{ item, index }">
                                        <span v-if="index === 0">{{ item }}</span>
                                        <!-- <v-chip v-if="index === 0">
                                        <span>{{ item }}</span>
                                        </v-chip> -->
                                        <span
                                        v-if="index === 1"
                                        class="grey--text caption"
                                        >
                                        (+{{ clinicalspl.length - 1 }} others)
                                        </span>
                                    </template>
                            </v-select>
                        
                        
                    </v-layout>
                </v-flex>
                
                <v-flex xs3 class="errordatemsgUT1">
                    <v-layout column class=" mx-3  cust_pr">
                        <p class="font-weight-bold cstm-clr-pr">
                            Remarks
                        </p>
                        <v-text-field
                            solo
                            dense
                            label="Enter..."
                            v-model="remarks"
                             class="rounded-0"
                              :maxlength="max1000"
                            :rules="[rules.max1000]"
                             >
                        </v-text-field>
                    </v-layout>
                </v-flex>
                </v-layout>
                </v-form>
            </v-card>

        <div>
        
        
            <v-card class="mb-2 mt-5 cust-bnkAcc-tbl" style="border:0.5px solid #C1C8CC;" tile>
				<v-layout  style="padding:15px;background-color:#F7F7F7;align-items:center; justify-center; justify-content: flex-end;">
                    <v-btn dark  tile color="#D1121B" class="clearbtn text-capitalize px-3 mx-3" @click="clear()" small><v-icon dark small>delete</v-icon> <v-divider vertical class="mx-2"></v-divider>Clear</v-btn>
                    <v-btn dark  tile color="#23B1A9" class="savebtn text-capitalize px-3 mx-3" @click="submit(id)" :dark="!updateBtn" :disabled="updateBtn" small><v-icon dark small>check_circle</v-icon> <v-divider vertical class="mx-2"></v-divider>Submit</v-btn>
                    <v-btn dark  tile color="#E46A25" class="cancelbtn text-capitalize px-3 mx-3" @click="$router.go(-1)" small><v-icon dark small>check_circle</v-icon> <v-divider vertical class="mx-2"></v-divider> Cancel</v-btn>
                    <v-btn dark  tile color="#23B1A9" class="newbtn text-capitalize px-3 mx-2" @click="save()" :dark="!createBtn" :disabled="createBtn" small><v-icon dark small>add</v-icon> <v-divider vertical class="mx-2"></v-divider>Create New</v-btn>
				</v-layout>
            </v-card>         
        </div>


        
    </v-app>
</template>
<script>
import moment from 'moment';
import axios from "axios";



export default {

    components:{
    //   NetworkSearchVue
    },

    created() {

        this.$store.commit("SAVE_PAGE_HEADER", "Network Setup");
        
        this.token=this.$store.getters.successToken

         this.netbtnvalue=this.$route.params.value
         console.log('incoming params--->',this.netbtnvalue)

         this.editvalue=this.$route.params.editValue
        console.log('incoming editValue params--->',this.editvalue)

        this.getallclinicalspecialities()
        
                
        if(this.netbtnvalue == true ){
            this.createBtn=false
            this.updateBtn=true
            console.log('incoming params createbtn----->')
                
        //  }else{
        //        this.updateID= this.$store.getters.fetchupdateNetworkData._id
        //         console.log("update all network data by id--->>>", this.$store.getters.fetchupdateNetworkData)

        //         this.getnetworkDetailsByID(this.updateID)
        //         console.log("update network data by id--->>>", this.updateID)
        //          this.createBtn=true 
        //         //  this.updateBtn=false
        //  }
        }else if(this.editvalue == true ) {
               this.updateID= this.$store.getters.fetchupdateNetworkData._id
                this.getnetworkDetailsByID(this.updateID)
                console.log("update network  id--->>>", this.updateID)
                 this.createBtn=true 
                 this.updateBtn=false
         }

    },

    computed: {
         cmputdpdtEfftvDteFrmMmntjs(){
            return this.netstartdate ? moment(this.netstartdate).format("DD-MM-YYYY"): ''
        },
         cmputdpdtEfftvDteToMmntjs(){
            return this.netenddate ? moment(this.netenddate).format("DD-MM-YYYY"): ''
        },
        validDateChk(){
            

            let todayDate = Date.now();
            let Todate= moment(this.netenddate,'YYYY-MM-DD ').toDate().getTime()
            console.log(todayDate,"todayDate")
            let CurrentDate= todayDate

            if(Todate < CurrentDate){
                return this.planStatus="Inactive"
            }else{
                return this.planStatus="Active"
            }
        }

    },


    data() {
        return {
        netbtnvalue:false,
        editvalue:false,
        token:null,
        updateID:'',
        valid:false,
        updateBtn:true,
        createBtn:false,
        // showbtn:'',
        networkName:'',
        netstartdate:'',
        netenddate:'',
        clinicalspl:'',
        selectclinicalspl:[],
        // selectclinicalspl:['abc','General Surgery'],
        remarks:'',
        showCreatedbtn:'',
        max1000:1000,
        netstartDatecalender:false,
        netendDatecalender:false,

        rules: {
                required: value => !!value || 'This Field is Required.',

                requiredarr:  v=>v && v.length>0 || "This Field is Required",
               
                max1000: v =>v.length < 1000 ||  'Maximum ‘1000’ characters are acceptable.',
                
                netname: value => {
                        const pattern = /^[a-zA-Z ]+$/g 
                        return value.length <=0  || pattern.test(value) || 'Only alphabets required.'
                },
                
            },
       
                
            pastdateCheck(){
                if(this.editvalue == true ) {
                    return
                }else{
                    let todayDate = Date.now();
                    console.log(todayDate,"todayDate")
                     let fromDate=moment(this.netstartdate,'YYYY-MM-DD').toDate().getTime()

                     let CurrentDate= todayDate
                    
                        console.log(CurrentDate,"<<<----CurrentDate from date---->>",fromDate)

                    if((CurrentDate-86400000) > fromDate){
                        // this.showToast("The Product 'Effective date From' can not be greater than Product 'Effective date To'.",this.TOST().WARNING)
                        return "Enter valid effective start date."
                    }

                }
                
            },


            dateCheck(){
                let todayDate = Date.now();
                let fromDate=moment(this.netstartdate,'YYYY-MM-DD ').toDate().getTime()
                let Todate= moment(this.netenddate,'YYYY-MM-DD ').toDate().getTime()
                console.log(todayDate,"todayDate")
                let CurrentDate= todayDate
                if(Todate <= fromDate){
                    // this.showToast("The Product 'Effective date From' can not be greater than Product 'Effective date To'.",this.TOST().WARNING)
                    return "Enter valid date range."
                }
            }

    }},

    methods: {
        clearname(){
            console.log('%c clear name--------->>>>>>>','color:green')
                this.networkName=''
        },

        getallclinicalspecialities(){
                
                let self = this; 
                // self.showLoader('Loading', true);  
                axios({
                    method: "get",
                    url:self.API_Service_MASTER()+"clinical-specialities/get-all-clinical-specialities",
 
                    headers: {
                            "Content-Type": "application/json", 
                            "x-access-token": self.token,
                        }
                })
                .then(function(response) {
                    console.log('all clinical-specialities Response------->>>',response.data.data);
                        self.selectclinicalspl=response.data.data[0].clinicalSpecialities
                             
                })
                .catch(function(error) {
                    self.showLoader('Loading', false);
                    console.log(error);
                    // self.showToast(error.response.data.msg,self.TOST().ERROR);
                });

            },

        getnetworkDetailsByID(id){
                
                let self = this; 
                // self.showLoader('Loading', true);  
                axios({
                    method: "get",
                    // url:"//ab6e074e902674466b4d5a40e0c8c063-1268690213.ap-south-1.elb.amazonaws.com/network-management/network-management-service/api/network-management/get-network-by-id/"+id, 
                    url:self.API_Service_NETWORK_MGMNT()+"network-management/get-network-by-id/"+id,
                    
                    headers: {
                            "Content-Type": "application/json", 
                            "x-access-token": self.token,
                        }
                })
                .then(function(response) {
                    console.log('network Details Response------->>>',response.data.data);
                    // if(self.$store.getters.fetchBtnName.text==='Clone' ){
                        self.fetchNetworkAll(response.data.data)
                          self.networkCreatedbtn=response.data.data.networkCreated
                         console.log('networkCreated value from api',self.networkCreatedbtn)
                         
                    self.showLoader('Loading', false);
                })
                .catch(function(error) {
                    self.showLoader('Loading', false);
                    console.log(error);
                    // self.showToast(error.response.data.msg,self.TOST().ERROR);
                });

            },

            fetchNetworkAll(e){
           
            this.clinicalspl=e.clinicalSpecialities,
            this.showCreatedbtn=e.networkCreated,
            this.netstartdate=e.networkEffectiveStartDate,
            this.netenddate=e.networkEffectiveEndDate,
            this.networkName=e.networkName,
            this.remarks=e.remarks
            
        },

        save(){
            
            if (this.$refs.netform.validate()){
                let self=this
                
                let demodata={
                    networkName:self.networkName,
                    networkEffectiveStartDate:self.netstartdate,
                    networkEffectiveEndDate:self.netenddate,
                    clinicalSpecialities:self.clinicalspl,
                    remarks:self.remarks,
        
                }
                console.log("from demodata--------->",demodata);
                axios({
                    method: "post",
                    // url:"//ab6e074e902674466b4d5a40e0c8c063-1268690213.ap-south-1.elb.amazonaws.com/network-management/network-management-service/api/network-management/create-new-network",
                    url:self.API_Service_NETWORK_MGMNT()+"network-management/create-new-network",
                    
                    headers: {
                        "Content-Type": "application/json",
                        "x-access-token":self.token,
                    },
                    data:demodata,
                })
                .then(function (response) {
                    console.log(" network api responce---->>>>>>", response);
                      self.$store.commit("NETWORK_DATA",response.data.data)

                    // self.resetfrm();
                    self.showToast(response.data.msg,self.TOST().SUCCESS)
                    console.log("network create api success msg--->>>>>>", response.data.msg);
                    self.$router.push('/NetworkManagement')
                   
                })
                .catch(function (error) {
                    console.log("network create Error>>>>>>", error);
                    self.showToast(error.response.data.msg,self.TOST().ERROR);
                });
            }else{
                this.$refs.netform.validate()
                this.showToast("Enter valid values in all the required fields.",this.TOST().WARNING);
            }
        },

        submit(id){
            // self.$store.commit("NETWORK_DATA",response.data.data._id)
            //  console.log('incoming put api id is---->',id)
             if (this.$refs.netform.validate()){
                let self=this
            
                let demodata={
                    networkName:self.networkName,
                    networkEffectiveStartDate:self.netstartdate,
                    networkEffectiveEndDate:self.netenddate===null ? '' : self.netenddate,

                    // networkEffectiveEndDate:...(self.netenddate===null && {networkEffectiveEndDate:''}),
                    clinicalSpecialities:self.clinicalspl,
                    remarks:self.remarks,
        
                }
                console.log("from demodata--------->",demodata);
                axios({
                    method: "put",
                    // url:"//ab6e074e902674466b4d5a40e0c8c063-1268690213.ap-south-1.elb.amazonaws.com/network-management/network-management-service/api/network-management/create-new-network",
                    url:self.API_Service_NETWORK_MGMNT()+"network-management/update-network-by-id/"+self.updateID,
                    
                    
                    headers: {
                        "Content-Type": "application/json",
                        "x-access-token":self.token,
                    },
                    data:demodata,
                })
                .then(function (response) {
                    console.log(" network update api responce---->>>>>>", response);
                        // self.$store.commit("NETWORK_DATA",response.data.data._id)

                    // self.resetfrm();
                    self.showToast(response.data.msg,self.TOST().SUCCESS)
                    console.log("network update api success msg--->>>>>>", response.data.msg);
                    self.$router.push('/NetworkManagement')
                    return
                   
                })
                .catch(function (error) {
                    console.log("network update Error>>>>>>", error);
                    self.showToast(error.response.data.msg,self.TOST().ERROR);
                });
            }else{
                this.$refs.netform.validate()
                this.showToast("Enter valid values in all the required fields.",this.TOST().WARNING);
            }
        },



        cancelbtn(){
            this.$router.push('/NetworkSearch')

        },


        clear(){
            if((this.networkName===''||this.networkName===undefined||this.networkName===null) 
            && (this.netstartdate===''||this.netstartdate===undefined||this.netstartdate===null)
            && (this.netenddate===''||this.netenddate===undefined||this.netenddate===null) 
            && (this.clinicalspl===''||this.clinicalspl===undefined||this.clinicalspl===null)
            && (this.remarks===''||this.remarks===undefined||this.remarks===null) 
            
            ){
                this.$refs.netform.resetValidation()
                return
            }else{
                
                this.networkName=''
                this.netstartdate=''
                this.netenddate=''
                this.clinicalspl=''
                this.remarks=''
                
                this.$refs.netform.resetValidation()
                        
            }
        },
       

    },

    
}

</script>
<style >

.cust-rmvpd-pr .v-text-field.v-text-field--enclosed>.v-input__control>.v-input__slot {
    padding: 0px !important;;
}
.errorMsg123 .v-input__control .v-messages .v-messages__wrapper .v-messages__message{
    padding-top: 5px;
}

</style>