<template>
    <div class="ma-5">
        <div>
            <!-- <v-btn-toggle tile mandatory v-model="communicatorType" @change="checkCommunicatorType(communicatorType)">
                <v-btn value="repairer" class="text-capitalize font-weight-bold btn_tabs pr-3 mr-1 claimbtnstyle activebg"
                        style="height:40px" :class="[repairer ? 'activebg': 'inactivebg']">Repairer
                </v-btn>
                <v-btn value="vendor" class="text-capitalize font-weight-bold btn_tabs pr-3 mr-1 claimbtnstyle activebg" 
                        style="height:40px" :class="[vendor ? 'activebg': 'inactivebg']">Vendor
                </v-btn>
                <v-btn value="examiner" class="text-capitalize font-weight-bold btn_tabs pr-3 mr-1 claimbtnstyle activebg" 
                        style="height:40px" :class="[examiner ? 'activebg': 'inactivebg']">Examiner
                </v-btn>
            </v-btn-toggle>
            <hr class="mb-3"> -->
            <v-row>
                <v-col>
                    <p class="font-weight-bold">List of Communication Sent </p>
                </v-col>
                <v-col align="right">
                    <v-btn color="#23B1A9"  dark class="btnstyle" @click="openSearchDialog">
                        <v-icon class="mr-2 iconsize">mdi-magnify</v-icon>
                        <div class="vertical_line"></div>Search
                    </v-btn>
                </v-col>
            </v-row>
            <!-- Start of CM-257 -->
            <!-- <v-flex class="mt-3 mb-2 mr-3 borderline">
                <v-chip value="system" :class="[ systemShw === true ? 'activeredbg': 'inactivebg']" 
                        class="menutext mr-3" @click="switchSystemManualTab('system')">System</v-chip>
                <v-chip value="manual" :class="[ systemShw === false ? 'activeredbg': 'inactivebg']" 
                        class="menutext mr-5" @click="switchSystemManualTab('manual')">Manual</v-chip>
            </v-flex> -->
            <!-- End of CM-257 -->
            <v-flex class="mt-3 mb-2 mr-3 borderline">
                <v-chip value="email" :class="[ emailShw === true ? 'activeredbg': 'inactivebg']" 
                        class="menutext mr-3" @click="switchtab('email')">Email</v-chip>
                <v-chip value="sms" :class="[ smsShw === true ? 'activeredbg': 'inactivebg']" 
                        class="menutext mr-5" @click="switchtab('sms')">SMS</v-chip>
            </v-flex>
            <!-- Email Table -->
            <template v-if="emailShw">
                <v-data-table
                    :headers="communicationType == 'Common Module' ? emailHeaders : claimHeaders"
                    :items="emailData"
                    hide-default-footer
                    disable-pagination
                    :expanded.sync="expanded" 
                    :single-expand="singleExpand"
                    :no-data-text="emailData.length == 0 ? 'No Records for Email' : ''"
                    class="vDataTableStyling mt-0 tableborder">
                    <template v-slot:[`item.select`]="{ item, index }">
                        <v-menu
                            bottom
                            offset-y
                            rounded="0"
                            nudge-left="0">
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon class="mr-2 menusize" v-bind="attrs" v-on="on">mdi-dots-vertical</v-icon>
                            </template>
                            <v-list>
                                <v-list-item
                                    style="min-height: 28px !important" 
                                    v-for="(item1, index1) in menuItems"
                                    :key="index1"
                                    dense
                                    class="ma-0 pa-0"
                                    @click="modifyAction(item, index1, index)">
                                    <v-icon small class="pa-2 pt-2 iconsize" v-text="item1.icon" :style="item1.iconColor"></v-icon>
                                    <v-divider vertical class="mx-1"></v-divider>
                                    <v-list-item-title class="pa-1 pr-3 ma-0 mr-0" v-text="item1.title" :style="item1.color" :class="item1.name" style="font-size: 12px !important"></v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </template>
                    <template v-slot:[`item.eventName`]="{ item }">
                        <span>{{ item.eventName.split('-')[2] }}</span>
                    </template>
                    <template v-slot:[`item.eventType`]="{ item }">
                        <span>{{ item.subject.split('-')[1] }}</span>
                    </template>
                    <template v-slot:[`item.cc`]="{ item, index }">
                        <v-tooltip v-model="showCoverageTooltip[index]" top>
                            <template v-slot:activator="{ on, attrs  }">
                                <div @mouseenter="showCoverageTooltip[index] = true" @mouseleave="showCoverageTooltip[index] = false">
                                    <span v-on="on" v-bind="attrs">{{ limitTextLength(item.cc, 15) }}</span>
                                </div>
                            </template>
                            <span>{{item.cc.split(",").join(";")}}</span>
                        </v-tooltip>
                    </template>
                    <template v-slot:[`item.to`]="{ item, index }">
                        <v-tooltip v-model="showCoverageTooltip1[index]" top>
                            <template v-slot:activator="{ on, attrs  }">
                                <div @mouseenter="showCoverageTooltip1[index] = true" @mouseleave="showCoverageTooltip1[index] = false">
                                    <span v-on="on" v-bind="attrs">{{ limitTextLength(item.to, 15) }}</span>
                                </div>
                            </template>
                            <span>{{item.to.split(",").join(";")}}</span>
                        </v-tooltip>
                    </template>
                    <template v-slot:[`item.bcc`]="{ item, index }">
                        <v-tooltip v-model="showCoverageTooltip2[index]" top>
                            <template v-slot:activator="{ on, attrs  }">
                                <div @mouseenter="showCoverageTooltip2[index] = true" @mouseleave="showCoverageTooltip2[index] = false">
                                    <span v-on="on" v-bind="attrs">{{ limitTextLength(item.bcc, 15) }}</span>
                                </div>
                            </template>
                            <span>{{item.bcc.split(",").join(";")}}</span>
                        </v-tooltip>
                    </template>
                    <template v-slot:[`item.dateTime`]="{ item }">
                        <span>{{item.dateTime == "" ? "------" : dateFormat(item.dateTime)}}</span>
                    </template>
                    <template v-slot:expanded-item="{ headers, item}" :scope="props" class="mt-2">
                        <td class="px-1" :colspan="headers.length" v-if="item.docShow">
                            <div class="mt-4">
                                <v-flex class="mt-1">
                                    <template>      
                                        <v-row>
                                            <v-col align="right" class="mb-0 mt-0 mr-10 closediv pb-3">
                                                <span @click="closeHistory(emailData,item)" >CLOSE</span>
                                            </v-col>
                                        </v-row>                                          
                                        <!-- <v-simple-table class="vDataTableStyling docborder mx-10 mb-8 mt-0" hide-default-footer>
                                            <template>
                                                <thead>                
                                                    <tr>
                                                        <th class="text-center" width="10%">Action</th>
                                                        <th class="text-center" width="8%">Event</th>
                                                        <th class="text-center" width="15%">To List</th>
                                                        <th class="text-center" width="6%">Cc List</th>
                                                        <th class="text-center" width="6%">Bcc List</th>
                                                        <th class="text-center" width="10%">Sent Date and Time</th>
                                                    </tr>
                                                </thead>
                                                <tbody class="mt-2">                       
                                                    <tr v-for="(item, Rindex) in item.audits_email" :key="'CR'+ Rindex">
                                                        <td><span class="stopbreaking text-center textcolor font-weigh-bold pointerstyle" @click="viewTrack(item._id)">View Tracking</span></td>
                                                        <td><span class="stopbreaking text-center textcolor font-weigh-bold pointerstyle" @click="modifyAction(item, 0 ,Rindex)">{{item.eventName == '' ? '------' : item.eventName.split('-')[2]}}</span></td>
                                                        <td xs3><span class="text-center">{{item.to == '' ? '------' : semiColonAddedEmails(item.to)}}</span></td>
                                                        <td xs3><span class="text-center">{{item.cc == '' ? '------' : semiColonAddedEmails(item.cc)}}</span></td>
                                                        <td xs3><span class="text-center">{{item.bcc == '' ? '------' : semiColonAddedEmails(item.bcc)}}</span></td>
                                                        <td><span class="stopbreaking text-center">{{item.dateTime == '' ? '------' : dateFormat(item.dateTime)}}</span></td>
                                                    </tr>
                                                    <tr class="v-data-table__empty-wrapper" v-if="item.audits_email.length === 0">
                                                        <td colspan="12">No Records For History</td>
                                                    </tr>
                                                </tbody>
                                            </template>
                                        </v-simple-table> -->
                                        <v-simple-table
                                            class="vDataTableStyling docborder mx-10 mb-8 mt-0"
                                            hide-default-footer
                                            >
                                            <template>
                        <div class="table-responsive">
                            <table class="table table-bordered">
                            <!-- Table Code - Himanshu Start-->
                            <thead>
                                <tr>
                                <th class="text-center" style="width: 10%;">Action</th>
                                <th class="text-center" style="width: 10%;">Event</th>
                                <th class="text-center" style="width: 20%;">To List</th>
                                <th class="text-center" style="width: 20%;">Cc List</th>
                                <th class="text-center" style="width: 20%;">Bcc List</th>
                                <th class="text-center" style="width: 20%;">Sent Date and Time</th>
                                </tr>
                            </thead>
                            <!-- Table Code - Himanshu End-->
                            <tbody>
                                <tr v-for="(item, Rindex) in item.audits_email" :key="'CR' + Rindex">
                                <td>
                                    <span
                                    class="stopbreaking text-center textcolor font-weigh-bold pointerstyle"
                                    @click="viewTrack(item._id)"
                                    >View Tracking</span>
                                </td>
                                <td>
                                    <span
                                    class="stopbreaking text-center textcolor font-weigh-bold pointerstyle"
                                    @click="modifyAction(item, 0, Rindex)"
                                    >{{
                                    item.eventName == "" ? "------" : item.eventName.split("-")[2]
                                    }}</span>
                                </td>
                                <td>
                                    <div class="stopbreaking text-center py-2" style="max-width: 100%; overflow-x: auto">
                                    {{ item.to == "" ? "------" : semiColonAddedEmails(item.to) }}
                                    </div>
                                </td>
                                <td>
                                    <div class="stopbreaking text-center py-2" style="max-width: 100%; overflow-x: auto">
                                    {{ item.cc == "" ? "------" : semiColonAddedEmails(item.cc) }}
                                    </div>
                                </td>
                                <td>
                                    <div class="stopbreaking text-center py-2" style="max-width: 100%; overflow-x: auto">
                                    {{ item.bcc == "" ? "------" : semiColonAddedEmails(item.bcc) }}
                                    </div>
                                </td>
                                <td>
                                    <span class="stopbreaking text-center">{{
                                    item.dateTime == "" ? "------" : dateFormat(item.dateTime)
                                    }}</span>
                                </td>
                                </tr>
                                <tr class="v-data-table__empty-wrapper" v-if="item.audits_email.length === 0">
                                <td colspan="6">No Records For History</td>
                                </tr>
                            </tbody>
                            </table>
                        </div>
                        </template>
                    </v-simple-table>
                                        <!-- <div class="testInnerTable">
                                        <v-data-table
                                            :headers="viewTrackingInnerTableHeaders"
                                            :items="item.audits_email"
                                            hide-default-footer
                                            hide-default-header
                                            class="phaseTwoVDailogBoxStyling mb-3 mx-4"
                                        >
                                            <template v-slot:header="{ props }">
                                            <th v-for="head in props.headers" :key="head.text"  class="inner_table_color">{{ head.text }}</th>
                                            </template>
                                            
                                            <template v-slot:[`item.select`]="{item}">
                                                <span class="stopbreaking text-center textcolor font-weigh-bold pointerstyle" @click="viewTrack(item._id)">View Tracking</span>
                                            </template>

                                            <template v-slot:[`item.eventName`]="{item,index}">
                                                <span class="stopbreaking text-center textcolor font-weigh-bold pointerstyle" @click="modifyAction(item, 0 ,index)">{{item.eventName == '' ? '------' : item.eventName.split('-')[2]}}</span>
                                            </template>

                                            <template v-for="header in viewTrackingInnerTableHeaders.filter((header) => header.hasOwnProperty('formatter'))" v-slot:[`item.${header.value}`]="{  value }">
                                                {{ header.formatter(value) }}
                                            </template>                                           
                                        </v-data-table>
                                        </div> -->
                                    </template>
                                </v-flex>
                            </div>
                        </td>
                    </template>
                    <template v-slot:[`item.receiverType`]="{}">
                        <span > {{communicationType}}</span>

                    </template>
                </v-data-table>
                <div class="text-center graybackground pdct-stUp-bnkAcc-pgn mt-3 mx-4" v-if="emailData.length != 0">
                    <v-layout justify-end>
                            <div style="margin-top: 15px">
                            <span class="orangetext paginationText">
                                Showing {{ totalListCount ? pageStartIndex + 1 : 0 }} - {{ pageEndIndex }}
                            </span>
                            <span class=" mr-2 paginationText"> out of {{ totalListCount }} records </span>
                            </div>
                            <!-- @input="goNextPagePagination" -->
                            <v-pagination
                                v-model="pageNumber"
                                :length="totalPages"
                                :total-visible="pageRowCount" 
                                @input="getCommunicationDetail('email')"
                                color="#152F38"
                                class="navpagenumber pt-1"
                            ></v-pagination>
                    </v-layout>
                    <br />
                </div>
            </template>
            <!-- SMS Table -->
            <template v-if="smsShw">
                <v-data-table
                    :headers="smsHeaders"
                    :items="smsData"
                    hide-default-footer
                    disable-pagination
                    :expanded.sync="expanded" 
                    :single-expand="singleExpand"
                    :no-data-text="smsData.length == 0 ? 'No Records for SMS' : ''"
                    class="vDataTableStyling mt-0 tableborder summarystyle">
                    <template v-slot:[`item.select`]="{ item, index }">
                        <div>
                            <v-menu
                                bottom
                                offset-y
                                rounded="0"
                                nudge-left="0">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon class="mr-2 menusize" v-bind="attrs" v-on="on">mdi-dots-vertical</v-icon>
                                </template>
                                <v-list>
                                    <v-list-item
                                        style="min-height: 28px !important" 
                                        v-for="(item1, index1) in smsMenuItems"
                                        :key="index1"
                                        dense
                                        :color="item1.color"
                                        class="ma-0 pa-0"
                                        @click="modifyAction(item, index1, index)">
                                        <v-icon small class="pa-2 pt-2 iconsize" v-text="item1.icon" :style="item1.iconColor"></v-icon>
                                        <v-divider vertical class="mx-1"></v-divider>
                                        <v-list-item-title class="pa-1 pr-3 ma-0 mr-0" v-text="item1.title" :style="item1.color" :class="item1.name" style="font-size: 12px !important"></v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </div>
                    </template>
                    <template v-slot:[`item.eventName`]="{ item }">
                        <span>{{ item.eventName.split('-')[2] }}</span>
                    </template>
                    <template v-slot:[`item.dateTime`]="{ item }">
                        <span>{{item.dateTime == "" ? "------" : dateFormat(item.dateTime)}}</span>
                    </template>
                    <template v-slot:expanded-item="{ headers, item}" :scope="props">
                        <td class="px-1" :colspan="headers.length" v-if="item.docShow">
                            <div class="mt-4">
                                <v-flex>
                                    <template>  
                                        <v-row>
                                            <v-col align="right" class="mb-0 mt-0 mr-10 closediv pb-3">
                                                <span @click="closeHistory(smsData,item)" >CLOSE</span>
                                            </v-col>
                                        </v-row>                                                
                                        <v-simple-table class="vDataTableStyling docborder mx-10 mb-8 mt-0" hide-default-footer>
                                            <template>
                                                <thead>                
                                                    <tr>
                                                        <!-- <th class="text-center" width="8%">Action</th> -->
                                                        <th class="text-center" width="8%">Event</th>
                                                        <th class="text-center" width="15%">Mobile Number</th>
                                                        <th class="text-center" width="10%">Sent Date and Time</th>
                                                    </tr>
                                                </thead>
                                                <tbody class="mt-2">                       
                                                    <tr v-for="(item, Rindex) in item.audits_sms" :key="'CR'+ Rindex">
                                                        <td><span class="stopbreaking text-center textcolor font-weigh-bold pointerstyle" @click="modifyAction(item, 0, Rindex)">{{item.eventName == '' ? '------' : item.eventName.split('-')[2]}}</span></td>
                                                        <td><span class="stopbreaking text-center">{{item.mobileNo.length == 0 ? '------' : item.mobileNo}}</span></td>
                                                        <td><span class="stopbreaking text-center">{{item.dateTime == '' ? '------' : dateFormat(item.dateTime)}}</span></td>
                                                    </tr>
                                                    <tr class="v-data-table__empty-wrapper" v-if="item.audits_sms.length === 0">
                                                        <td colspan="12">No Records For History</td>
                                                    </tr>
                                                </tbody>
                                            </template>
                                        </v-simple-table>
                                    </template>
                                </v-flex>
                            </div>
                        </td>
                    </template>
                </v-data-table>
            </template>
            <!-- Search Dialog  -->
            <v-row justify="center">
                <v-dialog v-model="searchDialog" persistent max-width="65%" style="border-radius:0">
                    <v-card>
                        <v-card-title class="pl-0 px-0 py-0 dailogboxAllvcardTableBackground">
                            <v-row class="colorbg">
                                <span class="descrptionSpan mb-2 ml-8 mr-5">Search</span>
                                <v-icon @click="searchDialog = false" class="ml-2 mr-2 mt-2" color="#fff">mdi-window-close</v-icon>
                            </v-row>
                            <v-card-text class="vcardTextTableBackground pl-2">
                                <v-form ref="searchcommunicationref" @submit.prevent="searchCommunication">
                                    <v-row no-gutters>
                                        <v-col class="ml-3 mr-3">
                                            <p class="claimlabel mb-2">Communication Type </p>
                                            <v-select
                                                label="Select"
                                                v-model="communicationType"
                                                :items="communicationTypeOptions"
                                                @change="changeCommunicationType"
                                                solo
                                                dense
                                                class="forminput"
                                            ></v-select>
                                        </v-col>
                                        <v-col class="mr-3">
                                            <p class="claimlabel mb-2">LOB </p>
                                            <v-select
                                                label="Select"
                                                v-model="lob"
                                                :items="computedLobOptions"
                                                solo
                                                dense
                                                :disabled="communicationType == 'Common Module'"
                                                :class="communicationType == 'Common Module' ? 'forminput textDisable font-weight-bold' : 'forminput'"
                                                :background-color="communicationType == 'Common Module' ? '#e5e5e5' : '$fffff'"
                                            ></v-select>
                                        </v-col>
                                        <v-col class="mr-3" v-if="communicationType == 'Common Module'">
                                            <p class="claimlabel mb-2">Ntid/User Id <span class="mandatorycolor">*</span></p>
                                            <v-text-field
                                                placeholder="Enter"
                                                v-model="ntidUserId"
                                                :rules="[mandatoryrule()]"
                                                solo
                                                dense
                                                class="forminput"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col class="mr-3" v-else>
                                            <p class="claimlabel mb-2">Claim Number <span class="mandatorycolor">*</span></p>
                                            <v-text-field
                                                placeholder="Enter"
                                                v-model="claimNumber"
                                                :rules="[mandatoryrule(),numeric(claimNumber)]"
                                                :maxlength="10"
                                                solo
                                                dense
                                                class="forminput"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row no-gutters v-if="communicationType == 'Claim'">
                                        <v-col class="ml-3 mr-3">
                                            <p class="claimlabel mb-2">Feature Number <span class="mandatorycolor">*</span></p>
                                            <v-text-field
                                                placeholder="Enter"
                                                v-model="featureNumber"
                                                :rules="[mandatoryrule(),spclCharNot()]"
                                                solo
                                                dense
                                                class="forminput"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col class="mr-3"></v-col>
                                        <v-col class="mr-3"></v-col>
                                    </v-row>
                                    <v-row no-gutters>
                                        <v-col align="right">
                                            <v-btn outlined color="#D1121B" class="btnstyle mr-3" @click="searchDialog = false">
                                                <v-icon class="mr-2 iconsize">mdi-close-box-outline</v-icon>
                                                <div class="vertical_line"></div>Cancel
                                            </v-btn>
                                            <v-btn outlined color="#D1121B" class="btnstyle mr-3" @click="resetSearchDialoge">
                                                <v-icon class="mr-2 iconsize">mdi-close-box-outline</v-icon>
                                                <div class="vertical_line"></div>Reset
                                            </v-btn>
                                            <v-btn color="#23B1A9"  dark class="btnstyle" type="submit">
                                                <v-icon class="mr-2 iconsize">mdi-magnify</v-icon>
                                                <div class="vertical_line"></div>Search
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-form>
                            </v-card-text>
                        </v-card-title>
                    </v-card>
                </v-dialog>
            </v-row>
            <!-- Email dialog  -->
            <v-row justify="center">
                <v-dialog v-model="emailDialog" persistent max-width="65%" style="border-radius:0">
                    <v-card>
                        <v-card-title class="pl-0 px-0 py-0 dailogboxAllvcardTableBackground">
                            <v-row class="colorbg">
                                <span class="descrptionSpan mb-2 ml-8 mr-5">{{isView ? 'View Email Communication' : 'Resend Email Communication'}}</span>
                                <v-icon @click="emailDialog = false" class="ml-2 mr-2 mt-2" color="#fff">mdi-window-close</v-icon>
                            </v-row>
                            <v-card-text class="vcardTextTableBackground pl-2">
                                <div>
                                    <!-- ****** Himanshu - Code Start ****** -->
                                    <div class="common-search-field white d-flex align-baseline my-4">
                                        <span class="font-weight-bold textstyle ml-4 mr-2">To</span>
                                        <v-text-field
                                        :placeholder="isView ? '' : 'Enter space seperated email'"
                                        v-model="sendToUser"
                                        solo
                                        dense
                                        @keyup="checkwhiteSpace(sendToUser, 'to')"
                                        :disabled="isView"
                                        :class="isView ? 'textDisable font-weight-bold' : ''"
                                        class="forminput textfieldsize"
                                        >
                                        <template v-slot:prepend-inner>
                                            <!-- <span class="pa-1 font-weight-bold textstyle">To</span> -->
                                            <div class="d-flex flex-wrap ml-1">
                                            <div
                                            v-for="(chipText, index) in sendToUserData"
                                            :key="index"
                                            class="ml-1 my-1"
                                            >
                                            <v-chip class="text-truncate" style="max-width: ">
                                                {{ chipText }}
                                                <v-icon
                                                small
                                                dark
                                                color="#000000"
                                                @click="deleteChip(index, 'to')"
                                                v-if="!isView"
                                                >
                                                mdi-close
                                                </v-icon>
                                            </v-chip>
                                            </div>
                                            </div>
                                        </template>
                                        </v-text-field>
                                    </div>
                                    <div class="common-search-field white d-flex align-baseline my-4">
                                        <span class="font-weight-bold textstyle ml-4 mr-2">Cc</span>
                                        <v-text-field
                                        :placeholder="isView ? '' : 'Enter space seperated email'"
                                        v-model="sendCcUser"
                                        solo
                                        dense
                                        @keyup="checkwhiteSpace(sendCcUser, 'cc')"
                                        :disabled="isView"
                                        :class="isView ? 'textDisable font-weight-bold' : ''"
                                        class="forminput textfieldsize"
                                        >
                                        <template v-slot:prepend-inner class="align-baseline">
                                            <!-- <span class="font-weight-bold textstyle mx-4">Cc</span> -->
                                            <div class="d-flex flex-wrap ml-1">
                                            <div
                                                v-for="(chipText, index) in sendCcUserData"
                                                :key="index"
                                                class="ml-1 my-1"
                                            >
                                                <v-chip>
                                                {{ chipText }}
                                                <v-icon
                                                    small
                                                    dark
                                                    color="#000000"
                                                    @click="deleteChip(index, 'cc')"
                                                    v-if="!isView"
                                                >
                                                    mdi-close
                                                </v-icon>
                                                </v-chip>
                                            </div>
                                            </div>
                                        </template>
                                        </v-text-field>
                                    </div>

                                    <div class="common-search-field white d-flex align-baseline my-4">
                                        <span class="font-weight-bold textstyle ml-4 mr-2">Bcc</span>
                                        <v-text-field
                                        :placeholder="isView ? '' : 'Enter space seperated email'"
                                        v-model="sendBccUser"
                                        solo
                                        dense
                                        @keyup="checkwhiteSpace(sendBccUser, 'bcc')"
                                        :disabled="isView"
                                        :class="isView ? 'textDisable font-weight-bold' : ''"
                                        class="forminput textfieldsize"
                                        >
                                        <template v-slot:prepend-inner class="align-baseline">
                                            <!-- <span class="font-weight-bold textstyle ml-4">Bcc</span> -->
                                            <div class="d-flex flex-wrap ml-1">
                                            <div
                                            v-for="(chipText, index) in sendBccUserData"
                                            :key="index"
                                            class="ml-1 my-1"
                                            >
                                            <v-chip>
                                                {{ chipText }}
                                                <v-icon
                                                small
                                                dark
                                                color="#000000"
                                                @click="deleteChip(index, 'bcc')"
                                                v-if="!isView"
                                                >
                                                mdi-close
                                                </v-icon>
                                            </v-chip>
                                            </div>
                                            </div>
                                        </template>
                                        </v-text-field>
                                    </div>
                                    <div class="common-search-field white d-flex align-baseline my-4">
                                        <span class="font-weight-bold textstyle ml-4 mr-2">Subject</span>
                                        <v-text-field
                                        :placeholder="isView ? '' : 'Enter'"
                                        v-model="emailSubject"
                                        solo
                                        dense
                                        :disabled="isView"
                                        :class="isView ? 'textDisable font-weight-bold' : ''"
                                        class="forminput"
                                    >
                                        <template v-slot:prepend-inner>
                                        <!-- <span class="pa-1 font-weight-bold">Subject</span> -->
                                        </template>
                                    </v-text-field>
                                    </div>
                                    <!-- ****** Himanshu - Code End ****** -->
                                    <div v-html="this.emailContent"></div>
                                    <p class="font-weight-bold ma-3">Attachments</p>
                                    <v-row v-if="documents.length > 0" no-gutters>
                                        <v-col v-for="(docs , index) in documents" :key="index" class="ml-5">
                                            <a class="textcolor font-weight-bold" @click="viewDocument(docs)">
                                                {{docs.fileName}}
                                            </a>
                                        </v-col>
                                    </v-row>
                                    <v-row v-else class="claimlabel ml-5">
                                        <p>No Attachments Available</p>
                                    </v-row>
                                </div>
                                <v-row v-if="!isView" class="mt-3">
                                    <v-col align='right'>
                                        <v-btn outlined color="#000000" dark class="buttonscolor mr-3" @click="cancel()">Cancel</v-btn>
                                        <v-btn color="#E46A25" dark class="buttonscolor" @click="reSend()">Re-Send</v-btn>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card-title>
                    </v-card>
                </v-dialog>
            </v-row>
            <!-- SMS dialog  -->
            <v-row justify="center">
                <v-dialog v-model="smsDialog" persistent max-width="35%" style="border-radius:0">
                    <v-card>
                        <v-card-title class="pl-0 px-0 py-0 dailogboxAllvcardTableBackground">
                            <v-row class="colorbg">
                                <span class="smsdescrptionSpan mb-2 ml-8 mr-5">{{isView ? 'View SMS Communication' : 'Resend SMS Communication'}}</span>
                                <v-icon @click="smsDialog = false" class="ml-2 mr-2 mt-2" color="#fff">mdi-window-close</v-icon>
                            </v-row>
                            <v-card-text class="vcardTextTableBackground pl-2">
                                <div>
                                    <div style="overflow-x: scroll">
                                        <v-text-field
                                            :placeholder="isView ? '' : 'Enter mobile number'"
                                            v-model="sendToUserSms"
                                            solo
                                            dense
                                            @keyup="checkwhiteSpace(sendToUserSms, 'sms')"
                                            type="number"
                                            :disabled="isView"
                                            :class="isView ? 'textDisable font-weight-bold' : ''"
                                            class="forminput textfieldsize">
                                            <template v-slot:prepend-inner>
                                                <span class="pa-1 font-weight-bold textstyle">To</span>
                                                <div v-for="(chipText , index) in sendToUserSmsData" :key="index" class="ml-1">
                                                    <v-chip>
                                                        {{chipText}}
                                                        <v-icon small dark color="#000000" @click="deleteChip(index, 'sms')" v-if="!isView">
                                                            mdi-close
                                                        </v-icon>
                                                    </v-chip>
                                                    
                                                </div>
                                            </template>
                                        </v-text-field>
                                    </div>
                                    <div class="bgcolor pa-2">
                                        <p v-html="this.smsBody" class="ma-2 stopbreaking"></p>
                                    </div>
                                </div>
                                <v-row v-if="!isView" class="mt-3">
                                    <v-col align='right'>
                                        <v-btn outlined color="#000000" dark class="buttonscolor mr-3" @click="cancel()">Cancel</v-btn>
                                        <v-btn color="#E46A25" dark class="buttonscolor" @click="reSend()">Re-Send</v-btn>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card-title>
                    </v-card>
                </v-dialog>
            </v-row>
            <!-- View tracking dialog  -->
            <v-row justify="center">
                <v-dialog v-model="showTracking" persistent max-width="65%" style="border-radius:0">
                    <v-card>
                        <v-card-title class="pl-0 px-0 py-0 dailogboxAllvcardTableBackground">
                            <v-row class="colorbg">
                                <span class="descrptionSpan mb-2 ml-8 mr-5">View Tracking</span>
                                <v-icon @click="showTracking = false" class="ml-2 mr-2 mt-2" color="#fff">mdi-window-close</v-icon>
                            </v-row>
                            <v-card-text class="vcardTextTableBackground pl-2">
                                <v-data-table
                                    :headers="trackingHeaders"
                                    :items="trackingData"
                                    hide-default-footer
                                    disable-pagination
                                    :no-data-text="smsData.length == 0 ? 'No Records for Tracking' : ''"
                                    class="vDataTableStyling mt-0 tableborder">
                                        <template v-slot:[`item.bounce`]="{ item }">
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <span>{{ item.bounce }}
                                                        <span v-if="item.bounceReason">
                                                        <v-icon small v-bind="attrs" v-on="on">
                                                            mdi-information
                                                        </v-icon>
                                                        </span>
                                                    </span>
                                                </template>
                                                <span>{{item.bounceReason}}</span>
                                            </v-tooltip>
                                        </template>
                                </v-data-table>
                            </v-card-text>
                        </v-card-title>
                    </v-card>
                </v-dialog>
            </v-row>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                showCoverageTooltip: [],
                showCoverageTooltip1: [],
                showCoverageTooltip2: [],
                showInnerCoverageTooltip: [],
                showInnerCoverageTooltip1: [],
                showInnerCoverageTooltip2: [],
                searchDialog: false,
                communicationTypeOptions: ['Common Module', 'Claim'],
                lobOptions: [],
                communicationType: 'Common Module',
                lob: 'CM',
                ntidUserId: '',
                claimNumber: '',
                featureNumber: '',
                communicatorType: 'Repairer',
                repairer: true,
                vendor: false,
                examiner: false,
                emailShw: true,
                smsShw: false,
                systemShw:true,
                emailDialog: false,
                smsDialog: false,
                // Email Table data
                emailHeaders: [
                    {text: "Action",align: "start",value: "select",sortable: false, width: '7%'},
                    {text: "LOB",align: "start",value: "lob",sortable: false, width: '10%'},
                    {text: "NTID/User ID",align: "start",value: "ntidUserId",sortable: false, width: '10%'},
                    {text: "Event",align: "start",value: "eventName",sortable: false, width: '15%'},
                    {text: "Event Type",align: "start",value: "eventType",sortable: false },
                    {text: "From",align: "start",value: "from",sortable: false, width: '20%'},
                    {text: "To List",align: "start",value: "to",sortable: false, width: '20%'},
                    {text: "Cc List",align: "start",value: "cc",sortable: false, width: '20%'},
                    {text: "Bcc List",align: "start",value: "bcc",sortable: false, width: '20%'},
                    {text: "Communication Type",align: "start",value: "receiverType",sortable: false, width: '20%'},
                    {text: "Sent Date and Time",align: "start",value: "dateTime",sortable: false, width: '23%'},
                ],
                claimHeaders: [
                    {text: "Action",align: "start",value: "select",sortable: false, },
                    {text: "LOB",align: "start",value: "lob",sortable: false, },
                    {text: "Claim Number",align: "start",value: "claimNumber",sortable: false, },
                    {text: "Feature Number",align: "start",value: "featureNumber",sortable: false, },
                    {text: "Event",align: "start",value: "eventName",sortable: false, },
                    {text: "Event Type",align: "start",value: "eventType",sortable: false },
                    {text: "From",align: "start",value: "from",sortable: false, width: '20%'},
                    {text: "To List",align: "start",value: "to",sortable: false,},
                    {text: "Cc List",align: "start",value: "cc",sortable: false, },
                    {text: "Bcc List",align: "start",value: "bcc",sortable: false, },
                    {text: "Communication Type",align: "start",value: "receiverType",sortable: false, },
                    {text: "Sent Date and Time",align: "start",value: "dateTime",sortable: false, },
                ],
                viewTrackingInnerTableHeaders:  [
                    {text: "Action",align: "start",value: "select",sortable: false,width:"15%" },
                    {text: "Event",align: "start",value: "eventName",sortable: false, width:"10%"},
                    {text: "To List",align: "start",value: "to",sortable: false,formatter:this.semiColonAddedEmails,class:"clmwidth"},
                    {text: "Cc List",align: "start",value: "cc",sortable: false,formatter:this.semiColonAddedEmails,class:"clmwidth" },
                    {text: "Bcc List",align: "start",value: "bcc",sortable: false,formatter:this.semiColonAddedEmails,class:"clmwidth" },
                    // {text: "Communication Type",align: "start",value: "type",sortable: false, },
                    {text: "Sent Date and Time",align: "start",value: "dateTime",sortable: false,formatter:this.dateFormat,width:"10%" },
                ],
                emailData: [],
                menuItems: [
                    { title: 'VIEW', icon: 'mdi-eye', color: 'color:#10242B',iconColor: "color:#10242B" },
                    { title: 'RESEND', icon: 'mdi-send', color: 'color:#23b1a9',iconColor: "color:#23b1a9" },
                    { title: 'RESENT HISTORY', icon: 'mdi-eye', color: 'color:#23b1a9',iconColor: "color:#23b1a9" },
                    { title: 'VIEW TRACKING', icon: 'mdi-eye', color: 'color:orange',iconColor: "color:orange" },
                ],
                smsMenuItems: [
                    { title: 'VIEW', icon: 'mdi-eye', color: 'color:#10242B',iconColor: "color:#10242B" },
                    { title: 'RESEND', icon: 'mdi-send', color: 'color:#23b1a9',iconColor: "color:#23b1a9" },
                    { title: 'RESENT HISTORY', icon: 'mdi-eye', color: 'color:#23b1a9',iconColor: "color:#23b1a9" },
                ],
                // SMS table data 
                smsHeaders: [
                    {text: "Action",align: "center",value: "select",sortable: false, width: '7%'},
                    {text: "Event",align: "center",value: "eventName",sortable: false, width: '20%'},
                    {text: "Mobile Number",align: "center",value: "mobileNo",sortable: false, width: '20%'},
                    {text: "Sent Date and Time",align: "center",value: "dateTime",sortable: false, width: '13%'},
                ],
                smsData: [],
                // RULES 
                validationmessage: "This field is required",
                mandatoryrule() {
                    return (v) => !!v || this.validationmessage;
                },
                numeric(v) {
                    if (v && !(/^[0-9]+$/.test(v))) {
                            return 'Only Numbers are allowed'
                        } else {
                            return true;
                    }
                },
                spclCharNot(v) {
                    return (v) => !!(/^[A-Za-z]+$/.test(v))  || "Only alphabets allowed";                    
                },
                // Send email data
                sendToUser: '',
                sendCcUser: '',
                sendBccUser: '',
                sendToUserData: [],
                sendCcUserData: [],
                sendBccUserData: [],
                emailSubject: '',
                emailContent: '',
                sendToUserSms: '',
                sendToUserSmsData: [],
                isView: false,
                smsBody: '',
                id: '',
                expanded: [],
                singleExpand:true,
                doc_history:true,
                documents: [],
                showTracking: false,
                trackingHeaders: [
                    {text: "Recipient",align: "center",value: "email",sortable: false,},
                    {text: "Sent",align: "center",value: "send",sortable: false,},
                    {text: "Delivery",align: "center",value: "delivery",sortable: false,},
                    {text: "Bounce",align: "center",value: "bounce",sortable: false,},
                    {text: "Others",align: "center",value: "others",sortable: false,}
                ],
                trackingData: [],
                // Pagination
                pageNumber: 1,
                pageStartIndex: 0,
                pageRowCount: 10,
                totalListCount: 0,
                totalPages:null,
            }
        },

        created() {
            // this.getCommunicationDetail('email', true);
            this.getLOB();
            this.$store.commit("SAVE_PAGE_HEADER", "Common Communication");
        },

        computed: {
            pageEndIndex() {
                return this.pageStartIndex + this.pageRowCount <= this.totalListCount? this.pageStartIndex + this.pageRowCount: this.totalListCount;           
            },
            computedLobOptions() {
                console.log("communicationType = ",this.communicationType,this.lobOptions)
                let lobOptionsData  =   [...this.lobOptions]
                if(this.communicationType == "Claim"){
                    lobOptionsData.splice(this.lobOptions.findIndex((elem)=>{return elem == "CM"}),1)
                }
                return  lobOptionsData
            }
        },

        methods: {
            changeCommunicationType() {
                this.lob = this.communicationType == 'Common Module' ? 'CM' : '';
                this.ntidUserId = '';
                this.claimNumber = '';
                this.featureNumber = '';
            },

            searchCommunication() {
                if(this.$refs.searchcommunicationref.validate()) {
                    let mode = this.emailShw ? 'email' : 'sms';
                    this.getCommunicationDetail(mode);
                } else {
                    this.showAlert('Please enter valid mandatory fields');
                }
            },
            getLOB() {
                let reqData = '';
                this.showProgress();
                this.GETM("getLOB", reqData, (res) => {
                    this.hideProgress();
                    if(res.data.statusCode === 200) {
                        this.lobOptions = res.data.data[0].lineOfBusiness;
                    } else {
                        this.lobOptions = [];
                        this.showAlert(res.data.msg);
                    }
                }, (error) => {
                    console.log(error);
                    this.hideProgress();
                }, true)
            },

            openSearchDialog() {
                this.searchDialog = true;
            },

            resetSearchDialoge() {
                this.showConfirm("Confirmation", `Are you sure you want to reset?`, "Yes", "No", () => {
                    this.communicationType = '';
                    this.lob = '';
                    this.ntidUserId = '';
                    this.claimNumber = '';
                    this.featureNumber = '';
                    this.$refs.searchcommunicationref.resetValidation();
                    this.emailData  =   [];
                    this.smsData    =   [];
                    this.trackingData   =   [];
                }, () => {})
            },
            openHistory(item, index) {
                this.expanded = []
                this.expanded = item === this.expanded[index] ? [] : [item];
                let mode = '';
                if(this.emailShw) {
                    mode = 'email';
                }
                if(this.smsShw) {
                    mode = 'sms';
                }
                this.getCommunicationDetailHelper(mode, item._id, index);
            },
            closeHistory(data, item) {
                for(let i = 0; i < data.length; i++) {
                    if(data[i] === item) {
                        this.$set(data[i], 'docShow', false);
                    }
                }
            },

            getCommunicationDetailHelper(type, id, index) {
                let reqData = `parentId=${id}`;
                // if(this.lob) {
                //     reqData += `&lob=${this.lob}`
                // }
                // if(this.claimNumber) {
                //     reqData += `&claimNumber=${this.claimNumber}`
                // }
                // if(this.featureNumber) {
                //     reqData += `&featureNumber=${this.featureNumber}`
                // }
                // if(this.ntidUserId) {
                //     reqData += `&ntidUserId=${this.ntidUserId}`
                // }
                this.showProgress("Fetching... Please wait");
                this.GETM("getCommunications", reqData, (res) => {
                    this.hideProgress();
                    if(res.data.statusCode === 200 && res.data.data) {
                        if(this.emailShw) {
                            for(let i = 0; i < this.emailData.length; i++) {
                                // this.emailData[i].docShow = false;
                                if(i === index) {
                                    this.$set(this.emailData[i], 'docShow', true);
                                    this.$set(this.emailData[i], 'audits_email', res.data.data.data);
                                }
                            }
                        } else if(this.smsShw) {
                            for(let i = 0; i < this.smsData.length; i++) {
                                this.smsData[i].docShow = false;
                                if(i === index) {
                                    this.$set(this.smsData[i], 'docShow', true);
                                    this.$set(this.smsData[i], 'audits_sms', res.data.data.data);
                                }
                            }
                        }
                    } else {
                        this.showAlert(res.data.msg);
                    }
                }, (error) => {
                    this.hideProgress();
                    if(error.response.data.msg){
                        this.showAlert(error.response.data.msg);
                    }else if(error.response.data.message.msg){
                        this.showAlert(error.response.data.message.msg);
                    }else if(error.response.data.message){
                        this.showAlert(error.response.data.message);
                    }else{
                        this.showAlert("Something went wrong");
                    }
                }, true)
            },

            checkwhiteSpace(v, type) {
                let regex = /\s/g;
                let emailExp =/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
                if(regex.test(v)) {
                    let trimmedStr = v.trim();
                    if(emailExp.test(trimmedStr)) {
                        if(type === 'to') {
                            this.sendToUserData.push(v);
                            this.sendToUser = "";
                        } else if(type === 'cc') {
                            this.sendCcUserData.push(v);
                            this.sendCcUser = "";
                        } else if(type === 'bcc') {
                            this.sendBccUserData.push(v);
                            this.sendBccUser = "";
                        }
                    } else {
                        this.showAlert("Please enter valid email address.");
                        if(type === 'to') {
                            this.sendToUser = "";
                        } else if(type === 'cc') {
                            this.sendCcUser = "";
                        } else if(type === 'bcc') {
                            this.sendBccUser = "";
                        }
                    }
                }
                if(type === 'sms' && v.length === 10) {
                    this.sendToUserSmsData.push(v);
                    this.sendToUserSms = '';
                }
            },

            deleteChip(index, type) {
                if(type === 'to') {
                    this.sendToUserData.splice(index, 1);
                } else if(type === 'cc') {
                    this.sendCcUserData.splice(index, 1);
                } else if(type === 'bcc') {
                    this.sendBccUserData.splice(index, 1);
                } else if(type === 'sms') {
                    this.sendToUserSmsData.splice(index, 1);
                }
            },

            email(v){
                let emailExp =/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
                if(v && !(emailExp.test(v))){
                    return "Email must be valid"
                }else{
                    return true;
                }
            },

            // checkCommunicatorType(type) {
            //     if(type === 'repairer') {
            //         this.repairer = true;
            //         this.vendor = false;
            //         this.examiner = false;
            //     } else if(type === 'vendor') {
            //          this.repairer = false;
            //         this.vendor = true;
            //         this.examiner = false;
            //     } else if(type === 'examiner') {
            //         this.repairer = false;
            //         this.vendor = false;
            //         this.examiner = true;
            //     }
            //     let mode = '';
            //     if(this.emailShw) {
            //         mode = 'email';
            //     }
            //     if(this.smsShw) {
            //         mode = 'sms';
            //     }
            //     this.getCommunicationDetail(mode);
            // },

            switchtab(item) {
                if(item === 'email') {
                    this.emailShw = true;
                    this.smsShw = false;
                } else {
                    this.emailShw = false;
                    this.smsShw = true;
                }
                this.getCommunicationDetail(item);
            },

            switchSystemManualTab(item) {
                
                if(item === 'system') {
                    this.systemShw = true;                    
                } else {
                    this.systemShw = false;
                }
                this.emailShw = true;
                this.smsShw = false;
                this.smsData = [];
                this.emailData = [];
                // this.switchtab('email')
            },

            addwhiteSpace(v, type) {
                let val = v.replace(/,/g, '') + ' ';
                let regex = /\s/g;
                let emailExp =/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
                if(regex.test(val)) {
                    let trimmedStr = val.trim();
                    if(emailExp.test(trimmedStr)) {
                        if(type === 'to') {
                            this.sendToUserData.push(val);
                            this.sendToUser = "";
                        } else if(type === 'cc') {
                            this.sendCcUserData.push(val);
                            this.sendCcUser = "";
                        } else if(type === 'bcc') {
                            this.sendBccUserData.push(val);
                            this.sendBccUser = "";
                        }
                    }
                }
            },

            modifyAction(item, index, idx) {
                this.id = item._id;
                this.documents = item.documents
                if(this.emailShw && [0, 1].includes(index)) {
                    this.sendToUserData = item.to.includes(',') ? item.to.split(',')[item.to.split(',').length - 1] === '' ?  item.to.split(',').slice(0, -1) : item.to.split(',') : item.to === '' ? [] : item.to.split();
                    this.sendCcUserData = item.cc.includes(',') ? item.cc.split(',')[item.cc.split(',').length - 1] === '' ? item.cc.split(',').slice(0, -1) : item.cc.split(',') : item.cc === '' ? [] : item.cc.split();
                    this.sendBccUserData = item.bcc.includes(',') ? item.bcc.split(',')[item.bcc.split(',').length -1] === '' ? item.bcc.split(',').slice(0, -1) : item.bcc.split(',') : item.bcc === '' ? [] : item.bcc.split();
                    // this.sendToUserData = [];
                    // this.sendCcUserData = [];
                    // this.sendBccUserData = [];
                    // this.sendToUser = item.to;
                    // this.sendCcUser = item.cc;
                    // this.sendBccUser = item.bcc;
                    this.emailSubject = item.subject;
                    this.emailContent = item.html || item.body;
                    this.emailDialog = true;
                    // this.addwhiteSpace(this.sendToUser, 'to');
                    // this.addwhiteSpace(this.sendCcUser, 'cc');
                    // this.addwhiteSpace(this.sendBccUser, 'bcc');
                }
                if(this.smsShw && [0, 1].includes(index)) {
                    this.sendToUserSmsData = item.mobileNo
                    this.smsBody = item.body;
                    this.smsDialog = true;
                }
                if(index === 0) {
                    this.isView = true;
                }
                if(index === 1) {
                    this.isView = false;
                }
                if(index === 2) {
                    this.openHistory(item, idx);
                }
                if(index === 3) {
                    this.viewTrack(item._id);
                }
            },

            getCommunicationDetail(type, flag=false) {
                let reqData = `type=${type}&pageNo=${this.pageNumber}&perPage=${this.pageRowCount}`;
                if(this.lob) {
                    reqData += `&lob=${this.lob}`
                }
                if(this.claimNumber) {
                    reqData += `&claimNumber=${this.claimNumber}`
                }
                if(this.featureNumber) {
                    reqData += `&featureNumber=${this.featureNumber}`
                }
                if(this.ntidUserId) {
                    reqData += `&ntidUserId=${this.ntidUserId}`//.toUpperCase()
                }
                // reqData +=  `&receiverType=${this.systemShw ? 'Cron' : 'Non Cron'}`
                this.showProgress("Fetching... Please wait");
                this.GETM("getCommunications", reqData, (res) => {
                    this.hideProgress();
                    if(res.data.statusCode === 200 && res.data.data) {
                        if(this.emailShw) {
                            this.emailData = res.data.data.data;
                            this.smsData = [];
                            this.emailData.map((ele, i)=>{
                                // ele.docShow = flag;
                                this.$set(ele, 'docShow', flag);
                                this.$set(ele, 'audits_email', []);
                                // ele.cc  =   ele.cc.split(",").join(";")
                                // ele.bcc  =   ele.bcc.split(",").join(";")
                                // ele.to  =   ele.to.split(",").join(";")
                            })
                        } else if(this.smsShw) {
                            this.smsData = res.data.data.data;
                            this.emailData = [];
                            this.smsData.map((ele, i)=>{
                                // ele.docShow = flag;
                                this.$set(ele, 'docShow', flag);
                                this.$set(ele, 'audits_sms', []);
                            })
                        }
                        this.pageRowCount = 10;
                        this.totalListCount = res.data.data.total;
                        this.totalPages = Math.ceil(res.data.data.total / this.pageRowCount);
                        this.pageStartIndex = this.pageNumber == 1 ? 0 : (this.pageNumber - 1) * this.pageRowCount;
                        this.searchDialog = false;
                    } else {
                        this.pageRowCount = 0;
                        this.totalListCount = 0;
                        this.totalPages = 0;
                        this.pageStartIndex =0;
                        this.emailData  =   []
                        this.showAlert(res.data.msg);
                    }
                }, (error) => {
                    this.hideProgress();
                    if(error.response.data.msg){
                        this.showAlert(error.response.data.msg);
                    }else if(error.response.data.message.msg){
                        this.showAlert(error.response.data.message.msg);
                    }else if(error.response.data.message){
                        this.showAlert(error.response.data.message);
                    }else{
                        this.showAlert("Something went wrong");
                    }
                }, true)
            },

            cancel() {
                if(this.emailDialog) {
                    this.emailDialog = false;
                }
                if(this.smsDialog) {
                    this.smsDialog = false;
                }
            },

            reSendHelper() {
                let reqBody = {};
                if(this.emailShw) {
                    if(this.sendToUserData.length !== 0) {
                        reqBody = {
                            to: this.sendToUserData.toString(),
                            cc: this.sendCcUserData.toString(),
                            bcc: this.sendBccUserData.toString(),
                            subject: this.emailSubject,
                            type: 'Email',
                            id: this.id
                        }
                    }
                }
                if(this.smsShw) {
                    if(this.sendToUserSmsData.length !== 0) {
                        reqBody = {
                            sendTo: this.sendToUserSmsData.toString(),
                            type: 'SMS',
                            id: this.id
                        }
                    }
                }
                if(Object.keys(reqBody).length != 0) {
                    this.showConfirm("Confirmation","Are you sure you want to re-send ?","Yes","No",(Yes) => {
                        this.showProgress('Re-Sending.. please wait');
                        this.POSTM("resendCommunication", reqBody, (res) => {
                            this.hideProgress();
                            if(res.data.statusCode === 200) {
                                this.cancel();
                                this.showAlert(res.data.msg);
                            } else {
                                this.showAlert(res.data.msg);
                            }
                        },(error) => {
                            this.hideProgress();
                            if(error.response.data.msg){
                                this.showAlert(error.response.data.msg);
                            }else if(error.response.data.message.msg){
                                this.showAlert(error.response.data.message.msg);
                            }else if(error.response.data.message){
                                this.showAlert(error.response.data.message);
                            }else{
                                this.showAlert("Something went wrong");
                            }
                        }, true, this.phaseFlag)
                    },(No) => {});
                } else {
                    this.showAlert("Please add recipient.");
                }
            },

            reSend() {
                let emailExp =/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
                if(this.emailShw) {
                    if(this.sendToUser == '' && this.sendCcUser == '' && this.sendBccUser == '') {
                        this.reSendHelper();
                    } else if((this.sendToUser != '' && emailExp.test(this.sendToUser)) && (this.sendCcUser != '' && emailExp.test(this.sendCcUser)) && (this.sendBccUser != '' && emailExp.test(this.sendBccUser))) {
                        this.reSendHelper();
                    } else {
                        this.showAlert('Please enter valid email address.');
                    }
                } else if(this.smsShw) {
                    if(this.sendToUserSms == '' || this.sendToUserSms.length == 10) {
                        this.reSendHelper();
                    } else {
                        this.showAlert('Please enter valid mobile number.');
                    }
                }
            },

            viewTrack(_id) {
                this.showProgress("Fetching... Please wait");
                this.GET_ByURLM("getTracking", _id, (res) => {
                    this.hideProgress();
                    if(res.data.statusCode === 200 && res.data.data) {
                        this.trackingData = res.data.data;
                        this.showTracking = true;
                    } else {
                        this.showAlert(res.data.msg);
                    }
                }, (error) => {
                    this.hideProgress();
                    if(error.response.data.msg){
                        this.showAlert(error.response.data.msg);
                    }else if(error.response.data.message.msg){
                        this.showAlert(error.response.data.message.msg);
                    }else if(error.response.data.message){
                        this.showAlert(error.response.data.message);
                    }else{
                        this.showAlert("Something went wrong");
                    }
                }, true)
            },

            viewDocument(itemData) {
                if(itemData && itemData.fileBase) {
                    this.openBase64(itemData.fileBase)
                } else {
                    let requestData = "";
                    if(itemData) {
                        let documentID = itemData.documentId ? itemData.documentId : itemData.doucumentId;
                        requestData = "documentId=" + documentID + "&b64=true";
                    }
                    this.GETM("VIEW_DOCUMENT", requestData, (res) => {
                        if (res.data.statusCode === 200) {
                            this.openBase64(res.data.data)
                        }
                    },(error) => {
                        console.log("Error", error);
                    });
                }
            },

            openBase64(base64) {
                const base64ImageData = base64
                const contentType = base64.split(':')[1].split(';')[0];
                const byteCharacters = window.atob(base64ImageData.substr(`data:${contentType};base64,`.length));
                const byteArrays = [];
                for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
                    const slice = byteCharacters.slice(offset, offset + 1024);
                    const byteNumbers = new Array(slice.length);
                for (let i = 0; i < slice.length; i++) {
                    byteNumbers[i] = slice.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                    byteArrays.push(byteArray);
                }
                const blob = new Blob(byteArrays, {type: contentType});
                const blobUrl =  window.URL.createObjectURL(blob);
                    window.open(blobUrl, '_blank');
            },

            semiColonAddedEmails(mailIds){
                let emailList   =   ""
                let emailArrray =   mailIds.split(",")

                for(var i in emailArrray){
                    if(i%3 == 0){
                        emailList   += emailArrray[i] ?  "\n" + emailArrray[i] + ";" : ""
                    }else{
                        emailList   += emailArrray[i] ? emailArrray[i] + ";" : ""
                    }
                    
                }

                return emailList
            }
        }
    }
</script>

<style scoped>
    .claimbtnstyle{
        letter-spacing: unset;
        color: white;
    }
    .activebg{
        color:white !important;
        background-color: #01BBD9 !important;
    }
    .inactivebg{
        color:black !important;
        background-color: white !important;
    }
    .headingtext{
        font-size: 14px;
    }
    .activeredbg{
        color: #01bbd9;
        border-bottom: 2px solid #01bbd9 !important;
        /* background-color: white !important; */
        font-weight: bold;
    }

    .borderline{
        border-bottom: 1px solid #efefef;
    }

    .theme--light.v-chip:focus::before{
        opacity: 0;
    }

    .inactivebg{
        color:#909699;
        /* background-color: white !important; */
    }
    .menutext{
        font-size: 15px;
        /* border: 0 !important; */
        background-color: white !important;
        letter-spacing: 0px;
        text-transform: capitalize;
        height: 33px !important;
        border-radius:0 !important
    } 
    .summarystyle {
        width: 60%;
    }
    .colorbg{
        background-color: #23b1a9;
        color:#fff;
    }
    .descrptionSpan {
        width: 85%;
        margin-left: 53px;
        margin-top: 21px;
        margin-bottom: 14px !important;
        font-weight: bold;
        font-size: 16px;
    }
    .smsdescrptionSpan {
        width: 75%;
        margin-left: 53px;
        margin-top: 21px;
        margin-bottom: 14px !important;
        font-weight: bold;
        font-size: 16px;
    }
    .textstyle{
        margin: auto;
        width: max-content;
    }
    .text-center{
        text-align: center;
        font-size: 11px !important;
    }
    .bgcolor{
        background-color: #e5e5e5 !important;
    }
    .textcolor{
        color: #1A0DAB;
        font-weight: 400;
    }
    .closediv{
        font-weight: 600;
        cursor: pointer;
        color:#23B1A9;
        font-size: 12px;
    }
    .pointerstyle{
        cursor: pointer;
    }
    .btncolor{
        color: #1A0DAB;
    }
    .orangetext {
        color: #e46a25;
    }
    .clmwidth {
        max-width: 280px;
        overflow: scroll;
    }
    .testInnerTable {
    /* height: 101px; */
        margin-left: 0px;
        width:100%;
        margin-top: 10px;
        /* margin-left: -20%; */
        background-color: #f7f7f7;
        padding: 10px;
    }
    .testInnerTable .vDataTableStyling tr th{
        width:100px;
    }
    /* ********** Himanshu *********** */
.common-search-field {
    border-radius: 5px;
}
.common-search-field ::v-deep .v-text-field > .v-input__control > .v-input__slot {
    display: flex;
    flex-wrap: wrap;
    max-height: 100px;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0;
    padding-left: 0;
    box-shadow: none !important;
    background-color:  #fff !important;
    border-radius: 5px;
    padding-top: 5px;
}

.common-search-field ::v-deep .v-text-field > .v-input__control > .v-input__slot::-webkit-scrollbar {
    width: 5px;
}

.common-search-field ::v-deep .v-text-field > .v-input__control > .v-input__slot::-webkit-scrollbar-thumb {
    background-color: #b1b1b1bd;
    border-radius: 3px;
}

.common-search-field ::v-deep .v-text-field > .v-input__control > .v-input__slot::-webkit-scrollbar-track {
    background-color: #f2f2f2;
    border-radius: 3px;
}

.common-search-field ::v-deep .v-text-field.v-text-field--enclosed .v-text-field__details {
  display: none;
}
.common-search-field ::v-deep .v-text-field > .v-input__control > .v-input__slot > .v-text-field__slot {
  left: 10px;
}
.common-search-field ::v-deep .v-text-field > .v-input__control > .v-input__slot .textstyle {
  position: sticky;
  top: 13px;
  background-color: #ffffff;
  z-index: 1;
}
</style>