<template>
<v-app>

<!-- for Logged in screen -->
        <div >
            <v-form>
                <v-layout style="background-color: #F7F7F7;border:0.5px solid #C1C8CC;" class=" px-4 py-2">
                    
                        <!-- <v-card class="ma-2 " tile style="height:50px;width:300px;background-color: aliceblue;border:1px solid #C1C8CC;">
                            <v-flex row class="pa-1"  style="justify-content:space-between;">
                                <v-text class="ml-4 mt-2 font-weight-bold cstm-clr-pd">
                                    NEARING TAT
                                </v-text>

                                <v-text class="font-weight-bold cstm-clr-pd mt-2" style="font-size: 16px !important;">
                                    24
                                </v-text>
                            </v-flex>
                        </v-card>

                        <v-card class="ma-2" tile style="height:90px;width:300px;background-color: antiquewhite;border:2px solid rgb(215 66 43 / 85%)">
                            
                            <v-flex row class="pa-1"  style="justify-content:space-between;">
                                <v-text class="ml-4 mt-2 font-weight-bold cstm-clr-pd">
                                    NEARING TAT
                                </v-text>

                                <v-text class="font-weight-bold cstm-clr-pd mt-2" style="font-size: 16px !important;">
                                    24
                                </v-text>
                            </v-flex>
                             <v-flex class="pa-1">
                                <v-text class="ma-4  font-weight-bold cstm-clr-pd">
                                    TOTAL
                                </v-text>

                                <v-divider class="mx-4 my-2"></v-divider>

                                <v-text class="font-weight-bold cstm-clr-pd ml-4" style="font-size: 16px !important;color:red !important">
                                    24
                                </v-text>
                            </v-flex> 

                        </v-card> -->

              

                <v-flex xs4 >
                    <v-card tile class=" mx-3 my-2 py-1" style="background-color:#F0FDFF ;border:0.5px solid #C1C8CC;">
                        <v-flex row class=" px-2" style="justify-content:space-between;">   
                            <v-text class="ml-3  font-weight-bold cstm-clr-pd" style="align-self: center;">
                                TOTAL WORKING ITEM
                            </v-text>

                            <v-text class="font-weight-bold cstm-clr-pd " style="font-size: 18px !important;color:#018FA6 !important;align-self: center;">
                                    {{totalwrkitm}}
                            </v-text>
                        </v-flex>
                    </v-card>
                </v-flex>

                <v-flex xs4 >
                    <v-card tile class=" mx-3 my-2 py-1" style="background-color: #FEF9F6;border:2px solid #E46A25;">
                        <v-flex row class=" px-2" style="justify-content:space-between;">   
                            <v-text class="ml-3 font-weight-bold cstm-clr-pd" style="align-self: center;">
                                NEARING TAT
                            </v-text>

                            <v-text class="font-weight-bold cstm-clr-pd" style="font-size: 18px !important;color:#E46A25 !important;align-self: center;">
                                    {{nearTAT}}
                            </v-text>
                        </v-flex>
                    </v-card>
                </v-flex>

                <v-flex xs4 >
                    <v-card tile class=" mx-3 my-2 py-1" style="background-color:#FFFFFF ;border:0.5px solid #C1C8CC;">
                        <v-flex row class=" px-2" style="justify-content:space-between;">   
                            <v-text class="ml-3 font-weight-bold cstm-clr-pd" style="align-self: center;">
                                EXCEEDED TAT
                            </v-text>

                            <v-text class="font-weight-bold cstm-clr-pd " style="font-size: 18px !important;color:#1E9690 !important;align-self: center;">
                                    {{exceededTAT}}
                            </v-text>
                        </v-flex>
                    </v-card>
                </v-flex>

                <v-flex xs4 >
                    <v-btn dark  tile @click="clearAllbtn()" color="#E46A25" class="text-capitalize px-3 mx-2 mt-5" small><v-icon dark small>cancel</v-icon> <v-divider vertical class="mx-2"></v-divider>Clear All</v-btn>
                </v-flex>
                                      
            </v-layout>

            <v-layout class=" mt-2" >
            
                <v-flex xs3 class="ml-4">
                    <v-layout column class="cust_pd mx-3 my-2 ">
                        <v-text class="font-weight-bold cstm-clr-pd">
                            Claim Type
                        </v-text>
                        <v-select
                            :items="claimTypeList"
                            label="Select"
                            v-model="claimType"
                            solo
                            dense
                            class="rounded-0"
                            :rules="[]"
                            >
                        </v-select>
                    </v-layout>
                </v-flex>

                <v-flex xs3>
                    <v-layout column class=" mx-3 my-2 cust_pd">
                        <v-text class="font-weight-bold cstm-clr-pd">
                            Claim Status
                        </v-text>
                        <v-select
                            :items="claimStatusList"
                            label="Select"
                            v-model="claimStatus"
                            solo
                            dense
                            class="rounded-0"
                            :rules="[]"
                            >
                        </v-select>
                    </v-layout>
                </v-flex>

                <v-flex xs3>
                    <v-layout column class=" mx-3 my-2 cust_pd">
                        <v-text class="font-weight-bold cstm-clr-pd">
                            Claim Sub-Status
                        </v-text>
                        <v-select
                            :items="claimSubStatusList"
                            label="Select"
                            v-model="claimSubStatus"
                            solo
                            dense
                            class="rounded-0"
                            :rules="[]"
                            >
                        </v-select>
                    </v-layout>
                </v-flex>
                <v-flex xs3 class="mr-4">
                    <v-layout column class=" mx-3 my-2  cust_pd">
                        <v-text class="font-weight-bold cstm-clr-pd">
                            Flag
                        </v-text>
                        <v-select
                            :items="flaglist"
                            label="Select"
                            v-model="flag"
                            solo
                            dense
                            class="rounded-0"
                            :rules="[]"
                            >
                        </v-select>
                    </v-layout>
                </v-flex>
                                                        
            </v-layout>


            
            <v-layout class="mt-2 pa-3" style="flex:1 ;background-color: #F7F7F7;border:0.5px solid #C1C8CC;" justify-end > 
                    <v-btn dark  tile @click="GetFilterData()" color="#23B1A9" class="text-capitalize px-3 mx-2" small><v-icon dark small>tune</v-icon> <v-divider vertical class="mx-2"></v-divider>Filter</v-btn>
                    <v-btn dark  tile @click="clearFilter()" color="#E46A25" class="text-capitalize px-3 mx-2" small><v-icon dark small>cancel</v-icon> <v-divider vertical class="mx-2"></v-divider>Clear</v-btn>
            </v-layout>
            
            </v-form>
        </div>


    <!-- <div v-if="showSearchdata===true"> -->
        <v-card class="rounded-0">
            <v-layout class="headercolor">
                <p class="cardfont" style="font-weight:700;margin-bottom:7px!important;margin-top:10px;padding-left:10px;">Search Results</p>
            </v-layout>
       
                <v-data-table
                    
                    :headers="headers"
                    :items="table_data"
                    hide-default-header
                    hide-default-footer
                    class="elevation-1"
                >
                                
            <template #header="{ props: { headers } }">
                <thead  class="v-data-table-header makepointer">
                    <tr>
                        <!-- class="text-uppercase" -->
                        <th
                        v-for="header in headers"
                        :key="header.value"
                        class="hdr-txt-clr-WrkItemQue hdr-bg-clr-WrkItemQue"
                            color="red"
                        scope="col"
                            @click="headercontrol(header)"
                        >
                        {{ header.text  }} 
                        {{ header.stype }}
                            <v-icon style="background-color:#ccc; border-radius:55px;" v-if="header.stype != 'none'" >
                                {{ header.stype == 'asc' ? 'arrow_upward':'arrow_downward' }}  </v-icon>
                        
                        </th>
                
                    
                    </tr>
                </thead>
            </template>

                        
        
      </v-data-table>
    
            <v-divider></v-divider>
                
                <v-layout row justify-end align-center class="ma-2" >
                    <v-text class="mr-1 font-weight-bold" style="color:#3C3D3D; font-size:13px;">
                        <span class="" style="color:#E46A25;font-size:12px;">Showing {{fval}}-{{sval}} </span>out of {{totalLen}} records
                    </v-text>
                    <v-divider  class="mx-2" vertical ></v-divider>
                    <v-pagination
                        class="pdct-stUp-bnkAcc-pgn"
                                v-model="page"
                                color="#152F38"
                                flat
                                @input="nextPageData(page)"
                                :total-visible="9"
                                :length="pagelength"
                    ></v-pagination>
                </v-layout>
            </v-card>
        <!-- </div> -->

            <!-- <v-card tile v-if="showrsltnotfound===true" class="my-3 " style="background-color:#F7F7F7;border:1px solid #C1C8CC;flex:1;">
                    <v-layout row justify-start align-center justify-space-between class="mx-">
                            <v-text class="mr-1 mx-5 pa-3 font-weight-bold body-1" style="color:#3C3D3D; font-size:16px !important">
                                NO RESULTS FOUND
                            </v-text>
                    </v-layout>
                        
            </v-card> -->

    </v-app>
</template>

<script>

import axios from "axios";

  
export default {

    created() {
        
         console.log("Success Token", this.$store.getters.successToken)
         this.token=this.$store.getters.successToken
         this.$store.commit("SAVE_PAGE_HEADER", "HOME LoggedIn");

          this.GetTableData(null,null,this.page)
          this.GetTATcount()
          
          this.set_custom_headers();
          this.set_route_for_page = this.$router.history.current.fullPath
        this.set_table_data(this.$router.history.current.fullPath, 'normal')

        
    },
    computed: {},
    watch: {
        $route(to, from){
            // react somehow
            console.log(to,"Route name",from)
            this.set_route_for_page = to.fullPath
            this.set_table_data(to.fullPath, 'normal');
        }
    },

          
    

    data() {
        return {
            sortable_params:{}, 
            type_for_pagination:'',
            set_route_for_page:'',
            
            table_data1:[
                {
                    claimId:"1",
                    inwardId:"ABC",
                    claimType:"TYPE1",
                    claimSubType:"Ash",
                    claimStatus:'Sita Bardi', 
                     claimSubStatus:'Nagpur',
                     flags:'Maharashtra',
                     ageing:"12"
                    
                },{
                    claimId:"10",
                    inwardId:"xyz",
                    claimType:"TYPE1",
                    claimSubType:"Ashish b",
                    claimStatus:'Sita Bardi', 
                     claimSubStatus:'mumbai',
                     flags:'Maharashtra',
                     ageing:"1"
                    
                },{
                    claimId:"5",
                    inwardId:"ABC",
                    claimType:"TYPE1",
                    claimSubType:"Ashish a",
                    claimStatus:'Sita Bardi', 
                     claimSubStatus:'pune',
                     flags:'Maharashtra',
                     ageing:"1"
                    
                },
            ],
            

            typesearch:false,
            swap_final_count:false,
            fval:0,
            sval:0,
            totalLen:0,
            showrsltnotfound:false,
            page: 1,
            pagelength:0,
            itemsPerPage: 10,

            
                            
            headers:[
                
                {text: 'Claim ID', value: 'claimId',sortable:false,divider:true,class:"hdr-txt-clr-clr hdr-bg-clr srNoWidth1"},
                {text: 'Inward ID', value: 'inwardId',sortable:false,divider:true,class:"hdr-txt-clr-clr  hdr-bg-clr"},
                {text: 'Claim Type', value: 'claimType',sortable:false,divider:true,class:"hdr-txt-clr-clr hdr-bg-clr"},
                {text: 'Claim Sub Type', value: 'claimSubType',sortable:false,divider:true,class:"hdr-txt-clr-clr hdr-bg-clr"},
                {text: 'Claim Status', value: 'claimStatus',sortable:false,divider:true,class:"hdr-txt-clr-clr hdr-bg-clr"},
                {text: 'Claim Sub-Status', value: 'claimSubStatus',sortable:false,divider:true,class:"hdr-txt-clr-clr hdr-bg-clr"},
                {text: 'Ageing', value: 'ageing',sortable:false,divider:true,class:"hdr-txt-clr-clr hdr-bg-clr"},

                {text: 'Flags', value: 'flags',sortable:false,divider:true,class:"hdr-txt-clr-clr hdr-bg-clr"},
                

            ],
            store_header_index:null,

            totalwrkitm:'',
            nearTAT:'',
            exceededTAT:'',
            claimTypeList:['Cashless','Reimbursement','Junk'],
            claimType:'',
            claimStatusList:['Open','Closed','Cancelled','Suspended'],
            claimStatus:'',
            claimSubStatusList:['Queried','Inactive Policy','Invalid Policy','NIDB','RI Missing','Reimbursement Registered',
                                    'Medical Approved','Queried','Rejected','No query response','Partially Paid','Paid',
                                    'Re-opened','Cashless Registered','Commercial Approved'],
            claimSubStatus:'',
            flaglist:['Co-Issurance','VIP','Senior Citizen','Suspected','Social Media Complaint','64VB Compliance','NEFT',
                        'CKYC','Death','Employee'],
            flag:'',

             table_data:[],

            
        };

    },

filters:{

      
        checkdata(data){// eslint-disable-line no-mixed-spaces-and-tabs
            // console.log('Validity Data', data);
            if (data == "" || data == "undefined" || data == null || data == '' || data == 'Invalid Date') {
                return "-"
            } else {
                return data;
            }
        },
        
    },


    methods: {

        GetTATcount(){
                
                let self=this      
                let demodata={
        
                 }
                
                axios({
                    method: "get",
                      url:"http://claimsdrive-health.salesdrive.app/claim-management/claim-management-service/api/claim-management/get-tat-count",
                    //  url:self.API_Service_MASTER()+"line-of-business/get-line-of-business-list",
                    
                    headers: {
                        "Content-Type": "application/json",
                        "x-access-token":self.token,
                    },
                    data:demodata,
                })
                .then(function (response) {
                    let apiData = response.data.data

                    console.log("TAT API RESPONSE for ALL Network:::::",apiData);
                  
                    self.GetTATData = []
                    self.GetTATData= apiData
                    console.log(" TAT api responce---->>>>>>", self.GetTATData);
                    self.totalwrkitm= apiData.totalWorkItems
                    self.exceededTAT= apiData.exceedTAT
                    self.nearTAT= apiData.nearTAT

                    // self.showToast(response.data.msg,self.TOST().SUCCESS)
                    console.log("TAT api success msg--->>>>>>", response.data.msg);
                                       
                })
                .catch(function (error) {
                    console.log("TAT list Error>>>>>>", error);
                    self.showToast(error.response.data.msg,self.TOST().ERROR);
                });
            
        },

        clearAllbtn(){



            if((this.totalwrkitm===''||this.totalwrkitm===undefined||this.totalwrkitm===null) 
            && (this.nearTAT===''||this.nearTAT===undefined||this.nearTAT===null) 
            && (this.exceededTAT===''||this.exceededTAT===undefined||this.exceededTAT===null) 
            
            ){
                
                return
            }else{                
                this.totalwrkitm=''
                this.nearTAT=''
                this.exceededTAT=''
            
                
                this.GetTableData(null,1);
                this.typesearch = false
                this.page=1
                console.log('cancel search data',)
                this.$refs.form.resetValidation()
                 this.showSearchdata=true,
                     this.showrsltnotfound=false
                    
            }
        },

        clearFilter(){



            if((this.claimType===''||this.claimType===undefined||this.claimType===null) 
            && (this.claimSubStatus===''||this.claimSubStatus===undefined||this.claimSubStatus===null) 
            && (this.claimStatus===''||this.claimStatus===undefined||this.claimStatus===null)
            && (this.flag===''||this.flag===undefined||this.flag===null) 
            
            ){
                
                return
            }else{
                
                
                this.claimType=''
                this.claimSubStatus=''
                this.claimStatus=''
                this.flag=''
                
                this.GetTableData(null,1);
                this.typesearch = false
                this.page=1
                console.log('cancel search data',)
                this.$refs.form.resetValidation()
                 this.showSearchdata=true,
                     this.showrsltnotfound=false
                    
            }
        },

         set_custom_headers(){
            //ssetting new key for tracking sorting order for a particular column, along with index mapping to new key
             this.headers = this.headers.map((e, index)=>{
                 return {...e,index: index, stype:'none' }}) 
        },

        headercontrol(indata){
            console.log("getting header click now ?", indata)

                    if(indata.stype == 'none' &&  this.store_header_index != indata.index){
                            // ASC - from none
                            this.headers[indata.index].stype = 'asc'
                            if(this.store_header_index != null){
                                this.headers[this.store_header_index].stype = 'none'
                            }
                            this.store_header_index = indata.index

                    }else if(indata.stype == 'dsc'){
                            // ASC - from DSC
                            this.headers[indata.index].stype = 'asc'
                            this.store_header_index = indata.index
                    }else if(indata.stype == 'asc'){
                            //DSC - from ASC
                            this.headers[indata.index].stype = 'dsc'
                            this.store_header_index = indata.index
                    }

                    console.log(this.headers[indata.index].stype);
                  let srtby = this.headers[indata.index].stype == 'asc' ? '1' : '-1'

                   if(this.headers[indata.index].stype == 'none'){
                      this.sort_status = false;
                      let setting_type_outter = {type:'normal', params:''}
                      if(this.filter_status){
                          setting_type_outter.type = this.GetFilterData
                          setting_type_outter.params = {CT:this.claimTypeList,CSS:this.claimSubStatus,CS:this.claimStatusList}
                      }
                        
                      this.GetTableData(this.set_route_for_page, setting_type_outter.type, setting_type_outter.params)
                      return
                  }
                    
                    var sortable_parameters = {
                        sortBy_data:'&sortBy='+indata.value+'&sortdirection='+srtby
                    }

                    console.log("sortable_parameters ---> ", sortable_parameters);
                    this.sortable_params = sortable_parameters
                    this.GetTableData(sortable_parameters.sortBy_data,this.page)
                    this.headers.map(e=> {
                        if(e.stype == 'none'){
                            e.stype = 'none'
                        }
                    }) 
        //this.headers[indata.index].stype=earlier == 'asc' ? 'dsc' : 'asc'
      },
    
            returnbtn(){
                this.$router.push('/HomePage')
            },

            RoleClick(item){
                console.log(item,"search result item ")
                this.$router.push({name: 'RoleCreate', params:{item}})
                // this.$store.commit("NETWORK_DATA",item)
                // this.$router.push('/RoleCreate')
            },

        
        
        seekSearchData(indata){
            this.page=indata
            this.SearchRole(this.page);
        },
       
        nextPageData(indata){

            console.log("isSearchIndiv => ", this.typesearch);

            if(this.pagelength == indata){
                this.swap_final_count = true
            }else{
                this.swap_final_count = false
            }

            console.log('indiv sort value',this.indiviSortBy)
            console.log('indiv page no value',this.page)
            
            if(this.typesearch==false){
                this.GetTableData(null,this.page)
                  console.log("from if ",this.typesearch)
            }else{
                console.log("from else ",this.typesearch)
                this.seekSearchData(indata)
        
            }

        },

        GetTableData(sortBy1,type,pageNum) {
        console.log("result sort by-------",sortBy1)
                console.log("result page no ---->",pageNum)
                
                    let self = this;   
                    console.log('next btn',self.page)


                        let apiURL=''
                if(sortBy1===''||sortBy1===undefined||sortBy1===null){
                    apiURL="http://claimsdrive-health.salesdrive.app/claim-management/claim-management-service/api/claim-management/get-claim-list?perPage=10&pageNo="+pageNum
                }else{
                    apiURL="http://claimsdrive-health.salesdrive.app/claim-management/claim-management-service/api/claim-management/get-claim-list?perPage=10&pageNo="+pageNum+sortBy1
                }
                    
                    axios({
                    method: "get",
                    
                  url:apiURL,
                            
                            // url:self.API_Service_USER_MGMNT()+"role/search-role?perPage="+10+"&pageNo="+pageNum,
                            

                            headers: {
                                "Content-Type": "application/json", 
                                "x-access-token": self.token,                                
                            },
                        })
                        .then(function(response) {
                                    
                    let apiData = response.data.data.data

                    console.log("API RESPONSE for ALL Network:::::",apiData);
                  
                    self.table_data = []
                    // self.table_data= apiData
                    self.table_data= apiData.map((item,index) => {
                                                                        return{
                                                                                claimId:item.claimId,
                                                                                inwardId:item.inwardId,
                                                                                claimType:item.claimType,
                                                                                
                                                                                claimSubType:item.claimSubType,
                                                                                    claimStatus:item.claimStatus,
                                                                                    claimSubStatus:item.claimSubStatus,
                                                                                    ageing:item.ageing,
                                                                                    flags:item.flags,


                                                                                allData:item
                                                                            }
                                                                        });


                    
                     console.log("table_data RESPONSE ------->>>>>>>:::::",self.table_data);

                    //  console.log("table_data claimStatus RESPONSE ------->>>>>>>:::::",self.table_data[0]);

            //pagination 
                    self.pagelength=response.data.data.total_pages
                    console.log(self.page,' <-- pageindu | next btn, pg length is ----->',self.pagelength)

                     self.totalLen=response.data.data.total
                      let l = self.table_data.length
                
                
                    console.log( 10*self.page,'skip simulation ');

                      var less_enough = self.totalLen
                        var checkless_init = false
                        if(less_enough < 10){
                            checkless_init = false
                        }else{
                            checkless_init = true
                        }

                        // if less than 15 we have second value same as total value as no pagination will occur
                        if(checkless_init){
                            
                                // checkinit is true means the final count is more than 15 (- 1)
                                var traverse =  10*self.page
                                self.fval = traverse-9
                                if(self.swap_final_count){
                                    self.sval =  self.totalLen
                                }else{
                                    self.sval = traverse
                                }

                        }else if(self.totalLen == 0){
                            
                          self.sval = self.fval = 0
                        
                       }else{
                             self.fval = 1
                             self.sval = self.totalLen
                        }

                    console.log('total ----->',self.totalLen)
                    console.log(self.sval,'<------sval<<   >>fval ----->',self.fval)

						console.log("role table DATA is---->>::",self.table_data);
			
                        })
                        .catch(function(error) {
                            console.log(error);
                            self.showToast(error.response.data.msg,self.TOST().ERROR);
                        });
             
            },


            GetFilterData(sortBy1,pageNum) {
                console.log("filter sort by-------",sortBy1)
                console.log("filter page no ---->",pageNum)
                
                    let self = this;   
                    console.log('next btn',self.page)
                    
                    axios({
                    method: "get",
                    
                  url:"http://claimsdrive-health.salesdrive.app/claim-management/claim-management-service/api/claim-management/get-claim-list?perPage=10&pageNo=1&sortBy=1&claimType="+self.claimType+"&claimSubStatus="+self.claimSubStatus+"&claimStatus="+self.claimStatus+"&flag="+self.flag,
                            
                            // url:self.API_Service_USER_MGMNT()+"role/search-role?perPage="+10+"&pageNo="+pageNum,
                            
                            headers: {
                                "Content-Type": "application/json", 
                                "x-access-token": self.token,                                
                            },
                        })
                        .then(function(response) {
                                       
                    let apiData = response.data.data.data

                    console.log("API RESPONSE for ALL Network:::::",apiData);
                  
                    self.table_data = []
                    // self.table_data= apiData
                    self.table_data= apiData.map((item,index) => {
                                                                        return{
                                                                                claimId:item.claimId,
                                                                                inwardId:item.inwardId,
                                                                                claimType:item.claimType,
                                                                                claimSubType:item.claimSubType,
                                                                                claimStatus:item.claimStatus,
                                                                                claimSubStatus:item.claimSubStatus,
                                                                                ageing:item.ageing,
                                                                                flags:item.flags,
                                                                                allData:item
                                                                            }
                                                                        });


                    
                     console.log("table_data RESPONSE ------->>>>>>>:::::",self.table_data);

                    //  console.log("table_data claimStatus RESPONSE ------->>>>>>>:::::",self.table_data[0]);

            //pagination 
                    self.pagelength=response.data.data.total_pages
                    console.log(self.page,' <-- pageindu | next btn, pg length is ----->',self.pagelength)

                     self.totalLen=response.data.data.total
                      let l = self.table_data.length
                
                
                    console.log( 10*self.page,'skip simulation ');

                      var less_enough = self.totalLen
                        var checkless_init = false
                        if(less_enough < 10){
                            checkless_init = false
                        }else{
                            checkless_init = true
                        }

                        // if less than 15 we have second value same as total value as no pagination will occur
                        if(checkless_init){
                            
                                // checkinit is true means the final count is more than 15 (- 1)
                                var traverse =  10*self.page
                                self.fval = traverse-9
                                if(self.swap_final_count){
                                    self.sval =  self.totalLen
                                }else{
                                    self.sval = traverse
                                }

                        }else if(self.totalLen == 0){
                            
                          self.sval = self.fval = 0
                        
                       }else{
                             self.fval = 1
                             self.sval = self.totalLen
                        }

                    console.log('total ----->',self.totalLen)
                    console.log(self.sval,'<------sval<<   >>fval ----->',self.fval)

						console.log("role table DATA is---->>::",self.table_data);
			
                        })
                        .catch(function(error) {
                            console.log(error);
                            self.showToast(error.response.data.msg,self.TOST().ERROR);
                        });
             
            },

                    
         

    },


}
</script>

<style>

.cust-rmvpd-psins3 .v-text-field.v-text-field--enclosed>.v-input__control>.v-input__slot {
    padding-right: 0px !important;
}

.cust_pr2 .v-text-field .v-input__slot,
.v-text-field--outline .v-input__slot {
    min-height: 42px !important;
    display: flex !important;
    align-items: center !important;
    /* border:1px red solid; */
}

.cstm-clr-pr3{
    color:#646666 !important;
    margin-bottom: 5px !important;
    font-size: 13px !important;
}

.cust_pd1 .v-text-field .v-input__slot,
.v-text-field--outline .v-input__slot {
    min-height: 42px !important;
    display: flex !important;
    align-items: center !important;
    /* border:1px red solid; */
}

.cust-rmvpd-pr1 .v-text-field.v-text-field--enclosed>.v-input__control>.v-input__slot {
    padding: 0px !important;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td{
    font-size: 0.84rem !important;
}

.hdr-txt-clr-clr {
        color: white !important;
    font-weight: bold;

}

.hdr-bg-clr{
  background-color: #23B1A9 !important;
  color: #ffffff !important;
  
}
.headercolor{
    background-color: #eee;
}
.cardfont{
    font-size: 0.84rem !important;
    padding-right: 2px;
}
.srNoWidth{
    width: 80px;

}


</style>