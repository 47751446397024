<template>
	<div id="main">
		<v-form ref="recoveryForm" v-model="valid" :disabled="disableFields">
			<v-card tile class="pa-3">
				<v-layout>
					<v-flex xs3>
						<v-layout column class="cust_pln_em mx-2">
							<p class="font-weight-bold cstm-clr-pln-iphd_claims">Claim Number</p>
							<v-text-field solo label="Enter..." v-model="claimNumber" class="rounded-0" :maxlength="max30" disabled> </v-text-field>
						</v-layout>
					</v-flex>
					<v-flex xs3>
						<v-layout column class="cust_pln_em mx-2">
							<p class="font-weight-bold cstm-clr-pln-iphd_claims">Feature Number</p>
							<v-text-field solo label="Enter..." v-model="featureNumber" class="rounded-0" :maxlength="max30" disabled> </v-text-field>
						</v-layout>
					</v-flex>
					<v-flex xs3>
						<v-layout column class="cust_pln_em mx-2">
							<p class="font-weight-bold cstm-clr-pln-iphd_claims">Member Name</p>
							<v-text-field solo label="Enter..." v-model="memberName" class="rounded-0" :maxlength="max30" disabled>
							</v-text-field>
						</v-layout>
					</v-flex>
                    <v-flex xs3>
						<v-layout column class="cust_pln_em mx-2">
							<p class="font-weight-bold cstm-clr-pln-iphd_claims">Provider Name</p>
							<v-text-field solo label="Enter..." v-model="providerName" class="rounded-0" :maxlength="max30" disabled> </v-text-field>
						</v-layout>
					</v-flex>
                    <v-flex xs3>
						<v-layout column class="cust_pln_em mx-2">
							<p class="font-weight-bold cstm-clr-pln-iphd_claims">Adjudicator Name</p>
							<v-text-field solo label="Enter..." v-model="adjudicatorName" class="rounded-0" :maxlength="max30" disabled> </v-text-field>
						</v-layout>
					</v-flex>
					

				</v-layout>
				<v-layout class="">
                    <v-flex xs3>
						<v-layout column class="cust_pln_em mx-2">
							<p class="font-weight-bold cstm-clr-pln-iphd_claims">Claim Paid Amount</p>
							<v-text-field solo label="Enter..." v-model="claimPaidAmount" class="rounded-0" :maxlength="max30" disabled> </v-text-field>
						</v-layout>
					</v-flex>
                    <v-flex xs3>
						<v-layout column class="cust_pln_em mx-2">
							<p class="font-weight-bold cstm-clr-pln-iphd_claims">Expense Paid</p>
							<v-text-field solo label="Enter..." v-model="expensePaid" class="rounded-0" :maxlength="max30" disabled> </v-text-field>
						</v-layout>
					</v-flex>
                    <v-flex xs3>
						<v-layout column class="cust_pln_em mx-2">
							<p class="font-weight-bold cstm-clr-pln-iphd_claims">Net Score</p>
							<v-text-field solo label="Enter..." v-model="netScore" class="rounded-0" :maxlength="max5" disabled> </v-text-field>
						</v-layout>
					</v-flex>
                    <v-flex xs3>
                    </v-flex>
                    <v-flex xs3>
                    </v-flex>

					
				</v-layout>

            <v-layout class="mx-2">

                <v-flex xs3>
                    <div
                        style="background-color: #F7F7F7; cursor:pointer;border: 0.2px solid #C1C8CC;"
                        class="pa-2 pr-14 "
                        :class="[
                        QueryStatus == 'CFRtriggers'
                            ? 'activeRadioclass'
                            : '',
                        ]"
                        @click="changeQueryStatus('CFRtriggers','')"
                    >
                        <div
                        class="font-weight-bold"
                        :class="[
                            QueryStatus == 'CFRtriggers'
                            ? 'text-white-insrns1'
                            : 'text-dark-insrns1',
                        ]"
                        >
                        <!-- QueryStatus -->
                        CFR Triggers
                        </div>
                    </div>
                </v-flex>

                <v-flex xs3>
                    <div
                        style="background-color: #F7F7F7;  cursor:pointer;border: 0.2px solid #C1C8CC;"
                        class="pa-2"
                        :class="[
                        QueryStatus == 'procedureAndDiagnosis'
                            ? 'activeRadioclass'
                            : '',
                        ]"
                        @click="changeQueryStatus('procedureAndDiagnosis','Procedure And Diagnosis')"
                    >
                        <div
                        class="font-weight-bold"
                        :class="[
                            QueryStatus == 'procedureAndDiagnosis'
                            ? 'text-white-insrns1'
                            : 'text-dark-insrns1',
                        ]"
                        >
                        Procedure & Diagnosis
                        </div>
                    </div>
                </v-flex>

                <v-flex xs3>
                    <div
                        style="background-color: #F7F7F7;  cursor:pointer;border: 0.2px solid #C1C8CC;"
                        class="pa-2"
                        :class="[
                        QueryStatus == 'documentationAndFileManagement'
                            ? 'activeRadioclass'
                            : '',
                        ]"
                        @click="changeQueryStatus('documentationAndFileManagement','Documentation And File Management')"
                    >
                        <div
                        class="font-weight-bold"
                        :class="[
                            QueryStatus == 'documentationAndFileManagement'
                            ? 'text-white-insrns1'
                            : 'text-dark-insrns1',
                        ]"
                        >
                        Document & File Management
                        </div>
                    </div>
                </v-flex>

                <v-flex xs3>
                    <div
                        style="background-color: #F7F7F7;  cursor:pointer;border: 0.2px solid #C1C8CC;"
                        class="pa-2"
                        :class="[
                        QueryStatus == 'intimation'
                            ? 'activeRadioclass'
                            : '',
                        ]"
                        @click="changeQueryStatus('intimation','Intimation')"
                    >
                        <div
                        class="font-weight-bold"
                        :class="[
                            QueryStatus == 'intimation'
                            ? 'text-white-insrns1'
                            : 'text-dark-insrns1',
                        ]"
                        >
                        Intimation
                        </div>
                    </div>
                </v-flex>
                
                <v-flex xs3>
                    <div
                        style="background-color: #F7F7F7;  cursor:pointer;border: 0.2px solid #C1C8CC;"
                        class="pa-2"
                        :class="[
                        QueryStatus == 'SLA'
                            ? 'activeRadioclass'
                            : '',
                        ]"
                        @click="changeQueryStatus('SLA','SLA')"
                    >
                        <div
                        class="font-weight-bold"
                        :class="[
                            QueryStatus == 'SLA'
                            ? 'text-white-insrns1'
                            : 'text-dark-insrns1',
                        ]"
                        >
                        SLA
                        </div>
                    </div>
                </v-flex>
                
                <v-flex xs3>
                    <div
                        style="background-color: #F7F7F7;  cursor:pointer;border: 0.2px solid #C1C8CC;"
                        class="pa-2"
                        :class="[
                        QueryStatus == 'overAllRating'
                            ? 'activeRadioclass'
                            : '',
                        ]"
                        @click="changeQueryStatus('overAllRating','Overall Rating')"
                    >
                        <div
                        class="font-weight-bold"
                        :class="[
                            QueryStatus == 'overAllRating'
                            ? 'text-white-insrns1'
                            : 'text-dark-insrns1',
                        ]"
                        >
                        Overall Rating
                        </div>
                    </div>
                </v-flex>

            </v-layout>

            <v-layout class="mx-2">
                <v-flex xs12 style="min-height: 160px;border: 0.5px solid #C1C8CC;">
                    <!--tab 1  -->
                    <div v-if="QueryStatus == 'CFRtriggers'">

                        <v-card class="my-8 mx-2">
                            <v-data-table
                                :headers="viewScoreHeader"
                                :items="viewScoreItems"
                                hide-default-footer
                                dense
                            >
                                <!-- <template #item.serialNumber="{ item }">
                                <td>
                                    {{ viewScoreItems.indexOf(item) + 1 }}
                                </td>
                                </template> -->


                            </v-data-table>
                        </v-card>



                    </div>

                    <!--tab 2  -->
                    <div v-if="QueryStatus == 'procedureAndDiagnosis'">


                        <v-layout v-for="(i, index) in GetQueData" :key="index" class="cust_pln_em mx-2 mt-3">

                            <v-flex xs4 class="mt-3">
                                <p class="font-weight-bold cstm-clr-pln-iphd_claims">
                                    {{i.question}}
                                </p>
                            </v-flex>
                                    
                            <v-flex xs2>        
                                    <v-select
                                        solo
                                        :items="SelectItems"
                                        label="Select"
                                        class="rounded-0"
                                        :rules="[rules.required]"
                                        :disabled="disableWhenComplteCFR"
                                        :value="i.answer"
                                        @input="(answer) => updateTab2QueAns(i, answer,index,GetQueData)"

                                        >
                                    </v-select>
                            </v-flex>
                        </v-layout>

                        <!-- <v-layout v-else="abcd1 = true" v-for="(i, index) in GetQueData2" :key="index" class="cust_pln_em mx-2 mt-3">
                            <v-flex xs4 class="mt-3">
                                <p class="font-weight-bold cstm-clr-pln-iphd_claims">
                                    {{i.question}}
                                </p>
                            </v-flex>
                                    
                            <v-flex xs2>        
                                    <v-select
                                        solo
                                        :items="SelectItems"
                                        label="Select"
                                        class="rounded-0"
                                        :rules="[rules.required]"
                                        :disabled="disableWhenComplteCFR"
                                        :value="i.answer"
                                        @input="(answer) => updateTab2QueAns(i, answer)"


                                        >
                                    </v-select>
                            </v-flex>
                        </v-layout> -->

            <!-- <p>{{getTab2Final}}</p> -->
                        
                        

                        <!-- <v-layout class="cust_pln_em mx-2 mt-3">
                            <v-flex xs4 class="mt-3">
                                <p class="font-weight-bold cstm-clr-pln-iphd_claims">
                                    Was Medical Procedure matching with Diagnosis 
                                </p>
                            </v-flex>
                                    
                            <v-flex xs2>        
                                    <v-select
                                        solo
                                        :items="SelectItems"
                                        label="Select"
                                        v-model="tab2one"
                                        class="rounded-0" 
                                        :rules="[rules.required]"
                                        :disabled="disableWhenComplteCFR"
                                        >
                                    </v-select>
                            </v-flex>
                        </v-layout>

                        <v-layout class="cust_pln_em mx-2 ">
                            <v-flex xs4 class="mt-3">
                                <p class="font-weight-bold cstm-clr-pln-iphd_claims">
                                    Was Diagnosis matching with gender of patient 
                                </p>
                            </v-flex>
                                    
                            <v-flex xs2>        
                                    <v-select
                                        solo
                                        :items="SelectItems"
                                        label="Select"
                                        v-model="tab2two"
                                        class="rounded-0" 
                                        :rules="[rules.required]"
                                        :disabled="disableWhenComplteCFR"
                                        >
                                    </v-select>
                            </v-flex>
                        </v-layout>

                        <v-layout class="cust_pln_em mx-2 ">
                            <v-flex xs4 class="mt-3">
                                <p class="font-weight-bold cstm-clr-pln-iphd_claims">
                                    Was Diagnosis matching with age of patient  
                                </p>
                            </v-flex>
                                    
                            <v-flex xs2>        
                                    <v-select
                                        solo
                                        :items="SelectItems"
                                        label="Select"
                                        v-model="tab2three"
                                        class="rounded-0" 
                                        :rules="[rules.required]"
                                        :disabled="disableWhenComplteCFR"
                                        >
                                    </v-select>
                            </v-flex>
                        </v-layout> -->


                    </div>
                
                <!--tab 3  -->
                    <div v-if="QueryStatus == 'documentationAndFileManagement'">

                        <v-layout v-for="(i, index) in GetQueData" :key="index" class="cust_pln_em mx-2 mt-3">
                            <v-flex xs4 class="mt-3">
                                <p class="font-weight-bold cstm-clr-pln-iphd_claims">
                                    {{i.question}}
                                </p>
                            </v-flex>
                                    
                            <v-flex xs2>        
                                    <v-select
                                        solo
                                        :items="SelectItems"
                                        label="Select"
                                        class="rounded-0"
                                        :rules="[rules.required]"
                                        :disabled="disableWhenComplteCFR"
                                        :value="i.answer"
                                        @input="(answer) => updateTab3QueAns(i, answer,index,GetQueData)"


                                        >
                                    </v-select>
                            </v-flex>
                        </v-layout>
                
                <!-- <p>{{getTab3Final}}</p> -->


                        <!-- <v-layout class="cust_pln_em mx-2 mt-3">
                            <v-flex xs4 class="mt-3">
                                <p class="font-weight-bold cstm-clr-pln-iphd_claims">
                                    Were the coverages selected on time? 
                                </p>
                            </v-flex>
                                    
                            <v-flex xs2>        
                                    <v-select
                                        solo
                                        :items="SelectItems"
                                        label="Select"
                                        v-model="tab3one"
                                        class="rounded-0" 
                                        :rules="[rules.required]"
                                        :disabled="disableWhenComplteCFR"
                                        >
                                    </v-select>
                            </v-flex>
                        </v-layout>

                        <v-layout class="cust_pln_em mx-2 ">
                            <v-flex xs4 class="mt-3">
                                <p class="font-weight-bold cstm-clr-pln-iphd_claims">
                                    Did the Adjudicator check for pre-inspection/previous claim history?
                                </p>
                            </v-flex>
                                    
                            <v-flex xs2>        
                                    <v-select
                                        solo
                                        :items="SelectItems"
                                        label="Select"
                                        v-model="tab3two"
                                        class="rounded-0" 
                                        :rules="[rules.required]"
                                        :disabled="disableWhenComplteCFR"
                                        >
                                    </v-select>
                            </v-flex>
                        </v-layout>

                        <v-layout class="cust_pln_em mx-2 ">
                            <v-flex xs4 class="mt-3">
                                <p class="font-weight-bold cstm-clr-pln-iphd_claims">
                                    Were all the required documents obtained and filed on time?  
                                </p>
                            </v-flex>
                                    
                            <v-flex xs2>        
                                    <v-select
                                        solo
                                        :items="SelectItems"
                                        label="Select"
                                        v-model="tab3three"
                                        class="rounded-0" 
                                        :rules="[rules.required]"
                                        :disabled="disableWhenComplteCFR"
                                        >
                                    </v-select>
                            </v-flex>
                        </v-layout>

                        <v-layout class="cust_pln_em mx-2">
                            <v-flex xs4 class="mt-3">
                                <p class="font-weight-bold cstm-clr-pln-iphd_claims">
                                    Were the Reserves updated on time? 
                                </p>
                            </v-flex>
                                    
                            <v-flex xs2>        
                                    <v-select
                                        solo
                                        :items="SelectItems"
                                        label="Select"
                                        v-model="tab3four"
                                        class="rounded-0" 
                                        :rules="[rules.required]"
                                        :disabled="disableWhenComplteCFR"
                                        >
                                    </v-select>
                            </v-flex>
                        </v-layout>

                        <v-layout class="cust_pln_em mx-2 ">
                            <v-flex xs4 class="mt-3">
                                <p class="font-weight-bold cstm-clr-pln-iphd_claims">
                                    Was the reserve set adequate to the amount settled? 
                                </p>
                            </v-flex>
                                    
                            <v-flex xs2>        
                                    <v-select
                                        solo
                                        :items="SelectItems"
                                        label="Select"
                                        v-model="tab3five"
                                        class="rounded-0" 
                                        :rules="[rules.required]"
                                        :disabled="disableWhenComplteCFR"
                                        >
                                    </v-select>
                            </v-flex>
                        </v-layout>

                        <v-layout class="cust_pln_em mx-2">
                            <v-flex xs4 class="mt-3">
                                <p class="font-weight-bold cstm-clr-pln-iphd_claims">
                                    Did the Adjudicator review the claim in a timely manner?  
                                </p>
                            </v-flex>
                                    
                            <v-flex xs2>        
                                    <v-select
                                        solo
                                        :items="SelectItems"
                                        label="Select"
                                        v-model="tab3six"
                                        class="rounded-0" 
                                        :rules="[rules.required]"
                                        :disabled="disableWhenComplteCFR"
                                        >
                                    </v-select>
                            </v-flex>
                        </v-layout>

                        <v-layout class="cust_pln_em mx-2 ">
                            <v-flex xs4 class="mt-3">
                                <p class="font-weight-bold cstm-clr-pln-iphd_claims">
                                    Were any red flags brought to the notice of the supervisor on time? 
                                </p>
                            </v-flex>
                                    
                            <v-flex xs2>        
                                    <v-select
                                        solo
                                        :items="SelectItems"
                                        label="Select"
                                        v-model="tab3seven"
                                        class="rounded-0" 
                                        :rules="[rules.required]"
                                        :disabled="disableWhenComplteCFR"
                                        >
                                    </v-select>
                            </v-flex>
                        </v-layout>

                        <v-layout class="cust_pln_em mx-2 ">
                            <v-flex xs4 class="mt-3">
                                <p class="font-weight-bold cstm-clr-pln-iphd_claims">
                                    Were the documents uploaded to omniDocs? 
                                </p>
                            </v-flex>
                                    
                            <v-flex xs2>        
                                    <v-select
                                        solo
                                        :items="SelectItems"
                                        label="Select"
                                        v-model="tab3eight"
                                        class="rounded-0" 
                                        :rules="[rules.required]"
                                        :disabled="disableWhenComplteCFR"
                                        >
                                    </v-select>
                            </v-flex>
                        </v-layout>

                        <v-layout class="cust_pln_em mx-2 ">
                            <v-flex xs4 class="mt-3">
                                <p class="font-weight-bold cstm-clr-pln-iphd_claims">
                                    Is the file CKYC Compliant?  
                                </p>
                            </v-flex>
                                    
                            <v-flex xs2>        
                                    <v-select
                                        solo
                                        :items="SelectItems"
                                        label="Select"
                                        v-model="tab3nine"
                                        class="rounded-0" 
                                        :rules="[rules.required]"
                                        :disabled="disableWhenComplteCFR"
                                        >
                                    </v-select>
                            </v-flex>
                        </v-layout>

                        <v-layout class="cust_pln_em mx-2">
                            <v-flex xs4 class="mt-3">
                                <p class="font-weight-bold cstm-clr-pln-iphd_claims">
                                    Was the payment posted within the prescribed TAT?  
                                </p>
                            </v-flex>
                                    
                            <v-flex xs2>        
                                    <v-select
                                        solo
                                        :items="SelectItems"
                                        label="Select"
                                        v-model="tab3ten"
                                        class="rounded-0" 
                                        :rules="[rules.required]"
                                        :disabled="disableWhenComplteCFR"
                                        >
                                    </v-select>
                            </v-flex>
                        </v-layout> -->

            <!--tab 4  -->
                    </div>

                    <div v-if="QueryStatus == 'intimation'">

                        <v-layout v-for="(i, index) in GetQueData" :key="index" class="cust_pln_em mx-2 mt-3">
                            <v-flex xs4 class="mt-3">
                                <p class="font-weight-bold cstm-clr-pln-iphd_claims">
                                    {{i.question}}
                                </p>
                            </v-flex>
                                    
                            <v-flex xs2>        
                                    <v-select
                                        solo
                                        :items="SelectItems"
                                        label="Select"
                                        class="rounded-0"
                                        :rules="[rules.required]"
                                        :disabled="disableWhenComplteCFR"
                                        :value="i.answer"
                                        @input="(answer) => updateTab4QueAns(i, answer,index,GetQueData)"


                                        >
                                    </v-select>
                            </v-flex>
                        </v-layout>
            <!-- <p>{{getTab4Final}}</p> -->

                        <!-- <v-layout class="cust_pln_em mx-2 mt-3">
                            <v-flex xs4 class="mt-3">
                                <p class="font-weight-bold cstm-clr-pln-iphd_claims">
                                    Was the first contact made on time?  
                                </p>
                            </v-flex>
                                    
                            <v-flex xs2>        
                                    <v-select
                                        solo
                                        :items="SelectItems"
                                        label="Select"
                                        v-model="tab4one"
                                        class="rounded-0"
                                        :rules="[rules.required]" 
                                        :disabled="disableWhenComplteCFR"
                                        >
                                    </v-select>
                            </v-flex>
                        </v-layout>

                        <v-layout class="cust_pln_em mx-2">
                            <v-flex xs4 class="mt-3">
                                <p class="font-weight-bold cstm-clr-pln-iphd_claims">
                                    Were initial communications to insured sent on time and duly recorded?  
                                </p>
                            </v-flex>
                                    
                            <v-flex xs2>        
                                    <v-select
                                        solo
                                        :items="SelectItems"
                                        label="Select"
                                        v-model="tab4two"
                                        class="rounded-0" 
                                        :rules="[rules.required]"
                                        :disabled="disableWhenComplteCFR"
                                        >
                                    </v-select>
                            </v-flex>
                        </v-layout> -->


                    </div>
                
                <!--tab 5  -->
                    <div v-if="QueryStatus == 'SLA'">

                        <v-layout v-for="(i, index) in GetQueData" :key="index" class="cust_pln_em mx-2 mt-3">
                            <v-flex xs4 class="mt-3">
                                <p class="font-weight-bold cstm-clr-pln-iphd_claims">
                                    {{i.question}}
                                </p>
                            </v-flex>
                                    
                            <v-flex xs2>        
                                    <v-select
                                        solo
                                        :items="SelectItems"
                                        label="Select"
                                        class="rounded-0"
                                        :rules="[rules.required]"
                                        :disabled="disableWhenComplteCFR"
                                        :value="i.answer"
                                        @input="(answer) => updateTab5QueAns(i, answer,index,GetQueData)"


                                        >
                                    </v-select>
                            </v-flex>
                        </v-layout>

                        <!-- <p>{{getTab5Final}}</p> -->


                        <!-- <v-layout class="cust_pln_em mx-2 mt-3">
                            <v-flex xs4 class="mt-3">
                                <p class="font-weight-bold cstm-clr-pln-iphd_claims">
                                    Was the claim registration completed within required SLA 
                                </p>
                            </v-flex>
                                    
                            <v-flex xs2>        
                                    <v-select
                                        solo
                                        :items="SelectItems"
                                        label="Select"
                                        v-model="tab5one"
                                        class="rounded-0"
                                        :rules="[rules.required]"
                                        :disabled="disableWhenComplteCFR" 
                                        >
                                    </v-select>
                            </v-flex>
                        </v-layout>

                        <v-layout class="cust_pln_em mx-2 ">
                            <v-flex xs4 class="mt-3">
                                <p class="font-weight-bold cstm-clr-pln-iphd_claims">
                                    Was Medical Claim approval completed within required SLA
                                </p>
                            </v-flex>
                                    
                            <v-flex xs2>        
                                    <v-select
                                        solo
                                        :items="SelectItems"
                                        label="Select"
                                        v-model="tab5two"
                                        class="rounded-0" 
                                        :rules="[rules.required]"
                                        :disabled="disableWhenComplteCFR"
                                        >
                                    </v-select>
                            </v-flex>
                        </v-layout>

                        <v-layout class="cust_pln_em mx-2 ">
                            <v-flex xs4 class="mt-3">
                                <p class="font-weight-bold cstm-clr-pln-iphd_claims">
                                    Was commercial approval completed within required SLA  
                                </p>
                            </v-flex>
                                    
                            <v-flex xs2>        
                                    <v-select
                                        solo
                                        :items="SelectItems"
                                        label="Select"
                                        v-model="tab5three"
                                        class="rounded-0" 
                                        :rules="[rules.required]"
                                        :disabled="disableWhenComplteCFR"
                                        >
                                    </v-select>
                            </v-flex>
                        </v-layout> -->


                    </div>
                
                <!--tab 6  -->    
                    <div v-if="QueryStatus == 'overAllRating'">

                        <v-layout v-for="(i, index) in GetQueData" :key="index" class="cust_pln_em mx-2 mt-3">
                            <v-flex xs4 class="mt-3">
                                <p class="font-weight-bold cstm-clr-pln-iphd_claims">
                                    {{i.question}}
                                </p>
                            </v-flex>
                                    
                            <v-flex xs2>        
                                    <v-select
                                        solo
                                        :items="SelectItems"
                                        label="Select"
                                        class="rounded-0"
                                        :rules="[rules.required]"
                                        :disabled="disableWhenComplteCFR"
                                        :value="i.answer"
                                        @input="(answer) => updateTab6QueAns(i, answer,index,GetQueData)"


                                        >
                                    </v-select>
                            </v-flex>
                        </v-layout>
                        
            <!-- <p>{{getTab6Final}}</p> -->


                        <!-- <v-layout class="cust_pln_em mx-2 mt-3">
                            <v-flex xs4 class="mt-3">
                                <p class="font-weight-bold cstm-clr-pln-iphd_claims">
                                    Was the insured information about the settlement details? 
                                </p>
                            </v-flex>
                                    
                            <v-flex xs2>        
                                    <v-select
                                        solo
                                        :items="SelectItems"
                                        label="Select"
                                        v-model="tab6one"
                                        class="rounded-0" 
                                        :rules="[rules.required]"
                                        :disabled="disableWhenComplteCFR"
                                        >
                                    </v-select>
                            </v-flex>
                        </v-layout>

                        <v-layout class="cust_pln_em mx-2 ">
                            <v-flex xs4 class="mt-3">
                                <p class="font-weight-bold cstm-clr-pln-iphd_claims">
                                    Did the overall handling of the claim meet all standards and expectations?
                                </p>
                            </v-flex>
                                    
                            <v-flex xs2>        
                                    <v-select
                                        solo
                                        :items="SelectItems"
                                        label="Select"
                                        v-model="tab6two"
                                        class="rounded-0" 
                                        :rules="[rules.required]"
                                        :disabled="disableWhenComplteCFR"
                                        >
                                    </v-select>
                            </v-flex>
                        </v-layout> -->


                    </div>
                </v-flex>

            </v-layout>

            <v-layout v-if="QueryStatus == 'overAllRating'" class="my-2">

                <v-layout class="cust_pln_em mx-2 mt-3">
                    <v-flex xs3>
						<v-layout column class="cust_pln_em mx-2">
							<p class="font-weight-bold cstm-clr-pln-iphd_claims">Recovery To Made</p>
                                    <v-select
                                        solo
                                        :items="SelectItemsRTM"
                                        label="Select"
                                        v-model="recoveryToMade"
                                        class="rounded-0" 
                                        
                                        :disabled="disableWhenComplteCFR"
                                        >
                                    </v-select>
						</v-layout>
					</v-flex>
                    <!-- :rules="[rules.required]" -->

                    <v-flex xs3>
						<v-layout column class="cust_pln_em mx-2">
							<p class="font-weight-bold cstm-clr-pln-iphd_claims">Type of Recovery</p>
                                    <v-select
                                        solo
                                        :items="RecoveryItems"
                                        label="Select"
                                        v-model="typeofRecovery"
                                        class="rounded-0" 
                                        
                                        :disabled="disableWhenComplteCFR"
                                        >
                                    </v-select>
						</v-layout>
					</v-flex>
                    <v-flex xs3>
                    </v-flex>
                    <v-flex xs3>
                    </v-flex>
                    <v-flex xs3>
                    </v-flex>

                </v-layout>
            
            </v-layout>

				<v-layout class="my-2">

					<v-flex xs12>
						<v-layout column class="cust_pln_em mx-2">
							<p class="font-weight-bold cstm-clr-pln-iphd_claims">Comments *</p>
							<v-text-field solo label="Enter..." v-model="comments" 
                            :rules="[rules.required,rules.alphaRule]"
                            :disabled="disableWhenComplteCFR"
                            class="rounded-0" :maxlength="max500"> </v-text-field>
						</v-layout>
					</v-flex>
					
				</v-layout>
				<v-layout>

					<v-flex xs3>
						<v-layout column class="cust_pln_em mx-2">
							<p class="font-weight-bold cstm-clr-pln-iphd_claims">User ID</p>
							<v-text-field solo label="Enter..."   v-model="userID" class="rounded-0" disabled> </v-text-field>
						</v-layout>
					</v-flex>
					<v-flex xs3>
						<v-layout column class="cust_pln_em mx-2">
							<p class="font-weight-bold cstm-clr-pln-iphd_claims">Date</p>
							<v-menu
								ref="calender"
								:close-on-content-click="true"
								v-model="chequeDateCalender"
								:nudge-right="40"
								transition="scale-transition"
								max-width="290px"
								min-width="290px"
								class="rounded-0"
							>
								<template v-slot:activator="{ on, attrs }">
									<v-text-field
										v-bind="attrs"
										label="Select.."
										:value="chequeDateCalenderMmntStatus"
										solo
										class="rounded-0 iconAlign"
										readonly
										v-on="on"
                                        disabled

									>
										<div slot="prepend-inner" style="background-color: #f7f7f7; padding: 9px !important" >
											<v-icon>event</v-icon>
										</div>
									</v-text-field>
								</template>
								<v-date-picker v-model="dateCalender" no-title disabled></v-date-picker>
							</v-menu>
						</v-layout>
					</v-flex>
                    <v-flex xs3>
                    </v-flex>
                    <v-flex xs3>
                    </v-flex>
                    <v-flex xs3>
                    </v-flex>

				</v-layout>
				<v-layout>

				</v-layout>
			</v-card>
		</v-form>

		<v-form ref="recoveryForm2" v-model="recoveryForm2">
			<v-card tile style="background-color: #f7f7f7" class="py-2">

						<v-layout justify-end>
                            
								<v-btn tile color="#23B1A9" :disabled="disableWhenComplteCFR" class="text-capitalize text-white px-3 mx-2 my-2" @click="saveCFR()" small style="color: #fff">
									<v-icon dark small>check_circle</v-icon>
									<v-divider vertical class="mx-2"></v-divider>Submit
								</v-btn>

                                <v-btn
                                    tile
                                    color="#23B1A9"
                                    class="text-capitalize text-white px-3 mx-2 my-2"
                                    small
                                    style="color: #fff"
                                    @click="saveAsDraft()"
                                    :disabled="disableWhenComplteCFR"
                                >
                                    <v-icon dark small>check_circle</v-icon>
                                    <v-divider vertical class="mx-2"></v-divider>Save as Draft
                                </v-btn>

                                <!-- for claim details -->
                
                        <v-btn dark tile color="#23B1A9" class="text-capitalize px-3 mx-2 my-2" small @click="goToclaimDetails(incmngData)"><v-icon dark small>list</v-icon><v-divider vertical class="mx-2"></v-divider>Claim Details</v-btn>

                                <v-btn  tile color="#E46A25" 
                                    :disabled="disableWhenComplteCFR" 
                                    class="text-capitalize text-white px-3 mx-2 my-2 mr-5" 
                                    style="color: #fff" small @click="clearData"
                                >
                                    <v-icon dark small>refresh</v-icon>
                                    <v-divider vertical class="mx-2"></v-divider>Reset
                                </v-btn>

                                    <!-- <v-btn dark tile color="#E46A25" class="text-capitalize px-3 mx-2 my-2" small @click="goBack">
                                        <v-icon dark small>cancel</v-icon>
                                        <v-divider vertical class="mx-2"></v-divider>Close
                                    </v-btn> -->
                            
                        </v-layout>

			</v-card>
		</v-form>

	</div>
</template>
<script>
import moment from "moment";
export default {
    created() {

        this.$store.commit("SAVE_PAGE_HEADER", "CFR Audit Sheet");
        console.log("Success Token", this.$store.getters.successToken);
        this.token = this.$store.getters.successToken;
		this.incmngData = this.$route.params.allData;
		this.populateData(this.incmngData);
    },
	data() {
		return {
            tabstab:'',
            claimsregistrationid:'',
            cfrunVal: false,
            cfrcoVal: true,

            disableWhenComplteCFR:false,
            GetQueData:[],

            abcd2:false,
            GetQueData2:[],

            abcd3:false,
            GetQueData3:[],

            abcd4:false,
            GetQueData4:[],

            abcd5:false,
            GetQueData5:[],

            abcd6:false,
            GetQueData6:[],
            
            getTab22:[],
            getTab2Final:[],

            getTab33:[],
            getTab3Final:[],

            getTab44:[],
            getTab4Final:[],

            getTab55:[],
            getTab5Final:[],

            getTab66:[],
            getTab6Final:[],

            QueryStatus: 'CFRtriggers',
            SelectItems:['Yes','No','NA'],
            SelectItemsRTM:['Yes','No'],
            RecoveryItems:['Partial recovery','Complete recovery'],

            draftCFRVal:false,
            formData:[],

            // tab1one:'',

            // tab2one:'',
            // tab2two:'',
            // tab2three:'',

            // tab3one:'',
            // tab3two:'',
            // tab3three:'',
            // tab3four:'',
            // tab3five:'',
            // tab3six:'',
            // tab3seven:'',
            // tab3eight:'',
            // tab3nine:'',
            // tab3ten:'',
            
            // tab4one:'',
            // tab4two:'',

            // tab5one:'',
            // tab5two:'',
            // tab5three:'',

            // tab6one:'',
            // tab6two:'',
            recoveryToMade:'',
            typeofRecovery:'',

            
			
			valid: false,
			recoveryForm2: false,
			claimNumber: "",
			featureNumber: "",
			memberName: "",
			claimPaidAmount: "",
			providerName: "",
            adjudicatorName: "",
            expensePaid:'',
            netScore:"",
            comments:'',
            userID:'',
            
			chequeDateCalender: false,
			dateCalender: "",
			
			disableFields: false,

            

			//   for filed length
            max30: 30,
            max5: 5,
            max500: 500,
            allGetData:[],

            viewScoreItems: [],
            viewScoreHeader: [
                
                {
                text: "Trigger Number",
                value: "triggerNumber",
                sortable: false,
                divider: true,
                class: "textClass bgColor",
                align: "center",
                },
                {
                text: "Trigger Description",
                value: "triggerDescription",
                sortable: false,
                divider: true,
                class: "textClass bgColor",
                align: "center",
                },
                {
                text: "Trigger Flag",
                value: "triggerFlag",
                sortable: false,
                divider: true,
                class: "textClass bgColor",
                align: "center",
                }
            ],

            rules: {
                required: v => !!v || "This field is required",
                alphaRule: v =>{ 
                                        const pattern = /^[ A-Za-z0-9_@!*$%~,()?<>=^./#&+-]*$/
                                        return v.length <= 0 || pattern.test(v) || 'Enter valid values.'
                    },
            },
            
			
			//rules
			alphaNumericRequired(v) {
				if (v.length > 0) {
					return (v) => /^[a-zA-Z0-9\/ ]+$/.test(v) || "Enter Valid Input";
				} else {
					return (v) => !!v || "This Field is required!";
				}
			},
			numericRequired(v) {
				if (v.length > 0) {
					return (v) => /^[0-9 ]+$/.test(v) || `Only Numbers are allowed.`;
				}else {
					return (v) => !!v || "This Field is required!";
				}
			},
			recoveryRule(v){
				console.log("recover amount and claimPaidAmount",v,this.claimPaidAmount);
				if(Number(v) > Number(this.claimPaidAmount)){
					return (v) => !v || "Enter correct value"
				}
			},
			required(v) {
				if (v.length < 1) {
					return (v) => !!v || "This Field is required!";
				}
			},
			chequeRule(v) {
				if (v.length) {
					return (v) => /^[0-9 ]+$/.test(v) || 'Cheque DD Number to be numeric';
				}
			},

			
		};
	},
	// async mounted() {
	// 	console.log(this.$route.params, "params");

	// 	this.$store.commit("SAVE_PAGE_HEADER", "CFR Audit Sheet");
	// 	this.incmngData = this.$route.params.allData;

	// 	this.populateData(this.incmngData);
		
	// },
	computed: {
		
		chequeDateCalenderMmntStatus() {
			return this.dateCalender ? moment(this.dateCalender).format("DD/MM/YYYY") : "";
		},
	},
	watch: {
		
	},
	methods: {

        //AA for claim Details

            goToclaimDetails(val){
                console.log("val---------===>",val);
                    try{
                        let sellob = {
                            lob:'' ,
                            usr_jti:this.$store.state.Auth.user.signInUserSession.idToken.payload.jti,
                            metaVal : 'clm_Version' +'='+ val.claimRegistrationId  

                        }

                        sellob.lob = 'Health'
                        // let apiis = this.cognito_login()+'session/redirect?lob='+sellob.lob+'&jti='+sellob.usr_jti
                        let apiis = this.cognito_login()+'session/redirect?lob='+sellob.lob+'&jti='+sellob.usr_jti+'&metadata='+sellob.metaVal
                        
                        console.log('apiis------>>>', apiis)
                        window.location.href = apiis

                        return
                    }catch(errct){
                    console.log("error from catch re-direction",errct)
                    }

            },
        
        clearquestion(){
            let apiURL = `ofr-cfr-master/view-cfr-questions?filedType=`+this.tabstab
            this.GET(21, apiURL, (response, error) => {
                if (!error) {
                    let apiData = response.data.data;
                    if (this.disableWhenComplteCFR == false) {
                        // alert('Not complted')
                            console.log('????????????????underCFR que tab== ----===------------>');
                            console.log('GetQueData2 dataaa ---+++++-===>',this.GetQueData2);

                            if (this.abcd2== true && val=='procedureAndDiagnosis') {
                                
                           
                            this.GetQueData = this.GetQueData2
                           
                            // this.GetQueData2
                            console.log('GetQueData2 dataaa ----===>',this.GetQueData);

                            
                            }else if (this.abcd3== true && val=='documentationAndFileManagement') {
                                this.GetQueData = this.GetQueData3
                                // this.GetQueData3
                                console.log('GetQueData3 dataaa ===>',this.GetQueData);
                                
                            }else if (this.abcd4== true && val=='intimation') {
                                this.GetQueData = this.GetQueData4
                                console.log('GetQueData4 dataaa ===>',this.GetQueData);
                                
                            }else if (this.abcd5== true && val=='SLA') {
                                this.GetQueData = this.GetQueData5
                                console.log('GetQueData5 dataaa ===>',this.GetQueData);
                                
                            }else if (this.abcd6== true && val=='overAllRating') {
                                this.GetQueData = this.GetQueData6
                                console.log('GetQueData6 dataaa ===>',this.GetQueData);
                                
                            } else {
                                this.GetQueData = apiData.questions    
                            }
                            
                        } else {
                            // alert('completed')
                            if (this.abcd2== true || val=='procedureAndDiagnosis') {
                            
                            this.GetQueData = this.GetQueData2
                            // this.GetQueData2
                                console.log('GetQueData2 dataaa ----===>',this.GetQueData);

                            
                            }else if (this.abcd3== true || val=='documentationAndFileManagement') {
                                this.GetQueData = this.GetQueData3
                                // this.GetQueData3
                                console.log('GetQueData3 dataaa ===>',this.GetQueData);
                                
                            }else if (this.abcd4== true || val=='intimation') {
                                this.GetQueData = this.GetQueData4
                                console.log('GetQueData4 dataaa ===>',this.GetQueData);
                                
                            }else if (this.abcd5== true || val=='SLA') {
                                this.GetQueData = this.GetQueData5
                                console.log('GetQueData5 dataaa ===>',this.GetQueData);
                                
                            }else if (this.abcd6== true || val=='overAllRating') {
                                this.GetQueData = this.GetQueData6
                                console.log('GetQueData6 dataaa ===>',this.GetQueData);
                                
                            } else {
                                this.GetQueData = apiData.questions
                                console.log('GetQueData dataaa ==>',this.GetQueData);
                                
                            }
                            
                        }

                }else{
                    this.showToast(error.response.data.message.msg,this.TOST().ERROR)
                
                }
            }) 

        },
        changeQueryStatus(val,queType){
          console.log(val,'ww------------');
          this.QueryStatus = val;
          this.tabstab = queType

            console.log('<<< === val dataaa ===>',queType);
          
            let apiURL = `ofr-cfr-master/view-cfr-questions?filedType=`+queType

            this.GET(21, apiURL, (response, error) => {
                if (!error) {
                let apiData = response.data.data;
                // this.GetQueData = apiData.questions
                // console.log('GetQueData dataaa ===>',this.GetQueData);
                        if (this.disableWhenComplteCFR == false) {
                        // alert('Not complted')
                            console.log('????????????????underCFR que tab== ----===------------>');
                            console.log('GetQueData2 dataaa ---+++++-===>',this.GetQueData2);

                            if (this.abcd2== true && val=='procedureAndDiagnosis') {
                                
                           
                            this.GetQueData = this.GetQueData2
                           
                            // this.GetQueData2
                            console.log('GetQueData2 dataaa ----===>',this.GetQueData);

                            
                            }else if (this.abcd3== true && val=='documentationAndFileManagement') {
                                this.GetQueData = this.GetQueData3
                                // this.GetQueData3
                                console.log('GetQueData3 dataaa ===>',this.GetQueData);
                                
                            }else if (this.abcd4== true && val=='intimation') {
                                this.GetQueData = this.GetQueData4
                                console.log('GetQueData4 dataaa ===>',this.GetQueData);
                                
                            }else if (this.abcd5== true && val=='SLA') {
                                this.GetQueData = this.GetQueData5
                                console.log('GetQueData5 dataaa ===>',this.GetQueData);
                                
                            }else if (this.abcd6== true && val=='overAllRating') {
                                this.GetQueData = this.GetQueData6
                                console.log('GetQueData6 dataaa ===>',this.GetQueData);
                                
                            } else {
                                this.GetQueData = apiData.questions
                                if(val=='procedureAndDiagnosis'){
                                    this.fetchquestiondata('procedureAndDiagnosis')
                                }else if(val=='documentationAndFileManagement'){
                                    this.fetchquestiondata('documentationAndFileManagement')
                                }else if(val=='intimation'){
                                    this.fetchquestiondata('intimation')
                                }else if(val=='SLA'){
                                    this.fetchquestiondata('SLA')
                                }else if(val=='overAllRating'){
                                    this.fetchquestiondata('overAllRating')
                                }
                                console.log('GetQueData dataaa ==>',this.GetQueData);
                                
                            }
                            
                        } else {
                            // alert('completed')
                            if (this.abcd2== true || val=='procedureAndDiagnosis') {
                            
                            this.GetQueData = this.GetQueData2
                            // this.GetQueData2
                                console.log('GetQueData2 dataaa ----===>',this.GetQueData);

                            
                            }else if (this.abcd3== true || val=='documentationAndFileManagement') {
                                this.GetQueData = this.GetQueData3
                                // this.GetQueData3
                                console.log('GetQueData3 dataaa ===>',this.GetQueData);
                                
                            }else if (this.abcd4== true || val=='intimation') {
                                this.GetQueData = this.GetQueData4
                                console.log('GetQueData4 dataaa ===>',this.GetQueData);
                                
                            }else if (this.abcd5== true || val=='SLA') {
                                this.GetQueData = this.GetQueData5
                                console.log('GetQueData5 dataaa ===>',this.GetQueData);
                                
                            }else if (this.abcd6== true || val=='overAllRating') {
                                this.GetQueData = this.GetQueData6
                                console.log('GetQueData6 dataaa ===>',this.GetQueData);
                                
                            } else {
                                this.GetQueData = apiData.questions
                                console.log('GetQueData dataaa ==>',this.GetQueData);
                                
                            }
                            
                        }

                }else{
                    this.showToast(error.response.data.message.msg,this.TOST().ERROR)
                
                }
            })


            
          
        },

        updateTab2QueAns(allTab22,answer,index,comletearray) {
            let temobj = []
            temobj = comletearray
            this.getTab2Final = []
            console.log("allTab22",allTab22)
            console.log("INDEX::",index)
            console.log("comletearray",comletearray)
            console.log("answer::",answer)

            
            if(allTab22.hasOwnProperty('answer')){
                console.log("inside::")
                // temobj.splice(index, 1)
                temobj[index].answer = answer
                this.getTab2Final = temobj
                temobj = []
                console.log("temobj",this.getTab2Final)
            }else{
                allTab22.answer = answer
                console.log(allTab22,'tab22 dataaa ===>');     
                this.getTab22.push(allTab22)
                console.log('getTab22 dataaa ===>',this.getTab22);

                let newArray = [];
                let uniqueObject = {};
                let objTitle=[]

                for (let i in this.getTab22) {
      
                    objTitle = this.getTab22[i]['_id'];
        
                    uniqueObject[objTitle] = this.getTab22[i];
                }
              
                // Loop to push unique object into array
                for (let a in uniqueObject) {
                    newArray.push(uniqueObject[a]);
                }
                
                // Display the unique objects
                // temobj[index] = newArray
                console.log('newww-------------------->>',newArray);
                this.getTab2Final= newArray
            }
            

            
        },

        updateTab3QueAns(allTab33, answer,index,comletearray) {

            let temobj = comletearray
            console.log("allTab33",allTab33)
            console.log("INDEX::",index)
            console.log("comletearray",comletearray)
            console.log("answer::",answer)


            if(allTab33.hasOwnProperty('answer')){
                temobj[index].answer = answer
                this.getTab3Final = temobj
                console.log("temobj",this.getTab3Final)
            }else{
                allTab33.answer = answer
                this.getTab33.push(allTab33)
                let newArray = [];
                let uniqueObject = {};
                let objTitle=[]

                for (let i in this.getTab33) {
                    objTitle = this.getTab33[i]['_id'];
                    uniqueObject[objTitle] = this.getTab33[i];
                }
                // Loop to push unique object into array
                for (let a in uniqueObject) {
                    newArray.push(uniqueObject[a]);
                }
                // Display the unique objects
                console.log('newww-------------------->>',newArray);
                this.getTab3Final= newArray
            }
                
        },

        updateTab4QueAns(allTab44, answer,index,comletearray) {
            let temobj = comletearray
            console.log("allTab44",allTab44)
            console.log("INDEX::",index)
            console.log("comletearray",comletearray)
            console.log("answer::",answer)


            if(allTab44.hasOwnProperty('answer')){
                temobj[index].answer = answer
                this.getTab4Final = temobj
                console.log("temobj",this.getTab4Final)
            }else{
                allTab44.answer = answer
                
                this.getTab44.push(allTab44)
                let newArray = [];
                let uniqueObject = {};
                let objTitle=[]

                for (let i in this.getTab44) {
                    objTitle = this.getTab44[i]['_id'];
                    uniqueObject[objTitle] = this.getTab44[i];
                }
                // Loop to push unique object into array
                for (let a in uniqueObject) {
                    newArray.push(uniqueObject[a]);
                }
                // Display the unique objects
                console.log('newww-------------------->>',newArray);
                this.getTab4Final= newArray
            }
                
        },

        updateTab5QueAns(allTab55, answer,index,comletearray) {
            let temobj = comletearray
            console.log("allTab55",allTab55)
            console.log("INDEX::",index)
            console.log("comletearray",comletearray)
            console.log("answer::",answer)


            if(allTab55.hasOwnProperty('answer')){
                temobj[index].answer = answer
                this.getTab5Final = temobj
                console.log("temobj",this.getTab5Final)
            }else{
                allTab55.answer = answer
                
                this.getTab55.push(allTab55)
                let newArray = [];
                let uniqueObject = {};
                let objTitle=[]

                for (let i in this.getTab55) {
                    objTitle = this.getTab55[i]['_id'];
                    uniqueObject[objTitle] = this.getTab55[i];
                }
                // Loop to push unique object into array
                for (let a in uniqueObject) {
                    newArray.push(uniqueObject[a]);
                }
                // Display the unique objects
                console.log('newww-------------------->>',newArray);
                this.getTab5Final= newArray
            }
            
        },

        updateTab6QueAns(allTab66, answer,index,comletearray) {
            let temobj = comletearray
            console.log("allTab66",allTab66)
            console.log("INDEX::",index)
            console.log("comletearray",comletearray)
            console.log("answer::",answer)


            if(allTab66.hasOwnProperty('answer')){
                temobj[index].answer = answer
                this.getTab6Final = temobj
                console.log("temobj",this.getTab6Final)
            }else{
                allTab66.answer = answer
                this.getTab66.push(allTab66)
                let newArray = [];
                let uniqueObject = {};
                let objTitle=[]

                for (let i in this.getTab66) {
                    objTitle = this.getTab66[i]['_id'];
                    uniqueObject[objTitle] = this.getTab66[i];
                }
                // Loop to push unique object into array
                for (let a in uniqueObject) {
                    newArray.push(uniqueObject[a]);
                }
                // Display the unique objects
                console.log('newww-------------------->>',newArray);
                this.getTab6Final= newArray
            }
                
        },




		reasonRule(v){
			console.log(v,'dd');
			// alert(1)
					console.log('hhhh')
					if (v.length < 1) {
						console.log('hhhh')
						return (v) => !!v || "This Field is required!";
					}
			},



		goBack() {
			this.$router.push("/CFRdashboard");
		},

        fetchquestiondata(tabsname){
            let apiURL = `cfr/cfr-by-id?claimRegistrationId=`+this.claimsregistrationid
            this.GET(20, apiURL, (response, error) => {
                if (!error) {
                    let apiData = response.data.data;
                    console.log("get data>>>>",apiData)

                    if(tabsname == 'procedureAndDiagnosis'){
                        if(apiData.procedureAndDiagnosis.length > 0){
                            this.GetQueData = apiData.procedureAndDiagnosis
                        }
                    }

                    if(tabsname == 'documentationAndFileManagement'){
                        if(apiData.documentationAndFileManagement.length > 0){
                            this.GetQueData = apiData.documentationAndFileManagement
                        }
                    }

                    if(tabsname == 'intimation'){
                        if(apiData.intimation.length > 0){
                            this.GetQueData = apiData.intimation
                        }
                    }

                    if(tabsname == 'SLA'){
                        if(apiData.SLA.length > 0){
                            this.GetQueData = apiData.SLA
                        }
                    }

                    if(tabsname == 'overAllRating'){
                        if(apiData.overAllRating.length > 0){
                            this.GetQueData = apiData.overAllRating
                        }
                    }
                    


                    

                }else{
                    this.showToast(error.response.data.message.msg,this.TOST().ERROR)
                
                }
            })
        },
        populateData(val) {
            console.log('<<< === val dataaa ===>',val);
            this.claimsregistrationid = val.claimRegistrationId
            
          
            // let apiURL = `cfr/cfr-by-id/`+val._id
            let apiURL = `cfr/cfr-by-id?claimRegistrationId=`+val.claimRegistrationId

            this.GET(20, apiURL, (response, error) => {
                if (!error) {
                let apiData = response.data.data;
                this.allGetData = apiData
                console.log(this.allGetData,'cfr api dataaa ----===>',apiData)

                this.triggerCFRScoreClick(this.allGetData)
                
                    this.claimNumber =apiData.claimId ? apiData.claimId : "--"
                    this.featureNumber =apiData.featureNumber ? apiData.featureNumber : "--"
                    this.memberName =apiData.memberName ? apiData.memberName : "--",
                    this.claimPaidAmount =apiData.claimedAmountPaid ? apiData.claimedAmountPaid : "--",
                    this.providerName =apiData.hospitalName ? apiData.hospitalName : "--",
                    this.adjudicatorName =apiData.adjudicatorName ? apiData.adjudicatorName : "--",
                    // this.expensePaid =apiData.,
                    this.netScore =apiData.netScore ? apiData.netScore : "--"  ,
                    this.comments =apiData.comments ? apiData.comments : "",
                    this.userID =apiData.userId ? apiData.userId : "--"
                    // this.dateCalender =apiData.TAT ? moment(apiData.TAT).format("DD/MM/YYYY") : "--";
                    

                    if (apiData.procedureAndDiagnosis.length !=0) {
                        this.abcd2 == true
                        this.GetQueData2 = apiData.procedureAndDiagnosis

                        console.log('GetQueData2 cfr api dataaa ----===>',this.GetQueData2)
                        // this.tab2one = apiData.procedureAndDiagnosis[0].answer
                        // this.tab2two = apiData.procedureAndDiagnosis[1].answer
                        // this.tab2three = apiData.procedureAndDiagnosis[2].answer
                    }

                    if (apiData.documentationAndFileManagement.length !=0) {
                        this.abcd3 == true
                        this.GetQueData3 = apiData.documentationAndFileManagement
                    console.log('if cfr api dataaa documentationAndFileManagement----===>',this.abcd3)
                        // this.tab3one = apiData.documentationAndFileManagement[0].answer
                        // this.tab3two = apiData.documentationAndFileManagement[1].answer
                        // this.tab3three = apiData.documentationAndFileManagement[2].answer
                        // this.tab3four = apiData.documentationAndFileManagement[3].answer
                        // this.tab3five = apiData.documentationAndFileManagement[4].answer
                        // this.tab3six = apiData.documentationAndFileManagement[5].answer
                        // this.tab3seven = apiData.documentationAndFileManagement[6].answer
                        // this.tab3eight = apiData.documentationAndFileManagement[7].answer
                        // this.tab3nine = apiData.documentationAndFileManagement[8].answer
                        // this.tab3ten = apiData.documentationAndFileManagement[9].answer

                    }
                    // else{
                    //     this.abcd3 == false
                    //     this.changeQueryStatus('documentationAndFileManagement','Documentation And File Management')
                    //     console.log('else cfr api dataaa documentationAndFileManagement----===>',this.abcd3)

                    // }

                    if (apiData.intimation.length !=0) {
                        this.abcd4 == true
                        this.GetQueData4 = apiData.intimation
                        // this.tab4one = apiData.intimation[0].answer
                        // this.tab4two = apiData.intimation[1].answer

                    }

                    if (apiData.SLA.length !=0) {
                        this.abcd5 == true
                        this.GetQueData5 = apiData.SLA
                        // this.tab5one = apiData.SLA[0].answer
                        // this.tab5two = apiData.SLA[1].answer
                        // this.tab5three = apiData.SLA[2].answer

                    }
                    if (apiData.overAllRating.length !=0) {
                        this.abcd6 == true
                        this.GetQueData6 = apiData.overAllRating
                        // this.tab6one = apiData.overAllRating[0].answer
                        // this.tab6two = apiData.overAllRating[1].answer

                    }
                     this.recoveryToMade= apiData.recoveryToMade
                        this.typeofRecovery= apiData.typeOfRecovery

                    if (apiData.cfrCompletedCase == true) {
                        this.disableWhenComplteCFR = true
                        
                    }

                    if (this.disableWhenComplteCFR == false) {
                        // this.dateCalender = moment(new Date()).format("DD/MM/YYYY")
                        this.dateCalender = new Date() ;
                     
                        console.log("CALEDAR DATEE::",this.dateCalender)
                    }else{
                        this.dateCalender = apiData.Date
                    }


                }else{
                    this.showToast(error.response.data.message.msg,this.TOST().ERROR)
                
                }
            })
        },

        triggerCFRScoreClick(val){
             console.log("selected triggerCFRScoreClick ----->>>",val);
            // if (this.claimNumber!=0) {

                let apiURL = `cfr/view-cfr-trigger-score?claimId=`+val.claimId;
                
                this.GET(20, apiURL, (res, error) => {
                    if (!error) {
                        this.showToast(res.data.msg, this.TOST().SUCCESS);
                        let resArray = res.data.data.trigger
                        console.log("resArray indata------->",resArray)
                            this.viewScoreItems = resArray.map(itemTriArr => {
                                return {
                                        triggerNumber : itemTriArr.triggerNumber,
                                        triggerFlag : itemTriArr.triggerFlag,
                                        triggerDescription : itemTriArr.triggerDescription,

                                        // triggerScore : itemTriArr.cfrTriggerScore ? itemTriArr.cfrTriggerScore : '--',
                                }
                            })
                        console.log("this.viewScoreItems ------->",this.viewScoreItems)

                        // this.viewScoreData=true
                    } else {
                        this.showToast(error.response.data.msg, this.TOST().ERROR);
                    }
                });

            // }else{
            //     this.showToast('Trigger Score not valid.',this.TOST().WARNING)
            //     return;
            // }

                
        },
        

        async saveAsDraft() {
            this.draftCFRVal= true
            this.saveCFR()
        
        },

		


        async saveCFR() {
			if (this.$refs.recoveryForm.validate() ) {
			
                let apiURL = `cfr/update-cfr-audit-sheet/`+this.allGetData._id;

                this.formData =[]
                
                    if (this.draftCFRVal== true) {



                        this.formData = {
                
                                _id: this.allGetData._id,
                                auditType:this.QueryStatus,
                                caseUnderCFR: this.allGetData.caseUnderCFR,
                                cfrCompletedCase: this.allGetData.cfrCompletedCase,
                                claimRegistrationId: this.allGetData.claimRegistrationId,
                                claimId: this.claimNumber,
                                featureNumber: this.featureNumber,
                                memberName: this.memberName,
                                // corporateGroupName: "",
                                coverage: this.allGetData.coverage,

                                insuredName: this.allGetData.insuredName,
                                policyNumber: this.allGetData.policyNumber,
                                hospitalName: this.providerName,
                                patientName: this.allGetData.patientName,
                                productName: this.allGetData.productName,
                                totalAmountApproved: this.claimPaidAmount,
                                claimedAmountPaid: this.claimPaidAmount,
                                claimedAmount: this.allGetData.claimedAmount,
                                adjudicatorName: this.adjudicatorName,
                                claimantName: this.allGetData.claimantName,
                                sumInsured: this.allGetData.sumInsured,
                                reserveAmount: this.allGetData.reserveAmount,
                                litigationType: this.allGetData.litigationType,
                                TAT: this.allGetData.TAT,
                               
                               
                            //    if (abcd1== true) {
                            //         procedureAndDiagnosis: this.GetQueData1
                            //     } else {
                            //         procedureAndDiagnosis: this.getTab2Final
                            //     },

                            procedureAndDiagnosis: this.abcd2== false ? this.getTab2Final : this.GetQueData2,
                            documentationAndFileManagement: this.abcd3== false ? this.getTab3Final : this.GetQueData3 ,
                            intimation: this.abcd4== false ? this.getTab4Final : this.GetQueData4 ,
                            SLA: this.abcd5== false ? this.getTab5Final : this.GetQueData5 ,
                            overAllRating: this.abcd6== false ? this.getTab6Final : this.GetQueData6 ,

                                
                                recoveryToMade:this.recoveryToMade,
                                typeOfRecovery:this.typeofRecovery,

                                comments:this.comments,
                                userId:this.userID,
                                Date:this.dateCalender,
                                cfrId: this.allGetData.cfrId
                    }
                    console.log("if formdata -=------>", this.formData)

                    }else{

                        if (this.getTab2Final.length ===0 ||this.getTab3Final.length ===0 ||this.getTab4Final.length ===0 ||this.getTab5Final.length ===0 ||this.getTab6Final.length ===0 ) {
                            this.showToast("Please fill all tab required fields.", this.TOST().WARNING);
				            return;
                                
                        } else {
                           this.formData = {
                
                                _id: this.allGetData._id,
                                auditType:this.QueryStatus,
                                // caseUnderCFR: this.allGetData.caseUnderCFR,
                                // cfrCompletedCase: this.allGetData.cfrCompletedCase,
                                // caseUnderCFR:false,
                                // cfrCompletedCase:true,

                                caseUnderCFR: this.cfrunVal,
                                cfrCompletedCase: this.cfrcoVal,

                                claimRegistrationId: this.allGetData.claimRegistrationId,
                                claimId: this.claimNumber,
                                featureNumber: this.featureNumber,
                                memberName: this.memberName,
                                // corporateGroupName: "",
                                coverage: this.allGetData.coverage,

                                insuredName: this.allGetData.insuredName,
                                policyNumber: this.allGetData.policyNumber,
                                hospitalName: this.providerName,
                                patientName: this.allGetData.patientName,
                                productName: this.allGetData.productName,
                                totalAmountApproved: this.claimPaidAmount,
                                claimedAmountPaid: this.claimPaidAmount,
                                claimedAmount: this.allGetData.claimedAmount,
                                adjudicatorName: this.adjudicatorName,
                                claimantName: this.allGetData.claimantName,
                                sumInsured: this.allGetData.sumInsured,
                                reserveAmount: this.allGetData.reserveAmount,
                                litigationType: this.allGetData.litigationType,
                                TAT: this.allGetData.TAT,

                            procedureAndDiagnosis: this.abcd2== false ? this.getTab2Final : this.GetQueData2,
                            documentationAndFileManagement: this.abcd3== false ? this.getTab3Final : this.GetQueData3 ,
                            intimation: this.abcd4== false ? this.getTab4Final : this.GetQueData4 ,
                            SLA: this.abcd5== false ? this.getTab5Final : this.GetQueData5 ,
                            overAllRating: this.abcd6== false ? this.getTab6Final : this.GetQueData6 ,

                                
                                recoveryToMade:this.recoveryToMade,
                                typeOfRecovery:this.typeofRecovery,

                                comments:this.comments,
                                userId:this.userID,
                                Date:this.dateCalender,
                                cfrId: this.allGetData.cfrId

                                
                            }
                            console.log("else formdata -=------>", this.formData)

                        }

            

                    }
                    console.log("Final Formdata", this.formData)
                
                await this.PUT(20, apiURL, this.formData, (res, error) => {
                    if (!error) {
                        this.showToast(res.data.msg, this.TOST().SUCCESS);
                        
                        if (this.draftCFRVal== true) {
                            
                            this.populateData(this.incmngData)
                            this.draftCFRVal=false
                            console.log("if redirection       -=------>", this.draftCFRVal)

                        }else{
                            
                            this.draftCFRVal=false
                            console.log("else redirection     -=------>",this.draftCFRVal)
                            this.$router.push({name: 'CFRdashboard' })
                        }

                        
                    } else {
                        this.showToast(error.response.data.msg, this.TOST().ERROR);
                    }
                });
            }else{
                this.$refs.recoveryForm.validate();
                // this.$refs.tab2Form.validate();
                
				this.showToast("Please fill required fields", this.TOST().WARNING);
				return;
			}
		},


		clearData() {
			console.log("nknknkn")
            
			this.comments = "";

            // this.userID = "";
            // this.dateCalender = "";

            // this.tab1one= "";

            // this.tab2one= "";
            // this.tab2two= "";
            // this.tab2three= "";

            // this.tab3one= "";
            // this.tab3two= "";
            // this.tab3three= "";
            // this.tab3four= "";
            // this.tab3five= "";
            // this.tab3six= "";
            // this.tab3seven= "";
            // this.tab3eight= "";
            // this.tab3nine= "";
            // this.tab3ten= "";
            
            // this.tab4one= "";
            // this.tab4two= "";

            // this.tab5one= "";
            // this.tab5two= "";
            // this.tab5three= "";

            // this.tab6one= "";
            // this.tab6two= "";

            this.recoveryToMade= "";
            this.typeofRecovery= "";

            this.clearquestion()
            

		},

	

	},
};
</script>

<style scoped>
.text-dark-insrns1 {
    color: #10242b;
    font-size: 11px;
}
.text-white-insrns1 {
    color: #fff;
    font-size: 11px;
}

.iconAlign >>> .v-input__slot{
	padding-left: 0 !important;
}
</style>
