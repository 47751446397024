<template>
  <v-form ref="pdfForm">
    <div id="panelPdf">
      <table style="border: 1px solid #999999" width="1450" border="0" cellpadding="0" cellspacing="0" class="tb">
        <tbody>
          <tr>
            <td height="25" colspan="4" align="center" class="txt" style="
                border-bottom: 1px solid #ddd;
                background: yellow;
                font-weight: 600;
                font-size: 13px;
                font-family: 'Muli', sans-serif;
              ">
              TAX INVOICE U/S 31 OF GST Bill, 2017 (As recommended in draft
              invoicing Rule 1&7)
            </td>
          </tr>
          <tr>
            <td height="35" colspan="4" align="left" class="txt" style="
                border-bottom: 1px solid #ddd;
                font-size: 13px;
                font-family: 'Muli', sans-serif;
                color: red;
              ">
              Suppliers Name : Tata AIG General Insurance Company limited <br />
              Suppliers Address : MUMBAI - 400065
            </td>
          </tr>
          <tr>
            <td height="25" colspan="4" align="left" class="txt" style="
                border-bottom: 1px solid #ddd;
                font-size: 13px;
                font-family: 'Muli', sans-serif;
              ">
              Tel : {{ contactDetail }} &nbsp; Email : {{ email }}
            </td>
          </tr>
          <tr>
            <td height="25" colspan="4" align="right" class="txt" style="
                border-bottom: 2px solid black;
                font-size: 13px;
                font-family: 'Muli', sans-serif;
                color: red;
              ">
              GSTIN of Supplier : 06AABCT3518Q1Z0
            </td>
            &nbsp;
          </tr>

          <tr>
            <td height="25" colspan="4" align="right" class="txt" style="
                border-bottom: 2px solid black;
                font-size: 13px;
                font-family: 'Muli', sans-serif;
                color: red;
              "></td>
            &nbsp;
          </tr>

          <br />
          <tr>
            <td colspan="2">
              <table width="200%" border="0" cellpadding="0" cellspacing="0" class="tb1">
                <tbody>
                  <tr>
                    <td>
                      <table style="border: 1px solid #999999" width="100%" border="1" cellpadding="0" cellspacing="0"
                        class="tb2">
                        <tbody>
                          <tr style="
                              font-size: 13px;
                              color: #000;
                              padding: 5px;
                              font-family: 'Muli', sans-serif;
                            ">
                            <td height="30" align="center" width="10%">
                              <strong>Sold To</strong>
                            </td>
                            <td align="center"></td>
                            <td align="center"></td>
                            <td align="center"></td>
                            <td align="center"></td>
                            <td align="center"></td>
                            <td align="center"></td>
                            <td align="center"></td>
                            <td align="center"></td>
                            <td align="center"></td>
                            <td align="center" width="10%">Invoice No.</td>
                            <td align="center"></td>
                            <td align="center"></td>
                          </tr>
                          <tr style="
                              font-size: 13px;
                              color: #000;
                              padding: 5px;
                              font-family: 'Muli', sans-serif;
                            ">
                            <td height="30" align="center" style="color: red">
                              <strong>Receipient Name</strong>
                            </td>
                            <td align="center" colspan="9">
                              {{ paymentRecoveryObj.payerName }}
                            </td>
                            <!-- <td align="center"></td>
                                                          <td align="center"></td>
                                                          <td align="center"></td>
                                                          <td align="center"></td>
                                                          <td align="center"></td>
                                                          <td align="center"></td>
                                                          <td align="center"></td>
                                                          <td align="center"></td> -->
                            <td align="center" style="color: red">
                              Date of issue
                            </td>
                            <td align="center" colspan="2">
                              {{ ISOtoDDMMYYComputed(paymentRecoveryObj.createdAt) }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td>&nbsp;</td>
                  </tr>

                  <tr>
                    <td>&nbsp;</td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>

          <tr>
            <td height="45" colspan="4" align="left" class="txt" style="
                border-bottom: 1px solid #ddd;
                font-size: 13px;
                font-family: 'Muli', sans-serif;
              ">
              Recipient Address: {{ReceiptAddress}}
            </td>
          </tr>

          <tr>
            <td height="45" colspan="4" class="txt" style="
                border-bottom: 1px solid #ddd;
                font-size: 13px;
                font-family: 'Muli', sans-serif;
                color: red;
              ">
              <v-layout>
                <v-flex xs9>
                  <span align="left">Place of Supply (along with state name) : </span>&nbsp; &nbsp;
                  {{ paymentRecoveryObj.salvageBuyerState }}</v-flex>

                <span align="right">Place of Supply (State code) : &nbsp; &nbsp;
                  {{ paymentRecoveryObj.salvageBuyerGSTNumber && paymentRecoveryObj.salvageBuyerGSTNumber.slice(0, 2) }}
                </span>
              </v-layout>
            </td>
          </tr>

          <tr>
            <td height="25" colspan="4" align="left" class="txt" style="
                border-bottom: 1px solid #ddd;
                font-size: 13px;
                font-family: 'Muli', sans-serif;
                color: red;
              ">
              GSTIN of the recipient/ Unique ID* : &nbsp; &nbsp; &nbsp;
              {{ paymentRecoveryObj.salvageBuyerGSTNumber }}
            </td>
          </tr>

          <tr>
            <td height="25" colspan="4" align="left" class="txt" style="
                border-bottom: 1px solid #ddd;
                font-size: 13px;
                font-family: 'Muli', sans-serif;
              ">
              PAN : {{panNumberForInvoice}}
            </td>
          </tr>

          <tr>
            <td height="25" colspan="4" align="left" class="txt" style="
                border-bottom: 1px solid #ddd;
                font-size: 13px;
                font-family: 'Muli', sans-serif;
              ">
              Attention To : Contact Person &nbsp; &nbsp; &nbsp;
              {{ paymentRecoveryObj.payerName }}
            </td>
          </tr>

          <tr>
            <td height="25" colspan="4" align="left" class="txt" style="
                border-bottom: 1px solid #ddd;
                font-size: 13px;
                font-family: 'Muli', sans-serif;
              ">
              Contact detail of the customer: {{contactDetail}}
            </td>
          </tr>

          <tr>
            <td height="25" colspan="4" align="left" class="txt" style="
                border-bottom: 1px solid #ddd;
                font-size: 13px;
                font-family: 'Muli', sans-serif;
                color: red;
              "></td>
          </tr>

          <tr>
            <td width="3%">&nbsp;</td>
            <td colspan="2">
              <table width="100%" border="0" cellpadding="0" cellspacing="0" class="tb1">
                <tbody>
                  <tr>
                    <td>
                      <table style="border: 1px solid #999999" width="100%" border="1" cellpadding="0" cellspacing="0"
                        class="tb2">
                        <tbody>
                          <tr style="
                              font-size: 13px;
                              color: red;
                              padding: 5px;
                              font-family: 'Muli', sans-serif;
                            ">
                            <td height="30" align="center" width="10%">
                              <strong>S.N</strong>
                            </td>
                            <td align="center">
                              <strong>Description </strong>
                            </td>
                            <td align="center"><strong>HSN </strong></td>
                            <td align="center">
                              <strong>Taxable Value </strong>
                            </td>
                            <td align="center" colspan="2">
                              <strong>IGST </strong>
                            </td>
                            <td align="center" colspan="2">
                              <strong>CGST</strong>
                            </td>
                            <td align="center" colspan="2">
                              <strong>SGST</strong>
                            </td>
                            <td align="center" colspan="2">
                              <strong>CESS</strong>
                            </td>
                            <td align="center" colspan="2">
                              <strong>Total Value</strong>
                            </td>
                          </tr>
                          <tr style="
                              font-size: 13px;
                              color: red;
                              padding: 5px;
                              font-family: 'Muli', sans-serif;
                            ">
                            <td height="30" align="center"></td>
                            <td align="center"></td>
                            <td align="center"></td>
                            <td align="center"></td>
                            <td align="center">Rate</td>
                            <td align="center">Amount</td>
                            <td align="center">Rate</td>
                            <td align="center">Amount</td>
                            <td align="center">Rate</td>
                            <td align="center">Amount</td>
                            <td align="center">Rate</td>
                            <td align="center">Amount</td>
                            <td colspan="2"></td>
                          </tr>
                          <tr style="
                              font-size: 13px;
                              color: #000;
                              padding: 5px;
                              font-family: 'Muli', sans-serif;
                            " v-for="(
                              obj, index
                            ) in paymentRecoveryObj.recoveryInformation" :key="index">
                            <td height="30" align="center">{{ index + 1 }}</td>
                            <td align="center">{{ obj.commodityName }}</td>
                            <td align="center">{{ obj.HSNSACCode }}</td>
                            <td align="center">{{ obj.amount }}</td>
                            <td align="center">{{ obj.taxRate }}</td>
                            <td align="center">{{ obj.IGST || 0 }}</td>
                            <td align="center">{{ obj.taxRate }}</td>
                            <td align="center">{{ obj.CGST || 0 }}</td>
                            <td align="center">{{ obj.taxRate }}</td>
                            <td align="center">{{ obj.SGST || 0 }}</td>
                            <td align="center">{{ obj.taxRate || 0 }}</td>
                            <td align="center">{{ obj.CESS || 0 }}</td>
                            <td colspan="2" align="center">
                              {{
                                  roundOffValue(obj.amount) +
                                  (roundOffValue(obj.IGST) || 0) +
                                  (roundOffValue(obj.CGST) || 0) +
                                  (roundOffValue(obj.SGST) || 0) +
                                  (roundOffValue(obj.CESS) || 0)
                              }}
                            </td>
                          </tr>
                          <tr style="
                              font-size: 13px;
                              color: #000;
                              padding: 5px;
                              font-family: 'Muli', sans-serif;
                            ">
                            <td height="30" align="center" colspan="2" width="28%;">
                              &nbsp;Total Amount Due
                            </td>

                            <td align="center">&nbsp;</td>
                            <td align="center"><strong></strong></td>
                            <td align="center">&nbsp;</td>
                            <td align="center">&nbsp;</td>
                            <td align="center">&nbsp;</td>
                            <td align="center">&nbsp;</td>
                            <td align="center">&nbsp;</td>
                            <td align="center">&nbsp;</td>
                            <td align="center">&nbsp;</td>
                            <td align="center">&nbsp;</td>
                            <td colspan="2" align="center">
                              {{ this.paymentRecoveryObj.netAmount }}
                            </td>
                          </tr>

                          <tr style="
                              font-size: 13px;
                              color: #000;
                              padding: 5px;
                              font-family: 'Muli', sans-serif;
                            ">
                            <td height="30" align="center" colspan="2">
                              &nbsp;Amount received in advance (if any)
                            </td>

                            <td align="center">&nbsp;</td>
                            <td align="center"><strong></strong></td>
                            <td align="center">&nbsp;</td>
                            <td align="center">&nbsp;</td>
                            <td align="center">&nbsp;</td>
                            <td align="center">&nbsp;</td>
                            <td align="center">&nbsp;</td>
                            <td align="center">&nbsp;</td>
                            <td align="center">&nbsp;</td>
                            <td align="center">&nbsp;</td>
                            <td colspan="2" align="center">&nbsp;</td>
                          </tr>

                          <tr style="
                              font-size: 13px;
                              color: #000;
                              padding: 5px;
                              font-family: 'Muli', sans-serif;
                            ">
                            <td height="30" align="center" colspan="2">
                              &nbsp;"Transaction ID No. ------- (Generated at
                              the time of payment of GST on advance)"
                            </td>

                            <td align="center">&nbsp;</td>
                            <td align="center"><strong></strong></td>
                            <td align="center">&nbsp;</td>
                            <td align="center">&nbsp;</td>
                            <td align="center">&nbsp;</td>
                            <td align="center">&nbsp;</td>
                            <td align="center">&nbsp;</td>
                            <td align="center">&nbsp;</td>
                            <td align="center">&nbsp;</td>
                            <td align="center">&nbsp;</td>
                            <td colspan="2" align="center">&nbsp;</td>
                          </tr>

                          <tr style="
                              font-size: 13px;
                              color: #000;
                              padding: 5px;
                              font-family: 'Muli', sans-serif;
                            ">
                            <td height="30" align="center" colspan="2">
                              &nbsp;Balance payable
                            </td>

                            <td align="center">&nbsp;</td>
                            <td align="center"><strong></strong></td>
                            <td align="center">&nbsp;</td>
                            <td align="center">&nbsp;</td>
                            <td align="center">&nbsp;</td>
                            <td align="center">&nbsp;</td>
                            <td align="center">&nbsp;</td>
                            <td align="center">&nbsp;</td>
                            <td align="center">&nbsp;</td>
                            <td align="center">&nbsp;</td>
                            <td colspan="2" align="center">&nbsp;</td>
                          </tr>

                          <tr style="
                              font-size: 13px;
                              color: #000;
                              padding: 5px;
                              font-family: 'Muli', sans-serif;
                            ">
                            <td height="25" align="left" colspan="13" style="color: red">
                              &nbsp;Total Invoice value (In figure)
                            </td>

                            <td align="center">
                              {{ this.paymentRecoveryObj.netAmount }}
                            </td>
                          </tr>

                          <tr style="
                              font-size: 13px;
                              color: #000;
                              padding: 5px;
                              font-family: 'Muli', sans-serif;
                            ">
                            <td height="25" align="left" colspan="13" style="color: red">
                              &nbsp;Total Invoice value (In words)
                            </td>

                            <td align="center">&nbsp;{{inWords(this.paymentRecoveryObj.netAmount)}}</td>
                          </tr>
                          <tr style="
                              font-size: 13px;
                              color: #000;
                              padding: 5px;
                              font-family: 'Muli', sans-serif;
                            ">
                            <td height="25" align="left" colspan="13" style="color: red">
                              &nbsp;Amount of tax subject to reverse charge
                            </td>

                            <td align="center">&nbsp;</td>
                          </tr>

                          <tr style="
                              font-size: 13px;
                              color: #000;
                              padding: 5px;
                              font-family: 'Muli', sans-serif;
                              font-weight: 600;
                            ">
                            <td height="25" align="left" colspan="14">
                              &nbsp;Remarks, if any :
                              {{ this.paymentRecoveryObj.remarks }}
                            </td>
                          </tr>
                          <tr style="
                              font-size: 13px;
                              color: #000;
                              padding: 5px;
                              font-family: 'Muli', sans-serif;
                              font-weight: 600;
                            "></tr>
                          <tr style="
                              font-size: 13px;
                              color: #000;
                              padding: 5px;
                              font-family: 'Muli', sans-serif;
                              font-weight: 600;
                            ">
                            <td height="25" align="left" colspan="14">
                              &nbsp;Payment Details:
                            </td>
                          </tr>

                          <tr style="
                              font-size: 13px;
                              color: #000;
                              padding: 5px;
                              font-family: 'Muli', sans-serif;
                            ">
                            <td height="25" align="left" colspan="14">
                              &nbsp;Please credit the amount in our bank
                              account. Details are as follows:
                            </td>
                          </tr>

                          <tr style="
                              font-size: 13px;
                              color: #000;
                              padding: 5px;
                              font-family: 'Muli', sans-serif;
                            ">
                            <td align="center">1</td>
                            <td height="25" align="left" colspan="13">
                              Bank Name :
                              {{ this.paymentRecoveryObj.payeeBankName }}
                            </td>
                          </tr>

                          <tr style="
                              font-size: 13px;
                              color: #000;
                              padding: 5px;
                              font-family: 'Muli', sans-serif;
                            ">
                            <td align="center">2</td>
                            <td height="25" align="left" colspan="13">
                              Bank Branch :
                              {{ this.paymentRecoveryObj.payeeBankBranch }}
                            </td>
                          </tr>

                          <tr style="
                              font-size: 13px;
                              color: #000;
                              padding: 5px;
                              font-family: 'Muli', sans-serif;
                            ">
                            <td align="center">3</td>
                            <td height="25" align="left" colspan="13">
                              Account No:
                            </td>
                          </tr>

                          <tr style="
                              font-size: 13px;
                              color: #000;
                              padding: 5px;
                              font-family: 'Muli', sans-serif;
                            ">
                            <td align="center">4</td>
                            <td height="25" align="left" colspan="13">
                              IFSC : {{ this.paymentRecoveryObj.payeeBankDetails }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td>&nbsp;</td>
                  </tr>

                  <tr>
                    <td>&nbsp;</td>
                  </tr>
                </tbody>
              </table>
            </td>
            <td width="3%">&nbsp;</td>
          </tr>
          <tr>
            <td colspan="4">&nbsp;</td>
          </tr>
          <tr>
            <td height="32">&nbsp;</td>
            <td style="
                font-size: 13px;
                color: #000;
                padding: 5px;
                font-family: 'Muli', sans-serif;
              " width="47%" height="32">
              <strong>Declaration Company's PAN : AABCT3518Q</strong>
            </td>
            <td style="
                font-size: 13px;
                color: #000;
                padding: 5px;
                font-family: 'Muli', sans-serif;
              " width="47%" align="right">
              <strong>For : Tata AIG General Insurance Company limited</strong>
            </td>
            <td height="32">&nbsp;</td>
          </tr>
          <tr>
            <td colspan="4">&nbsp;</td>
          </tr>
          <tr>
            <td height="72">&nbsp;</td>
            <td>&nbsp;</td>
            <td style="
                font-size: 13px;
                color: #000;
                padding: 5px;
                font-family: 'Muli', sans-serif;
              " align="right" valign="bottom">
              <strong>Authorised Signature</strong>
            </td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td colspan="4">&nbsp;</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div id="panelWord">
      <div class="WordSection1">
        <table style="border: 1px solid #999999; width: 100%" border="0" cellpadding="0" cellspacing="0"
          class="tb WordSection1">
          <tbody>
            <tr>
              <td height="25" colspan="4" align="center" class="txt" style="
                  border-bottom: 1px solid #ddd;
                  background: yellow;
                  font-weight: 600;
                  font-size: 13px;
                  font-family: 'Muli', sans-serif;
                ">
                TAX INVOICE U/S 31 OF GST Bill, 2017 (As recommended in draft
                invoicing Rule 1&7)
              </td>
            </tr>
            <tr>
              <td height="35" colspan="4" align="left" class="txt" style="
                  border-bottom: 1px solid #ddd;
                  font-size: 13px;
                  font-family: 'Muli', sans-serif;
                  color: red;
                ">
                Suppliers Name : Tata AIG General Insurance Company limited
                <br />
                Suppliers Address : MUMBAI - 400065
              </td>
            </tr>
            <tr>
              <td height="25" colspan="4" align="left" class="txt" style="
                  border-bottom: 1px solid #ddd;
                  font-size: 13px;
                  font-family: 'Muli', sans-serif;
                ">
                Tel :{{ contactDetail }} &nbsp; Email : {{ email }}
              </td>
            </tr>
            <tr>
              <td height="25" colspan="4" align="left" class="txt" style="
                  border-bottom: 2px solid black;
                  font-size: 13px;
                  font-family: 'Muli', sans-serif;
                  color: red;
                ">
                GSTIN of Supplier : 06AABCT3518Q1Z0
              </td>
              &nbsp;
            </tr>

            <tr>
              <td height="25" colspan="4" align="left" class="txt" style="
                  border-bottom: 2px solid black;
                  font-size: 13px;
                  font-family: 'Muli', sans-serif;
                  color: red;
                "></td>
              &nbsp;
            </tr>

            <br />
            <tr>
              <td colspan="3">
                <table style="width: 100%" border="0" cellpadding="0" cellspacing="0" class="tb1">
                  <tbody>
                    <tr>
                      <td>
                        <table style="border: 1px solid #999999" width="75%" border="1" cellpadding="0" cellspacing="0"
                          class="tb2">
                          <tbody>
                            <tr style="
                                font-size: 13px;
                                color: #000;
                                padding: 5px;
                                font-family: 'Muli', sans-serif;
                              ">
                              <td height="30" align="center" width="10%">
                                <strong>Sold To</strong>
                              </td>
                              <td align="center"></td>
                              <td align="center"></td>
                              <td align="center"></td>
                              <td align="center"></td>
                              <td align="center"></td>
                              <td align="center"></td>
                              <td align="center"></td>
                              <td align="center"></td>
                              <td align="center"></td>
                              <td align="center" width="10%">Invoice No.</td>
                              <td align="center"></td>
                              <td align="center"></td>
                            </tr>
                            <tr style="
                                font-size: 13px;
                                color: #000;
                                padding: 5px;
                                font-family: 'Muli', sans-serif;
                              ">
                              <td height="30" align="center" style="color: red">
                                <strong>Receipient Name</strong>
                              </td>
                              <td align="center" colspan="9">
                                {{ paymentRecoveryObj.payerName }}
                              </td>
                              <!-- <td align="center"></td>
                                    <td align="center"></td>
                                    <td align="center"></td>
                                    <td align="center"></td>
                                    <td align="center"></td>
                                    <td align="center"></td>
                                    <td align="center"></td>
                                    <td align="center"></td> -->
                              <td align="center" style="color: red">
                                Date of issue
                              </td>
                              <td align="center" colspan="2">
                                {{
                                    ISOtoDDMMYYComputed(
                                      paymentRecoveryObj.createdAt
                                    )
                                }}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                    </tr>

                    <tr>
                      <td>&nbsp;</td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>

            <tr>
              <td height="45" colspan="4" align="left" class="txt" style="
                  border-bottom: 1px solid #ddd;
                  font-size: 13px;
                  font-family: 'Muli', sans-serif;
                ">
                Recipient Address: {{ReceiptAddress}}
              </td>
            </tr>

            <tr>
              <td height="45" colspan="4" class="txt" style="
                  border-bottom: 1px solid #ddd;
                  font-size: 13px;
                  font-family: 'Muli', sans-serif;
                  color: red;
                ">
                <v-layout>
                  <v-flex xs9>
                    <span align="left">Place of Supply (along with state name) : {{ paymentRecoveryObj.salvageBuyerState }}</span></v-flex>

                  <span align="right">Place of Supply (State code) : 
                {{ paymentRecoveryObj.salvageBuyerGSTNumber && paymentRecoveryObj.salvageBuyerGSTNumber.slice(0, 2) }}</span>
                </v-layout>
              </td>
            </tr>

            <tr>
              <td height="25" colspan="4" align="left" class="txt" style="
                  border-bottom: 1px solid #ddd;
                  font-size: 13px;
                  font-family: 'Muli', sans-serif;
                  color: red;
                ">
                GSTIN of the recipient/ Unique ID* : {{ paymentRecoveryObj.salvageBuyerGSTNumber }}
              </td>
            </tr>

            <tr>
              <td height="25" colspan="4" align="left" class="txt" style="
                  border-bottom: 1px solid #ddd;
                  font-size: 13px;
                  font-family: 'Muli', sans-serif;
                ">
                PAN : {{panNumberForInvoice}}
              </td>
            </tr>

            <tr>
              <td height="25" colspan="4" align="left" class="txt" style="
                  border-bottom: 1px solid #ddd;
                  font-size: 13px;
                  font-family: 'Muli', sans-serif;
                ">
                Attention To : Contact Person {{ paymentRecoveryObj.payerName }}
              </td>
            </tr>

            <tr>
              <td height="25" colspan="4" align="left" class="txt" style="
                  border-bottom: 1px solid #ddd;
                  font-size: 13px;
                  font-family: 'Muli', sans-serif;
                ">
                Contact detail of the customer: {{contactDetail}}
              </td>
            </tr>

            <tr>
              <td height="25" colspan="4" align="left" class="txt" style="
                  border-bottom: 1px solid #ddd;
                  font-size: 13px;
                  font-family: 'Muli', sans-serif;
                  color: red;
                "></td>
            </tr>

            <tr>
              <!-- <td width="3%">&nbsp;</td> -->
              <td colspan="2">
                <table style="width: 100%" border="0" cellpadding="0" cellspacing="0" class="tb1">
                  <tbody>
                    <tr>
                      <td>
                        <table style="border: 1px solid #999999; width: 100%" border="1" cellpadding="0" cellspacing="0"
                          class="tb2">
                          <tbody>
                            <tr style="
                                font-size: 13px;
                                color: red;
                                padding: 5px;
                                font-family: 'Muli', sans-serif;
                              ">
                              <td height="30" align="center" width="10%">
                                <strong>S.N</strong>
                              </td>
                              <td align="center">
                                <strong>Description </strong>
                              </td>
                              <td align="center"><strong>HSN </strong></td>
                              <td align="center">
                                <strong>Taxable Value </strong>
                              </td>
                              <td align="center" colspan="2">
                                <strong>IGST </strong>
                              </td>
                              <td align="center" colspan="2">
                                <strong>CGST</strong>
                              </td>
                              <td align="center" colspan="2">
                                <strong>SGST</strong>
                              </td>
                              <td align="center" colspan="2">
                                <strong>CESS</strong>
                              </td>
                              <td align="center" colspan="2">
                                <strong>Total Value</strong>
                              </td>
                            </tr>
                            <tr style="
                                font-size: 13px;
                                color: red;
                                padding: 5px;
                                font-family: 'Muli', sans-serif;
                              ">
                              <td height="30" align="center"></td>
                              <td align="center"></td>
                              <td align="center"></td>
                              <td align="center"></td>
                              <td align="center">Rate</td>
                              <td align="center">Amount</td>
                              <td align="center">Rate</td>
                              <td align="center">Amount</td>
                              <td align="center">Rate</td>
                              <td align="center">Amount</td>
                              <td align="center">Rate</td>
                              <td align="center">Amount</td>
                              <td colspan="2"></td>
                            </tr>
                            <tr style="
                                font-size: 13px;
                                color: #000;
                                padding: 5px;
                                font-family: 'Muli', sans-serif;
                              " v-for="(
                                obj, index
                              ) in paymentRecoveryObj.recoveryInformation" :key="index">
                              <td height="30" align="center">
                                {{ index + 1 }}
                              </td>
                              <td align="center">{{ obj.commodityName }}</td>
                              <td align="center">{{ obj.HSNSACCode }}</td>
                              <td align="center">{{ obj.amount }}</td>
                              <td align="center">{{ obj.taxRate }}</td>
                              <td align="center">{{ obj.IGST || 0 }}</td>
                              <td align="center">{{ obj.taxRate }}</td>
                              <td align="center">{{ obj.CGST || 0 }}</td>
                              <td align="center">{{ obj.taxRate }}</td>
                              <td align="center">{{ obj.SGST || 0 }}</td>
                              <td align="center">{{ obj.taxRate }}</td>
                              <td align="center">{{ obj.CESS || 0 }}</td>
                              <td colspan="2" align="center">
                                {{
                                    roundOffValue(obj.amount) +
                                    (roundOffValue(obj.IGST) || 0) +
                                    (roundOffValue(obj.CGST) || 0) +
                                    (roundOffValue(obj.SGST) || 0) +
                                    (roundOffValue(obj.CESS) || 0)
                                }}
                              </td>
                            </tr>
                            <tr style="
                                font-size: 13px;
                                color: #000;
                                padding: 5px;
                                font-family: 'Muli', sans-serif;
                              ">
                              <td height="30" align="center" colspan="2" width="28%;">
                                &nbsp;Total Amount Due
                              </td>

                              <td align="center">&nbsp;</td>
                              <td align="center"><strong></strong></td>
                              <td align="center">&nbsp;</td>
                              <td align="center">&nbsp;</td>
                              <td align="center">&nbsp;</td>
                              <td align="center">&nbsp;</td>
                              <td align="center">&nbsp;</td>
                              <td align="center">&nbsp;</td>
                              <td align="center">&nbsp;</td>
                              <td align="center">&nbsp;</td>
                              <td colspan="2" style="text-align: center">
                                {{ this.paymentRecoveryObj.netAmount }}
                              </td>
                            </tr>

                            <tr style="
                                font-size: 13px;
                                color: #000;
                                padding: 5px;
                                font-family: 'Muli', sans-serif;
                              ">
                              <td height="30" align="center" colspan="2">
                                &nbsp;Amount received in advance (if any)
                              </td>

                              <td align="center">&nbsp;</td>
                              <td align="center"><strong></strong></td>
                              <td align="center">&nbsp;</td>
                              <td align="center">&nbsp;</td>
                              <td align="center">&nbsp;</td>
                              <td align="center">&nbsp;</td>
                              <td align="center">&nbsp;</td>
                              <td align="center">&nbsp;</td>
                              <td align="center">&nbsp;</td>
                              <td align="center">&nbsp;</td>
                              <td colspan="2" align="center">&nbsp;</td>
                            </tr>

                            <tr style="
                                font-size: 13px;
                                color: #000;
                                padding: 5px;
                                font-family: 'Muli', sans-serif;
                              ">
                              <td height="30" align="center" colspan="2">
                                &nbsp;"Transaction ID No. ------- (Generated at
                                the time of payment of GST on advance)"
                              </td>

                              <td align="center">&nbsp;</td>
                              <td align="center"><strong></strong></td>
                              <td align="center">&nbsp;</td>
                              <td align="center">&nbsp;</td>
                              <td align="center">&nbsp;</td>
                              <td align="center">&nbsp;</td>
                              <td align="center">&nbsp;</td>
                              <td align="center">&nbsp;</td>
                              <td align="center">&nbsp;</td>
                              <td align="center">&nbsp;</td>
                              <td colspan="2" align="center">&nbsp;</td>
                            </tr>

                            <tr style="
                                font-size: 13px;
                                color: #000;
                                padding: 5px;
                                font-family: 'Muli', sans-serif;
                              ">
                              <td height="30" align="center" colspan="2">
                                &nbsp;Balance payable
                              </td>

                              <td align="center">&nbsp;</td>
                              <td align="center"><strong></strong></td>
                              <td align="center">&nbsp;</td>
                              <td align="center">&nbsp;</td>
                              <td align="center">&nbsp;</td>
                              <td align="center">&nbsp;</td>
                              <td align="center">&nbsp;</td>
                              <td align="center">&nbsp;</td>
                              <td align="center">&nbsp;</td>
                              <td align="center">&nbsp;</td>
                              <td colspan="2" align="center">&nbsp;</td>
                            </tr>

                            <tr style="
                                font-size: 13px;
                                color: #000;
                                padding: 5px;
                                font-family: 'Muli', sans-serif;
                              ">
                              <td height="25" align="left" colspan="13" style="color: red">
                                &nbsp;Total Invoice value (In figure)
                              </td>

                              <td style="text-align: center">
                                {{ this.paymentRecoveryObj.netAmount }}
                              </td>
                            </tr>

                            <tr style="
                                font-size: 13px;
                                color: #000;
                                padding: 5px;
                                font-family: 'Muli', sans-serif;
                              ">
                              <td height="25" align="left" colspan="13" style="color: red">
                                &nbsp;Total Invoice value (In words)
                              </td>

                              <td align="center">&nbsp;{{inWords(this.paymentRecoveryObj.netAmount)}}</td>
                            </tr>
                            <tr style="
                                font-size: 13px;
                                color: #000;
                                padding: 5px;
                                font-family: 'Muli', sans-serif;
                              ">
                              <td height="25" align="left" colspan="13" style="color: red">
                                &nbsp;Amount of tax subject to reverse charge
                              </td>

                              <td align="center">&nbsp;</td>
                            </tr>

                            <tr style="
                                font-size: 13px;
                                color: #000;
                                padding: 5px;
                                font-family: 'Muli', sans-serif;
                                font-weight: 600;
                              ">
                              <td height="25" style="text-align: left" colspan="14">
                                &nbsp;Remarks, if any :
                                {{ this.paymentRecoveryObj.remarks }}
                              </td>
                            </tr>
                            <tr style="
                                font-size: 13px;
                                color: #000;
                                padding: 5px;
                                font-family: 'Muli', sans-serif;
                                font-weight: 600;
                              ">
                              <td height="25" align="left" colspan="14">
                                &nbsp;Payment Details:
                              </td>
                            </tr>

                            <tr style="
                                font-size: 13px;
                                color: #000;
                                padding: 5px;
                                font-family: 'Muli', sans-serif;
                              ">
                              <td height="25" align="left" colspan="14">
                                &nbsp;Please credit the amount in our bank
                                account. Details are as follows:
                              </td>
                            </tr>

                            <tr style="
                                font-size: 13px;
                                color: #000;
                                padding: 5px;
                                font-family: 'Muli', sans-serif;
                              ">
                              <td align="right">1</td>
                              <td height="25" align="left" colspan="13">
                                Bank Name :
                                {{ this.paymentRecoveryObj.payeeBankName }}
                              </td>
                            </tr>

                            <tr style="
                                font-size: 13px;
                                color: #000;
                                padding: 5px;
                                font-family: 'Muli', sans-serif;
                              ">
                              <td align="right">2</td>
                              <td height="25" align="left" colspan="13">
                                Bank Branch :
                                {{ this.paymentRecoveryObj.payeeBankBranch }}
                              </td>
                            </tr>

                            <tr style="
                                font-size: 13px;
                                color: #000;
                                padding: 5px;
                                font-family: 'Muli', sans-serif;
                              ">
                              <td align="right">3</td>
                              <td height="25" align="left" colspan="13">
                                Account No:
                              </td>
                            </tr>

                            <tr style="
                                font-size: 13px;
                                color: #000;
                                padding: 5px;
                                font-family: 'Muli', sans-serif;
                              ">
                              <td align="right">4</td>
                              <td height="25" align="left" colspan="13">
                                IFSC :
                                {{ this.paymentRecoveryObj.payeeBankDetails }}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                    </tr>

                    <tr>
                      <td>&nbsp;</td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>

            <tr width="100%">
              <td height="32">&nbsp;</td>
              <td style="
                  font-size: 13px;
                  color: #000;
                  padding: 5px;
                  font-family: 'Muli', sans-serif;
                ">
                <strong> Declaration Company's PAN : AABCT3518Q</strong>
              </td>
            </tr>
            <tr width="100%" class="mb-20">
              <td height="32">&nbsp;</td>
              <td style="
                  font-size: 13px;
                  color: #000;
                  padding: 5px;
                  font-family: 'Muli', sans-serif;
                ">
                <strong>For : Tata AIG General Insurance Company limited</strong>
              </td>
            </tr>
            <tr width="100%" class="mt-20">
              <td height="32">&nbsp;</td>
              <td style="
                  font-size: 13px;
                  color: #000;
                  padding: 5px;
                  font-family: 'Muli', sans-serif;
                ">
                <strong> Authorised Signature</strong>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </v-form>
</template>

<script>
import moment from "moment";
import jsPDF from "jspdf";

export default {
  data() {
    return {
      IFSCCODE: "",
    };
  },

  props: {
    paymentRecoveryObj: Object,
    tabledata: Array,
    panNumberForInvoice: String,
    ReceiptAddress: String,
    contactDetail: String,
    supplierAddress: String,
    email:String,
  },

  methods: {
    inWords (num) {
        var a = ['','one ','two ','three ','four ', 'five ','six ','seven ','eight ','nine ','ten ','eleven ','twelve ','thirteen ','fourteen ','fifteen ','sixteen ','seventeen ','eighteen ','nineteen '];
        var b = ['', '', 'twenty','thirty','forty','fifty', 'sixty','seventy','eighty','ninety'];

        if ((num = num.toString()).length > 9) return 'overflow';
        let n
        n = ('000000000' + num).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
        if (!n) return; var str = '';
        str += (n[1] != 0) ? (a[Number(n[1])] || b[n[1][0]] + ' ' + a[n[1][1]]) + 'crore ' : '';
        str += (n[2] != 0) ? (a[Number(n[2])] || b[n[2][0]] + ' ' + a[n[2][1]]) + 'lakh ' : '';
        str += (n[3] != 0) ? (a[Number(n[3])] || b[n[3][0]] + ' ' + a[n[3][1]]) + 'thousand ' : '';
        str += (n[4] != 0) ? (a[Number(n[4])] || b[n[4][0]] + ' ' + a[n[4][1]]) + 'hundred ' : '';
        str += (n[5] != 0) ? ((str != '') ? 'and ' : '') + (a[Number(n[5])] || b[n[5][0]] + ' ' + a[n[5][1]]) + 'only ' : '';
        return str;
    },
    ISOtoDDMMYYComputed(date) {
      if (date) {
        return moment(date).format("DD/MM/YYYY");
      } else {
        return date;
      }
    },

    convertHTMLToPDF() {
      this.exportWord();
      var doc = new jsPDF("l", "mm", [1200, 1500]);
      var pdfjs = document.querySelector("#panelPdf");
      doc.html(pdfjs, {
        callback: function (doc) {
          doc.save("GSTINVOICE.pdf");
        },
        x: 10,
        y: 10,
      });
    },

    roundOffValue(value) {
      if (value && !isNaN(parseFloat(value))) {
        return parseFloat(parseFloat(value).toFixed(2));
      }
      return 0;
    },

    exportWord() {
      this.showProgress();
      let htmlCode = document.getElementById("panelWord").innerHTML;
      var css =
        "<style>" +
        "@page WordSection1{size: 841.95pt 595.35pt;mso-page-orientation: landscape;}" +
        "div.WordSection1 {page: WordSection1;}" +
        "</style>";
      var word = `<html xmlns:o='urn:schemas-microsoft-com:office:office xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML to Word Document with JavaScript</title></head><body>
                  ${css + htmlCode}
                  </body></html>`;
      var source =
        "data:application/msword;charset=utf-8," + encodeURIComponent(word);
      var fileDownload = document.createElement("a");
      document.body.appendChild(fileDownload);
      fileDownload.href = source;
      let fileName = "GSTINVOICE";
      fileDownload.download = fileName + ".doc";
      fileDownload.click();
      document.body.removeChild(fileDownload);
      this.hideProgress();
    },
  },
};
</script>
